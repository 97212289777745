import styled from "styled-components";
export default function StaticTable({
  headers,
  rows,
  setRows,
  nameRows,
  disabled,
}: {
  headers: string[];
  rows: string[][];
  setRows: (rows: string[][]) => void;
  nameRows: string[];
  disabled?: boolean;
}) {
  function changeCell(row: number, column: number, value: string) {
    const newRows = [...rows];
    newRows[row][column] = value;
    setRows(newRows);
  }

  return (
    <TableContainer>
      <Table>
        <thead>
          <TRH>
            {headers.map((d: string, index: number) => (
              <TH key={index}>{d}</TH>
            ))}
          </TRH>
        </thead>
        <tbody>
          {rows.map((row: string[], indexRow: number) => (
            <TR key={indexRow}>
              {row.map((cell: string, indexCell: number) => (
                <TD key={indexCell}>
                  <Cell
                    value={cell}
                    type="text"
                    onChange={({ target: { value } }) =>
                      changeCell(indexRow, indexCell, value)
                    }
                    disabled={disabled}
                  />
                </TD>
              ))}
            </TR>
          ))}
        </tbody>
      </Table>
      <NamesRow>
        {nameRows.map((d: string, index: number) => (
          <NameRow key={index}>{d}</NameRow>
        ))}
      </NamesRow>
    </TableContainer>
  );
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2px;
  @media (max-width: 768px) {
    min-width: 900px;
  }
`;
const TableContainer = styled.div`
  width: 100%;
  padding-right: 24px;
  padding-left: 120px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const TR = styled.tr`
  position: relative;
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
`;
const TRH = styled.tr`
  :nth-child(even) {
    background-color: #f9f9f9;
  }
`;
const TD = styled.td`
  padding: 14px 0px 13px 20px;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  text-transform: capitalize;
`;
const TH = styled.th`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  padding: 23px 20px;
  color: #128297;
  text-transform: uppercase;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const Cell = styled.input`
  width: 100%;
  outline: none;
  border: none;
  padding: 8px;
  background: none;
  border-bottom: 1px dotted #128297;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
`;
const NamesRow = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 120px;
  display: flex;
  flex-direction: column;
`;
const NameRow = styled.div`
  width: 100%;
  padding: 14px 0px 13px 20px;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  min-height: 57px;
  text-transform: capitalize;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-height: 37px;
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
`;
