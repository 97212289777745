import styled from "styled-components";
import CheckIcon from "../../assets/img/checkPermissionIcon.svg";

export default function Checkbox({
  value,
  setValue,
  disabled,
  empty,
}: {
  value: boolean;
  setValue: (value: boolean) => void;
  disabled?: boolean;
  empty?: boolean;
}) {
  function handleCheckboxChange() {
    if (disabled) return;
    setValue(!value);
  }
  return (
    <CheckboxComponent empty={empty || false} onClick={handleCheckboxChange}>
      {value && <img src={CheckIcon} alt="check icon" />}
    </CheckboxComponent>
  );
}
const CheckboxComponent = styled.div<{ empty: boolean }>`
  width: 24px;
  height: 24px;
  border: 1px solid #424242;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  background-color: ${(props) => (props.empty ? "#bcbbbb" : "#fff")};
  z-index: 1;
`;
  