import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../commonHooks/useAxios";
import { Group, Orders } from "../Interfaces";
import { db } from "../../App";
import { doc, getDoc, writeBatch } from "firebase/firestore";
import { notificationsContext } from "../../context/notificationsContext";
import { userContext } from "../../context/userContext";

export default function useCertificationOrdersView() {
  const [order, setOrder] = useState<Orders>();
  const [loading, setLoading] = useState<boolean>(true);
  const getData = useAxios();
  const navigate = useNavigate();
  const { id } = useParams();
  const [opened, setOpened] = useState<number[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [obra, setObra] = useState<string>("");
  const [agent, setAgent] = useState<string>("");
  const [client, setClient] = useState<string>("");
  const [ubication, setUbication] = useState<string>("");
  const [percentageGroups, setpercentageGroups] = useState<number[]>([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const { setNotification } = useContext(notificationsContext);
  const [newObservation, setNewObservation] = useState<boolean>(false);
  const [percentageApprovedGroups, setpercentagApprovedeGroups] = useState<number[]>([])
  const [iconGroups, setIconGroups] = useState<boolean[]>([])
  const {
    userState: { user },
  } = useContext(userContext);
  useEffect(() => {
    async function fetchData() {
      const response: any = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/orders/${id}`,
        headers: {
          accept: "*/*",
        },
      });
      setObra(response.work || "");
      setAgent(response.agent || "");
      setClient(response.client || "");
      setUbication(response.ubication || "");
      setOrder(response);
    }
    async function getQuestionnaire() {
      setLoading(true);
      const docRef = doc(db, "orders", `${id}`);
      // get doc with collection groups inside
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const groupCollection = docSnap.data().groups
        let newGroups: any[] = [];
        const groups = groupCollection.map((doc: any) => {
          return { ...doc };
        });
        for (let i = 0; i < groups.length; i++) {
          const questions = groups[i].questions.map((doc: any) => {
            const data = doc;
            if (data.type === "statictable" || data.type === "freetable") {
              data.rows = data.rows && JSON.parse(data.rows);
              if (typeof data.rows === 'string') {
                data.rows = JSON.parse(data.rows);
              }
            } else if (data.type === "date") {
              data.date = new Date(data.date);
            }
            return { ...data };
          });
          questions.sort((a: any, b: any) => a.order - b.order);
          newGroups.push({ ...groups[i], questions });
        }
        setGroups(newGroups.sort((a, b) => a.order - b.order));
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    if (id) {
      fetchData();
      getQuestionnaire();

    }
  }, [id]); // eslint-disable-line

  useEffect(() => {
    calculatePercentageGroups();
    calculatePercentageApprovedGroups();
  }, [groups]); // eslint-disable-line

  function calculatePercentageApprovedGroups(groupsRef: any = groups) {
    let newPercentageGroups: number[] = [];
    let iconGroups: boolean[] = [];
    for (let i = 0; i < groupsRef.length; i++) {
      let totalQuestions = groupsRef[i]?.questions?.length || 0;
      let totalApproved = 0;
      let icon = false;
      const questionsLength = groupsRef[i]?.questions?.length || 0;
      for (let j = 0; j < questionsLength; j++) {
        const question = groupsRef[i]?.questions?.[j];
        if (question?.status === "aprobado") {
          totalApproved++;
        }
        if (question?.status !== "aprobado" && question?.observations) {
          icon = true;
        }
      }
      const percentage = Math.round((totalApproved / totalQuestions) * 100);
      if (isNaN(percentage)) {
        if (questionsLength === 0) {
          newPercentageGroups.push(100);
        } else {
          newPercentageGroups.push(0);
        }
      } else
        newPercentageGroups.push(percentage);
      iconGroups.push(icon);
    }
    setpercentagApprovedeGroups(newPercentageGroups)
    setIconGroups(iconGroups)
  }

  function calculatePercentageGroups(groupsRef: any = groups) {
    let newPercentageGroups: number[] = [];
    for (let i = 0; i < groupsRef.length; i++) {
      let totalQuestions = groupsRef[i]?.questions?.length || 0;
      let totalAnswered = 0;
      const questionsLength = groupsRef[i]?.questions?.length || 0;
      for (let j = 0; j < questionsLength; j++) {
        const question = groupsRef[i]?.questions?.[j];
        if (question?.link || question?.number || question?.pdfname || question?.value || (question?.date && !question?.empty)) {
          totalAnswered++;
        }
        if (question.type === 'statictable') {
          const rows = question.rows
          const cells = rows.map((row: any) => row.map((cell: any) => cell)).flat()
          if (cells.every((cell: any) => cell)) {
            totalAnswered++;
          }
        }
        if (question.type === 'checkbox') {
          if (!question?.empty) {
            totalAnswered++;
          }
        }
        if (question.type === 'freetable') {
          const rows = question?.rows
          const cells = rows?.map((row: any) => row.map((cell: any) => cell)).flat()
          if (rows?.length && cells?.every((cell: any) => cell)) {
            totalAnswered++;
          }
        }
      }
      const percentage = Math.round((totalAnswered / totalQuestions) * 100);
      if (isNaN(percentage)) {
        if (questionsLength === 0) {
          newPercentageGroups.push(100);
        } else {
          newPercentageGroups.push(0);
        }
      } else
        newPercentageGroups.push(percentage);
    }
    const completed = newPercentageGroups.every((percentage) => percentage === 100);
    setIsCompleted(completed);
    setpercentageGroups(newPercentageGroups)
  }


  async function handleSave() {
    setLoading(true);
    const groupsRef = doc(db, "orders", `${id}`);
    const batch = writeBatch(db);
    let groupsNew = [];
    for (let i = 0; i < groups.length; i++) {
      const questionsCopy = groups?.[i]?.questions?.map((question: any) => {
        return { ...question };
      });
      const questions = questionsCopy?.map((question: any) => {
        if (question.type === "statictable" || question.type === "freetable") {
          question.rows = JSON.stringify(question.rows || []);
        } else if (question.type === "date") {
          question.date = question.date.toISOString();
        }
        return { ...question };
      });
      groupsNew.push({ ...groups[i], questions });
    }
    batch.update(groupsRef, {
      groups: groupsNew,
      work: obra,
      agent,
      client,
      ubication,
    });
    await batch.commit();
    setNotification({
      theme: "success",
      titleNotification: "Guardado",
      contentNotification: "Orden guardada correctamente",
      isVisible: true,
    });
    if (user?.roles_id === 5) {
      navigate(`/certification/ownorders`);
    } else {
      navigate(`/certification/orders`);
    }
    setLoading(false);
  }
  async function handleSend() {
    setLoading(true);
    const groupsRef = doc(db, "orders", `${id}`);
    const batch = writeBatch(db);
    let groupsNew = [];
    for (let i = 0; i < groups.length; i++) {
      const questionsCopy = groups?.[i]?.questions?.map((question: any) => {
        return { ...question };
      });
      const questions = questionsCopy?.map((question: any) => {
        if (question.type === "statictable" || question.type === "freetable") {
          question.rows = JSON.stringify(question.rows || []);
        } else if (question.type === "date") {
          question.date = question.date.toISOString();
        }
        return { ...question };
      });
      groupsNew.push({ ...groups[i], questions });
    }
    batch.update(groupsRef, {
      groups: groupsNew,
      work: obra,
      agent,
      client,
      ubication,
      state: "enviada",
      sended: true
    });
    await batch.commit();
    if (
      newObservation
    ) {
      const response: any = await getData({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/orders/sendmail`,
        headers: {
          accept: "*/*",
        },
        data: {
          orderId: order?.orders_id,
          type: order?.type,
          work: order?.work,
          agent_name: order?.agent_name,
          certifier_id: order?.certifier_id,
          recipient_id: order?.certifier_id,
        }
      });
    }
    if (!order?.sended) {
      const dataMail = {
        orderId: order?.orders_id,
        agent_name: order?.agent_name,
        certifier_id: order?.certifier_id,
        agents_id: order?.agents_id,
        type: order?.type,
      }
      await getData({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/orders/sentocertifier`,
        headers: {
          accept: "*/*",
        },
        data: dataMail,
      });
    }
    setNotification({
      theme: "success",
      titleNotification: "Enviada",
      contentNotification: "Orden enviada correctamente",
      isVisible: true,
    });
    if (user?.roles_id === 5) {
      navigate(`/certification/ownorders`);
    } else {
      navigate(`/certification/orders`);
    }
    setLoading(false);
  }

  function toggleGroup(id: number) {
    if (opened.includes(id)) {
      setOpened(opened.filter((item) => item !== id));
    } else {
      setOpened([...opened, id]);
    }
  }


  return {
    loading,
    groups,
    opened,
    toggleGroup,
    navigate,
    obra,
    setObra,
    agent,
    setAgent,
    client,
    setClient,
    ubication,
    setUbication,
    setGroups,
    order,
    handleSave,
    percentageGroups,
    calculatePercentageGroups,
    isCompleted,
    handleSend,
    setNewObservation,
    percentageApprovedGroups,
    iconGroups
  };
}
