import { useEffect, useState, useContext } from "react";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";

export default function useRolesForm({
  roleIdToEdit,
  setRoleIdToEdit,
  setRefreshRoles,
  setCreateNewRole,
}: {
  roleIdToEdit?: number;
  setRoleIdToEdit: (roleIdToEdit: number | null) => void;
  setRefreshRoles: (refresh: boolean) => void;
  setCreateNewRole: (createNewRole: boolean) => void;
}) {
  const [rol, setRol] = useState<string>("");
  const [usersConfig, setUsersConfig] = useState<boolean>(false);
  const [permissionsConfig, setPermissionsConfig] = useState<boolean>(false);
  const [bindAgents, setBindAgents] = useState<boolean>(false);
  const [adminAgents, setAdminAgents] = useState<boolean>(false);
  const [impersonate, setImpersonate] = useState<boolean>(false);
  const [initialRoleName, setInitialRoleName] = useState<string>("");
  const [mail_new_agent, setMail_new_agent] = useState<boolean>(false);
  const [mail_suspended_agent, setMail_suspended_agent] =
    useState<boolean>(false);
  const [mail_activated_agent, setMail_activated_agent] =
    useState<boolean>(false);
  const [mail_inactivated_agent, setMail_inactivated_agent] =
    useState<boolean>(false);
  const [agent_to_pending, setAgent_to_pending] = useState<boolean>(false);
  const [agent_to_suspended, setAgent_to_suspended] = useState<boolean>(false);
  const [agent_to_activated, setAgent_to_activated] = useState<boolean>(false);
  const [agent_to_inactivated, setAgent_to_inactivated] =
    useState<boolean>(false);
  const [allAgentStatusPermissions, setAllAgentStatusPermissions] =
    useState<boolean>(false);
  const [showAllPermisions, setShowAllPermisions] = useState<boolean>(false);
  const [showUsersPermissions, setShowUsersPermissions] =
    useState<boolean>(false);
  const [allUsersPermissions, setAllUsersPermissions] =
    useState<boolean>(false);
  const [showAgentsPermissiones, setShowAgentsPermissiones] =
    useState<boolean>(false);
  const [allAgentsPermissions, setAllAgentsPermissions] =
    useState<boolean>(false);
  const [showDocumentsPermissions, setShowDocumentsPermissions] = useState<boolean>(false);
  const [allDocumentsPermissions, setAllDocumentsPermissions] = useState<boolean>(false);
  const [see_users, setSee_users] = useState<boolean>(false);
  const [edit_users, setEdit_users] = useState<boolean>(false);
  const [enable_users, setEnable_users] = useState<boolean>(false);
  const [create_agent, setCreate_agent] = useState<boolean>(false);
  const [edit_agents, setEdit_agents] = useState<boolean>(false);
  const [coverage_zone, setCoverage_zone] = useState<boolean>(false);
  const [see_agent_users, setSee_agent_users] = useState<boolean>(false);
  const [see_logs, setSee_logs] = useState<boolean>(false);
  const [see_my_agent, setSee_my_agent] = useState<boolean>(false);
  const [export_agents, setExport_agents] = useState<boolean>(false);
  const [see_documents, setSee_documents] = useState<boolean>(false);
  const [edit_documents, setEdit_documents] = useState<boolean>(false);
  const [showLeadsPermissions, setShowLeadsPermissions] = useState<boolean>(false);
  const [allLeadsPermissions, setAllLeadsPermissions] = useState<boolean>(false);
  const [see_leads, setSee_leads] = useState<boolean>(false);
  const [edit_leads, setEdit_leads] = useState<boolean>(false);
  const [showCertificationsPermissions, setShowCertificationsPermissions] = useState<boolean>(false);
  const [allCertificationsPermissions, setAllCertificationsPermissions] = useState<boolean>(false);
  const [edit_questionnaire, setEdit_questionnaire] = useState<boolean>(false);
  const [see_questionnaire, setSee_questionnaire] = useState<boolean>(false);
  const [create_order, setCreate_order] = useState<boolean>(false);
  const [see_order, setSee_order] = useState<boolean>(false);
  const [review_own_order, setReview_own_order] = useState<boolean>(false);
  const [approve_order, setApprove_order] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    rol?: string;
  }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const axiosData = useAxios();
  const { setNotification } = useContext(notificationsContext);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await axiosData({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}/roles/${roleIdToEdit}`,
          headers: {
            accept: "*/*",
          },
        });
        setRol(response.name);
        setInitialRoleName(response.name);
        setUsersConfig(response.users_config);
        setPermissionsConfig(response.permissions_config);
        setBindAgents(response.bind_agents);
        setAdminAgents(response.manage_agents);
        setImpersonate(response.impersonate);
        setMail_new_agent(response.mail_new_agent);
        setMail_suspended_agent(response.mail_suspended_agent);
        setMail_activated_agent(response.mail_activated_agent);
        setMail_inactivated_agent(response.mail_inactivated_agent);
        setAgent_to_pending(response.agent_to_pending);
        setAgent_to_suspended(response.agent_to_suspended);
        setAgent_to_activated(response.agent_to_activated);
        setAgent_to_inactivated(response.agent_to_inactivated);
        setSee_users(response.see_users);
        setEdit_users(response.edit_users);
        setEnable_users(response.enable_users);
        setCreate_agent(response.create_agent);
        setEdit_agents(response.edit_agents);
        setCoverage_zone(response.coverage_zone);
        setSee_agent_users(response.see_agent_users);
        setSee_logs(response.see_logs);
        setSee_my_agent(response.see_my_agent);
        setExport_agents(response.export_agents);
        setEdit_documents(response.edit_documents);
        setSee_documents(response.see_documents);
        setSee_leads(response.see_leads);
        setEdit_leads(response.edit_leads);
        setEdit_questionnaire(response.edit_questionnaire);
        setSee_questionnaire(response.see_questionnaire);
        setCreate_order(response.create_order);
        setSee_order(response.see_order);
        setReview_own_order(response.review_own_order);
        setApprove_order(response.approve_order);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    if (roleIdToEdit) {
      fetchData();
    }
  }, []); // eslint-disable-line

  function handleCancel() {
    setRoleIdToEdit(null);
    setCreateNewRole(false);
  }

  function handleShowUsersPermissions() {
    setShowUsersPermissions(!showUsersPermissions);
    setShowAllPermisions(false);
    setShowAgentsPermissiones(false);
    setShowDocumentsPermissions(false);
    setShowLeadsPermissions(false);
    setShowCertificationsPermissions(false);
  }

  function handleShowAgentsPermissiones() {
    setShowAgentsPermissiones(!showAgentsPermissiones);
    setShowAllPermisions(false);
    setShowUsersPermissions(false);
    setShowDocumentsPermissions(false);
    setShowLeadsPermissions(false);
    setShowCertificationsPermissions(false);
  }

  function handleShowAllPermisions() {
    setShowAllPermisions(!showAllPermisions);
    setShowUsersPermissions(false);
    setShowAgentsPermissiones(false);
    setShowDocumentsPermissions(false);
    setShowLeadsPermissions(false);
    setShowCertificationsPermissions(false);
  }

  function handleShowDocumentsPermissions() {
    setShowDocumentsPermissions(!showDocumentsPermissions);
    setShowAllPermisions(false);
    setShowUsersPermissions(false);
    setShowAgentsPermissiones(false);
    setShowLeadsPermissions(false);
    setShowCertificationsPermissions(false);
  }

  function handleShowLeadsPermissions() {
    setShowLeadsPermissions(!showLeadsPermissions);
    setShowAllPermisions(false);
    setShowUsersPermissions(false);
    setShowAgentsPermissiones(false);
    setShowDocumentsPermissions(false);
    setShowCertificationsPermissions(false);
  }

  function handleShowCertificationsPermissions() {
    setShowCertificationsPermissions(!showCertificationsPermissions);
    setShowAllPermisions(false);
    setShowUsersPermissions(false);
    setShowAgentsPermissiones(false);
    setShowDocumentsPermissions(false);
    setShowLeadsPermissions(false);
  }

  function handleUsersPermissions() {
    if (allUsersPermissions) {
      setSee_users(false);
      setEdit_users(false);
      setEnable_users(false);
      setImpersonate(false);
      setAllUsersPermissions(false);
    } else {
      setSee_users(true);
      setEdit_users(true);
      setEnable_users(true);
      setImpersonate(true);
      setAllUsersPermissions(true);
    }
  }

  function handleAgentsPermissions() {
    if (allAgentsPermissions) {
      setCreate_agent(false);
      setEdit_agents(false);
      setBindAgents(false);
      setAdminAgents(false);
      setCoverage_zone(false);
      setSee_agent_users(false);
      setSee_logs(false);
      setSee_my_agent(false);
      setAllAgentsPermissions(false);
      setExport_agents(false);
    } else {
      setCreate_agent(true);
      setEdit_agents(true);
      setBindAgents(true);
      setAdminAgents(true);
      setCoverage_zone(true);
      setSee_agent_users(true);
      setSee_logs(true);
      setSee_my_agent(true);
      setAllAgentsPermissions(true);
      setExport_agents(true);
    }
  }

  function handleDocumentPermissions() {
    if (allDocumentsPermissions) {
      setSee_documents(false);
      setEdit_documents(false);
      setAllDocumentsPermissions(false);
    } else {
      setSee_documents(true);
      setEdit_documents(true);
      setAllDocumentsPermissions(true);
    }
  }

  function handleStatusPermissions() {
    if (allAgentStatusPermissions) {
      setAgent_to_pending(false);
      setAgent_to_suspended(false);
      setAgent_to_activated(false);
      setAgent_to_inactivated(false);
      setMail_activated_agent(false);
      setMail_inactivated_agent(false);
      setMail_suspended_agent(false);
      setMail_new_agent(false);
      setAllAgentStatusPermissions(false);
    } else {
      setAgent_to_pending(true);
      setAgent_to_suspended(true);
      setAgent_to_activated(true);
      setAgent_to_inactivated(true);
      setMail_activated_agent(true);
      setMail_inactivated_agent(true);
      setMail_suspended_agent(true);
      setMail_new_agent(true);
      setAllAgentStatusPermissions(true);
    }
  }

  function handleLeadsPermissions() {
    if (allLeadsPermissions) {
      setSee_leads(false);
      setEdit_leads(false);
      setAllLeadsPermissions(false);
    } else {
      setSee_leads(true);
      setEdit_leads(true);
      setAllLeadsPermissions(true);
    }
  }

  function handleCertificationsPermissions() {
    if (allCertificationsPermissions) {
      setEdit_questionnaire(false);
      setSee_questionnaire(false);
      setCreate_order(false);
      setSee_order(false);
      setReview_own_order(false);
      setApprove_order(false);
      setAllCertificationsPermissions(false);
    } else {
      setEdit_questionnaire(true);
      setSee_questionnaire(true);
      setCreate_order(true);
      setSee_order(true);
      setReview_own_order(true);
      setApprove_order(true);
      setAllCertificationsPermissions(true);
    }
  }

  async function handleSubmit() {
    const errors: any = {};
    if (!rol) {
      setErrors((prev: any) => ({
        ...prev,
        rol: "El nombre del rol es requerido.",
      }));
      errors.rol = "El nombre del rol es requerido.";
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const data = {
        rol,
        usersConfig,
        permissionsConfig,
        bindAgents,
        adminAgents,
        impersonate,
        agent_to_activated,
        agent_to_inactivated,
        agent_to_pending,
        agent_to_suspended,
        mail_activated_agent,
        mail_inactivated_agent,
        mail_suspended_agent,
        mail_new_agent,
        see_users,
        edit_users,
        enable_users,
        create_agent,
        edit_agents,
        coverage_zone,
        see_agent_users,
        see_logs,
        see_my_agent,
        export_agents,
        edit_documents,
        see_documents,
        edit_leads,
        see_leads,
        edit_questionnaire,
        see_questionnaire,
        create_order,
        see_order,
        review_own_order,
        approve_order
      };
      if (roleIdToEdit) {
        try {
          const role = await axiosData({
            method: "PUT",
            url: `${process.env.REACT_APP_API_URL}/roles/${roleIdToEdit}`,
            headers: {
              accept: "*/*",
            },
            data,
          });
          if (role) {
            setLoading(false);
            setNotification({
              theme: "success",
              titleNotification: "Edición exitosa",
              contentNotification: "El rol se editó correctamente.",
              isVisible: true,
            });
            setRefreshRoles(true);
            setRoleIdToEdit(null);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      } else {
        try {
          const role = await axiosData({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/roles`,
            headers: {
              accept: "*/*",
            },
            data,
          });
          if (role) {
            setLoading(false);
            setNotification({
              theme: "success",
              titleNotification: "Rol creado",
              contentNotification: "El rol se creó correctamente.",
              isVisible: true,
            });
            setRefreshRoles(true);
            setCreateNewRole(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    } else {
      if (roleIdToEdit) {
        setNotification({
          theme: "error",
          titleNotification: "Edición fallida",
          contentNotification: errors[Object.keys(errors)[0]],
          isVisible: true,
        });
      } else {
        setNotification({
          theme: "error",
          titleNotification: "Creación fallida",
          contentNotification: errors[Object.keys(errors)[0]],
          isVisible: true,
        });
      }
    }
    setTimeout(() => {
      setErrors({});
    }, 3000);
  }

  return {
    rol,
    setRol,
    bindAgents,
    setBindAgents,
    adminAgents,
    setAdminAgents,
    impersonate,
    setImpersonate,
    errors,
    loading,
    handleSubmit,
    handleCancel,
    initialRoleName,
    mail_new_agent,
    setMail_new_agent,
    mail_suspended_agent,
    setMail_suspended_agent,
    mail_activated_agent,
    setMail_activated_agent,
    mail_inactivated_agent,
    setMail_inactivated_agent,
    agent_to_pending,
    setAgent_to_pending,
    agent_to_suspended,
    setAgent_to_suspended,
    agent_to_activated,
    setAgent_to_activated,
    agent_to_inactivated,
    setAgent_to_inactivated,
    allAgentStatusPermissions,
    handleStatusPermissions,
    showAllPermisions,
    showUsersPermissions,
    showAgentsPermissiones,
    see_users,
    setSee_users,
    edit_users,
    setEdit_users,
    enable_users,
    setEnable_users,
    create_agent,
    setCreate_agent,
    edit_agents,
    setEdit_agents,
    coverage_zone,
    setCoverage_zone,
    see_agent_users,
    setSee_agent_users,
    see_logs,
    setSee_logs,
    see_my_agent,
    setSee_my_agent,
    allUsersPermissions,
    handleUsersPermissions,
    handleAgentsPermissions,
    allAgentsPermissions,
    handleShowAllPermisions,
    handleShowAgentsPermissiones,
    handleShowUsersPermissions,
    export_agents,
    setExport_agents,
    see_documents,
    setSee_documents,
    edit_documents,
    setEdit_documents,
    handleDocumentPermissions,
    allDocumentsPermissions,
    handleShowDocumentsPermissions,
    showDocumentsPermissions,
    see_leads,
    setSee_leads,
    edit_leads,
    setEdit_leads,
    allLeadsPermissions,
    handleLeadsPermissions,
    handleShowLeadsPermissions,
    showLeadsPermissions,
    handleCertificationsPermissions,
    handleShowCertificationsPermissions,
    showCertificationsPermissions,
    edit_questionnaire,
    setEdit_questionnaire,
    see_questionnaire,
    setSee_questionnaire,
    create_order,
    setCreate_order,
    see_order,
    setSee_order,
    review_own_order,
    setReview_own_order,
    approve_order,
    setApprove_order
  };
}
