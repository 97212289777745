import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import Button from "../../commonComponents/Button";
import Modal from "../../commonComponents/Modal";
import useModalSelectUser from "./../hooks/useModalSelectUser";

export default function ModalSelectUser({
  inputUserSelect,
  setInputUserSelect,
  setArrayUsers,
  arrayUsers,
}: {
  inputUserSelect: number | null;
  setInputUserSelect: (user: number | null) => void;
  setArrayUsers: (users: string[]) => void;
  arrayUsers: any[];
}) {
  const { loading, searchByName, setsearchByName, users, handleUser } =
    useModalSelectUser({
      inputUserSelect,
      setInputUserSelect,
      setArrayUsers,
      arrayUsers,
    });
  return (
    <Modal type="edituser" title={"Selector de Usuarios"}>
      <Container>
        {loading ? (
          <Loading>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </Loading>
        ) : (
          <>
            {" "}
            <SearchContainer>
              <FieldContainer>
                <Input
                  required
                  value={searchByName}
                  onChange={({ target: { value } }) => setsearchByName(value)}
                />
                <Label>Buscar usuario por nombre</Label>
              </FieldContainer>
            </SearchContainer>
            <TableContainer>
              <Table>
                <TRH>
                  <TH>Nombre</TH>
                  <TH>Apellido</TH>
                  <TH>E-mail</TH>
                </TRH>
              </Table>
              <Table>
                <tbody>
                  {users?.map((user: any) => (
                    <TR
                      key={user.users_id}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleUser(user)}
                    >
                      <TD>{user.first_name}</TD>
                      <TD>{user.last_name}</TD>
                      <TD>{user.email}</TD>
                    </TR>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          </>
        )}

        <ButtonContainer>
          <Button type={"primary"} onClick={() => setInputUserSelect(null)}>
            CERRAR
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
}
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const Container = styled.div`
  width: 760px;
  height: 556px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    padding: 10px 10px;
    height: auto;
  }
`;
const TableContainer = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    align-items: flex-start;
    height: 300px;
  }
`;
const SearchContainer = styled.div`
  width: 400px;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 40px;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    flex-direction: column;
    height: auto;
    gap: 20px;
  }
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2px;
  table-layout: fixed;
  @media (max-width: 768px) {
    min-width: 500px;
  }
`;
const TRH = styled.tr``;

const TR = styled.tr`
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
  :hover {
    background-color: #1283971d;
  }
`;
const TD = styled.td`
  padding: 14px 0px 13px 20px;
  font-size: 13px;
  line-height: 12px;
  color: #424242;
`;
const TH = styled.th`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  padding: 23px 20px;
  color: #128297;
  text-transform: uppercase;
  text-align: start;
  :last-of-type {
    text-align: end;
    width: 150px;
  }
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  @media (max-width: 768px) {
    height: 300px;
  }
`;
const FieldContainer = styled.div`
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    width: 80%;
    flex-direction: column;
  }
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #424242;
  height: 55px;
  border-radius: 5px;
  border-radius: 5px;
  outline: none;
  padding: 20px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const Label = styled.label`
  font-size: 15px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 11.5px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      line-height: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
