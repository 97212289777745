import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import useRolesAndPermissions from "../hooks/useRolesAndPermissions";
import RolesIcon from "../../assets/img/rolesIcon.svg";
import EditRole from "../../assets/img/editRole.svg";
import TrashIcon from "../../assets/img/trashIcon.svg";
import Pagination from "../../commonComponents/Pagination";
import AddIcon from "../../assets/img/addIcon.svg";
import Button from "./../../commonComponents/Button";
import RolesForm from "../components/RolesForm";
import { Role } from "../Interfaces";
import Modal from "../../commonComponents/Modal";
import Tooltip from "./../../commonComponents/Tooltip";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
export default function RolesAndPermissions() {
  const {
    roles,
    loading,
    handleRolesPerPage,
    page,
    totalPages,
    rolesPerPage,
    handlePage,
    setRoleIdToEdit,
    roleIdToEdit,
    setRefreshRoles,
    createNewRole,
    setCreateNewRole,
    setRoleIdToDelete,
    roleToDelete,
    setRoleToDelete,
    deleteRole,
    loadingDelete,
    handleSort,
    orderSorted,
    fieldSorted,
  } = useRolesAndPermissions();

  return (
    <Container>
      <Title>Roles y Permisos</Title>
      <MainContainer>
        <TopContainer>
          <IconContainer>
            <img src={RolesIcon} alt="usersIcon" />
          </IconContainer>
        </TopContainer>
        <CreateRoleContainer>
          <Button type="primary" onClick={() => setCreateNewRole(true)}>
            <ButtonTextContainer>
              <img src={AddIcon} alt="add icon" /> NUEVO ROL
            </ButtonTextContainer>
          </Button>
        </CreateRoleContainer>
        <TableContainer>
          <Table>
            <thead>
              <TRH>
                <TH onClick={() => handleSort("roles_id")}>
                  <THContainer>
                    Id
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "roles_id" && orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "roles_id" && orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("name")}>
                  <THContainer>
                    Nombre Rol
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "name" && orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "name" && orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH>Acciones</TH>
              </TRH>
            </thead>
            <tbody>
              {roles?.map((role: Role) => (
                <TR key={role.roles_id}>
                  <TD>{role.roles_id}</TD>
                  <TD>{role.name}</TD>
                  <TD>
                    <ActionsContainer>
                      {role.is_editable && (
                        <Tooltip text="Editar Rol">
                          <EditButton
                            onClick={() => setRoleIdToEdit(role.roles_id)}
                          >
                            <img src={EditRole} alt="view Icon" />
                          </EditButton>
                        </Tooltip>
                      )}
                      {role.is_removable && (
                        <Tooltip text="Eliminar Rol">
                          <EditButton onClick={() => setRoleIdToDelete(role)}>
                            <img src={TrashIcon} alt="view Icon" />
                          </EditButton>
                        </Tooltip>
                      )}
                    </ActionsContainer>
                  </TD>
                </TR>
              ))}
            </tbody>
          </Table>
        </TableContainer>
        {loading && (
          <>
            <Loading>
              <CircularProgress
                sx={{
                  color: "#128297",
                }}
                size={100}
              />
            </Loading>
          </>
        )}
        <PaginationContainer>
          <SelectRolesPerPageContainer>
            <SelectRolesPerPage>
              <SelectRolesPerPageField
                value={rolesPerPage}
                onChange={({ target: { value } }) => handleRolesPerPage(+value)}
              >
                <OptionPage value={5}>5 filas</OptionPage>
                <OptionPage value={10}>10 filas</OptionPage>
                <OptionPage value={20}>20 filas</OptionPage>
                <OptionPage value={50}>50 filas</OptionPage>
              </SelectRolesPerPageField>
            </SelectRolesPerPage>
          </SelectRolesPerPageContainer>
          <Pagination
            page={page}
            handlePage={handlePage}
            totalPages={totalPages}
          />
        </PaginationContainer>
      </MainContainer>
      {roleIdToEdit && (
        <RolesForm
          roleIdToEdit={roleIdToEdit}
          setRoleIdToEdit={setRoleIdToEdit}
          setRefreshRoles={setRefreshRoles}
          setCreateNewRole={setCreateNewRole}
        />
      )}
      {createNewRole && (
        <RolesForm
          setCreateNewRole={setCreateNewRole}
          setRoleIdToEdit={setRoleIdToEdit}
          setRefreshRoles={setRefreshRoles}
        />
      )}
      {roleToDelete && (
        <Modal type="roles" title={roleToDelete.name}>
          <ModalContainer
            style={{
              justifyContent: loadingDelete ? "center" : "space-between",
            }}
          >
            {loadingDelete ? (
              <Loading>
                <CircularProgress
                  sx={{
                    color: "#128297",
                  }}
                  size={100}
                />
              </Loading>
            ) : (
              <>
                <DeleteRoleText>
                  Esta seguro que desea eliminar el rol {roleToDelete.name}?
                </DeleteRoleText>
                <ButtonsModal>
                  <Button
                    type="secondary"
                    onClick={() => setRoleToDelete(null)}
                  >
                    VOLVER
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => deleteRole(roleToDelete)}
                  >
                    ACEPTAR
                  </Button>
                </ButtonsModal>
              </>
            )}
          </ModalContainer>
        </Modal>
      )}
    </Container>
  );
}
const THContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  color: #128297;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const ArrowsIcon = styled.div`
  display: flex;
  flex-direction: row;
  transform: rotateZ(90deg);
`;
const Container = styled.div`
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;
const ModalContainer = styled.div`
  width: 450px;
  height: 331px;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
  }
`;
const ButtonsModal = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
  gap: 20px;
`;
const DeleteRoleText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #3c4858;
  margin-top: 110px;
  @media (max-width: 768px) {
    text-align: center;
  }
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  margin-top: 38px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
  }
`;
const CreateRoleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 60px 10px 25px 10px;
  margin-bottom: 60px;
  position: relative;
  @media (max-width: 768px) {
    margin-top: 40px;
    padding: 30px 10px 25px 10px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: -17px;
    left: 10px;
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;
const TableContainer = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2px;
`;
const TR = styled.tr`
  height: 61px;
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
  @media (max-width: 768px) {
    height: 33px;
  }
`;
const TRH = styled.tr`
  height: 61px;
  :nth-child(even) {
    background-color: #f9f9f9;
  }
`;
const TD = styled.td`
  padding: 14px 0px 13px 20px;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
`;
const TH = styled.th`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  padding: 23px 20px;
  color: #128297;
  text-transform: uppercase;
  text-align: start;
  :last-of-type {
    text-align: end;
    width: 150px;
  }
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
const EditButton = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #128297;
  :hover {
    filter: brightness(90%);
  }
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
`;
const SelectRolesPerPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const SelectRolesPerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OptionPage = styled.option`
  font-size: 14px;
  color: #383838;
`;
const SelectRolesPerPageField = styled.select`
  width: 80px;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  padding: 8px 10px 8px 20px;
  font-size: 14px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  cursor: pointer;
`;
const ButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;
