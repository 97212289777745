import { useContext, useEffect } from "react";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";
import { useNavigate, useParams } from "react-router-dom";
import { userContext } from "../../context/userContext";
import { User } from "../../configs/Interfaces";
import { agentFormContext } from "../../context/agentFormContext";

export default function useAgentForm() {
  const {
    agentName,
    setAgentName,
    sameAgentName,
    tradeName,
    setTradeName,
    documentType,
    setDocumentType,
    documentNumber,
    setDocumentNumber,
    taxStreet,
    setTaxStreet,
    taxCity,
    setTaxCity,
    taxProvince,
    setTaxProvince,
    contactName,
    setContactName,
    contactPhone,
    setContactPhone,
    contactPhoneOptional,
    setContactPhoneOptional,
    contactEmail,
    setContactEmail,
    operationalStreet,
    setOperationalStreet,
    operationalAdressNumber,
    setOperationalAdressNumber,
    operationalCity,
    setOperationalCity,
    operationalProvince,
    setOperationalProvince,
    active,
    setActive,
    emailEECC,
    setEmailEECC,
    setLoading,
    agent,
    setAgent,
    currentStep,
    totalSteps,
    setTotalSteps,
    arrayUsers,
    setArrayUsers,
    setErrors,
    setLoadingSubmit,
    agents_id,
    setAgents_id,
    latitude,
    setLatitude,
    longitude,
    setLongitude,
    setPolygonCoverageZone,
    setRadiusCoverageZone,
    setRadius,
    radius,
    observations,
    setObservations,
    polygonPoints,
    setNameEECC,
    setLastNameEECC,
    setCategories,
    setCategory,
    category,
    setInstalator,
    instalator,
    setLogo,
    imageLogo,
    website,
    setWebsite,
    instagram,
    setInstagram,
    scope,
    setScope,
    has_showroom,
    setHas_showroom
  } = useContext(agentFormContext);
  const { agentId } = useParams();
  const sendData = useAxios();
  const { setNotification } = useContext(notificationsContext);
  const {
    userState: { user },
    setUserState,
  } = useContext(userContext);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      setLoadingSubmit(true);
      const categoriesResponse: any[] = await sendData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/categories`,
        headers: {
          accept: "*/*",
        },
      });
      categoriesResponse.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      setCategories(categoriesResponse);
      const response: any = await sendData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/agents/${agentId}`,
        headers: {
          accept: "*/*",
        },
      });
      setAgent(response);
      setAgents_id(response.agents_id);
      setAgentName(response.agent_name);
      setTradeName(response.trade_name);
      setDocumentType(response.document_type);
      setDocumentNumber("" + response.document_number);
      setTaxStreet(response.tax_address);
      setTaxCity(response.tax_city);
      setTaxProvince(response.tax_province);
      setContactName(response.contact_name);
      setContactPhone("" + response.contact_phone);
      setContactPhoneOptional(response.contact_phone_optional ?? "");
      setContactEmail(response.contact_email);
      setOperationalStreet(response.operational_street);
      setOperationalAdressNumber("" + response.operational_address_number);
      setOperationalCity(response.operational_city);
      setOperationalProvince(response.operational_province);
      setActive(response.active);
      setEmailEECC(response.EECC?.email ?? "");
      setNameEECC(response.EECC?.first_name ?? "");
      setLastNameEECC(response.EECC?.last_name ?? "");
      setLatitude(response.latitude);
      setLongitude(response.longitude);
      setObservations(response.observations ?? "");
      setInstalator(response.instalator);
      setLogo(response.logo);
      setWebsite(response.website);
      setInstagram(response.instagram);
      setScope(response.scope);
      setHas_showroom(response.has_showroom);
      if (response.categories_id) {
        setCategory(response.categories_id);
      }
      if (response.radius) {
        setRadius(response.radius);
        setRadiusCoverageZone(true);
        setPolygonCoverageZone(false);
      } else {
        setRadiusCoverageZone(false);
        setPolygonCoverageZone(true);
      }
      const copyArrayUsers = [...arrayUsers];
      response.agentUsers.forEach((user: User, index: number) => {
        copyArrayUsers[index] = {
          users_id: user.users_id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        };
      });
      setArrayUsers(copyArrayUsers);
      setLoading(false);
      setLoadingSubmit(false);
    }

    async function fetchIdAndCategories() {
      setLoading(true);
      setLoadingSubmit(true);
      const response: any = await sendData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/generateId`,
        headers: {
          accept: "*/*",
        },
      });
      const categoriesResponse: any[] = await sendData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/categories`,
        headers: {
          accept: "*/*",
        },
      });
      categoriesResponse.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (a.name < b.name) {
          return -1;
        }
        return 0;
      });
      setCategories(categoriesResponse);
      setAgents_id(response.agents_id);
      setLoading(false);
      setLoadingSubmit(false);
    }

    if (agentId) {
      fetchData();
    } else {
      fetchIdAndCategories();
    }
    if (user?.roles_id !== 1 && user?.roles_id !== 2 && user?.roles_id !== 3) {
      const newArrayUsers = [...arrayUsers];
      newArrayUsers[0] = {
        users_id: user?.users_id,
        first_name: user?.first_name,
        last_name: user?.last_name,
        email: user?.email,
      };
      setArrayUsers(newArrayUsers);
    }
    if (user?.roles_id === 1 || user?.roles_id === 2 || user?.roles_id === 3) {
      setTotalSteps(4);
    } else if (
      user?.roles_id !== 1 &&
      user?.roles_id !== 2 &&
      user?.roles_id !== 3 &&
      user?.role?.coverage_zone
    ) {
      setTotalSteps(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId]);

  async function submitForm({
    errors = {},
    polygon_points = polygonPoints,
  }: {
    errors?: any;
    polygon_points?: any;
  }) {
    if (Object.keys(errors).length === 0) {
      if (agentId) {
        try {
          setLoadingSubmit(true);
          const agent = await sendData({
            method: "PUT",
            url: `${process.env.REACT_APP_API_URL}/agents/${agentId}`,
            headers: {
              accept: "*/*",
            },
            data: {
              agents_id,
              agent_name: agentName,
              trade_name: sameAgentName ? agentName : tradeName,
              city: taxCity,
              province: taxProvince,
              tax_address: taxStreet,
              tax_city: taxCity,
              tax_province: taxProvince,
              document_type: documentType,
              document_number: documentNumber,
              contact_name: contactName,
              contact_phone: contactPhone,
              contact_phone_optional: contactPhoneOptional,
              contact_email: contactEmail,
              operational_street: operationalStreet,
              operational_address_number: operationalAdressNumber,
              operational_city: operationalCity,
              operational_province: operationalProvince,
              users_id: user?.users_id,
              EECC_email: emailEECC,
              active,
              agentUsers: arrayUsers,
              latitude,
              longitude,
              radius: radius === "" ? null : radius,
              observations,
              categories_id: category,
              instalator,
              website,
              instagram,
              scope,
              has_showroom,
            },
          });
          if (agent) {
            await sendData({
              method: "PUT",
              url: `${process.env.REACT_APP_API_URL}/coveragezone/`,
              headers: {
                accept: "*/*",
              },
              data: {
                polygon_points,
                agents_id: agent.agents_id,
              },
            });
            if (imageLogo) {
              const formData = new FormData();
              formData.append("image", imageLogo);
              formData.append("agents_id", agent.agents_id);
              await sendData({
                method: "POST",
                url: `${process.env.REACT_APP_API_URL}/uploadLogo`,
                headers: {
                  accept: "*/*",
                },
                data: formData,
              });
            }
            setLoadingSubmit(false);
            setNotification({
              theme: "success",
              titleNotification: "Distribuidor Editado",
              contentNotification: "El distribuidor se ha editado correctamente.",
              isVisible: true,
            });
            if (
              user?.roles_id !== 1 &&
              user?.roles_id !== 2 &&
              user?.roles_id !== 3
            ) {
              setUserState(
                (prev: {
                  user: User | undefined;
                  originalUser: User | undefined;
                }) => ({
                  ...prev,
                  user: {
                    ...prev.user,
                    agent,
                  },
                })
              );
              navigate("/my-agent");
            } else {
              navigate("/admin-agents");
            }
          } else {
            setNotification({
              theme: "error",
              titleNotification: "Edición Fallida",
              contentNotification: "Error al editar el distribuidor.",
              isVisible: true,
            });
          }
        } catch (error) {
          setNotification({
            theme: "error",
            titleNotification: "Creación Fallida",
            contentNotification: "Error al editar el distribuidor.",
            isVisible: true,
          });
        }
      } else {
        try {
          setLoadingSubmit(true);
          const agent = await sendData({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/agents`,
            headers: {
              accept: "*/*",
            },
            data: {
              agents_id,
              agent_name: agentName,
              trade_name: sameAgentName ? agentName : tradeName,
              city: taxCity,
              province: taxProvince,
              tax_address: taxStreet,
              tax_city: taxCity,
              tax_province: taxProvince,
              document_type: documentType,
              document_number: documentNumber,
              contact_name: contactName,
              contact_phone: contactPhone,
              contact_phone_optional: contactPhoneOptional,
              contact_email: contactEmail,
              operational_street: operationalStreet,
              operational_address_number: operationalAdressNumber,
              operational_city: operationalCity,
              operational_province: operationalProvince,
              users_id: user?.users_id,
              EECC_email: user?.isEECC ? user?.email : emailEECC,
              active,
              agentUsers: arrayUsers,
              creator_first_name: user?.first_name,
              creator_last_name: user?.last_name,
              creator_email: user?.email,
              latitude,
              longitude,
              radius: radius === "" ? null : radius,
              observations,
              categories_id: category,
              instalator,
              website,
              instagram,
              scope,
              has_showroom,
            },
          });
          if (agent) {
            await sendData({
              method: "PUT",
              url: `${process.env.REACT_APP_API_URL}/coveragezone/`,
              headers: {
                accept: "*/*",
              },
              data: {
                polygon_points,
                agents_id: agent.agents_id,
              },
            });
            if (imageLogo) {
              const formData = new FormData();
              formData.append("image", imageLogo);
              formData.append("agents_id", agent.agents_id);
              await sendData({
                method: "POST",
                url: `${process.env.REACT_APP_API_URL}/uploadLogo`,
                headers: {
                  accept: "*/*",
                },
                data: formData,
              });
            }
            setLoadingSubmit(false);
            setNotification({
              theme: "success",
              titleNotification: "Distribuidor Creado",
              contentNotification: "Se ha creado un nuevo distribuidor.",
              isVisible: true,
            });
            if (
              user?.roles_id !== 1 &&
              user?.roles_id !== 2 &&
              user?.roles_id !== 3
            ) {
              setUserState(
                (prev: {
                  user: User | undefined;
                  originalUser: User | undefined;
                }) => ({
                  ...prev,
                  user: {
                    ...prev.user,
                    agent,
                  },
                })
              );
              navigate("/my-agent");
            } else {
              navigate("/admin-agents");
            }
          } else {
            setNotification({
              theme: "error",
              titleNotification: "Creación Fallida",
              contentNotification: "Error al crear un nuevo distribuidor.",
              isVisible: true,
            });
          }
        } catch (error) {
          setNotification({
            theme: "error",
            titleNotification: "Creación Fallida",
            contentNotification: "Error al crear un nuevo distribuidor.",
            isVisible: true,
          });
        }
      }
    }
    setTimeout(() => {
      setErrors({});
    }, 3000);
  }

  return {
    user,
    agent,
    currentStep,
    totalSteps,
    submitForm,
  };
}
