import { agentFormContext } from "../../context/agentFormContext";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { notificationsContext } from "../../context/notificationsContext";

export default function useTaxDataForm({
  submitForm,
}: {
  submitForm: (errors: {}) => void;
}) {
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [height, setHeight] = useState<number>(
    window.innerWidth < 768 ? 34 : 55
  );

  const {
    agentName,
    sameAgentName,
    tradeName,
    documentType,
    documentNumber,
    taxStreet,
    taxCity,
    taxProvince,
    contactName,
    contactPhone,
    contactEmail,
    setErrors,
    setCurrentStep,
    category,
    setImageLogo,
    setPreviewImage,
    imageLogo,
    agents_id,
  } = useContext(agentFormContext);
  const { agentId } = useParams();
  const navigate = useNavigate();
  const { setNotification } = useContext(notificationsContext);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setHeight(34);
    }
    window.addEventListener("resize", (e: any) => {
      if (e.target.window.innerWidth < 768) {
        setHeight(34);
      } else {
        setHeight(55);
      }
    });
    return () => {
      window.removeEventListener("resize", (e: any) => {
        if (e.target.window.innerWidth < 768) {
          setHeight(34);
        } else {
          setHeight(55);
        }
      });
    };
  }, []);

  useEffect(() => {
    if (
      agentName &&
      tradeName &&
      documentType &&
      documentNumber &&
      taxStreet &&
      taxCity &&
      taxProvince &&
      contactName &&
      contactPhone &&
      contactEmail &&
      category
    ) {
      setIsComplete(true);
    } else if (
      agentName &&
      sameAgentName &&
      documentType &&
      documentNumber &&
      taxStreet &&
      taxCity &&
      taxProvince &&
      contactName &&
      contactPhone &&
      contactEmail &&
      category
    ) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }, [
    agentName,
    sameAgentName,
    tradeName,
    documentType,
    documentNumber,
    taxStreet,
    taxCity,
    taxProvince,
    contactName,
    contactPhone,
    contactEmail,
    category
  ]);

  function handleCancel() {
    navigate(-1);
  }
  useEffect(() => {
    if (imageLogo) {
      if (imageLogo?.name !== agents_id) {
        const newImage = new File([imageLogo], agents_id, { type: imageLogo.type });
        setImageLogo(newImage);
      }
    }

  }, [imageLogo]);

  function validateForm() {
    const regexEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const errors: any = {};
    if (!agentName) {
      setErrors((prev: any) => ({
        ...prev,
        agentName: "La razón social es requerida.",
      }));
      errors.agentName = "La razón social es requerida.";
    }
    if (!tradeName && !sameAgentName) {
      setErrors((prev: any) => ({
        ...prev,
        tradeName: "El nombre comercial es requerido.",
      }));
      errors.tradeName = "El nombre comercial es requerido.";
    }
    if (!documentType) {
      setErrors((prev: any) => ({
        ...prev,
        documentType: "El tipo de documento es requerido.",
      }));
      errors.documentType = "El tipo de documento es requerido.";
    }
    if (!category) {
      setErrors((prev: any) => ({
        ...prev,
        category: "El tipo de distribuidor es requerido.",
      }));
      errors.category = "El tipo de distribuidor es requerido.";
    }
    if (!documentNumber) {
      setErrors((prev: any) => ({
        ...prev,
        documentNumber: "El número de documento es requerido.",
      }));
      errors.documentNumber = "El número de documento es requerido.";
    }
    if (!taxStreet) {
      setErrors((prev: any) => ({
        ...prev,
        taxStreet: "El domicilio fiscal es requerido.",
      }));
      errors.taxStreet = "El domicilio fiscal es requerido.";
    }
    if (!taxCity) {
      setErrors((prev: any) => ({
        ...prev,
        taxCity: "La localidad fiscal es requerida.",
      }));
      errors.taxCity = "La localidad fiscal es requerida.";
    }
    if (!taxProvince) {
      setErrors((prev: any) => ({
        ...prev,
        taxProvince: "La provincia fiscal es requerida.",
      }));
      errors.taxProvince = "La provincia fiscal es requerida.";
    }
    if (!contactName) {
      setErrors((prev: any) => ({
        ...prev,
        contactName: "El nombre del contacto principal es requerido.",
      }));
      errors.contactName = "El nombre del contacto principal es requerido.";
    }
    if (!contactPhone) {
      setErrors((prev: any) => ({
        ...prev,
        contactPhone: "El telefono principal es requerido.",
      }));
      errors.contactPhone = "El telefono principal es requerido.";
    }
    if (!regexEmail.test(String(contactEmail).toLowerCase())) {
      setErrors((prev: any) => ({
        ...prev,
        contactEmail: "El email debe ser válido.",
      }));
      errors.contactEmail = "El email debe ser válido.";
    }
    setTimeout(() => {
      setErrors({});
    }, 3000);
    return errors;
  }

  function handleNextPage() {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      setCurrentStep(2);
    } else {
      setNotification({
        theme: "error",
        titleNotification: "Registro fallido",
        contentNotification: errors[Object.keys(errors)[0]],
        isVisible: true,
      });
    }
  }

  function handleSubmit() {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      submitForm({});
    } else {
      setNotification({
        theme: "error",
        titleNotification: "Registro fallido",
        contentNotification: errors[Object.keys(errors)[0]],
        isVisible: true,
      });
    }
  }

  function changeLogo(e: any) {
    // cange file name
    setImageLogo(e.target.files[0]);

    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    }
  }

  return {
    handleNextPage,
    isComplete,
    height,
    agentId,
    handleCancel,
    handleSubmit,
    changeLogo,
  };
}
