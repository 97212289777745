import styled from "styled-components";
import UsersIcon from "../../assets/img/usersIcon.svg";
import ViewIcon from "../../assets/img/viewIcon.svg";
import AddIcon from "../../assets/img/addIcon.svg";
import ButtonComponent from "./../../commonComponents/Button";
import { useContext, useState } from "react";
import { userContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import BindingRequestModal from "../components/BindingRequestModal";
import Tooltip from "../../commonComponents/Tooltip";

export default function MyAgent() {
  const {
    userState: { user },
  } = useContext(userContext);
  const [showBindingRequestModal, setShowBindingRequestModal] = useState(false);
  const navigate = useNavigate();
  return (
    <Container>
      <Title>Mi Distribuidor</Title>
      <CreateAgentContainer>
        {!user?.agent && (
          <>
            <ButtonComponent
              type="primary"
              onClick={() => setShowBindingRequestModal(true)}
            >
              <ButtonTextContainer>VINCULAR DISTRIBUIDOR</ButtonTextContainer>
            </ButtonComponent>

            <ButtonComponent
              type="primary"
              onClick={() => navigate("/create-agent")}
            >
              <ButtonTextContainer>
                <img src={AddIcon} alt="add icon" /> NUEVO DISTRIBUIDOR
              </ButtonTextContainer>
            </ButtonComponent>
          </>
        )}
      </CreateAgentContainer>
      <MainContainer>
        <TopContainer>
          <IconContainer>
            <img src={UsersIcon} alt="usersIcon" />
          </IconContainer>
        </TopContainer>
        {(user?.binding_request_status === "autorizado" && user?.agent?.status === "autorizado" || (user?.email === user?.agent?.creator_email && user?.agent?.status === "autorizado") || (!user?.binding_request_status && user?.agent?.status === "autorizado" )) && ( // eslint-disable-line
              <>
                <TableContainer>
                  <Table>
                    <thead>
                      <TRH>
                        <TH>ID DISTRIBUIDOR</TH>
                        <TH>RAZÓN SOCIAL</TH>
                        <TH>NOMBRE COMERCIAL</TH>
                        <TH>LOCALIDAD</TH>
                        <TH>PROVINCIA</TH>
                        <TH>ESTADO</TH>
                        <TH>EECC</TH>
                        <TH>ACCIONES</TH>
                      </TRH>
                    </thead>
                    <tbody>
                      {user?.agent && (
                        <TR key={user?.agent.agents_id}>
                          <TD>{user?.agent.agents_id}</TD>
                          <TD>{user?.agent.agent_name}</TD>
                          <TD>{user?.agent.trade_name}</TD>
                          <TD>{user?.agent.city}</TD>
                          <TD>{user?.agent.province}</TD>
                          <TD>{user?.agent.active ? "Activo" : "Inactivo"}</TD>
                          <TD>
                            {user?.agent.EECC
                              ? user?.agent.EECC?.first_name +
                                " " +
                                user?.agent.EECC?.last_name
                              : "Sin EECC asociado"}
                          </TD>
                          <TD>
                            {user?.role?.edit_agents && (
                              <Tooltip text="Editar Distribuidor">
                                <Button
                                  onClick={() =>
                                    navigate(
                                      `/edit-agent/${user?.agent.agents_id}`
                                    )
                                  }
                                >
                                  <img src={ViewIcon} alt="view Icon" />
                                </Button>
                              </Tooltip>
                            )}
                          </TD>
                        </TR>
                      )}
                    </tbody>
                  </Table>
                </TableContainer>
              </>
            )}
        {user?.binding_request_status === "pendiente" && user?.email !== user?.agent?.creator_email && (
          <TextContainer>
            <Text>
              Su solicitud de vinculación se encuentra pendiente de autorizar.
            </Text>
          </TextContainer>
        )}
        {!user?.agent?.agents_id && (
          <TextContainer>
            <Text>Aún no tienes creado ningún Distribuidor</Text>
          </TextContainer>
        )}
        {(!user?.binding_request_status || user?.email === user?.agent?.creator_email) && user?.agent?.agents_id && user?.agent?.status === "pendiente"  && (
          <TextContainer>
            <Text>Su distribuidor fue creado y está en proceso de autorización.</Text>
          </TextContainer>
        )}
        {(!user?.binding_request_status || user?.email === user?.agent?.creator_email) && user?.agent?.agents_id && user?.agent?.status === "denegado"  && (
          <TextContainer>
            <Text>
              Su distribuidor fue denegado. Por favor comuníquese con su Ejecutivo
              Comercial.
            </Text>
          </TextContainer>
        )}
        {(!user?.binding_request_status || user?.email === user?.agent?.creator_email) && user?.agent?.agents_id && user?.agent?.status === "suspendido"  && (
          <TextContainer>
            <Text>
              Su distribuidor fue suspendido. Por favor comuníquese con su Ejecutivo
              Comercial.
            </Text>
          </TextContainer>
        )}
      </MainContainer>
      {showBindingRequestModal && (
        <BindingRequestModal
          setShowBindingRequestModal={setShowBindingRequestModal}
        />
      )}
    </Container>
  );
}

const Container = styled.div`
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;
const TextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-left: 50px;
  @media (max-width: 768px) {
    padding-left: 10px;
  }
`;
const Text = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #3c4858;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  margin-top: 38px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
  }
`;
const CreateAgentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
  min-height: 55px;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const ButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 60px 10px 25px 10px;
  margin-bottom: 60px;
  position: relative;
  min-height: 120px;
  @media (max-width: 768px) {
    margin-top: 40px;
    padding: 30px 10px 25px 10px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: -17px;
    left: 10px;
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2px;
  @media (max-width: 768px) {
    min-width: 900px;
  }
`;
const TableContainer = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const TR = styled.tr`
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
`;
const TRH = styled.tr`
  :nth-child(even) {
    background-color: #f9f9f9;
  }
`;
const TD = styled.td`
  padding: 14px 0px 13px 20px;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
`;
const TH = styled.th`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  padding: 23px 20px;
  color: #128297;
  text-transform: uppercase;
  text-align: start;
  :last-of-type {
    text-align: end;
    width: 150px;
  }
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const Button = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #128297;
  :hover {
    filter: brightness(90%);
  }
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
