import { createContext, useState } from "react";
import { Agent } from "../agents/Interfaces";

interface IAgentFormConext {
  agentName: string;
  setAgentName: (agentName: string) => void;
  sameAgentName: boolean;
  setSameAgentName: (sameAgentName: boolean) => void;
  tradeName: string;
  setTradeName: (tradeName: string) => void;
  showDropDownRole: boolean;
  setshowDropDownRole: (showDropDownRole: boolean) => void;
  showDropDownCategory: boolean;
  setshowDropDownCategory: (showDropDownCategory: boolean) => void;
  documentType: string;
  setDocumentType: (documentType: string) => void;
  documentTypes: string[];
  category: string;
  setCategory: (category: string) => void;
  categories: string[];
  setCategories: (categories: any[]) => void;
  documentNumber: string;
  setDocumentNumber: (documentNumber: string) => void;
  taxStreet: string;
  setTaxStreet: (taxStreet: string) => void;
  taxCity: string;
  setTaxCity: (taxCity: string) => void;
  taxProvince: string;
  setTaxProvince: (taxProvince: string) => void;
  contactName: string;
  setContactName: (contactName: string) => void;
  contactPhone: string;
  setContactPhone: (contactPhone: string) => void;
  contactPhoneOptional: string;
  setContactPhoneOptional: (contactPhoneOptional: string) => void;
  contactEmail: string;
  setContactEmail: (contactEmail: string) => void;
  firstFormFinished: boolean;
  setFirstFormFinished: (firstFormFinished: boolean) => void;
  operationalStreet: string;
  setOperationalStreet: (operationalStreet: string) => void;
  operationalAdressNumber: string;
  setOperationalAdressNumber: (operationalAdressNumber: string) => void;
  operationalCity: string;
  setOperationalCity: (operationalCity: string) => void;
  operationalProvince: string;
  setOperationalProvince: (operationalProvince: string) => void;
  active: boolean;
  setActive: (active: boolean) => void;
  observations: string;
  setObservations: (observations: string) => void;
  emailEECC: string;
  setEmailEECC: (emailEECC: string) => void;
  marker: any;
  setMarker: (marker: any) => void;
  map: any;
  setMap: (map: any) => void;
  confirmUbication: string;
  setconfirmUbication: (confirmUbication: string) => void;
  inputUserSelect: number | null;
  setInputUserSelect: (inputUserSelect: number | null) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  agent: Agent | null;
  setAgent: (agent: Agent | null) => void;
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;
  totalSteps: number;
  setTotalSteps: (totalSteps: number) => void;
  arrayUsers: any[];
  setArrayUsers: (arrayUsers: any[]) => void;
  errors: any;
  setErrors: (errors: any) => void;
  loadingSubmit: boolean;
  setLoadingSubmit: (loadingSubmit: boolean) => void;
  agents_id: string;
  setAgents_id: (agents_id: string) => void;
  latitude: number | null;
  setLatitude: (latitude: number | null) => void;
  longitude: number | null;
  setLongitude: (longitude: number | null) => void;
  polygonCoverageZone: boolean;
  setPolygonCoverageZone: (polygonCoverageZone: boolean) => void;
  radiusCoverageZone: boolean;
  setRadiusCoverageZone: (radiusCoverageZone: boolean) => void;
  radius: number | string;
  setRadius: (radius: number | string) => void;
  circle: any;
  setCircle: (circle: any) => void;
  polygonPoints: any[];
  setPolygonPoints: (polygonPoints: any[]) => void;
  nameEECC: string;
  setNameEECC: (nameEECC: string) => void;
  lastNameEECC: string;
  setLastNameEECC: (lastNameEECC: string) => void;
  instalator: boolean;
  setInstalator: (instalator: boolean) => void;
  logo: boolean;
  setLogo: (logo: boolean) => void;
  imageLogo: any;
  setImageLogo: (logo: any) => void;
  previewImage: any;
  setPreviewImage: (previewImage: any) => void;
  website: string;
  setWebsite: (website: string) => void;
  instagram: string;
  setInstagram: (instagram: string) => void;
  scope: string;
  setScope: (scope: string) => void;
  has_showroom: boolean;
  setHas_showroom: (has_showroom: boolean) => void;
  showDorpdownScope: boolean;
  setShowDorpdownScope: (showDorpdownScope: boolean) => void;
}

const agentFormContext = createContext<IAgentFormConext>({
  agentName: "",
  setAgentName: () => {},
  sameAgentName: false,
  setSameAgentName: () => {},
  tradeName: "",
  setTradeName: () => {},
  showDropDownRole: false,
  setshowDropDownRole: () => {},
  showDropDownCategory: false,
  setshowDropDownCategory: () => {},
  documentType: "",
  setDocumentType: () => {},
  documentTypes: ["CUIT", "CUIL", "DNI"],
  category: "",
  setCategory: () => {},
  setCategories: () => {},
  categories: [],
  documentNumber: "",
  setDocumentNumber: () => {},
  taxStreet: "",
  setTaxStreet: () => {},
  taxCity: "",
  setTaxCity: () => {},
  taxProvince: "",
  setTaxProvince: () => {},
  contactName: "",
  setContactName: () => {},
  contactPhone: "",
  setContactPhone: () => {},
  contactPhoneOptional: "",
  setContactPhoneOptional: () => {},
  contactEmail: "",
  setContactEmail: () => {},
  firstFormFinished: false,
  setFirstFormFinished: () => {},
  operationalStreet: "",
  setOperationalStreet: () => {},
  operationalAdressNumber: "",
  setOperationalAdressNumber: () => {},
  operationalCity: "",
  setOperationalCity: () => {},
  operationalProvince: "",
  setOperationalProvince: () => {},
  active: false,
  setActive: () => {},
  observations: "",
  setObservations: () => {},
  emailEECC: "",
  setEmailEECC: () => {},
  marker: null,
  setMarker: () => {},
  map: null,
  setMap: () => {},
  confirmUbication: "",
  setconfirmUbication: () => {},
  inputUserSelect: null,
  setInputUserSelect: () => {},
  loading: false,
  setLoading: () => {},
  agent: null,
  setAgent: () => {},
  currentStep: 1,
  setCurrentStep: () => {},
  totalSteps: 1,
  setTotalSteps: () => {},
  arrayUsers: [
    {
      users_id: null,
      first_name: "",
      last_name: "",
      email: "",
    },
    {
      users_id: null,
      first_name: "",
      last_name: "",
      email: "",
    },
    {
      users_id: null,
      first_name: "",
      last_name: "",
      email: "",
    },
    {
      users_id: null,
      first_name: "",
      last_name: "",
      email: "",
    },
  ],
  setArrayUsers: () => {},
  errors: {},
  setErrors: () => {},
  loadingSubmit: false,
  setLoadingSubmit: () => {},
  agents_id: "",
  setAgents_id: () => {},
  latitude: null,
  setLatitude: () => {},
  longitude: null,
  setLongitude: () => {},
  polygonCoverageZone: false,
  setPolygonCoverageZone: () => {},
  radiusCoverageZone: true,
  setRadiusCoverageZone: () => {},
  radius: "",
  setRadius: () => {},
  circle: null,
  setCircle: () => {},
  polygonPoints: [],
  setPolygonPoints: () => {},
  nameEECC: "",
  setNameEECC: () => {},
  lastNameEECC: "",
  setLastNameEECC: () => {},
  instalator: false,
  setInstalator: () => {},
  logo: false,
  setLogo: () => {},
  imageLogo: null,
  setImageLogo: () => {},
  previewImage: null,
  setPreviewImage: () => {},
  website: "",
  setWebsite: () => {},
  instagram: "",
  setInstagram: () => {},
  scope: "",
  setScope: () => {},
  has_showroom: false,
  setHas_showroom: () => {},
  showDorpdownScope: false,
  setShowDorpdownScope: () => {},
});

const AgentFormProvider = ({ children }: { children: React.ReactNode }) => {
  const [agents_id, setAgents_id] = useState<string>("");
  const [agentName, setAgentName] = useState<string>("");
  const [sameAgentName, setSameAgentName] = useState<boolean>(false);
  const [tradeName, setTradeName] = useState<string>("");
  const [showDropDownRole, setshowDropDownRole] = useState<boolean>(false);
  const [showDropDownCategory, setshowDropDownCategory] =
    useState<boolean>(false);
  const [documentType, setDocumentType] = useState<string>("");
  const documentTypes = ["CUIT", "CUIL", "DNI"];
  const [category, setCategory] = useState<string>("");
  const [categories, setCategories] = useState<any[]>([]);
  const [documentNumber, setDocumentNumber] = useState<string>("");
  const [taxStreet, setTaxStreet] = useState<string>("");
  const [taxCity, setTaxCity] = useState<string>("");
  const [taxProvince, setTaxProvince] = useState<string>("");
  const [contactName, setContactName] = useState<string>("");
  const [contactPhone, setContactPhone] = useState<string>("");
  const [contactPhoneOptional, setContactPhoneOptional] = useState<string>("");
  const [contactEmail, setContactEmail] = useState<string>("");
  const [firstFormFinished, setFirstFormFinished] = useState<boolean>(false);
  const [operationalStreet, setOperationalStreet] = useState<string>("");
  const [operationalAdressNumber, setOperationalAdressNumber] =
    useState<string>("");
  const [operationalCity, setOperationalCity] = useState<string>("");
  const [operationalProvince, setOperationalProvince] = useState<string>("");
  const [active, setActive] = useState<boolean>(false);
  const [observations, setObservations] = useState<string>("");
  const [emailEECC, setEmailEECC] = useState<string>("");
  const [marker, setMarker] = useState<any>(null);
  const [map, setMap] = useState<null | google.maps.Map>(null);
  const [confirmUbication, setconfirmUbication] = useState<string>("");
  const [inputUserSelect, setInputUserSelect] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [agent, setAgent] = useState<Agent | null>(null);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [totalSteps, setTotalSteps] = useState<number>(2);
  const [arrayUsers, setArrayUsers] = useState<any[]>([
    {
      users_id: null,
      first_name: "",
      last_name: "",
      email: "",
    },
    {
      users_id: null,
      first_name: "",
      last_name: "",
      email: "",
    },
    {
      users_id: null,
      first_name: "",
      last_name: "",
      email: "",
    },
    {
      users_id: null,
      first_name: "",
      last_name: "",
      email: "",
    },
  ]);
  const [errors, setErrors] = useState<any>({});
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [polygonCoverageZone, setPolygonCoverageZone] =
    useState<boolean>(false);
  const [radiusCoverageZone, setRadiusCoverageZone] = useState<boolean>(true);
  const [radius, setRadius] = useState<number | string>("");
  const [circle, setCircle] = useState<any | null>(null);
  const [polygonPoints, setPolygonPoints] = useState<any[]>([]);
  const [nameEECC, setNameEECC] = useState<string>("");
  const [lastNameEECC, setLastNameEECC] = useState<string>("");
  const [instalator, setInstalator] = useState<boolean>(false);
  const [logo, setLogo] = useState<boolean>(false);
  const [imageLogo, setImageLogo] = useState<any>(null);
  const [previewImage, setPreviewImage] = useState<any>(null);
  const [website, setWebsite] = useState<string>("");
  const [instagram, setInstagram] = useState<string>("");
  const [scope, setScope] = useState<string>("");
  const [has_showroom, setHas_showroom] = useState<boolean>(false);
  const [showDorpdownScope, setShowDorpdownScope] = useState<boolean>(false);

  return (
    <agentFormContext.Provider
      value={{
        agentName,
        setAgentName,
        sameAgentName,
        setSameAgentName,
        tradeName,
        setTradeName,
        showDropDownRole,
        setshowDropDownRole,
        showDropDownCategory,
        setshowDropDownCategory,
        documentType,
        setDocumentType,
        documentTypes,
        category,
        setCategory,
        categories,
        setCategories,
        documentNumber,
        setDocumentNumber,
        taxStreet,
        setTaxStreet,
        taxCity,
        setTaxCity,
        taxProvince,
        setTaxProvince,
        contactName,
        setContactName,
        contactPhone,
        setContactPhone,
        contactPhoneOptional,
        setContactPhoneOptional,
        contactEmail,
        setContactEmail,
        firstFormFinished,
        setFirstFormFinished,
        operationalStreet,
        setOperationalStreet,
        operationalAdressNumber,
        setOperationalAdressNumber,
        operationalCity,
        setOperationalCity,
        operationalProvince,
        setOperationalProvince,
        active,
        setActive,
        observations,
        setObservations,
        emailEECC,
        setEmailEECC,
        marker,
        setMarker,
        map,
        setMap,
        confirmUbication,
        setconfirmUbication,
        inputUserSelect,
        setInputUserSelect,
        loading,
        setLoading,
        agent,
        setAgent,
        currentStep,
        setCurrentStep,
        totalSteps,
        setTotalSteps,
        arrayUsers,
        setArrayUsers,
        errors,
        setErrors,
        loadingSubmit,
        setLoadingSubmit,
        agents_id,
        setAgents_id,
        latitude,
        setLatitude,
        longitude,
        setLongitude,
        polygonCoverageZone,
        setPolygonCoverageZone,
        radiusCoverageZone,
        setRadiusCoverageZone,
        radius,
        setRadius,
        circle,
        setCircle,
        polygonPoints,
        setPolygonPoints,
        nameEECC,
        setNameEECC,
        lastNameEECC,
        setLastNameEECC,
        instalator,
        setInstalator,
        logo,
        setLogo,
        imageLogo,
        setImageLogo,
        previewImage,
        setPreviewImage,
        website,
        setWebsite,
        instagram,
        setInstagram,
        scope,
        setScope,
        has_showroom,
        setHas_showroom,
        showDorpdownScope,
        setShowDorpdownScope,
      }}
    >
      {children}
    </agentFormContext.Provider>
  );
};

export { AgentFormProvider, agentFormContext };
