import { useContext, useEffect, useState } from "react";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";
import { Questionnaire } from "../../questionnaires/Interfaces";

export default function useCertificationForm({
  setCreateNewCertificationOrder,
  setRefreshCertificationOrders,
}: {
  setCreateNewCertificationOrder: (refresh: boolean) => void;
  setRefreshCertificationOrders: (createNewAgent: boolean) => void;
}) {
  const [agentSelected, setAgentSelected] = useState<{
    agents_id: string | null;
    agent_name: string;
    trade_name: string;
  }>({
    agents_id: null,
    agent_name: "",
    trade_name: "",
  });
  const [inputAgentSelect, setInputAgentSelect] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [newCertificaationType, setNewCertificaationType] = useState<string>("");
  const [questionnaires, setQuestionnaires] = useState<any[]>([]);
  const [type, setType] = useState<string>("");
  const axiosData = useAxios();
  const [errors, setErrors] = useState<{
    type?: string;
  }>({});
  const { setNotification } = useContext(notificationsContext);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const responseQuestionnaire: Questionnaire[] = await axiosData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/questionnaires`,
        headers: {
          accept: "*/*",
        },
      });
      const activatedOptions = responseQuestionnaire.filter((questionnaire) => questionnaire.activated === true);
      const options = activatedOptions.map((questionnaire) => ({
        value: questionnaire.questionnaires_id,
        label: questionnaire.type,
      }));
      setQuestionnaires(options);
      setLoading(false);
      setRefreshCertificationOrders(false);
    }
    if (questionnaires.length === 0) {
      fetchData();
    }
  }, []); // eslint-disable-line

  async function handleSubmit() {
    const errors: any = {};
    if (!agentSelected.agents_id) {
      setErrors((prev: any) => ({
        ...prev,
        agent: "Debe seleccionar un distribuidor.",
      }));
      errors.agent = "Debe seleccionar un distribuidor.";
    }
    if (!newCertificaationType) {
      setErrors((prev: any) => ({
        ...prev,
        type: "Debe seleccionar un tipo de certificado.",
      }));
      errors.type = "Debe seleccionar un tipo de certificado.";
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const data = {
        agent: agentSelected,
        type: newCertificaationType,
      };

      try {
        const documentResponse = await axiosData({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/orders`,
          headers: {
            accept: "*/*",
          },
          data,
        });
        if (documentResponse) {
          const dataMail = {
            orderId: documentResponse.orders_id,
            agent_name: documentResponse.agent_name,
            certifier_id: documentResponse.certifier_id,
            agents_id: documentResponse.agents_id,
            type: documentResponse.type,
          }
          const sendMail = await axiosData({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/orders/sentoagent`,
            headers: {
              accept: "*/*",
            },
            data: dataMail,
          });
          setLoading(false);
          setNotification({
            theme: "success",
            titleNotification: "Orden creada",
            contentNotification: "La orden se creó correctamente.",
            isVisible: true,
          });
          setCreateNewCertificationOrder(false);
          setRefreshCertificationOrders(true);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {

      setNotification({
        theme: "error",
        titleNotification: "Creación fallida",
        contentNotification: errors[Object.keys(errors)[0]],
        isVisible: true,
      });
    }
    setTimeout(() => {
      setErrors({});
    }, 3000);
  }


  return {
    loading,
    type,
    setType,
    handleSubmit,
    errors,
    questionnaires,
    newCertificaationType,
    setNewCertificaationType,
    agentSelected,
    setAgentSelected,
    inputAgentSelect,
    setInputAgentSelect
  };
}
