import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminAgents from "./agents/screens/AdminAgents";
import RegisterForm from "./auth/screens/RegisterForm";
import SignIn from "./auth/screens/SignIn";
import AdminUsers from "./configs/screens/AdminUsers";
import RolesAndPermissions from "./configs/screens/RolesAndPermissions";
import Home from "./Home";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import firebaseConfig from "./firebase/firebase";
import PrivateRoute from "./routes/PrivateRoute";
import ResetPassword from "./auth/screens/ResetPassword";
import { NotificationProvider } from "./context/notificationsContext";
import { UserProvider } from "./context/userContext";
import Notification from "./commonComponents/Notification";
import AgentRegister from "./agents/screens/AgentForm";
import MyAgent from "./agents/screens/MyAgent";
import AgentRequests from "./agents/screens/AgentRequests";
import BindingRequestList from "./agents/screens/BindingRequestList";
import SuperAdminRoute from "./routes/SuperAdminRoute";
import { AgentFormProvider } from "./context/agentFormContext";
import PermissionsRoute from "./routes/PermissionsRoute";
import OwnAgentRoute from "./routes/OwnAgentRoute";
import HomePage from "./routes/HomePage";
import ManageAgentsRoute from "./routes/ManageAgentsRoute";
import BindingRequestRoute from "./routes/BindingRequestRoute";
import ZonesMap from "./agents/screens/ZonesMap";
import DocumentTypes from "./documents/screens/DocumentTypes";
import Documents from "./documents/screens/Documents";
import BindingDocuments from "./documents/screens/BindingDocuments";
import MyDocuments from "./documents/screens/MyDocuments";
import { DocumentNotificationProvider } from "./context/documentsNotContext";
import DocumentNotification from "./commonComponents/DocumentsNotification";
import LeadsForm from "./leads/screens/leadsForm";
import { LeadFormProvider } from "./context/leadFormContext";
import Advisors from "./advisors/screens/Advisors";
import LeadsTracking from "./leads/screens/LeadsTracking";
import MyLeads from "./leads/screens/MyLeads";
import StatusBindings from "./documents/screens/StatusBindings";
import StatusAllBindings from "./documents/screens/StatusAllBindings";
import AdminRoute from "./routes/AdminRoute";
import ConfigLeadsForm from "./leads/screens/ConfigLeadsForm";
import Inheritance from "./configs/screens/Inheritance";
import Questionnaires from "./questionnaires/screens/Questionnaires";
import QuestionnaireDesign from "./questionnaires/screens/QuestionnaireDesign";
import QuestionnaireViw from "./questionnaires/screens/QuestionnaireView";
import CertificationOrders from "./orders/screens/CertificationOrders";
import CertificationOrdersView from "./orders/screens/CertificationOrdersView";
import CertificationOrdersAdmin from "./orders/screens/CertificationOrdersAdmin";
import CertificationOwnOrders from "./orders/screens/CertificationOwnOrders";
import CertificationOrdersSended from "./orders/screens/CertificationOrdersSended";
import CertificationAllOrders from "./orders/screens/CertificationAllOrders";
import CertificationOrdersOnlyView from "./orders/screens/CertificationOrdersOnlyView";
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export default function App() {
  return (
    <UserProvider>
      <DocumentNotificationProvider>
        <NotificationProvider>
          <Notification />
          <DocumentNotification />
          <BrowserRouter>
            <Routes>
              <Route path="/login" element={<SignIn />} />
              <Route path="/login/:useremail" element={<SignIn />} />
              <Route path="/signup" element={<RegisterForm />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route
                path="/leads"
                element={
                  <LeadFormProvider>
                    <LeadsForm />
                  </LeadFormProvider>
                }
              />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                }
              >
                <Route index element={<HomePage />} />
                <Route
                  path="admin-agents"
                  element={
                    <PermissionsRoute permission="create_agent">
                      <AdminAgents />
                    </PermissionsRoute>
                  }
                />
                <Route
                  path="admin-users"
                  element={
                    <PermissionsRoute permission="see_users">
                      <AdminUsers />
                    </PermissionsRoute>
                  }
                />
                <Route
                  path="role-permission"
                  element={
                    <SuperAdminRoute>
                      <RolesAndPermissions />
                    </SuperAdminRoute>
                  }
                />
                <Route
                  path="inheritance"
                  element={
                    <SuperAdminRoute>
                      <Inheritance />
                    </SuperAdminRoute>
                  }
                />
                <Route
                  path="create-agent"
                  element={
                    <AgentFormProvider>
                      <AgentRegister />
                    </AgentFormProvider>
                  }
                />
                <Route
                  path="agent-requests"
                  element={
                    <PermissionsRoute permission="manage_agents">
                      <AgentRequests />
                    </PermissionsRoute>
                  }
                />
                {/* <Route
                path="categories"
                element={
                  <PermissionsRoute permission="manage_agents">
                    <Categories />
                  </PermissionsRoute>
                }
              /> */}
                <Route
                  path="agent-requests/:id"
                  element={
                    <ManageAgentsRoute>
                      <AgentRequests />
                    </ManageAgentsRoute>
                  }
                />
                <Route
                  path="binding-requests"
                  element={
                    <PermissionsRoute permission="bind_agents">
                      <BindingRequestList />
                    </PermissionsRoute>
                  }
                />
                <Route
                  path="binding-requests/:id"
                  element={
                    <BindingRequestRoute>
                      <BindingRequestList />
                    </BindingRequestRoute>
                  }
                />
                <Route
                  path="my-agent"
                  element={
                    <PermissionsRoute permission="see_my_agent">
                      <MyAgent />
                    </PermissionsRoute>
                  }
                />
                {/* <Route path="heatmap" element={<HeatMap />} /> */}
                <Route
                  path="reporting/zonesmap"
                  element={
                    <AdminRoute>
                      <ZonesMap />
                    </AdminRoute>
                  }
                />
                <Route
                  path="documents/list"
                  element={
                    <PermissionsRoute permission="see_documents">
                      <Documents />
                    </PermissionsRoute>
                  }
                />
                <Route
                  path="documents/binding/:id"
                  element={
                    <PermissionsRoute permission="see_documents">
                      <BindingDocuments />
                    </PermissionsRoute>
                  }
                />
                <Route
                  path="documents/status/:id"
                  element={
                    <PermissionsRoute permission="see_documents">
                      <StatusBindings />
                    </PermissionsRoute>
                  }
                />
                <Route
                  path="documents/status-all"
                  element={
                    <PermissionsRoute permission="edit_documents">
                      <StatusAllBindings />
                    </PermissionsRoute>
                  }
                />
                <Route path="documents/mydocuments" element={<MyDocuments />} />
                <Route
                  path="edit-agent/:agentId"
                  element={
                    <OwnAgentRoute>
                      <AgentFormProvider>
                        <AgentRegister />
                      </AgentFormProvider>
                    </OwnAgentRoute>
                  }
                />
                <Route
                  path="parameters/types"
                  element={
                    <PermissionsRoute permission="see_documents">
                      <DocumentTypes />
                    </PermissionsRoute>
                  }
                />
                <Route
                  path="parameters/advisors"
                  element={
                    <AdminRoute>
                      <Advisors />
                    </AdminRoute>
                  }
                />
                <Route
                  path="parameters/config-leads-form"
                  element={
                    <SuperAdminRoute>
                      <ConfigLeadsForm />
                    </SuperAdminRoute>
                  }
                />
                <Route
                  path="leads/listing"
                  element={
                    <PermissionsRoute permission="see_leads">
                      <LeadsTracking />
                    </PermissionsRoute>
                  }
                />
                <Route
                  path="certification/questtionaires"
                  element={
                    <PermissionsRoute permission="see_questionnaire">
                      <Questionnaires />
                    </PermissionsRoute>
                  }
                />
                <Route
                  path="certification/questtionaires/:id"
                  element={
                    <PermissionsRoute permission="edit_questionnaire">
                      <QuestionnaireDesign />
                    </PermissionsRoute>
                  }
                />
                <Route
                  path="certification/questtionaires/view/:id"
                  element={
                    <PermissionsRoute permission="see_questionnaire">
                      <QuestionnaireViw />
                    </PermissionsRoute>
                  }
                />
                <Route
                  path="certification/orders"
                  element={
                    <PermissionsRoute permission="see_order">
                      <CertificationOrders />
                    </PermissionsRoute>
                  }
                />
                <Route
                  path="certification/allorders"
                  element={
                    <PermissionsRoute permission="see_order">
                      <CertificationAllOrders />
                    </PermissionsRoute>
                  }
                />
                <Route
                  path="certification/orderssent"
                  element={
                    <PermissionsRoute permission="see_order">
                      <CertificationOrdersSended />
                    </PermissionsRoute>
                  }
                />
                <Route
                  path="certification/ownorders"
                  element={
                    <PermissionsRoute permission="review_own_order">
                      <CertificationOwnOrders />
                    </PermissionsRoute>
                  }
                />
                <Route
                  path="certification/orders/:id"
                  element={<CertificationOrdersView />}
                />
                <Route
                  path="certification/orders/view/:id"
                  element={<CertificationOrdersOnlyView />}
                />
                <Route
                  path="certification/orders/admin/:id"
                  element={
                    <PermissionsRoute permission="approve_order">
                      <CertificationOrdersAdmin />
                    </PermissionsRoute>
                  }
                />
                <Route path="leads/myleads" element={<MyLeads />} />
              </Route>
              <Route path="*" element={<h2>404</h2>} />
            </Routes>
          </BrowserRouter>
        </NotificationProvider>
      </DocumentNotificationProvider>
    </UserProvider>
  );
}
