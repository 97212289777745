import { useContext } from "react";
import { userContext } from "../context/userContext";
import { Navigate } from "react-router-dom";

export default function PermissionsRoute({ children,permission }: { children: any; permission: string }) {
  const {
    userState: { user },
  } = useContext(userContext);

  if (user) {
    if (user?.role[permission]===false) {
      return <Navigate to="/" />;
    } else {
      return children;
    }
  }
}
