import useAxios from "../../commonHooks/useAxios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Agent } from "../Interfaces";
import { userContext } from "../../context/userContext";

export default function useAdminAgents() {
  const [agentName, setAgentName] = useState<string>("");
  const [tradeName, setTradeName] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [active, setActive] = useState<number>(2);
  const [EECC, setEECC] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [initialAgents, setInitialAgents] = useState<Agent[]>([]);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [agentsFiltered, setAgentsFiltered] = useState<Agent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [agentsPerPage, setAgentsPerPage] = useState<number>(10);
  const [agentIdToEdit, setAgentIdToEdit] = useState<string | null>(null);
  const [refreshagents, setRefreshAgents] = useState<boolean>(true);
  const [visibleExport, setVisibleExport] = useState<boolean>(false)
  const navigate = useNavigate();
  const getData = useAxios();
  const [fieldSorted, setFieldSorted] = useState("agent_name");
  const [orderSorted, setOrderSorted] = useState("asc");
  const [showModalExport, setshowModalExport] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [instalator, setInstalator] = useState<number>(2);
  const {
    userState: { user },
  } = useContext(userContext);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);

      if (
        (user?.isEECC === true ||user?.role.name === "EECC" ) &&
        user?.role.name !== "SuperAdmin" &&
        user?.role.name !== "Admin"
      ) {
        const filterByEECC: Agent[] = await getData({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/agentsEECC`,
          headers: {
            accept: "*/*",
          },
          data: {
            users_id: user.users_id,
          },
        });
        const newAgents = filterByEECC;
        const agentsSorted = sortItems(newAgents, "agent_name", "asc");
        setAgents(agentsSorted);
        setInitialAgents(agentsSorted);
        setAgentsFiltered(agentsSorted);
        setTotalPages(Math.ceil(agentsSorted.length / agentsPerPage));
        handlePage(1, agentsSorted);
        setEECC(user.first_name + " " + user.last_name);
      } else {
        const response: Agent[] = await getData({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}/agents`,
          headers: {
            accept: "*/*",
          },
        });
        const newAgents = response;
        const agentsSorted = sortItems(newAgents, "agent_name", "asc");
        setAgents(agentsSorted);
        setInitialAgents(agentsSorted);
        setAgentsFiltered(agentsSorted);
        setTotalPages(Math.ceil(agentsSorted.length / agentsPerPage));
        handlePage(1, agentsSorted);
      }
      setLoading(false);
      setRefreshAgents(false);
    }
    if ((agents.length === 0 || refreshagents) && user) {
      fetchData();
    }
  }, [refreshagents, user]); // eslint-disable-line

  useEffect(() => {
    const newAgentsArray = sortItems(agentsFiltered, fieldSorted, orderSorted);
    handlePage(1, newAgentsArray);
  }, [fieldSorted, orderSorted]); // eslint-disable-line

  const handleSort = (field: string) => {
    let order = "asc";
    if (fieldSorted === field) {
      order = orderSorted === "asc" ? "desc" : "asc";
      setOrderSorted(order);
    } else {
      setFieldSorted(field);
      setOrderSorted(order);
    }
  };


  function sortItems(
    array: any = agentsFiltered,
    field: string = fieldSorted,
    order: string = orderSorted
  ) {
    if (field === "role") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field].name.toLowerCase() > b[field].name.toLowerCase())
            return 1;
          if (a[field].name.toLowerCase() < b[field].name.toLowerCase())
            return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field].name.toLowerCase() < b[field].name.toLowerCase())
            return 1;
          if (a[field].name.toLowerCase() > b[field].name.toLowerCase())
            return -1;
          return 0;
        });
      }
    } else if (field === "active") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field] > b[field]) return 1;
          if (a[field] < b[field]) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field] < b[field]) return 1;
          if (a[field] > b[field]) return -1;
          return 0;
        });
      }
    } else if (field === "EECC") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          const aName = a.EECC?.first_name
            ? a[field].first_name.toLowerCase()
            : "ZZZZZZZZZZZZZ";
          const bName = b.EECC?.first_name
            ? b[field].first_name.toLowerCase()
            : "ZZZZZZZZZZZZZ";
          if (aName > bName) return 1;
          if (aName < bName) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          const aName = a.EECC?.first_name
            ? a[field].first_name.toLowerCase()
            : "ZZZZZZZZZZZZZ";
          const bName = b.EECC?.first_name
            ? b[field].first_name.toLowerCase()
            : "ZZZZZZZZZZZZZ";
          if (aName < bName) return 1;
          if (aName > bName) return -1;
          return 0;
        });
      }
    } else {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() < b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() > b[field].toLowerCase()) return -1;
          return 0;
        });
      }
    }

    return array;
  }

  function handleAgentsPerPage(
    userPerPage: number,
    agents: Agent[] = agentsFiltered
  ) {
    setTotalPages(Math.ceil(agents.length / userPerPage));
    handlePage(1, agents, userPerPage);
  }

  function handlePage(
    page: number,
    agents = agentsFiltered,
    totalagentsPerPage = agentsPerPage
  ) {
    setAgentsPerPage(totalagentsPerPage);
    setPage(page);
    const agentsSorted = sortItems(agents);
    const indexOfLastUser = page * totalagentsPerPage;
    const indexOfFirstUser = indexOfLastUser - totalagentsPerPage;
    const currentagents = agentsSorted.slice(indexOfFirstUser, indexOfLastUser);
    setAgents(currentagents);
  }

  function handleFilter() {
    const agentNameFilter = initialAgents.filter((agent: Agent) => {
      return agent.agent_name.toLowerCase().includes(agentName.toLowerCase());
    });
    const tradeNameFilter = agentNameFilter.filter((agent: Agent) => {
      return agent.trade_name.toLowerCase().includes(tradeName.toLowerCase());
    });
    const cityFilter = tradeNameFilter.filter((agent: Agent) => {
      return agent.city.toLowerCase().includes(city.toLowerCase());
    });
    const activeFilter = cityFilter.filter((agent: Agent) => {
      if (active === 2) {
        return true;
      } else if (active === 1 && agent.active === true) {
        return true;
      } else if (
        (active === 0 && agent.active === false) ||
        (active === 0 && agent.active === null)
      ) {
        return true;
      } else {
        return false;
      }
    });
    const EECCFilter = activeFilter.filter((agent: Agent) => {
      if (agent.EECC) {
        const fullName = agent.EECC.first_name + " " + agent.EECC.last_name;
        return fullName.toLowerCase().includes(EECC.toLowerCase());
      } else if (!EECC) {
        return true;
      } else {
        return false;
      }
    });
    const categoryFilter = EECCFilter.filter((agent: Agent) => {
      if (agent.category) {
        return agent.category?.name
          .toLowerCase()
          .includes(category.toLowerCase());
      } else if (!category) {
        return true;
      } else {
        return false;
      }
    });
    const instalatorFilter = categoryFilter.filter((agent: Agent) => {
      if (instalator === 2) {
        return true;
      } else if (instalator === 1 && agent.instalator === true) {
        return true;
      } else if (
        (instalator === 0 && agent.instalator === false) ||
        (instalator === 0 && agent.instalator === null)
      ) {
        return true;
      } else {
        return false;
      }
    });
    handleAgentsPerPage(agentsPerPage, instalatorFilter);
    setAgentsFiltered(instalatorFilter);
  }

  function handleEnter(e: any) {
    if (e.key === "Enter") {
      handleFilter();
    }
  }

  function handleReset() {
    setAgentName("");
    setTradeName("");
    setCity("");
    setEECC("");
    setCategory("");
    setActive(1);
    handleAgentsPerPage(agentsPerPage, initialAgents);
    setAgentsFiltered(initialAgents);
  }

  function closeExport() {
    setVisibleExport(false);
    setshowModalExport(false);
  }

  return {
    agentName,
    setAgentName,
    tradeName,
    setTradeName,
    city,
    setCity,
    EECC,
    setEECC,
    active,
    setActive,
    agents,
    handleFilter,
    handleReset,
    loading,
    agentsPerPage,
    page,
    totalPages,
    handleAgentsPerPage,
    handlePage,
    navigate,
    setAgentIdToEdit,
    agentIdToEdit,
    setRefreshAgents,
    user,
    handleEnter,
    handleSort,
    orderSorted,
    fieldSorted,
    category,
    setCategory,
    agentsFiltered,
    visibleExport,
    setVisibleExport,
    closeExport,
    showModalExport,
    setshowModalExport,
    loadingExport,
    setLoadingExport,
    instalator,
    setInstalator
  };
}
