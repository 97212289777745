import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import useQuestionnaireDesign from "../hooks/useQuestionnaireDesign";
import ButtonComponent from "../../commonComponents/Button";
import AddIcon from "../../assets/img/addIconPrimary.svg";
import ArrowTop from "../../assets/img/arrowTop.svg";
import ArrowDown from "../../assets/img/arrowDown.svg";
import FeedIcon from "../../assets/img/questionnaireIcon.svg";
import EditIcon from "../../assets/img/editIconPrimary.svg";
import TrashIcon from "../../assets/img/trashIconPrimary.svg";
import ArrowIcon from "../../assets/img/arrowAcordeon.svg";
import CustomTable from "../components/CustomTable";
import StaticTable from "../components/StaticTable";
import { Group, Question } from "../Interfaces";
import TypeInput from "../components/TypeInput";
import Modal from "../../commonComponents/Modal";
import Combobox from "../components/Combobox";
import RemoveSvg from "../../assets/img/remove.svg";
import PlusSvg from "../../assets/img/plus.svg";

export default function QuestionnaireDesign() {
  const {
    loading,
    groups,
    setShowModalQuestion,
    setShowModalGroup,
    opened,
    upGroup,
    downGroup,
    handleEditGroup,
    deleteGroup,
    toggleGroup,
    upQuestion,
    downQuestion,
    handleEditQuestion,
    deleteQuestion,
    showModalGroup,
    newGroupName,
    setNewGroupName,
    cancelEditGroup,
    createGroup,
    editGroupId,
    confirmEditGroup,
    showModalQuestion,
    editQuestionId,
    groupSelected,
    setGroupSelected,
    newQuestionName,
    setNewQuestionName,
    newQuestionType,
    setNewQuestionType,
    options,
    setOptions,
    columns,
    setColumns,
    rows,
    setRows,
    checkedImages,
    setCheckedImages,
    cancelEditQuestion,
    createQuestion,
    confirmEditQuestion,
    TYPEQUESTION,
    navigate,
    handleSave,
    questionnaire,
  } = useQuestionnaireDesign();

  return (
    <Container>
      <Title>
        Cuestionario{questionnaire?.type && `: ${questionnaire.type}`}
      </Title>
      <MainContainer>
        <TopContainer>
          <IconContainer>
            <img src={FeedIcon} alt="questionnaire icon" />
          </IconContainer>
        </TopContainer>
        <ButtonsContainer>
          {groups.length ? (
            <ButtonComponent
              type="secondary"
              onClick={() => {
                setShowModalQuestion(true);
              }}
            >
              <ButtonTextContainer>
                <img src={AddIcon} alt="add icon" /> NUEVA PREGUNTA
              </ButtonTextContainer>
            </ButtonComponent>
          ) : (
            <></>
          )}
          <ButtonComponent
            type="secondary"
            onClick={() => {
              setShowModalGroup(true);
            }}
          >
            <ButtonTextContainer>
              <img src={AddIcon} alt="add icon" /> NUEVA SECCIÓN
            </ButtonTextContainer>
          </ButtonComponent>
        </ButtonsContainer>
        <FilterContainer>
          <FieldContainer>
            <Input required value={""} disabled />
            <Label>OBRA</Label>
          </FieldContainer>
          <FieldContainer>
            <Input required value={""} disabled />
            <Label>INSTALADOR</Label>
          </FieldContainer>
          <FieldContainer>
            <Input required value={""} disabled />
            <Label>CLIENTE</Label>
          </FieldContainer>
          <FieldContainer>
            <Input required value={""} disabled />
            <Label>UBICACIÓN</Label>
          </FieldContainer>
        </FilterContainer>
        <Separator />

        {!loading ? (
          <GroupsContainer>
            {groups.map((group: Group, index) => (
              <GroupContainer>
                <GroupVisibleContainer
                  className={opened.includes(group.id) ? "open" : ""}
                >
                  <VisibleLeft>
                    <OrderButtonsContainer>
                      {group.order && group.order > 1 && (
                        <img
                          onClick={() => upGroup(group.id)}
                          src={ArrowTop}
                          alt="arrow top"
                        />
                      )}
                      {group.order && group.order < groups.length && (
                        <img
                          onClick={() => downGroup(group.id)}
                          src={ArrowDown}
                          alt="arrow down"
                        />
                      )}
                    </OrderButtonsContainer>
                    <VisibleText>{group.name}</VisibleText>
                  </VisibleLeft>
                  <VisibleRight>
                    <Button
                      onClick={() => {
                        handleEditGroup(group.id);
                      }}
                    >
                      <img src={EditIcon} alt="edit Icon" />
                    </Button>
                    <Button
                      onClick={() => {
                        deleteGroup(group.id);
                      }}
                    >
                      <img src={TrashIcon} alt="trash Icon" />
                    </Button>
                    <OpenButton
                      onClick={() => toggleGroup(group.id)}
                      className={opened.includes(group.id) ? "open" : ""}
                    >
                      <img src={ArrowIcon} alt="arrow Icon" />
                    </OpenButton>
                  </VisibleRight>
                </GroupVisibleContainer>
                <QuestionsContainer
                  className={opened.includes(group.id) ? "open" : ""}
                >
                  {group.questions &&
                    group.questions.map((question: Question) => (
                      <QuestionContainer>
                        <TopQuestionContainer>
                          <OrderContainer>
                            <OrderButtonsContainer>
                              {question.order && question.order > 1 && (
                                <img
                                  onClick={() =>
                                    upQuestion(group.id, question.id)
                                  }
                                  src={ArrowTop}
                                  alt="arrow top"
                                />
                              )}
                              {group.questions &&
                                question.order &&
                                question.order < group.questions.length && (
                                  <img
                                    onClick={() =>
                                      downQuestion(group.id, question.id)
                                    }
                                    src={ArrowDown}
                                    alt="arrow down"
                                  />
                                )}
                            </OrderButtonsContainer>
                            <OrderDsiplay>{question.order}</OrderDsiplay>
                          </OrderContainer>
                          <VisibleRight
                            style={{
                              width: "100%",
                              paddingLeft: "16px",
                              justifyContent: "space-between",
                            }}
                          >
                            <LabelQuestion>{question.name}</LabelQuestion>
                            <RowTopContainer>
                              <TypeInput question={question} disabled />
                            </RowTopContainer>
                            <ButtonsContainer style={{ width: "auto" }}>
                              <Button
                                onClick={() => {
                                  handleEditQuestion(group.id, question.id);
                                }}
                                className="secondary"
                              >
                                <img src={EditIcon} alt="edit Icon" />
                              </Button>
                              <Button
                                onClick={() => {
                                  deleteQuestion(group.id, question.id);
                                }}
                                className="secondary"
                              >
                                <img src={TrashIcon} alt="trash Icon" />
                              </Button>
                            </ButtonsContainer>
                          </VisibleRight>
                        </TopQuestionContainer>
                        <TableContainer>
                          {question.type === "freetable" && (
                            <CustomTable
                              disabled
                              headers={question.headers || []}
                              rows={question.rows || []}
                              setRows={() => {}}
                            />
                          )}
                          {question.type === "statictable" && (
                            <StaticTable
                              disabled
                              headers={question.headers || []}
                              rows={question.rows || []}
                              setRows={() => {}}
                              nameRows={question.nameRows || []}
                            />
                          )}
                        </TableContainer>
                      </QuestionContainer>
                    ))}
                </QuestionsContainer>
              </GroupContainer>
            ))}
          </GroupsContainer>
        ) : (
          <Loading>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </Loading>
        )}
        {showModalGroup && (
          <Modal
            style={{
              paddingTop: "64px",
              maxHeight: "95vh",
            }}
            type="type"
            title={"Nueva Sección"}
          >
            <ContainerModal>
              {loading ? (
                <LoadingContainer>
                  <CircularProgress
                    sx={{
                      color: "#128297",
                    }}
                    size={100}
                  />
                </LoadingContainer>
              ) : (
                <>
                  <FieldContainer>
                    <Input
                      required
                      value={newGroupName}
                      onChange={({ target: { value } }) =>
                        setNewGroupName(value)
                      }
                    />
                    <Label>Titulo de Sección</Label>
                  </FieldContainer>
                  <ButtonsContainerModal>
                    <ButtonComponent
                      type="secondary"
                      onClick={() => {
                        cancelEditGroup();
                      }}
                    >
                      Cancelar
                    </ButtonComponent>
                    <ButtonComponent
                      type="primary"
                      onClick={() => {
                        createGroup(newGroupName);
                      }}
                    >
                      Guardar
                    </ButtonComponent>
                  </ButtonsContainerModal>
                </>
              )}
            </ContainerModal>
          </Modal>
        )}
        {editGroupId && (
          <Modal
            style={{
              paddingTop: "64px",
              maxHeight: "95vh",
            }}
            type="type"
            title={"Editar Sección"}
          >
            <ContainerModal>
              {loading ? (
                <LoadingContainer>
                  <CircularProgress
                    sx={{
                      color: "#128297",
                    }}
                    size={100}
                  />
                </LoadingContainer>
              ) : (
                <>
                  <FieldContainer>
                    <Input
                      required
                      value={newGroupName}
                      onChange={({ target: { value } }) =>
                        setNewGroupName(value)
                      }
                    />
                    <Label>Titulo de Sección</Label>
                  </FieldContainer>
                  <ButtonsContainerModal>
                    <ButtonComponent
                      type="secondary"
                      onClick={() => {
                        cancelEditGroup();
                      }}
                    >
                      Cancelar
                    </ButtonComponent>
                    <ButtonComponent
                      type="primary"
                      onClick={() => {
                        confirmEditGroup();
                      }}
                    >
                      Guardar
                    </ButtonComponent>
                  </ButtonsContainerModal>
                </>
              )}
            </ContainerModal>
          </Modal>
        )}
        {(showModalQuestion || editQuestionId) && (
          <Modal
            style={{
              paddingTop: "64px",
              maxHeight: "95vh",
            }}
            type="type"
            title={editQuestionId ? "Editar Pregunta" : "Nueva Pregunta"}
          >
            <ContainerModal>
              {loading ? (
                <LoadingContainer>
                  <CircularProgress
                    sx={{
                      color: "#128297",
                    }}
                    size={100}
                  />
                </LoadingContainer>
              ) : (
                <>
                  <FieldContainer
                    style={{
                      zIndex: 4,
                    }}
                  >
                    <Combobox
                      options={
                        groups.map((group: Group) => group.name).length
                          ? groups.map((group: Group) => String(group.name))
                          : [""]
                      }
                      label="Sección"
                      value={groupSelected}
                      setValue={setGroupSelected}
                      width={335}
                      height={55}
                      index={10}
                    />
                  </FieldContainer>
                  <FieldContainer
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <Input
                      required
                      value={newQuestionName}
                      onChange={({ target: { value } }) =>
                        setNewQuestionName(value)
                      }
                    />
                    <Label>Titulo de Pregunta</Label>
                  </FieldContainer>
                  <FieldContainer
                    style={{
                      zIndex: 3,
                    }}
                  >
                    <Combobox
                      options={TYPEQUESTION}
                      label="Tipo de Pregunta"
                      value={newQuestionType}
                      setValue={setNewQuestionType}
                      width={335}
                      height={55}
                      index={9}
                      values={true}
                    />
                  </FieldContainer>
                  {newQuestionType === "combobox" &&
                    options.map((option, index) => (
                      <FieldContainer
                        style={{
                          marginTop: index === 0 ? "16px" : "0px",
                          width: "80%",
                        }}
                      >
                        <Input
                          required
                          value={options[index]}
                          onChange={({ target: { value } }) => {
                            const newOptions = [...options];
                            newOptions[index] = value;
                            setOptions(newOptions);
                          }}
                        />
                        <Label>Opción {index + 1}</Label>
                        <ButtonsComponent>
                          {index !== 0 && (
                            <ButtonRemove
                              onClick={() => {
                                const newOptions = [...options];
                                newOptions.splice(index, 1);
                                setOptions(newOptions);
                              }}
                            >
                              <img src={RemoveSvg} alt="remove" />
                            </ButtonRemove>
                          )}
                          {index === options.length - 1 && (
                            <ButtonPlus
                              onClick={() => {
                                const newOptions = [...options];
                                newOptions.push("");
                                setOptions(newOptions);
                              }}
                            >
                              <img src={PlusSvg} alt="plus" />
                            </ButtonPlus>
                          )}
                        </ButtonsComponent>
                      </FieldContainer>
                    ))}
                  {newQuestionType === "statictable" && (
                    <>
                      <TitleDesign>Columnas</TitleDesign>
                      {columns.map((option, index) => (
                        <FieldContainer
                          style={{
                            width: "80%",
                          }}
                        >
                          <Input
                            required
                            value={columns[index]}
                            onChange={({ target: { value } }) => {
                              const newColumns = [...columns];
                              newColumns[index] = value;
                              setColumns(newColumns);
                            }}
                          />
                          <Label>Columna {index + 1}</Label>
                          <ButtonsComponent>
                            {index !== 0 && (
                              <ButtonRemove
                                onClick={() => {
                                  const newColumns = [...columns];
                                  newColumns.splice(index, 1);
                                  setColumns(newColumns);
                                }}
                              >
                                <img src={RemoveSvg} alt="remove" />
                              </ButtonRemove>
                            )}
                            {index === columns.length - 1 && (
                              <ButtonPlus
                                onClick={() => {
                                  const newColumns = [...columns];
                                  newColumns.push("");
                                  setColumns(newColumns);
                                }}
                              >
                                <img src={PlusSvg} alt="plus" />
                              </ButtonPlus>
                            )}
                          </ButtonsComponent>
                        </FieldContainer>
                      ))}
                      <TitleDesign>Filas</TitleDesign>
                      {rows.map((option, index) => (
                        <FieldContainer
                          style={{
                            width: "80%",
                          }}
                        >
                          <Input
                            required
                            value={rows[index]}
                            onChange={({ target: { value } }) => {
                              const newRows = [...rows];
                              newRows[index] = value;
                              setRows(newRows);
                            }}
                          />
                          <Label>Fila {index + 1}</Label>
                          <ButtonsComponent>
                            {index !== 0 && (
                              <ButtonRemove
                                onClick={() => {
                                  const newRows = [...rows];
                                  newRows.splice(index, 1);
                                  setRows(newRows);
                                }}
                              >
                                <img src={RemoveSvg} alt="remove" />
                              </ButtonRemove>
                            )}
                            {index === rows.length - 1 && (
                              <ButtonPlus
                                onClick={() => {
                                  const newRows = [...rows];
                                  newRows.push("");
                                  setRows(newRows);
                                }}
                              >
                                <img src={PlusSvg} alt="plus" />
                              </ButtonPlus>
                            )}
                          </ButtonsComponent>
                        </FieldContainer>
                      ))}
                    </>
                  )}
                  {newQuestionType === "freetable" && (
                    <>
                      <TitleDesign>Columnas</TitleDesign>
                      {columns.map((option, index) => (
                        <FieldContainer
                          style={{
                            width: "80%",
                          }}
                        >
                          <Input
                            required
                            value={columns[index]}
                            onChange={({ target: { value } }) => {
                              const newColumns = [...columns];
                              newColumns[index] = value;
                              setColumns(newColumns);
                            }}
                          />
                          <Label>Columna {index + 1}</Label>
                          <ButtonsComponent>
                            {index !== 0 && (
                              <ButtonRemove
                                onClick={() => {
                                  const newColumns = [...columns];
                                  newColumns.splice(index, 1);
                                  setColumns(newColumns);
                                }}
                              >
                                <img src={RemoveSvg} alt="remove" />
                              </ButtonRemove>
                            )}
                            {index === columns.length - 1 && (
                              <ButtonPlus
                                onClick={() => {
                                  const newColumns = [...columns];
                                  newColumns.push("");
                                  setColumns(newColumns);
                                }}
                              >
                                <img src={PlusSvg} alt="plus" />
                              </ButtonPlus>
                            )}
                          </ButtonsComponent>
                        </FieldContainer>
                      ))}
                    </>
                  )}
                  <FieldContainer
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    <RowContainer>
                      <LabelText>Subir imagen</LabelText>
                      <SwitchContainer>
                        <Switch
                          checked={checkedImages}
                          onClick={() => setCheckedImages(true)}
                          style={{
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                          }}
                        >
                          Si
                        </Switch>
                        <Switch
                          checked={!checkedImages}
                          onClick={() => setCheckedImages(false)}
                          style={{
                            borderTopRightRadius: "4px",
                            borderBottomRightRadius: "4px",
                          }}
                        >
                          No
                        </Switch>
                      </SwitchContainer>
                    </RowContainer>
                  </FieldContainer>
                  <ButtonsContainerModal>
                    <ButtonComponent
                      type="secondary"
                      onClick={() => {
                        cancelEditQuestion();
                      }}
                    >
                      Cancelar
                    </ButtonComponent>
                    {showModalQuestion && (
                      <ButtonComponent
                        type="primary"
                        onClick={() => {
                          createQuestion();
                        }}
                      >
                        Guardar
                      </ButtonComponent>
                    )}
                    {editQuestionId && (
                      <ButtonComponent
                        type="primary"
                        onClick={() => {
                          confirmEditQuestion();
                        }}
                      >
                        Guardar
                      </ButtonComponent>
                    )}
                  </ButtonsContainerModal>
                </>
              )}
            </ContainerModal>
          </Modal>
        )}
      </MainContainer>
      <BottomContainer>
        <ButtonContainer>
          <ButtonComponent
            onClick={() => {
              navigate("/certification/questtionaires");
            }}
            type={"secondary"}
          >
            ATRAS
          </ButtonComponent>
          <ButtonComponent onClick={handleSave} type={"primary"}>
            GUARDAR
          </ButtonComponent>
        </ButtonContainer>
      </BottomContainer>
    </Container>
  );
}
const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
  padding: 0px 20px;
  @media (max-width: 768px) {
    padding: 0px 10px;
    align-items: center;
    justify-content: center;
  }
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const LabelText = styled.p`
  color: #3c4858;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;
const TopQuestionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;
const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #e7e7e7;
  border-radius: 4px;
`;
const Switch = styled.div<{ checked: boolean }>`
  width: 75px;
  height: 50px;
  background-color: ${(props) => (props.checked ? "#E7E7E7" : "white")};
  color: #424242;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
`;
const TableContainer = styled.div`
  width: 100%;
`;
const Container = styled.div`
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  margin-top: 38px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
  }
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 24px 10px 25px 10px;
  margin-bottom: 60px;
  position: relative;
  margin-top: 50px;
  @media (max-width: 768px) {
    margin-top: 40px;
    padding: 30px 10px 25px 10px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: -17px;
    left: 10px;
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 0px;
  gap: 8px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;
const ButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 16px;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const FieldContainer = styled.div`
  position: relative;
  width: 100%;
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #424242;
  height: 55px;
  border-radius: 5px;
  border-radius: 5px;
  outline: none;
  padding: 20px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const Label = styled.label`
  font-size: 15px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 14px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e7e7e7;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const GroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const GroupVisibleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 66px;
  border-radius: 8px;
  background-color: #e7e7e7;
  padding: 8px 16px;
  &.open {
    border-radius: 8px 8px 0 0;
  }
`;
const VisibleLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
const OrderButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  img {
    cursor: pointer;
  }
`;
const VisibleText = styled.h3`
  font-size: 15px;
  font-weight: 500;
  font-family: "Roboto";
  color: #424242;
  @media (max-width: 768px) {
    font-size: 13px;
  }
`;
const VisibleRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
const Button = styled.button`
  min-width: 34px;
  min-height: 34px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #ffffff;
  transition: all 0.1s ease;
  :hover {
    filter: brightness(94%);
  }
  img {
    width: 18px;
  }
  &.secondary {
    background-color: transparent;
    border: 1px solid #e7e7e7;
  }
`;
const OpenButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #128297;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: rotate(0deg);
  &.open {
    transform: rotate(180deg);
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;
const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  max-height: 0px;
  opacity: 0;
  border-radius: 0 0 8px 8px;
  padding: 0 16px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  overflow: hidden;
  &.open {
    border: 1px solid #e7e7e7;
    padding: 24px 16px;
    max-height: 100%;
    opacity: 1;
  }
`;
const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const OrderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
const OrderDsiplay = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #128297;
  width: 26px;
  height: 26px;
  text-align: center;
  font-size: 15px;
  font-family: "Roboto";
  font-weight: 600;
  cursor: default;
`;
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;
const ContainerModal = styled.div`
  width: 432px;
  height: 80vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 40px 10px 40px;
  position: relative;
  transition: all 0.3s;
  overflow-y: scroll;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    padding: 10px 40px 20px 20px;
  }
`;
const ButtonsContainerModal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  min-width: 100%;
  margin-top: 40px;
  bottom: 20px;
  left: 0;
  @media (max-width: 768px) {
    width: calc(100vw - 50px);
    padding-right: 0px;
    gap: 8px;
  }
`;
const ButtonsComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: -87px;
  top: 11px;
`;
const ButtonRemove = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid var(--gray-02, #e7e7e7);
  background: var(--paleta-principal-corporative-principal-03, #fff);
  cursor: pointer;
`;
const ButtonPlus = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 5px;
  border: 1px solid var(--gray-02, #e7e7e7);
  background: var(--paleta-principal-corporative-principal-03, #fff);
  cursor: pointer;
`;
const TitleDesign = styled.h3`
  font-size: 18px;
  font-weight: 500;
  font-family: "Roboto";
  color: #424242;
  margin-top: 20px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
const LabelQuestion = styled.h3`
  color: var(--paleta-principal-corporative-principal-04, #424242);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: 27px;
  width: 100%;
`;
const RowTopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 16px;
`;
