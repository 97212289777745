import styled from "styled-components";
import { CenterContainerColumn } from "../../commonComponents/Layouts";
import Logo from "../../assets/img/logo.svg";
import LogoMobile from "../../assets/img/logoMobile.svg";
import BackgroundLogin from "../../assets/img/backgroundLogin.jpg";
import EmailIcon from "../../assets/img/emailIcon.svg";
import useResetPassword from "../hooks/useResetPassword";

export default function ResetPassword() {
  const {
    email,
    setEmail,
    resetPasswordConfirmed,
    confirmResetPassword,
    navigate,
  } = useResetPassword();

  return (
    <Container>
      <BackgroundContainer></BackgroundContainer>
      <ResetContainer>
        <LogoContainer>
          <img src={Logo} alt="Logo YPF SOLAR" />
        </LogoContainer>
        <LogoContainerMobile>
          <img src={LogoMobile} alt="Logo YPF SOLAR" />
        </LogoContainerMobile>
        {!resetPasswordConfirmed ? (
          <>
            <ResetForm>
              <CenterContainerColumn>
                <Title>Olvidé mi Contraseña</Title>
              </CenterContainerColumn>
              <InputContainer>
                <Image src={EmailIcon} alt="Email Icon" />
                <EmailInput
                  value={email}
                  placeholder="Email"
                  onChange={({ target: { value } }) => setEmail(value)}
                />
              </InputContainer>
              <ResetButton onClick={confirmResetPassword}>
                RESETEAR CONSTRASEÑA
              </ResetButton>
            </ResetForm>
            <LoginContainer>
              <LoginLink onClick={() => navigate("/login")}>
                Volver al login
              </LoginLink>
            </LoginContainer>
          </>
        ) : (
          <ResetForm>
            <CenterContainerColumn>
              <Title>Reseteo de contraseña exitoso</Title>
            </CenterContainerColumn>
            <TextContainer>
              <TextRegistrationConfirmed>
                Hemos enviado un mail para el cambio de contraseña exitosamente,
                por favor revisa tu bandeja de entrada y cambia tu contraseña.
              </TextRegistrationConfirmed>
            </TextContainer>
            <ResetButton onClick={() => navigate("/login")}>
              Ingresar aquí
            </ResetButton>
          </ResetForm>
        )}
      </ResetContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 485px;
  overflow-x: hidden;
  position: relative;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${window.innerHeight}px;
  }
`;
const BackgroundContainer = styled.div`
  display: flex;
  background-image: url(${BackgroundLogin});
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    position: absolute;
    width: 100%;
    min-height: ${window.innerHeight}px;
    z-index: 2;
  }
`;
const ResetContainer = styled.div`
  padding: 20px 0px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 768px) {
    position: absolute;
    width: calc(100% - 20px);
    min-height: calc(${window.innerHeight}px - 20px);
    z-index: 2;
    background: #ffffff;
    border-radius: 3px;
  }
`;
const LogoContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;
const LogoContainerMobile = styled.div`
  width: 100%;
  height: 100px;
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const ResetForm = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    min-height: calc(${window.innerHeight}px - 300px);
    justify-content: space-between;
  }
`;

const Title = styled.h1`
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  color: #128297;
  margin-bottom: 80px;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 20px;
  }
`;
const InputContainer = styled.div`
  position: relative;
`;
const Image = styled.img`
  position: absolute;
  top: 23px;
  left: 20px;
  width: 24px;
  @media (max-width: 768px) {
    top: 13px;
    left: 13px;
    width: 24px;
  }
`;
const EmailInput = styled.input`
  width: 364px;
  height: 64px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-bottom: 50px;
  font-size: 15px;
  line-height: 15px;
  padding: 0px 20px 0px 64px;
  color: #128297;
  ::placeholder {
    color: #128297;
  }
  :focus {
    outline: 1px solid #128297;
  }
  @media (max-width: 768px) {
    width: calc(100vw - 40px);
    height: 44px;
    border-radius: 5px;
    font-size: 12px;
    font-size: 12px;
    margin-bottom: 20px;
  }
`;
const ResetButton = styled.button`
  width: 200px;
  height: 55px;
  background: #128297;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  cursor: pointer;
  margin-bottom: 20px;
  &:focus {
    outline: 1px solid #29707d;
  }
  @media (max-width: 768px) {
    width: calc(100% - 20px);
    height: 43px;
    padding: 11px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const LoginContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;
const LoginLink = styled.p`
  font-family: "D-DIN-PRO-Bold";
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #128297;
  cursor: pointer;
  text-decoration: underline;
`;
const TextRegistrationConfirmed = styled.p`
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: #128297;
`;
const TextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 43px;
  margin-bottom: 80px;
`;
