import useAxios from "../../commonHooks/useAxios";
import { useEffect, useState, useContext } from "react";
import { notificationsContext } from "../../context/notificationsContext";
import { Lead, Product } from "../Interfaces";

export default function useEditUser({
  leadIdToEdit,
  setLeadIdToEdit,
  setRefreshLeads,
}: {
  leadIdToEdit: number | null;
  setLeadIdToEdit: (userIdToEdit: number | null) => void;
  setRefreshLeads: (refreshUsers: boolean) => void;
}) {
  const [createdAt, setCreatedAt] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [province, setProvince] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [contactDate, setContactDate] = useState<Date | null>(null);
  const [showDropDownInterest, setShowDropDownInterest] = useState<boolean>(false);
  const [interest, setInterest] = useState<string>("");
  const [showDropDownStatus, setShowDropDownStatus] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const [comments, setComments] = useState<string>("");
  const [errors, setErrors] = useState<{
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
  }>({});
  const getData = useAxios();
  const dtf = new Intl.DateTimeFormat("es-ES", {
    calendar: "gregory",
    numberingSystem: "latn",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
  const [height, setHeight] = useState<number>(window.innerWidth < 768 ? 34 : 50);
  const { setNotification } = useContext(notificationsContext);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response: any = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/leads/${leadIdToEdit}`,
        headers: {
          accept: "*/*",
        },
      });
      const leadData: Lead = response.lead;
      let arrayProducts: string[] = [];
      response?.products.forEach((product: Product) => {
        arrayProducts[product.products_id] = product.description ? product.description : product.name;
      });
      let productsLead: number[] = response.lead.products.split(",").map((product: string) => parseInt(product));
      let arrayProductsLead: string[] = productsLead.map((product: number) => arrayProducts[product]);
      setSelectedProducts(arrayProductsLead);
      setCreatedAt(dtf.format(new Date(leadData.created_at)));
      setContactDate(leadData.contacted_date ? new Date(leadData.contacted_date) : null);
      setFirstName(leadData.first_name);
      setlastName(leadData.last_name);
      setProvince(leadData.province);
      setCity(leadData.city);
      setEmail(leadData.email);
      setPhone(leadData.phone);
      setInterest(leadData.interest);
      setStatus(leadData.status);
      setComments(leadData.comments);
      setLoading(false);
    }
    if (leadIdToEdit) {
      fetchData();
    }
    if (window.innerWidth < 768) {
      setHeight(34);
    }
    window.addEventListener("resize", (e: any) => {
      if (e.target.window.innerWidth < 768) {
        setHeight(34);
      } else {
        setHeight(50);
      }
    });
    return () => {
      window.removeEventListener("resize", (e: any) => {
        if (e.target.window.innerWidth < 768) {
          setHeight(34);
        } else {
          setHeight(50);
        }
      });
    };
  }, []); // eslint-disable-line

  async function handleSubmit() {
    const errors: any = {};
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const data = {
        interest,
        contacted_date: contactDate,
        status,
        comments
      };
      try {
        const response = await getData({
          method: "PUT",
          url: `${process.env.REACT_APP_API_URL}/leads/${leadIdToEdit}`,
          headers: {
            accept: "*/*",
          },
          data,
        });
        if (response.message === "Lead updated") {
          setLoading(false);
          setNotification({
            theme: "success",
            titleNotification: "Edición exitosa",
            contentNotification: "El lead se editó correctamente.",
            isVisible: true,
          });
          setRefreshLeads(true);
          setLeadIdToEdit(null);
        } else {
          setNotification({
            theme: "error",
            titleNotification: "Edición fallida",
            contentNotification: "El lead no se pudo editar.",
            isVisible: true,
          });
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setNotification({
          theme: "error",
          titleNotification: "Edición fallida",
          contentNotification: "El lead no se pudo editar.",
          isVisible: true,
        });
        setLoading(false);
      }
    } else {
      setNotification({
        theme: "error",
        titleNotification: "Edición fallida",
        contentNotification: errors[Object.keys(errors)[0]],
        isVisible: true,
      });
    }
    setTimeout(() => {
      setErrors({});
    }, 3000);
  }

  return {
    createdAt,
    setCreatedAt,
    firstName,
    setFirstName,
    lastName,
    setlastName,
    province,
    setProvince,
    email,
    setEmail,
    phone,
    setPhone,
    loading,
    handleSubmit,
    errors,
    height,
    city,
    setCity,
    selectedProducts,
    contactDate,
    setContactDate,
    showDropDownInterest,
    setShowDropDownInterest,
    interest,
    setInterest,
    showDropDownStatus,
    setShowDropDownStatus,
    status,
    setStatus,
    comments,
    setComments
  };
}
