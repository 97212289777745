import { useContext, useEffect, useState } from "react";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";
import { userContext } from "../../context/userContext";
import { Agent, Category } from "../../agents/Interfaces";
import { Document } from "../Interfaces";
import { useNavigate, useParams } from "react-router-dom";

export default function useBindingDocuments() {
  const [agentName, setAgentName] = useState<string>("");
  const [category, setCategory] = useState<number>(999);
  const [eecc, setEecc] = useState<string>("");
  const [province, setProvince] = useState<string>("")
  const [instalator, setInstalator] = useState<number>(999)
  const [initialAgents, setInitialAgents] = useState<Agent[]>([]);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [agentsFiltered, setAgentsFiltered] = useState<Agent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [agentsPerPage, setAgentsPerPage] = useState<number>(10);
  const [refreshAgents, setRefreshAgents] = useState<boolean>(true);
  const [fieldSorted, setFieldSorted] = useState("agent_name");
  const [orderSorted, setOrderSorted] = useState("asc");
  const [document, setDocument] = useState<Document>({} as Document)
  const {
    userState: { user },
  } = useContext(userContext);
  const { setNotification } = useContext(notificationsContext);
  const [optionsType, setOptionsType] = useState<Category[]>([]);
  const { id } = useParams<{ id: string }>();
  const [allChecked, setAllChecked] = useState<boolean>(false);
  const [allMandatory, setAllMandatory] = useState<boolean>(false)
  const getData = useAxios();
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response: Agent[] = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/bindingdocuments/allagents/${id}`,
        headers: {
          accept: "*/*",
        },
      });
      const categoriesResponse: Category[] = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/categories`,
        headers: {
          accept: "*/*",
        },
      });
      const documentResponse: Document = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/documents/${id}`,
        headers: {
          accept: "*/*",
        },
      });
      setDocument(documentResponse);
      setOptionsType(categoriesResponse);
      const newAgents = response;
      const agentsSorted = sortItems(newAgents, "agent_name", "asc");
      setAgents(agentsSorted);
      setInitialAgents(agentsSorted);
      setAgentsFiltered(agentsSorted);
      setTotalPages(Math.ceil(agentsSorted.length / agentsPerPage));
      handlePage(1, agentsSorted);
      setLoading(false);
      setRefreshAgents(false);
    }
    if ((agents.length === 0 || refreshAgents) && user && id) {
      fetchData();
    }
  }, [refreshAgents, user, id]); // eslint-disable-line
  useEffect(() => {
    const documentsArray = sortItems(agentsFiltered, fieldSorted, orderSorted);
    handlePage(1, documentsArray);
  }, [fieldSorted, orderSorted]); // eslint-disable-line

  const handleSort = (field: string) => {
    let order = "asc";
    if (fieldSorted === field) {
      order = orderSorted === "asc" ? "desc" : "asc";
      setOrderSorted(order);
    } else {
      setFieldSorted(field);
      setOrderSorted(order);
    }
  };

  function sortItems(
    array: Agent[] = agentsFiltered,
    field: string = fieldSorted,
    order: string = orderSorted
  ) {
    if (field === "EECC") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          const aEECC = a?.EECC?.first_name ? a.EECC.first_name : "";
          const bEECC = b?.EECC?.first_name ? b.EECC.first_name : "";
          if (aEECC.toLowerCase() > bEECC.toLowerCase()) return 1;
          if (aEECC.toLowerCase() < bEECC.toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          const aEECC = a?.EECC?.first_name ? a.EECC.first_name : "";
          const bEECC = b?.EECC?.first_name ? b.EECC.first_name : "";
          if (aEECC.toLowerCase() < bEECC.toLowerCase()) return 1;
          if (aEECC.toLowerCase() > bEECC.toLowerCase()) return -1;
          return 0;
        });
      }
    } else if (field === "category") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          const aCategory = a?.category?.name ? a.category.name : "";
          const bCategory = b?.category?.name ? b.category.name : "";
          if (aCategory.toLowerCase() > bCategory.toLowerCase()) return 1;
          if (aCategory.toLowerCase() < bCategory.toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          const aCategory = a?.category?.name ? a.category.name : "";
          const bCategory = b?.category?.name ? b.category.name : "";
          if (aCategory.toLowerCase() < bCategory.toLowerCase()) return 1;
          if (aCategory.toLowerCase() > bCategory.toLowerCase()) return -1;
          return 0;
        });
      }
    } else if (field === "instalator") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a.instalator > b.instalator) return 1;
          if (a.instalator < b.instalator) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a.instalator < b.instalator) return 1;
          if (a.instalator > b.instalator) return -1;
          return 0;
        });
      }
    } else if (field === "checked" || field === "mandatory") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field] > b[field]) return 1;
          if (a[field] < b[field]) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field] < b[field]) return 1;
          if (a[field] > b[field]) return -1;
          return 0;
        });
      }
    } else {
        if (order === "asc") {
          array.sort(function (a: any, b: any) {
            if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
            if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
            return 0;
          });
        } else {
          array.sort(function (a: any, b: any) {
            if (a[field].toLowerCase() < b[field].toLowerCase()) return 1;
            if (a[field].toLowerCase() > b[field].toLowerCase()) return -1;
            return 0;
          });
        }
      }

      return array;
    }

    function handleDocumentsPerPage(
      documentsPerPage: number,
      agents: Agent[] = agentsFiltered
    ) {
      setTotalPages(Math.ceil(agents.length / documentsPerPage));
      handlePage(1, agents, documentsPerPage);
    }

    function handlePage(
      page: number,
      agents = agentsFiltered,
      totalDocumentsPerPage = agentsPerPage
    ) {
      setAgentsPerPage(totalDocumentsPerPage);
      setPage(page);
      const agentsSorted = sortItems(agents);
      const indexOfLastAgent = page * totalDocumentsPerPage;
      const indexOfFirstAgent = indexOfLastAgent - totalDocumentsPerPage;
      const currentAgents = agentsSorted.slice(indexOfFirstAgent, indexOfLastAgent);
      setAgents(currentAgents);
    }

    function handleFilter() {
      const agentNameFiltered = initialAgents.filter((agent: Agent) => {
        if (agent.agent_name) {
          return agent.agent_name
            .toLowerCase()
            .includes(agentName.toLowerCase());
        }
        return true;
      });
      const provinceFilter = agentNameFiltered.filter((agent: Agent) => {
        if (agent.province) {
          return agent.province.toString()
            .toLowerCase()
            .includes(province.toLowerCase());
        }
        return true;
      });
      const eeccFilter = provinceFilter.filter((agent: Agent) => {
        if (agent?.EECC) {
          const nameEECC = agent?.EECC.first_name + " " + agent?.EECC.last_name;
          return nameEECC.toLowerCase().includes(eecc.toLowerCase());
        } else if (!eecc) {
          return true;
        }
        else {
          return false;
        }
      });
      const typeFilter = eeccFilter.filter((agent: Agent) => {
        if (category === 999) {
          return true;
        } else if (category === agent.categories_id) {
          return true;
        } else {
          return false;
        }
      });
      const instalatorFilter = typeFilter.filter((agent: Agent) => {
        if (instalator === 999) {
          return true;
        } else if (instalator === 1 && agent.instalator === true) {
          return true;
        } else if (
          (instalator === 0 && agent.instalator === false) ||
          (instalator === 0 && agent.instalator === null)
        ) {
          return true;
        } else {
          return false;
        }
      });
      const everyChecked = instalatorFilter.length > 0 ? instalatorFilter.every((agent: Agent) => agent.checked) : false;
      const everyMandatory = instalatorFilter.length > 0 ? instalatorFilter.every((agent: Agent) => agent.mandatory) : false;
      setAllMandatory(everyMandatory);
      setAllChecked(everyChecked);
      handleDocumentsPerPage(agentsPerPage, instalatorFilter);
      setAgentsFiltered(instalatorFilter);
    }

    function handleEnter(e: any) {
      if (e.key === "Enter") {
        handleFilter();
      }
    }

    function handleChecked(agentId: string) {
      const agent = agents.find((agent: Agent) => agent.agents_id === agentId);
      const agentInitial = initialAgents.find((agent: Agent) => agent.agents_id === agentId);
      const agentFiltered = agentsFiltered.find((agent: Agent) => agent.agents_id === agentId);
      if (agent) {
        agent.checked = !agent.checked;
        setAgents([...agents]);
      }
      if (agentInitial) {
        agentInitial.checked = !agentInitial.checked;
        setInitialAgents([...initialAgents]);
      }
      if (agentFiltered) {
        agentFiltered.checked = !agentFiltered.checked;
        setAgentsFiltered([...agentsFiltered]);
      }
      const everyChecked = agentsFiltered.length > 0 ? agentsFiltered.every((agent: Agent) => agent.checked) : false;
      setAllChecked(everyChecked);
    }

    function handleMandatory(agentId: string) {
      const agent = agents.find((agent: Agent) => agent.agents_id === agentId);
      const agentInitial = initialAgents.find((agent: Agent) => agent.agents_id === agentId);
      const agentFiltered = agentsFiltered.find((agent: Agent) => agent.agents_id === agentId);
      if (agent) {
        agent.mandatory = !agent.mandatory;
        setAgents([...agents]);
      }
      if (agentInitial) {
        agentInitial.mandatory = !agentInitial.mandatory;
        setInitialAgents([...initialAgents]);
      }
      if (agentFiltered) {
        agentFiltered.mandatory = !agentFiltered.mandatory;
        setAgentsFiltered([...agentsFiltered]);
      }
      const everyMandatory = agentsFiltered.length > 0 ? agentsFiltered.every((agent: Agent) => agent.mandatory) : false;
      setAllMandatory(everyMandatory);
    }

    function handleCheckAll(agentsFilter: Agent[] = agentsFiltered) {
      if (allChecked) {
        const newAgentsFiltered = agentsFilter.map((agent: Agent) => {
          agent.checked = false;
          return agent;
        });
        const agentsIdUncheked = newAgentsFiltered.map((agent: Agent) => agent.agents_id);
        const newInitialAgents = initialAgents.map((agent: Agent) => {
          if (agentsIdUncheked.includes(agent.agents_id)) {
            agent.checked = false;
          }
          return agent;
        });
        const newAgents = agents.map((agent: Agent) => {
          if (agentsIdUncheked.includes(agent.agents_id)) {
            agent.checked = false;
          }
          return agent;
        });
        setAgents(newAgents);
        setInitialAgents(newInitialAgents);
        setAgentsFiltered(newAgentsFiltered);
        setAllChecked(false);
      } else {
        const newAgentsFiltered = agentsFilter.map((agent: Agent) => {
          agent.checked = true;
          return agent;
        });
        const agentsIdChecked = newAgentsFiltered.map((agent: Agent) => agent.agents_id);
        const newInitialAgents = initialAgents.map((agent: Agent) => {
          if (agentsIdChecked.includes(agent.agents_id)) {
            agent.checked = true;
          }
          return agent;
        });
        const newAgents = agents.map((agent: Agent) => {
          if (agentsIdChecked.includes(agent.agents_id)) {
            agent.checked = true;
          }
          return agent;
        });
        setAgents(newAgents);
        setInitialAgents(newInitialAgents);
        setAgentsFiltered(newAgentsFiltered);
        setAllChecked(true);
      }
    }

    function handleMandatoryAll(agentsFilter: Agent[] = agentsFiltered) {
      if (allMandatory) {
        const newAgentsFiltered = agentsFilter.map((agent: Agent) => {
          agent.mandatory = false;
          return agent;
        });
        const agentsIdUncheked = newAgentsFiltered.map((agent: Agent) => agent.agents_id);
        const newInitialAgents = initialAgents.map((agent: Agent) => {
          if (agentsIdUncheked.includes(agent.agents_id)) {
            agent.mandatory = false;
          }
          return agent;
        });
        const newAgents = agents.map((agent: Agent) => {
          if (agentsIdUncheked.includes(agent.agents_id)) {
            agent.mandatory = false;
          }
          return agent;
        });
        setAgents(newAgents);
        setInitialAgents(newInitialAgents);
        setAgentsFiltered(newAgentsFiltered);
        setAllMandatory(false);
      } else {
        const newAgentsFiltered = agentsFilter.map((agent: Agent) => {
          agent.mandatory = true;
          return agent;
        });
        const agentsIdChecked = newAgentsFiltered.map((agent: Agent) => agent.agents_id);
        const newInitialAgents = initialAgents.map((agent: Agent) => {
          if (agentsIdChecked.includes(agent.agents_id)) {
            agent.mandatory = true;
          }
          return agent;
        });
        const newAgents = agents.map((agent: Agent) => {
          if (agentsIdChecked.includes(agent.agents_id)) {
            agent.mandatory = true;
          }
          return agent;
        });
        setAgents(newAgents);
        setInitialAgents(newInitialAgents);
        setAgentsFiltered(newAgentsFiltered);
        setAllMandatory(true);
      }
    }

    async function saveBindings() {
      try {
        setLoading(true);
        const agents = await getData({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/bindingdocuments/save`,
          headers: {
            accept: "*/*",
          },
          data: {
            documents_id: id,
            agents: agentsFiltered,
          },
        });
        if (agents) {
          setNotification({
            theme: "success",
            titleNotification: "Guardado Exitoso",
            contentNotification: "Se guardó la vinculación de documento.",
            isVisible: true,
          });
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setNotification({
          theme: "error",
          titleNotification: "Guardado Fallido",
          contentNotification: "Error al guardar la vinculación de documento.",
          isVisible: true,
        });
      }
    }

    return {
      agentName,
      setAgentName,
      category,
      setCategory,
      eecc,
      setEecc,
      province,
      setProvince,
      instalator,
      setInstalator,
      agents,
      loading,
      handleFilter,
      agentsPerPage,
      handleDocumentsPerPage,
      page,
      handlePage,
      totalPages,
      setRefreshAgents,
      handleEnter,
      user,
      handleSort,
      orderSorted,
      fieldSorted,
      optionsType,
      document,
      handleChecked,
      handleMandatory,
      allChecked,
      handleCheckAll,
      allMandatory,
      handleMandatoryAll,
      saveBindings,
      navigate
    };
  }
