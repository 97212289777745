import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import Modal from "../../commonComponents/Modal";
import Button from "../../commonComponents/Button";
import useQuestionarieForm from "../hooks/useQuestionarieForm";

export default function QuestionarieForm({
  questionnaireIdToEdit,
  setQuestionnaireIdToEdit,
  setRefreshQuestionnaires,
  setCreateNewQuestionnaire,
}: {
  questionnaireIdToEdit?: string | null;
  setQuestionnaireIdToEdit: (agentId: string | null) => void;
  setRefreshQuestionnaires: (refresh: boolean) => void;
  setCreateNewQuestionnaire: (create: boolean) => void;
}) {
  const {
    loading,
    type,
    setType,
    handleSubmit,
    errors,
  } = useQuestionarieForm({
    questionnaireIdToEdit,
    setQuestionnaireIdToEdit,
    setRefreshQuestionnaires,
    setCreateNewQuestionnaire,
  });

  return (
    <Modal
      type="document"
      title={questionnaireIdToEdit ? "Editar Cuestionario" : "Nuevo Cuestionario"}
    >
      <Container>
        {loading ? (
          <LoadingContainer>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </LoadingContainer>
        ) : (
          <>
            <FieldsContainer>
              <FieldContainer>
                <Input
                  color={errors.type ? "#FF3D00" : undefined}
                  required
                  value={type}
                  onChange={({ target: { value } }) => setType(value)}
                />
                <Label color={errors.type ? "#FF3D00" : undefined}>
                  Cuestionario
                </Label>
              </FieldContainer>
            </FieldsContainer>
            <ButtonsContainer>
              <Button
                type="secondary"
                onClick={() => {
                  setQuestionnaireIdToEdit(null);
                  setCreateNewQuestionnaire(false);
                }}
              >
                Cancelar
              </Button>
              <Button type="primary" onClick={() => handleSubmit()}>
                Aceptar
              </Button>
            </ButtonsContainer>
          </>
        )}
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  width: 415px;
  height: 510px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 73px 40px 50px 40px;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    padding: 20px 10px 50px 10px;
    height: auto;
  }
`;
const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  width: 335px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  margin-top: 50px;
`;
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;
const Input = styled.input`
  width: 335px;
  height: 55px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const Label = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 11.5px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      line-height: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
