import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../commonHooks/useAxios";
import { Role } from "../../configs/Interfaces";
import { notificationsContext } from "../../context/notificationsContext";
import { userContext } from "../../context/userContext";

export default function useUserAuthorization({
  userIdToAuthorize,
  setUserIdToAuthorize,
  setRefreshUsers,
}: {
  userIdToAuthorize: string | null;
  setUserIdToAuthorize: (agentId: string | null) => void;
  setRefreshUsers: (refresh: boolean) => void;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [dateOfRequest, setDateOfRequest] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [showDropDownRole, setShowDropDownRole] = useState<boolean>(false);
  const [initialRole, setInitialRole] = useState<number | null>(null);
  const [role, setRole] = useState<number | null>(null);
  const [roles, setRoles] = useState<Role[]>([]);
  const axiosData = useAxios();
  const [errorRole, setErrorRole] = useState<boolean>(false);
  const dtf = new Intl.DateTimeFormat("es-ES", {
    calendar: "gregory",
    numberingSystem: "latn",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
  const [height, setHeight] = useState<number>(
    window.innerWidth < 768 ? 34 : 55
  );
  const { setNotification } = useContext(notificationsContext);
  const {
    userState: { user },
  } = useContext(userContext);
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await axiosData({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}/users/${userIdToAuthorize}`,
          headers: {
            accept: "*/*",
          },
        });
        const dataRoles: Role[] = await axiosData({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}/roles`,
          headers: {
            accept: "*/*",
          },
        });
        setRoles(dataRoles);
        setDateOfRequest(
          dtf.format(new Date(response.user.binding_request_at))
        );
        setFirstName(response.user.first_name);
        setLastName(response.user.last_name);
        setRole(response.user.roles_id);
        setInitialRole(response.user.roles_id);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    if (window.innerWidth < 768) {
      setHeight(34);
    }
    window.addEventListener("resize", (e: any) => {
      if (e.target.window.innerWidth < 768) {
        setHeight(34);
      } else {
        setHeight(55);
      }
    });
    return () => {
      window.removeEventListener("resize", (e: any) => {
        if (e.target.window.innerWidth < 768) {
          setHeight(34);
        } else {
          setHeight(55);
        }
      });
    };
  }, []); // eslint-disable-line

  async function handleSubmit() {
    const errors: any = {};
    if (!role) {
      setErrorRole(true);
      errors.role = "El perfil es requerido.";
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const data = {
        users_id: userIdToAuthorize,
        roles_id: role === 500 ? initialRole : role,
        binding_request_status: role === 500 ? "denegado" : "autorizado",
        admin_email: user?.email,
      };

      try {
        const response = await axiosData({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/bindingauthorization/`,
          headers: {
            accept: "*/*",
          },
          data,
        });
        if (response.users_id) {
          setNotification({
            theme: "success",
            titleNotification: "Autorización aprobada",
            contentNotification: `El usuario se vinculó correctamente.`,
            isVisible: true,
          });
          setLoading(false);
          setUserIdToAuthorize(null);
          setRefreshUsers(true);
          if (id) {
            navigate("/binding-requests");
          }
        } else {
          setNotification({
            theme: "error",
            titleNotification: "Error",
            contentNotification: `El usuario no se pudo vincular.`,
            isVisible: true,
          });
          setLoading(false);
          if (id) {
            navigate("/binding-requests");
          }
        }
      } catch (error) {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: `El usuario no se pudo vincular.`,
          isVisible: true,
        });
        setLoading(false);
        if (id) {
          navigate("/binding-requests");
        }
      }
    } else {
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: `El perfil del usuario es requerido.`,
        isVisible: true,
      });
    }
    setTimeout(() => {
      setErrorRole(false);
    }, 3000);
  }

  return {
    loading,
    dateOfRequest,
    firstName,
    lastName,
    showDropDownRole,
    setShowDropDownRole,
    errorRole,
    roles,
    role,
    setRole,
    handleSubmit,
    height,
  };
}
