import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "../../commonHooks/useAxios";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { notificationsContext } from "../../context/notificationsContext";

export default function useRegisterForm() {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmedPassword, setConfirmedPassword] = useState<string>("");
  const [registrationConfirmed, setRegistrationConfirmed] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<{
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
    password?: string;
    confirmedPassword?: string;
  }>({});
  const sendData = useAxios();
  const navigate = useNavigate();
  const auth = getAuth();
  const { setNotification } = useContext(notificationsContext);

  async function handleSubmit() {
    const regexEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regexPassword = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d@$!%*?&-_]{8,}$/;
    const errors: any = {};
    if (!firstName) {
      setErrors((prev: any) => ({
        ...prev,
        firstName: "El nombre es requerido.",
      }));
      errors.firstName = "El nombre es requerido.";
    }
    if (!lastName) {
      setErrors((prev: any) => ({
        ...prev,
        lastName: "El apellido es requerido.",
      }));
      errors.lastName = "El apellido es requerido.";
    }
    if (!phone || !Number.isInteger(Number(phone))) {
      setErrors((prev: any) => ({
        ...prev,
        phone: "El telefono debe ser válido.",
      }));
      errors.phone = "El telefono debe ser válido.";
    }
    if (!regexEmail.test(String(email).toLowerCase())) {
      setErrors((prev: any) => ({
        ...prev,
        email: "El email debe ser válido.",
      }));
      errors.email = "El email debe ser válido.";
    }
    if (!regexPassword.test(String(password))) {
      setErrors((prev: any) => ({
        ...prev,
        password:
          "La contraseña debe contener al menos 8 caracteres, una mayúscula y un número.",
      }));
      errors.password =
        "La contraseña debe contener al menos 8 caracteres, una mayúscula y un número.";
    }
    if (password !== confirmedPassword) {
      setErrors((prev: any) => ({
        ...prev,
        confirmedPassword: "Las contraseñas no coinciden.",
      }));
      errors.confirmedPassword = "Las contraseñas no coinciden.";
    }

    if (Object.keys(errors).length === 0) {
      try {
        setLoading(true);
        const user = await sendData({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/signup`,
          headers: {
            accept: "*/*",
          },
          data: {
            firstName,
            lastName,
            phone,
            email,
            password,
          },
        });
        if (user) {
          await signInWithEmailAndPassword(auth, email, password);
          setNotification({
            theme: "success",
            titleNotification: "Registro exitoso",
            contentNotification: "Se ha creado una cuenta para ti.",
            isVisible: true,
          });
          setLoading(false);
          setRegistrationConfirmed(true);
        } else {
          setNotification({
            theme: "error",
            titleNotification: "Registro fallido",
            contentNotification: "El email ya está en uso.",
            isVisible: true,
          });
          setLoading(false);
        }
      } catch (error) {
        setNotification({
          theme: "error",
          titleNotification: "Registro fallido",
          contentNotification: "El email ya está en uso.",
          isVisible: true,
        });
        setLoading(false);
      }
    } else {
      setNotification({
        theme: "error",
        titleNotification: "Registro fallido",
        contentNotification: errors[Object.keys(errors)[0]],
        isVisible: true,
      });
    }
    setTimeout(() => {
      setErrors({});
    }, 3000);
  }

  function handleEnter(e:any){
    if(e.key === "Enter"){
      handleSubmit();
    }
  }

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phone,
    setPhone,
    email,
    setEmail,
    password,
    setPassword,
    confirmedPassword,
    setConfirmedPassword,
    errors,
    handleSubmit,
    navigate,
    registrationConfirmed,
    loading,
    handleEnter,
  };
}
