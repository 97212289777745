import useAxios from "../../commonHooks/useAxios";
import { useEffect, useState, useContext } from "react";
import { notificationsContext } from "../../context/notificationsContext";
import { userContext } from "../../context/userContext";
import { BindingDocument } from './../Interfaces';
import { documentsNotContext } from "../../context/documentsNotContext";

export default function useMyDocuments() {
  const [bindingDocuments, setBindingDocuments] = useState<BindingDocument[]>([]);
  const [initialBindingDocuments, setInitialBindingDocuments] = useState<BindingDocument[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [bindingDocumentPerPage, setBindingDocumentPerPage] = useState<number>(5);
  const [refreshBindingDocument, setRefreshBindingDocument] = useState<boolean>(true);
  const [fieldSorted, setFieldSorted] = useState("binding_documents_id");
  const [orderSorted, setOrderSorted] = useState("desc");
  const [loadingApprove, setLoadingApprove] = useState<number | null>(null)
  const getData = useAxios();
  const { setNotification } = useContext(notificationsContext);
  const { setNotification: setDocumentNotification } = useContext(documentsNotContext);
  const {
    userState: { user, originalUser },
    setUserState
  } = useContext(userContext);
  const dtf = new Intl.DateTimeFormat("es-ES", {
    calendar: "gregory",
    numberingSystem: "latn",
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

  useEffect(() => {
    async function fetchData() {
      const response: BindingDocument[] = await getData({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/mydocuments`,
        headers: {
          accept: "*/*",
        },
        data: {
          agents_id: user?.agents_id,
        }
      });
      const documentsMandatoriesUnapproved = response.filter((document) => document.approved === false && document.mandatory === true);
      const documentsUnapproved = response.filter((document) => document.approved === false);
      if (documentsMandatoriesUnapproved.length === 0) {
        setUserState({ user: { ...user, hasMandatory: false }, originalUser: { ...originalUser } })
      }
      if(documentsUnapproved.length === 0) {
        setDocumentNotification({
          theme: "warning",
          titleNotification: "Documentos",
          contentNotification: "Tienes documentos pendientes de aprobación.",
          isVisible: false,
        });
      }
      const responseBindingDocument = response;
      const bindingDocumentsSorted = sortItems(responseBindingDocument, "binding_documents_id", "desc");
      setBindingDocuments(bindingDocumentsSorted);
      setInitialBindingDocuments(bindingDocumentsSorted);
      setTotalPages(Math.ceil(bindingDocumentsSorted.length / bindingDocumentPerPage));
      handlePage(1, bindingDocumentsSorted);
      setLoading(false);
      setRefreshBindingDocument(false);
      setLoadingApprove(null);
    }
    if (user && (bindingDocuments.length === 0 || refreshBindingDocument)) {
      fetchData();
    }
  }, [user, refreshBindingDocument]); // eslint-disable-line

  useEffect(() => {
    const newBindingDocumentArray = sortItems(bindingDocuments, fieldSorted, orderSorted);
    handlePage(1, newBindingDocumentArray);
  }, [fieldSorted, orderSorted]); // eslint-disable-line

  const handleSort = (field: string) => {
    let order = "asc";
    if (fieldSorted === field) {
      order = orderSorted === "asc" ? "desc" : "asc";
      setOrderSorted(order);
    } else {
      setFieldSorted(field);
      setOrderSorted(order);
    }
  };

  function sortItems(
    array: BindingDocument[] = bindingDocuments,
    field: string = fieldSorted,
    order: string = orderSorted
  ) {
    if (field === "binding_documents_id") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field] > b[field]) return 1;
          if (a[field] < b[field]) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field] < b[field]) return 1;
          if (a[field] > b[field]) return -1;
          return 0;
        });
      }
    } else {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() < b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() > b[field].toLowerCase()) return -1;
          return 0;
        });
      }
    }

    return array;
  }

  function handleDocumentTypesPerPage(bindingDocumentsPerPage: number) {
    setTotalPages(Math.ceil(initialBindingDocuments.length / bindingDocumentsPerPage));
    handlePage(1, initialBindingDocuments, bindingDocumentsPerPage);
  }

  function handlePage(
    page: number,
    bindingDocuments = initialBindingDocuments,
    totalBindingDocumentPerPage = bindingDocumentPerPage
  ) {
    setBindingDocumentPerPage(totalBindingDocumentPerPage);
    setPage(page);
    const indexOfLastDocument = page * totalBindingDocumentPerPage;
    const indexOfFirstDocument = indexOfLastDocument - totalBindingDocumentPerPage;
    const currentType = bindingDocuments.slice(indexOfFirstDocument, indexOfLastDocument);
    setBindingDocuments(currentType);
  }

  async function handleApproveDocument(bindingDocument: BindingDocument) {
    setLoadingApprove(bindingDocument.binding_documents_id);
    try {
      const response = await getData({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/mydocuments/approve`,
        headers: {
          accept: "*/*",
        },
        data: {
          agents_id: user?.agents_id,
          documents_id: bindingDocument.documents_id,
        },
      });
      if (response) {
        setRefreshBindingDocument(true);
        setNotification({
          theme: "success",
          titleNotification: "Documento Aceptado",
          contentNotification: "El documento fue aceptado correctamente.",
          isVisible: true,
        });
      } else {
        setLoadingApprove(null);
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: "El documento no pudo ser aceptado.",
          isVisible: true,
        });
      }
    } catch (error) {
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: "El documento no pudo ser aceptado.",
        isVisible: true,
      });
      setLoadingApprove(null);
    }
  }

  return {
    bindingDocuments,
    loading,
    handleDocumentTypesPerPage,
    page,
    totalPages,
    bindingDocumentPerPage,
    handlePage,
    setRefreshBindingDocument,
    handleSort,
    orderSorted,
    fieldSorted,
    dtf,
    handleApproveDocument,
    loadingApprove
  };
}
