import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import Modal from "../../commonComponents/Modal";
import useDocumentTypesForm from "../hooks/useDocumentTypesForm";
import Button from "../../commonComponents/Button";

export default function DocumentTypesForm({
  typeIdToEdit,
  setTypeIdToEdit,
  setRefreshTypes,
  setCreateNewType,
}: {
  typeIdToEdit?: number;
  setTypeIdToEdit: (roleIdToEdit: number | null) => void;
  setRefreshTypes: (refresh: boolean) => void;
  setCreateNewType: (createNewRole: boolean) => void;
}) {
  const {
    documentType,
    setDocumentType,
    errors,
    loading,
    handleSubmit,
    handleCancel,
    initialDocumentTypeName,
  } = useDocumentTypesForm({
    typeIdToEdit,
    setTypeIdToEdit,
    setRefreshTypes,
    setCreateNewType,
  });

  return (
    <Modal
      type="type"
      title={
        typeIdToEdit
          ? "Editando Tipo de Documento " + initialDocumentTypeName
          : "Nuevo Tipo de Documento"
      }
    >
      <Container>
        {loading ? (
          <LoadingContainer>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </LoadingContainer>
        ) : (
          <>
            <FieldContainer>
              <Input
                color={errors.documentType ? "#FF3D00" : undefined}
                required
                value={documentType}
                onChange={({ target: { value } }) => setDocumentType(value)}
              />
              <Label color={errors.documentType ? "#FF3D00" : undefined}>
                Tipo de Documento
              </Label>
            </FieldContainer>

            <ButtonsContainer>
              <Button type="secondary" onClick={handleCancel}>
                Cancelar
              </Button>
              <Button type="primary" onClick={handleSubmit}>
                Guardar
              </Button>
            </ButtonsContainer>
          </>
        )}
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  width: 415px;
  min-height: 335px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 53px 40px 20px 40px;
  position: relative;
  transition: all 0.3s;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    padding: 10px 40px 20px 20px;
  }
`;
const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
  position: relative;
  width: 335px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  min-width: 100%;
  margin-top: 40px;
  bottom: 20px;
  left: 0;
  @media (max-width: 768px) {
    width: calc(100vw - 50px);
    padding-right: 0px;
    gap: 8px;
  }
`;
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;
const Input = styled.input`
  width: 335px;
  height: 55px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const Label = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 11.5px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      line-height: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
