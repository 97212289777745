import styled from "styled-components";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Lead } from "../Interfaces";
import ExcelIcon from "../../assets/img/excelIcon.svg";

export default function LeadExcelExport({
  leads,
  fileName,
  closeExport,
  setLoadingExport,
  products,
}: any) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  interface LeadToExport {
    FECHA: string;
    NOMBRE: string;
    APELLIDO: string;
    PROVINCIA: string;
    LOCALIDAD: string;
    MAIL: string;
    CELULAR: string;
    PRODUCTOS: string;
    REPRESENTANTE: string;
    EECC: string;
    CONTACTADO: string;
    INTERÉS: string;
    ESTADO: string;
  }
  const dtf = new Intl.DateTimeFormat("es-ES", {
    calendar: "gregory",
    numberingSystem: "latn",
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });

  function setExportLeads(leads: Lead[]) {
    const newLeadsToExport: LeadToExport[] = [];
    leads.forEach((lead) => {
      const newLead: LeadToExport = {
        FECHA: dtf.format(new Date(lead.created_at)),
        NOMBRE: lead.first_name,
        APELLIDO: lead.last_name,
        PROVINCIA: lead.province,
        LOCALIDAD: lead.city,
        MAIL: lead.email,
        CELULAR: lead.phone,
        PRODUCTOS: lead.products
          .split(",")
          .map((product) => {
            return products[+product].description
              ? products[+product].description
              : products[+product].name;
          })
          .join(", "),
        REPRESENTANTE: lead.agent ? lead.agent.trade_name : "Sin distribuidor",
        EECC: lead.agent?.EECC
          ? lead.agent?.EECC?.first_name + " " + lead.agent?.EECC?.last_name
          : "Asesor Comercial",
        CONTACTADO: lead.contacted_date
          ? dtf.format(new Date(lead.contacted_date))
          : "-",
        INTERÉS: lead.interest ? lead.interest : "-",
        ESTADO: lead.status ? lead.status : "-",
      };
      newLeadsToExport.push(newLead);
    });
    return newLeadsToExport;
  }

  const exportToExcel = async () => {
    setLoadingExport(true);
    const newLeadsToExport = setExportLeads(leads);
    const ws = XLSX.utils.json_to_sheet(newLeadsToExport);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    closeExport();
    setLoadingExport(false);
  };

  return (
    <ExportIcon onClick={exportToExcel}>
      <img src={ExcelIcon} alt="export pdf" />
    </ExportIcon>
  );
}

const ExportIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  cursor: pointer;
  img {
    max-width: 80px;
    max-height: 80px;
  }
`;
