import styled from "styled-components";
export default function Toggle({
  onClick,
  checked,
}: {
  onClick?: () => void;
  checked?: boolean;
}) {
  return (
    <LabelSwitch onClick={onClick}>
      <InputCheckbox checked={checked} type="checkbox" />
      <SpanSlider></SpanSlider>
    </LabelSwitch>
    // <ButtonContainer color={type} onClick={onClick}>
    //   {children}
    // </ButtonContainer>
  );
}

const LabelSwitch = styled.label`
  display: block;
  width: 45px;
  height: 25px;
  position: relative;
`;
const InputCheckbox = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  &:checked + span:before {
    transform: translateX(20px);
    background-color: #128297;
  }
`;
const SpanSlider = styled.span`
  position: absolute;
  inset: 0;
  background-color: #e7e7e7db;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.5s ease-out;
  &:before {
    position: absolute;
    background: #e7e7e7;
    content: "";
    top: 3px;
    left: 3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #424242;
    transform: translateX(0);
    transition: all 0.5s ease-out;
  }
`;
