import styled from "styled-components";
import CloseNotificationIcon from "../assets/img/closeNotificationIcon.svg";
import { User } from "../configs/Interfaces";
import { useContext } from "react";
import { userContext } from "../context/userContext";
import { useNavigate } from "react-router-dom";

export default function ImpersonateNotification({
  admin,
  userToImpersonate,
}: {
  admin: User | undefined;
  userToImpersonate: User | undefined;
}) {
  const { userState, setUserState } = useContext(userContext);
  const navigate = useNavigate();
  function reverseImpersonate() {
    setUserState({
      user: userState.originalUser,
      originalUser: undefined,
    });
    navigate("/");
  }
  return (
    <NotificationContainer>
      <NotificationText>
        <TitleContainer>ADVERTENCIA</TitleContainer>
        <TitleContainer>-</TitleContainer> Usted{" "}
        {admin?.first_name + " " + admin?.last_name} está impersonando al
        usuario {userToImpersonate?.email}
      </NotificationText>
      <CloseNotificationIconContainer onClick={() => reverseImpersonate()}>
        <img src={CloseNotificationIcon} alt="close notification" />
      </CloseNotificationIconContainer>
    </NotificationContainer>
  );
}

const NotificationContainer = styled.div`
  width: 70%;
  height: 44px;
  padding: 15px 15px;
  border-radius: 5px;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 16;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  background-color: #ffb953;
  position: absolute;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    position: relative;
    padding: 10px 10px;
    height: auto;
    margin-left: 20px;
    margin-top: -10px;
    margin-bottom: 10px;
  }
`;

const NotificationText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 2px;
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 13px;
    line-height: 13px;
    width: calc(100% - 40px);
  }
`;
const TitleContainer = styled.p`
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  text-transform: uppercase;
  @media (max-width: 768px) {
    &:nth-child(2) {
      display: none;
    }
  }
`;
const CloseNotificationIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 34px;
  top: 18px;
  cursor: pointer;
  @media (max-width: 768px) {
    top: 26px;
  }
`;
