import { useContext, useEffect, useState } from "react";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";
import { Questionnaire } from "../Interfaces";

export default function useQuestionarieForm({
  questionnaireIdToEdit,
  setQuestionnaireIdToEdit,
  setRefreshQuestionnaires,
  setCreateNewQuestionnaire,
}: {
  questionnaireIdToEdit?: string | null;
  setQuestionnaireIdToEdit: (agentId: string | null) => void;
  setRefreshQuestionnaires: (refresh: boolean) => void;
  setCreateNewQuestionnaire: (createNewAgent: boolean) => void;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const axiosData = useAxios();
  const [errors, setErrors] = useState<{
    type?: string;
  }>({});
  const { setNotification } = useContext(notificationsContext);
  
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const documentResponse: Questionnaire = await axiosData({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}/questionnaires/${questionnaireIdToEdit}`,
          headers: {
            accept: "*/*",
          },
        });
        setType(documentResponse.type ? documentResponse.type : "");
        setLoading(false);
      } catch (error) {
        console.log(error);
        setQuestionnaireIdToEdit(null);
      }
    }
    if (questionnaireIdToEdit) {
      fetchData();
    } 
  }, []); // eslint-disable-line

  async function handleSubmit() {
    const errors: any = {};
    if (!type) {
      setErrors((prev: any) => ({
        ...prev,
        type: "El nombre del cuestionario es requerido.",
      }));
      errors.type = "El nombre del cuestionario es requerido.";
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const data = {
         type,
      };
      if (questionnaireIdToEdit) {
        try {
          const documentResponse = await axiosData({
            method: "PUT",
            url: `${process.env.REACT_APP_API_URL}/questionnaires/${questionnaireIdToEdit}`,
            headers: {
              accept: "*/*",
            },
            data,
          });
          if (documentResponse) {
            setLoading(false);
            setNotification({
              theme: "success",
              titleNotification: "Edición exitosa",
              contentNotification: "El cuestionario se editó correctamente.",
              isVisible: true,
            });
            setRefreshQuestionnaires(true);
            setQuestionnaireIdToEdit(null);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      } else {
        try {
          const documentResponse = await axiosData({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/questionnaires`,
            headers: {
              accept: "*/*",
            },
            data,
          });
          if (documentResponse) {
            setLoading(false);
            setNotification({  
              theme: "success",
              titleNotification: "Cuestionario creado",
              contentNotification: "El cuestionario se creó correctamente.",
              isVisible: true,
            });
            setRefreshQuestionnaires(true);
            setCreateNewQuestionnaire(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    } else {
      if (questionnaireIdToEdit) {
        setNotification({
          theme: "error",
          titleNotification: "Edición fallida",
          contentNotification: errors[Object.keys(errors)[0]],
          isVisible: true,
        });
      } else {
        setNotification({
          theme: "error",
          titleNotification: "Creación fallida",
          contentNotification: errors[Object.keys(errors)[0]],
          isVisible: true,
        });
      }
    }
    setTimeout(() => {
      setErrors({});
    }, 3000);
  }


  return {
    loading,
    type,
    setType,
    handleSubmit,
    errors,
  };
}
