import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import useMyDocuments from "../hooks/useMyDocuments";
import ClassIcon from "../../assets/img/classIcon.svg";
import Pagination from "../../commonComponents/Pagination";
import { BindingDocument } from "../Interfaces";
export default function MyDocuments() {
  const {
    bindingDocuments,
    loading,
    handleDocumentTypesPerPage,
    page,
    totalPages,
    bindingDocumentPerPage,
    handlePage,
    dtf,
    handleApproveDocument,
    loadingApprove,
  } = useMyDocuments();

  return (
    <Container>
      <Title>Documentos Vinculados</Title>
      <MainContainer>
        <TopContainer>
          <IconContainer>
            <img src={ClassIcon} alt="DocumentType Icon" />
          </IconContainer>
        </TopContainer>
        {bindingDocuments.length === 0 && !loading && (
          <TextContainer>
            <Text>No posees documentos vinculados.</Text>
          </TextContainer>
        )}

        {bindingDocuments.length > 0 && !loading && (
          <TableContainer>
            <Table>
              <thead>
                <TRH>
                  <TH>DOCUMENTO</TH>
                  <TH>FECHA</TH>
                  <TH>TIPO</TH>
                  <TH>VERSIÓN</TH>
                  <TH>VER DOCUMENTO</TH>
                  <TH>ESTADO</TH>
                  <TH>ACCIÓN</TH>
                </TRH>
              </thead>
              <tbody>
                {bindingDocuments?.map((bindingDocument: BindingDocument) => (
                  <TR key={bindingDocument.binding_documents_id}>
                    <TD>{bindingDocument.document.name}</TD>
                    <TD>{dtf.format(new Date(bindingDocument.date))}</TD>
                    <TD>{bindingDocument.document.document_type.name}</TD>
                    <TD>{bindingDocument.document.version}</TD>
                    <TD style={{ textTransform: "none" }}>
                      <a
                        href={bindingDocument.document.link}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {bindingDocument.document.link}
                      </a>
                    </TD>
                    <TD>
                      <StatusContainer
                        status={bindingDocument.approved ? "active" : "pending"}
                      >
                        {bindingDocument.approved ? "Aceptado" : "Pendiente"}{" "}
                      </StatusContainer>{" "}
                    </TD>
                    <TD>
                      <ActionsContainer>
                        {!bindingDocument.approved &&
                          (loadingApprove ===
                          bindingDocument.binding_documents_id ? (
                            <Loading>
                              <CircularProgress
                                sx={{
                                  color: "#128297",
                                }}
                                size={20}
                              />
                            </Loading>
                          ) : (
                            <ButtonContainer
                              color="primary"
                              onClick={() =>
                                handleApproveDocument(bindingDocument)
                              }
                            >
                              ACEPTAR
                            </ButtonContainer>
                          ))}
                      </ActionsContainer>
                    </TD>
                  </TR>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        )}
        {loading && (
          <>
            <Loading>
              <CircularProgress
                sx={{
                  color: "#128297",
                }}
                size={100}
              />
            </Loading>
          </>
        )}
        {bindingDocuments.length > 0 && !loading && (
          <PaginationContainer>
            <SelectTypesPerPageContainer>
              <SelectTypesPerPage>
                <SelectTypesPerPageField
                  value={bindingDocumentPerPage}
                  onChange={({ target: { value } }) =>
                    handleDocumentTypesPerPage(+value)
                  }
                >
                  <OptionPage value={5}>5 filas</OptionPage>
                  <OptionPage value={10}>10 filas</OptionPage>
                  <OptionPage value={20}>20 filas</OptionPage>
                  <OptionPage value={50}>50 filas</OptionPage>
                </SelectTypesPerPageField>
              </SelectTypesPerPage>
            </SelectTypesPerPageContainer>
            <Pagination
              page={page}
              handlePage={handlePage}
              totalPages={totalPages}
            />
          </PaginationContainer>
        )}
      </MainContainer>
    </Container>
  );
}
const Container = styled.div`
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  margin-top: 38px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
  }
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 60px 10px 25px 10px;
  margin-bottom: 60px;
  position: relative;
  @media (max-width: 768px) {
    margin-top: 40px;
    padding: 30px 10px 25px 10px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: -17px;
    left: 10px;
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;
const TableContainer = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2px;
`;
const TR = styled.tr`
  height: 61px;
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
  @media (max-width: 768px) {
    height: 33px;
  }
`;
const TRH = styled.tr`
  height: 61px;
  :nth-child(even) {
    background-color: #f9f9f9;
  }
`;
const TD = styled.td`
  padding: 14px 0px 13px 20px;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
`;
const TH = styled.th`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  padding: 23px 20px;
  color: #128297;
  text-transform: uppercase;
  text-align: start;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
`;
const SelectTypesPerPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const SelectTypesPerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OptionPage = styled.option`
  font-size: 14px;
  color: #383838;
`;
const SelectTypesPerPageField = styled.select`
  width: 80px;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  padding: 8px 10px 8px 20px;
  font-size: 14px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  cursor: pointer;
`;
const ButtonContainer = styled.button`
  background-color: ${({ color }: { color: string }) => {
    switch (color) {
      case "primary":
        return "#128297";
      case "secondary":
        return "#ffffff";
      case "disabled":
        return "#1282974d";
      case "back":
        return "#505050";
      default:
        return "#128297";
    }
  }};
  color: ${({ color }: { color: string }) => {
    switch (color) {
      case "primary":
        return "#ffffff";
      case "secondary":
        return "#128297";
      default:
        return "#ffffff";
    }
  }};
  border: ${({ color }: { color: string }) => {
    switch (color) {
      case "primary":
        return "none";
      case "secondary":
        return "1px solid #128297";
      default:
        return "none";
    }
  }};
  border-radius: 6px;
  text-transform: uppercase;
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 13px;
  padding: 12px 20px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
  :focus {
    outline: none;
  }
  @media (max-width: 768px) {
    padding: 12px 16px;
    font-size: 11px;
    line-height: 13px;
  }
`;

const StatusContainer = styled.div<{ status: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 90px;
  background: ${({ status }) => {
    switch (status) {
      case "active":
        return "rgba(120, 205, 128, 0.2)";
      case "pending":
        return "rgba(255, 185, 83, 0.2)";
      default:
        return "rgba(120, 205, 128, 0.2)";
    }
  }};
  border: 1px solid
    ${({ status }) => {
      switch (status) {
        case "active":
          return "#78CD80";
        case "pending":
          return "#FFB953";
        default:
          return "#78CD80";
      }
    }};
  border-radius: 5px;
  padding: 10px 10px;
`;
const TextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-left: 50px;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    padding-left: 10px;
  }
`;
const Text = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #3c4858;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }
`;
