import useAxios from "../../commonHooks/useAxios";
import { useEffect, useState } from "react";
import { Lead, Product } from "../Interfaces";

export default function useShowLead({
  leadIdToEdit,
  setLeadIdToEdit,
  setRefreshLeads,
}: {
  leadIdToEdit: number | null;
  setLeadIdToEdit: (userIdToEdit: number | null) => void;
  setRefreshLeads: (refreshUsers: boolean) => void;
}) {
  const [createdAt, setCreatedAt] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [province, setProvince] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [contactDate, setContactDate] = useState<Date | null>(null);
  const [interest, setInterest] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [comments, setComments] = useState<string>("");
  const getData = useAxios();
  const dtf = new Intl.DateTimeFormat("es-ES", {
    calendar: "gregory",
    numberingSystem: "latn",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response: any = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/leads/${leadIdToEdit}`,
        headers: {
          accept: "*/*",
        },
      });
      const leadData: Lead = response.lead;
      let arrayProducts: string[] = [];
      response?.products.forEach((product: Product) => {
        arrayProducts[product.products_id] = product.description ? product.description : product.name;
      });
      let productsLead: number[] = response.lead.products.split(",").map((product: string) => parseInt(product));
      let arrayProductsLead: string[] = productsLead.map((product: number) => arrayProducts[product]);
      setSelectedProducts(arrayProductsLead);
      setCreatedAt(dtf.format(new Date(leadData.created_at)));
      setContactDate(leadData.contacted_date ? new Date(leadData.contacted_date) : null);
      setFirstName(leadData.first_name);
      setlastName(leadData.last_name);
      setProvince(leadData.province);
      setCity(leadData.city);
      setEmail(leadData.email);
      setPhone(leadData.phone);
      setInterest(leadData.interest);
      setStatus(leadData.status);
      setComments(leadData.comments);
      setLoading(false);
    }
    if (leadIdToEdit) {
      fetchData();
    }
  }, []); // eslint-disable-line

  return {
    createdAt,
    setCreatedAt,
    firstName,
    setFirstName,
    lastName,
    setlastName,
    province,
    setProvince,
    email,
    setEmail,
    phone,
    setPhone,
    loading,
    city,
    setCity,
    selectedProducts,
    contactDate,
    setContactDate,
    interest,
    setInterest,
    status,
    setStatus,
    comments,
    setComments,
  };
}
