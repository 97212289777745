import { useContext, useEffect, useState } from "react";
import useAxios from "../../commonHooks/useAxios";
import { userContext } from "../../context/userContext";
import { User } from "../../configs/Interfaces";
import { useParams } from "react-router-dom";

export default function useBindingRequestList() {
  const [creatorName, setCreatorName] = useState<string>("");
  const [creatorLastName, setCreatorLastName] = useState<string>("");
  const [creatorEmail, setCreatorEmail] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [initialUsers, setInitialUsers] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [usersFiltered, setUsersFiltered] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [usersPerPage, setUsersPerPage] = useState<number>(10);
  const [refreshUsers, setRefreshUsers] = useState<boolean>(true);
  const [usersIdToAuthorize, setUsersIdToAuthorize] = useState<string | null>(
    null
  );
  const [userIdToViewLogs, setUserIdToViewLogs] = useState<string | null>(null);
  const getData = useAxios();
  const [fieldSorted, setFieldSorted] = useState("created_at");
  const [orderSorted, setOrderSorted] = useState("desc");
  const {
    userState: { user },
  } = useContext(userContext);
  const dtf = new Intl.DateTimeFormat("es-ES", {
    calendar: "gregory",
    numberingSystem: "latn",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  const { id } = useParams();
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response: User[] = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/users`,
        headers: {
          accept: "*/*",
        },
      });
      const bindings = response.filter(
        (user) => user.binding_request_status !== null && user.agent !== null
      );
      const bindingsSorted = sortItems(bindings, "created_at", "desc");
      setUsers(bindingsSorted);
      setInitialUsers(bindingsSorted);
      setUsersFiltered(bindingsSorted);
      setTotalPages(Math.ceil(bindingsSorted.length / usersPerPage));
      handlePage(1, bindingsSorted);
      setLoading(false);
      setRefreshUsers(false);
    }
    if ((users.length === 0 || refreshUsers) && user) {
      fetchData();
    }
    if (id) {
      setUsersIdToAuthorize(id);
    }
  }, [refreshUsers, user]); // eslint-disable-line

  useEffect(() => {
    const newUsersArray = sortItems(usersFiltered, fieldSorted, orderSorted);
    handlePage(1, newUsersArray);
  }, [fieldSorted, orderSorted]); // eslint-disable-line

  const handleSort = (field: string) => {
    let order = "asc";
    if (fieldSorted === field) {
      order = orderSorted === "asc" ? "desc" : "asc";
      setOrderSorted(order);
    } else {
      setFieldSorted(field);
      setOrderSorted(order);
    }
  };

  function sortItems(
    array: any = usersFiltered,
    field: string = fieldSorted,
    order: string = orderSorted
  ) {
    if (field === "role") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field].name.toLowerCase() > b[field].name.toLowerCase())
            return 1;
          if (a[field].name.toLowerCase() < b[field].name.toLowerCase())
            return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field].name.toLowerCase() < b[field].name.toLowerCase())
            return 1;
          if (a[field].name.toLowerCase() > b[field].name.toLowerCase())
            return -1;
          return 0;
        });
      }
    } else if (field === "agent") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          const nameA = a[field]?.agent_name
            ? a[field]?.agent_name.toLowerCase()
            : "ZZZZZZZZZZZZZZZ";
          const nameB = b[field]?.agent_name
            ? b[field]?.agent_name.toLowerCase()
            : "ZZZZZZZZZZZZZZZ";

          if (nameA > nameB) return 1;
          if (nameA < nameB) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          const nameA = a[field]?.agent_name
            ? a[field]?.agent_name.toLowerCase()
            : "ZZZZZZZZZZZZZZZ";
          const nameB = b[field]?.agent_name
            ? b[field]?.agent_name.toLowerCase()
            : "ZZZZZZZZZZZZZZZ";
          if (nameA < nameB) return 1;
          if (nameA > nameB) return -1;
          return 0;
        });
      }
    } else {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() < b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() > b[field].toLowerCase()) return -1;
          return 0;
        });
      }
    }

    return array;
  }

  function handleusersPerPage(
    userPerPage: number,
    users: User[] = usersFiltered
  ) {
    setTotalPages(Math.ceil(users.length / userPerPage));
    handlePage(1, users, userPerPage);
  }

  function handlePage(
    page: number,
    users = usersFiltered,
    totalusersPerPage = usersPerPage
  ) {
    setUsersPerPage(totalusersPerPage);
    setPage(page);
    const usersSorted = sortItems(users);
    const indexOfLastUser = page * totalusersPerPage;
    const indexOfFirstUser = indexOfLastUser - totalusersPerPage;
    const currentagents = usersSorted.slice(indexOfFirstUser, indexOfLastUser);
    setUsers(currentagents);
  }

  function handleFilter() {
    const firstNameFilter = initialUsers.filter((user: User) => {
      if (user.first_name) {
        return user.first_name
          .toLowerCase()
          .includes(creatorName.toLowerCase());
      }
      return true;
    });
    const lastNameFilter = firstNameFilter.filter((user: User) => {
      if (user.last_name) {
        return user.last_name
          .toLowerCase()
          .includes(creatorLastName.toLowerCase());
      }
      return true;
    });
    const creatorEmailFilter = lastNameFilter.filter((user: User) => {
      if (user.email) {
        return user.email.includes(creatorEmail.toLowerCase());
      }
      return true;
    });
    const statusFilter = creatorEmailFilter.filter((user: User) => {
      if (status === "todos") {
        return true;
      } else if (user.binding_request_status) {
        return user.binding_request_status
          .toLowerCase()
          .includes(status.toLowerCase());
      }
      return true;
    });
    handleusersPerPage(usersPerPage, statusFilter);
    setUsersFiltered(statusFilter);
  }

  function handleEnter(e: any) {
    if (e.key === "Enter") {
      handleFilter();
    }
  }

  return {
    creatorName,
    setCreatorName,
    creatorLastName,
    setCreatorLastName,
    creatorEmail,
    setCreatorEmail,
    status,
    setStatus,
    handleFilter,
    users,
    setUsersIdToAuthorize,
    loading,
    usersPerPage,
    handleusersPerPage,
    page,
    totalPages,
    handlePage,
    usersIdToAuthorize,
    setRefreshUsers,
    dtf,
    userIdToViewLogs,
    setUserIdToViewLogs,
    handleEnter,
    handleSort,
    orderSorted,
    fieldSorted,
  };
}
