import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import ButtonComponent from "../../commonComponents/Button";
import FeedIcon from "../../assets/img/questionnaireIcon.svg";
import ArrowIcon from "../../assets/img/arrowAcordeon.svg";
import CustomTable from "../../questionnaires/components/CustomTable";
import StaticTable from "../../questionnaires/components/StaticTable";
import { Group, Question } from "../Interfaces";
import TypeInput from "../../questionnaires/components/TypeInput";
import useCertificationOrdersView from "../hooks/useCertificationOrdersView";
import checkIcon from "../../assets/img/check.svg";
import crossIcon from "../../assets/img/crossIcon.svg";
import advertenciaIcon from "../../assets/img/advertencia.png";

export default function CertificationOrdersOnlyView() {
  const {
    loading,
    groups,
    opened,
    toggleGroup,
    navigate,
    obra,
    setObra,
    agent,
    setAgent,
    client,
    setClient,
    ubication,
    setUbication,
    setGroups,
    order,
    handleSave,
    percentageGroups,
    isCompleted,
    handleSend,
    setNewObservation,
    percentageApprovedGroups,
    iconGroups,
  } = useCertificationOrdersView();

  return (
    <Container>
      <Title>Certificado{order?.type && `: ${order.type}`}</Title>
      <MainContainer>
        <TopContainer>
          <IconContainer>
            <img src={FeedIcon} alt="order icon" />
          </IconContainer>
        </TopContainer>
        <IdContainer>
          <IdText>ID: {order?.orders_id?.slice(0, 10)}</IdText>
        </IdContainer>
        <FilterContainer>
          <FieldContainer>
            <Input
              required
              value={obra}
              onChange={(e) => setObra(e.target.value)}
            />
            <Label>OBRA</Label>
          </FieldContainer>
          <FieldContainer>
            <Input
              required
              value={agent}
              onChange={(e) => setAgent(e.target.value)}
            />
            <Label>INSTALADOR</Label>
          </FieldContainer>
          <FieldContainer>
            <Input
              required
              value={client}
              onChange={(e) => setClient(e.target.value)}
            />
            <Label>CLIENTE</Label>
          </FieldContainer>
          <FieldContainer>
            <Input
              required
              value={ubication}
              onChange={(e) => setUbication(e.target.value)}
            />
            <Label>UBICACIÓN</Label>
          </FieldContainer>
        </FilterContainer>
        <Separator />

        {!loading ? (
          <GroupsContainer>
            {groups.map((group: Group, index) =>
              group?.questions?.length ? (
                <GroupContainer>
                  <GroupVisibleContainer
                    className={opened.includes(group.id) ? "open" : ""}
                  >
                    <VisibleLeft>
                      <VisibleText>{group.name}</VisibleText>
                    </VisibleLeft>
                    <VisibleRight>
                      <StatePercentage>
                        <StateText>Aprobación:</StateText>
                        <PercentageText>
                          {percentageApprovedGroups[index]}%
                        </PercentageText>
                      </StatePercentage>
                      <StatePercentage>
                        <StateText>Completitud:</StateText>
                        <PercentageText>
                          {percentageGroups[index]}%
                        </PercentageText>
                      </StatePercentage>
                      {iconGroups[index] ? (
                        <StatePercentage style={{ paddingRight: 16 }}>
                          <img
                            style={{ width: "32px" }}
                            src={advertenciaIcon}
                            alt="Advertencia"
                          />
                        </StatePercentage>
                      ) : (
                        ""
                      )}
                      <OpenButton
                        onClick={() => toggleGroup(group.id)}
                        className={opened.includes(group.id) ? "open" : ""}
                      >
                        <img src={ArrowIcon} alt="arrow Icon" />
                      </OpenButton>
                    </VisibleRight>
                  </GroupVisibleContainer>
                  <QuestionsContainer
                    className={opened.includes(group.id) ? "open" : ""}
                  >
                    {group.questions &&
                      group.questions.map((question: Question) => (
                        <QuestionContainer>
                          <TopQuestionContainer>
                            <OrderContainer>
                              <OrderDsiplay>{question.order}</OrderDsiplay>
                            </OrderContainer>
                            <VisibleRight
                              style={{
                                width: "100%",
                                paddingLeft: "16px",
                                justifyContent: "space-between",
                              }}
                            >
                              <LabelQuestion>{question.name}</LabelQuestion>
                              <RowTopContainer>
                                <TypeInput
                                  groupId={group.id}
                                  groups={groups}
                                  setGroups={setGroups}
                                  question={question}
                                  disabled={true}
                                  order={order}
                                  setNewObservation={setNewObservation}
                                />
                              </RowTopContainer>
                              <StatusIconContainer>
                                {(question.status === "pendiente" ||
                                  !question.status) && (
                                  <StatusIcon className="pendiente"></StatusIcon>
                                )}
                                {question.status === "aprobado" && (
                                  <StatusIcon>
                                    <img src={checkIcon} alt="Check" />
                                  </StatusIcon>
                                )}
                                {question.status === "rechazado" && (
                                  <StatusIcon className="rechazado">
                                    <img src={crossIcon} alt="Check" />
                                  </StatusIcon>
                                )}
                              </StatusIconContainer>
                            </VisibleRight>
                          </TopQuestionContainer>
                          <TableContainer>
                            {question.type === "freetable" && (
                              <CustomTable
                                disabled={true}
                                headers={question.headers || []}
                                rows={question.rows || []}
                                setRows={(rows) => {
                                  const newRows = rows;
                                  const newGroups = [...groups!];
                                  const groupIndex = newGroups.findIndex(
                                    (groupArray) => groupArray.id === group.id
                                  );
                                  const questionIndex = newGroups?.[
                                    groupIndex
                                  ]?.questions?.findIndex(
                                    (question2) => question2.id === question.id
                                  );
                                  const questions =
                                    newGroups?.[groupIndex]?.questions;
                                  if (questions) {
                                    questions[questionIndex!].rows = newRows;
                                  }
                                  newGroups[groupIndex].questions = questions;
                                  setGroups!(newGroups);
                                }}
                              />
                            )}
                            {question.type === "statictable" && (
                              <StaticTable
                                disabled={true}
                                headers={question.headers || []}
                                rows={question.rows || []}
                                setRows={(rows) => {
                                  const newRows = rows;
                                  const newGroups = [...groups!];
                                  const groupIndex = newGroups.findIndex(
                                    (groupArray) => groupArray.id === group.id
                                  );
                                  const questionIndex = newGroups?.[
                                    groupIndex
                                  ]?.questions?.findIndex(
                                    (question2) => question2.id === question.id
                                  );
                                  const questions =
                                    newGroups?.[groupIndex]?.questions;
                                  if (questions) {
                                    questions[questionIndex!].rows = newRows;
                                  }
                                  newGroups[groupIndex].questions = questions;
                                  setGroups!(newGroups);
                                }}
                                nameRows={question.nameRows || []}
                              />
                            )}
                          </TableContainer>
                        </QuestionContainer>
                      ))}
                  </QuestionsContainer>
                </GroupContainer>
              ) : null
            )}
          </GroupsContainer>
        ) : (
          <Loading>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </Loading>
        )}
      </MainContainer>
      <BottomContainer>
        <ButtonContainer
          style={{
            zIndex: 1000,
          }}
        >
          <ButtonComponent
            onClick={() => {
              navigate(-1);
            }}
            type={"secondary"}
          >
            ATRAS
          </ButtonComponent>
        </ButtonContainer>
      </BottomContainer>
    </Container>
  );
}
const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
  padding: 0px 20px;
  @media (max-width: 768px) {
    padding: 0px 10px;
    align-items: center;
    justify-content: center;
  }
`;
const TopQuestionContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;
const TableContainer = styled.div`
  width: 100%;
`;
const Container = styled.div`
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  margin-top: 38px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
  }
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 24px 10px 25px 10px;
  margin-bottom: 60px;
  position: relative;
  margin-top: 50px;
  @media (max-width: 768px) {
    margin-top: 40px;
    padding: 30px 10px 25px 10px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: -17px;
    left: 10px;
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 16px;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const FieldContainer = styled.div`
  position: relative;
  width: 100%;
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #424242;
  height: 55px;
  border-radius: 5px;
  border-radius: 5px;
  outline: none;
  padding: 20px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const Label = styled.label`
  font-size: 15px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 14px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e7e7e7;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const GroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const GroupVisibleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 66px;
  border-radius: 8px;
  background-color: #e7e7e7;
  padding: 8px 16px;
  &.open {
    border-radius: 8px 8px 0 0;
  }
`;
const VisibleLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
const VisibleText = styled.h3`
  font-size: 15px;
  font-weight: 500;
  font-family: "Roboto";
  color: #424242;
  @media (max-width: 768px) {
    font-size: 13px;
  }
`;
const VisibleRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
const OpenButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #128297;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: rotate(0deg);
  &.open {
    transform: rotate(180deg);
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;
const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  max-height: 0px;
  opacity: 0;
  border-radius: 0 0 8px 8px;
  padding: 0 16px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  &.open {
    border: 1px solid #e7e7e7;
    padding: 24px 16px;
    max-height: 100%;
    opacity: 1;
  }
`;
const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const OrderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
const OrderDsiplay = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #128297;
  width: 26px;
  height: 26px;
  text-align: center;
  font-size: 15px;
  font-family: "Roboto";
  font-weight: 600;
  cursor: default;
`;
const StatePercentage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  padding-right: 64px;
`;
const StateText = styled.h3`
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`;
const PercentageText = styled.h3`
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const StatusIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
`;
const StatusIcon = styled.div`
  width: 34px;
  height: 34px;
  border-radius: 17px;
  border: 1px solid #78cd80;
  display: flex;
  justify-content: center;
  align-items: center;
  &.pendiente {
    border: 1px solid #7a7a7a;
  }
  &.rechazado {
    border: 1px solid #eb5151;
  }
`;
const LabelQuestion = styled.h3`
  color: var(--paleta-principal-corporative-principal-04, #424242);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  line-height: 27px;
  width: 100%;
`;
const RowTopContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 16px;
`;
const IdContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;
const IdText = styled.h3`
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #424242;
`;
