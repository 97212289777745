import useAxios from "../../commonHooks/useAxios";
import { useEffect, useState, useContext } from "react";
import { Role } from "../Interfaces";
import { notificationsContext } from "../../context/notificationsContext";

export default function useEditUser({
  userIdToEdit,
  setUserIdToEdit,
  setRefreshUsers,
}: {
  userIdToEdit: string | null;
  setUserIdToEdit: (userIdToEdit: string | null) => void;
  setRefreshUsers: (refreshUsers: boolean) => void;
}) {
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setlastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [role, setRole] = useState<number>(1);
  const [createdAt, setCreatedAt] = useState<string>("");
  const [registerType, setRegisterType] = useState<string>("Email");
  const [lastLogin, setLastLogin] = useState<string>("");
  const [isEECC, setIsEECC] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [roles, setRoles] = useState<Role[]>([]);
  const [showDropDownRole, setshowDropDownRole] = useState<boolean>(false);
  const [initialEmail, setInitialEmail] = useState<string>("");
  const [errors, setErrors] = useState<{
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
  }>({});
  const getData = useAxios();
  const dtf = new Intl.DateTimeFormat("es-ES", {
    calendar: "gregory",
    numberingSystem: "latn",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
  const [height, setHeight] = useState<number>(window.innerWidth < 768 ? 34 : 64);
  const { setNotification } = useContext(notificationsContext);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/users/${userIdToEdit}`,
        headers: {
          accept: "*/*",
        },
      });
      const dataRoles: Role[] = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/roles`,
        headers: {
          accept: "*/*",
        },
      });
      setRoles(dataRoles);
      setEmail(response.user.email);
      setInitialEmail(response.user.email);
      setFirstName(response.user.first_name);
      setlastName(response.user.last_name);
      setPhone(response.user.phone);
      setRole(response.user.roles_id);
      setCreatedAt(
        dtf.format(new Date(response.firebase.metadata.creationTime))
      );
      setLastLogin(
        dtf.format(new Date(response.firebase.metadata.lastSignInTime))
      );
      setIsEECC(response.user.isEECC);
      setLoading(false);
    }
    if (userIdToEdit) {
      fetchData();
    }
    if (window.innerWidth < 768) {
      setHeight(34);
    }
    window.addEventListener("resize", (e: any) => {
      if (e.target.window.innerWidth < 768) {
        setHeight(34);
      } else {
        setHeight(64);
      }
    });
    return () => {
      window.removeEventListener("resize", (e: any) => {
        if (e.target.window.innerWidth < 768) {
          setHeight(34);
        } else {
          setHeight(64);
        }
      });
    };
  }, []); // eslint-disable-line

  async function handleSubmit() {
    const regexEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const errors: any = {};
    if (!firstName) {
      setErrors((prev: any) => ({
        ...prev,
        firstName: "El nombre es requerido.",
      }));
      errors.firstName = "El nombre es requerido.";
    }
    if (!lastName) {
      setErrors((prev: any) => ({
        ...prev,
        lastName: "El apellido es requerido.",
      }));
      errors.lastName = "El apellido es requerido.";
    }
    if (!phone) {
      setErrors((prev: any) => ({
        ...prev,
        phone: "El telefono es requerido.",
      }));
      errors.phone = "El telefono es requerido.";
    }
    if (!regexEmail.test(String(email).toLowerCase())) {
      setErrors((prev: any) => ({
        ...prev,
        email: "El email debe ser válido.",
      }));
      errors.email = "El email debe ser válido.";
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const data = {
        email,
        firstName,
        lastName,
        phone,
        role,
        isEECC,
      };
      try {
        const response = await getData({
          method: "PUT",
          url: `${process.env.REACT_APP_API_URL}/users/${userIdToEdit}`,
          headers: {
            accept: "*/*",
          },
          data,
        });
        if (response.users_id) {
          setLoading(false);
          setNotification({
            theme: "success",
            titleNotification: "Edición exitosa",
            contentNotification: "El usuario se editó correctamente.",
            isVisible: true,
          });
          setRefreshUsers(true);
          setUserIdToEdit(null);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    } else {
      setNotification({
        theme: "error",
        titleNotification: "Edición fallida",
        contentNotification: errors[Object.keys(errors)[0]],
        isVisible: true,
      });
    }
    setTimeout(() => {
      setErrors({});
    }, 3000);
  }

  return {
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setlastName,
    phone,
    setPhone,
    role,
    setRole,
    createdAt,
    setCreatedAt,
    registerType,
    setRegisterType,
    lastLogin,
    setLastLogin,
    isEECC,
    setIsEECC,
    loading,
    handleSubmit,
    roles,
    errors,
    showDropDownRole,
    setshowDropDownRole,
    initialEmail,
    height,
  };
}
