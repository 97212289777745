import useAxios from "../../commonHooks/useAxios";
import { useContext, useEffect, useState } from "react";
import { Lead } from "../Interfaces";
import { Product } from './../Interfaces';
import { userContext } from "../../context/userContext";

export default function useLeadsTracking() {
  const [province, setProvince] = useState<string>("");
  const [bindAgent, setBindAgent] = useState<string>("");
  const [interest, setInterest] = useState<number>(0);
  const [state, setState] = useState<number>(0);
  const [initialLeads, setInitialLeads] = useState<Lead[]>([]);
  const [edit, setEdit] = useState<boolean>(false);
  const [leads, setLeads] = useState<Lead[]>([]);
  const [leadsFiltered, setLeadsFiltered] = useState<Lead[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [leadsPerPage, setLeadsPerPage] = useState<number>(10);
  const [leadIdToEdit, setLeadIdToEdit] = useState<number | null>(null);
  const [refreshLeads, setRefreshLeads] = useState<boolean>(true);
  const getData = useAxios();
  const [fieldSorted, setFieldSorted] = useState("created_at");
  const [orderSorted, setOrderSorted] = useState("desc");
  const [products, setProducts] = useState<Product[]>([]);
  const [showModalExport, setShowModalExport] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [visibleExport, setVisibleExport] = useState<boolean>(false);
  const [openGroup, setOpenGroup] = useState<null | number>(null);

  const [closedLeads, setClosedLeads] = useState<Lead[]>([])
  const [initialClosedLeads, setInitialClosedLeads] = useState<Lead[]>([])
  const [closedLeadsFiltered, setClosedLeadsFiltered] = useState<Lead[]>([])
  const [closedLeadsTotalPage, setClosedLeadsTotalPage] = useState<number>(1);
  const [closedLeadsPage, setClosedLeadsPage] = useState<number>(1);
  const [closedLeadsPerPage, setClosedLeadsPerPage] = useState<number>(10);

  const [revisionLeads, setRevisionLeads] = useState<Lead[]>([])
  const [initialRevisionLeads, setInitialRevisionLeads] = useState<Lead[]>([])
  const [revisionLeadsFiltered, setRevisionLeadsFiltered] = useState<Lead[]>([])
  const [revisionLeadsTotalPage, setRevisionLeadsTotalPage] = useState<number>(1);
  const [revisionLeadsPage, setRevisionLeadsPage] = useState<number>(1);
  const [revisionLeadsPerPage, setRevisionLeadsPerPage] = useState<number>(10);

  const [emptyLeads, setEmptyLeads] = useState<Lead[]>([])
  const [nitialEmptyLeads, setInitialEmptyLeads] = useState<Lead[]>([])
  const [emptyLeadsFiltered, setEmptyLeadsFiltered] = useState<Lead[]>([])
  const [emptyLeadsTotalPage, setEmptyLeadsTotalPage] = useState<number>(1);
  const [emptyLeadsPage, setEmptyLeadsPage] = useState<number>(1);
  const [emptyLeadsPerPage, setEmptyLeadsPerPage] = useState<number>(10);

  const dtf = new Intl.DateTimeFormat("es-ES", {
    calendar: "gregory",
    numberingSystem: "latn",
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
  const {
    userState: { user },
  } = useContext(userContext);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response: any = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/leads`,
        headers: {
          accept: "*/*",
        },
      });
      const responseLeads: Lead[] = response?.leads;
      let arrayProducts: Product[] = [];
      response?.products.forEach((product: Product) => {
        arrayProducts[product.products_id] = product;
      });
      setProducts(arrayProducts);
      const leadsSorted = sortItems(responseLeads, "created_at", "desc");

      const closedLeads = leadsSorted.filter((lead: Lead) => lead.status === "Cerrado") || []
      setInitialClosedLeads(closedLeads);
      setClosedLeads(closedLeads);
      setClosedLeadsFiltered(closedLeads);
      setClosedLeadsTotalPage(Math.ceil(closedLeads.length / closedLeadsPerPage));

      const revisionLeads = leadsSorted.filter((lead: Lead) => lead.status === "No se logró contacto" || lead.status === "Se informó" || lead.status === "Cotizado" || lead.status === "Vendido" || lead.status === "Perdido") || []
      setInitialRevisionLeads(revisionLeads);
      setRevisionLeads(revisionLeads);
      setRevisionLeadsFiltered(revisionLeads);
      setRevisionLeadsTotalPage(Math.ceil(revisionLeads.length / revisionLeadsPerPage));

      const emptyLeads = leadsSorted.filter((lead: Lead) => lead.status === null) || []
      setInitialEmptyLeads(emptyLeads);
      setEmptyLeads(emptyLeads);
      setEmptyLeadsFiltered(emptyLeads);
      setEmptyLeadsTotalPage(Math.ceil(emptyLeads.length / emptyLeadsPerPage));

      setLeads(leadsSorted);
      setLeadsFiltered(leadsSorted);
      // setTotalPages(Math.ceil(leadsSorted.length / leadsPerPage));
      // handlePage(1, leadsSorted);
      setLoading(false);
      setRefreshLeads(false);
    }
    if (leads.length === 0 || refreshLeads) {
      fetchData();
    }
  }, [refreshLeads]); // eslint-disable-line

  useEffect(() => {
    if (openGroup === 0) {
      const newLeadsArray = sortItems(closedLeadsFiltered, fieldSorted, orderSorted);
      handlePage(1, newLeadsArray);
    } else if (openGroup === 1) {
      const newLeadsArray = sortItems(revisionLeadsFiltered, fieldSorted, orderSorted);
      handlePage(1, newLeadsArray);
    } else if (openGroup === 2) {
      const newLeadsArray = sortItems(emptyLeadsFiltered, fieldSorted, orderSorted);
      handlePage(1, newLeadsArray);
    }
  }, [fieldSorted, orderSorted]); // eslint-disable-line

  const handleSort = (field: string) => {
    let order = "asc";
    if (fieldSorted === field) {
      order = orderSorted === "asc" ? "desc" : "asc";
      setOrderSorted(order);
    } else {
      setFieldSorted(field);
      setOrderSorted(order);
    }
  };

  function sortItems(
    array: any = leadsFiltered,
    field: string = fieldSorted,
    order: string = orderSorted
  ) {
    if (field === "created_at") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field] > b[field]) return 1;
          if (a[field] < b[field]) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field] < b[field]) return 1;
          if (a[field] > b[field]) return -1;
          return 0;
        });
      }
    } else {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() < b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() > b[field].toLowerCase()) return -1;
          return 0;
        });
      }
    }

    return array;
  }

  function handlePage(
    page: number,
    leads = leadsFiltered,
    totalLeadsPerPage = leadsPerPage
  ) {
    if (openGroup === 0) {
      setLeadsPerPage(closedLeadsPerPage);
      setClosedLeadsPage(page);
      const leadsSorted = sortItems(closedLeadsFiltered);
      const indexOfLastLead = page * closedLeadsPerPage;
      const indexOfFirstLead = indexOfLastLead - closedLeadsPerPage;
      const currentLeads = leadsSorted.slice(indexOfFirstLead, indexOfLastLead);
      setClosedLeads(currentLeads)
    } else if (openGroup === 1) {
      setLeadsPerPage(revisionLeadsPerPage);
      setRevisionLeadsPage(page);
      const leadsSorted = sortItems(revisionLeadsFiltered);
      const indexOfLastLead = page * revisionLeadsPerPage;
      const indexOfFirstLead = indexOfLastLead - revisionLeadsPerPage;
      const currentLeads = leadsSorted.slice(indexOfFirstLead, indexOfLastLead);
      setRevisionLeads(currentLeads)
    } else if (openGroup === 2) {
      setLeadsPerPage(emptyLeadsPerPage);
      setEmptyLeadsPage(page);
      const leadsSorted = sortItems(emptyLeadsFiltered);
      const indexOfLastLead = page * emptyLeadsPerPage;
      const indexOfFirstLead = indexOfLastLead - emptyLeadsPerPage;
      const currentLeads = leadsSorted.slice(indexOfFirstLead, indexOfLastLead);
      setEmptyLeads(currentLeads)
    }
  }

  function handleLeadsPerPage(
    leadsPerPage: number,
    leads: Lead[] = leadsFiltered
  ) {
    if (openGroup === 0) {
      setClosedLeadsTotalPage(Math.ceil(closedLeadsFiltered.length / closedLeadsPerPage));
      handlePage(1, closedLeadsFiltered, closedLeadsPerPage);
    } else if (openGroup === 1) {
      setRevisionLeadsTotalPage(Math.ceil(revisionLeadsFiltered.length / revisionLeadsPerPage));
      handlePage(1, revisionLeadsFiltered, revisionLeadsPerPage);
    } else if (openGroup === 2) {
      setEmptyLeadsTotalPage(Math.ceil(emptyLeadsFiltered.length / emptyLeadsPerPage));
      handlePage(1, emptyLeadsFiltered, emptyLeadsPerPage);
    }
  }

  function handleFilter() {
    let initialLeads: Lead[] = [];
    if (openGroup === 0) {
      initialLeads = initialClosedLeads;
    } else if (openGroup === 1) {
      initialLeads = initialRevisionLeads;
    } else if (openGroup === 2) {
      initialLeads = nitialEmptyLeads;
    }
    const provinceFilter = initialLeads.filter((lead: Lead) => {
      return lead.province.toLowerCase().includes(province.toLowerCase());
    });
    const agentFilter = provinceFilter.filter((lead: Lead) => {
      if (lead?.agent) {
        return lead.agent.agent_name.toLowerCase().includes(bindAgent.toLowerCase());
      } else if (bindAgent === "") {
        return true;
      } else {
        return false;
      }
    });
    // "Información" == 1 - "Cotización == 2" - "Todos" == 0 - "Incompleto" == 3
    const interestFilter = agentFilter.filter((lead: Lead) => {
      if (interest === 0) {
        return true;
      } else if (interest === 1 && lead.interest === "Información") {
        return true;
      } else if (interest === 2 && lead.interest === "Cotización") {
        return true;
      } else if (interest === 3 && lead.interest === null) {
        return true;
      } else {
        return false;
      }
    });
    //  "No se logró contacto" == 1
    // "Se informó" == 2
    // "Cotizado" == 3
    // "Vendido"  == 4
    // "Perdido"  == 5
    // "Todos" == 0
    // "Incompleto" == 6
    const stateFilter = interestFilter.filter((lead: Lead) => {
      if (state === 0) {
        return true;
      } else if (state === 1 && lead.status === "No se logró contacto") {
        return true;
      } else if (state === 2 && lead.status === "Se informó") {
        return true;
      } else if (state === 3 && lead.status === "Cotizado") {
        return true;
      } else if (state === 4 && lead.status === "Vendido") {
        return true;
      } else if (state === 5 && lead.status === "Perdido") {
        return true;
      } else if (state === 6 && lead.status === null) {
        return true;
      } else {
        return false;
      }
    });
    if (openGroup === 0) {
      setClosedLeadsFiltered(stateFilter);
      setClosedLeadsTotalPage(Math.ceil(stateFilter.length / closedLeadsPerPage));
      handlePage(1, stateFilter, Math.ceil(stateFilter.length / closedLeadsPerPage));
    } else if (openGroup === 1) {
      setRevisionLeadsFiltered(stateFilter);
      setRevisionLeadsTotalPage(Math.ceil(stateFilter.length / revisionLeadsPerPage));
      handlePage(1, stateFilter, Math.ceil(stateFilter.length / revisionLeadsPerPage));
    } else if (openGroup === 2) {
      setEmptyLeadsFiltered(stateFilter);
      setEmptyLeadsTotalPage(Math.ceil(stateFilter.length / emptyLeadsPerPage));
      handlePage(1, stateFilter, Math.ceil(stateFilter.length / emptyLeadsPerPage));
    }
  }

  function handleEnter(e: any) {
    if (e.key === "Enter") {
      handleFilter();
    }
  }

  function closeExport() {
    setVisibleExport(false);
    setShowModalExport(false);
  }

  function resetFilters(){
    setProvince("");
    setBindAgent("");
    setInterest(0);
    setState(0);
  }

  return {
    leads,
    loading,
    leadsPerPage,
    page,
    totalPages,
    handleLeadsPerPage,
    handlePage,
    setLeadIdToEdit,
    leadIdToEdit,
    setRefreshLeads,
    handleSort,
    orderSorted,
    fieldSorted,
    dtf,
    products,
    user,
    showModalExport,
    setShowModalExport,
    loadingExport,
    setLoadingExport,
    closeExport,
    leadsFiltered,
    closedLeads,
    openGroup,
    setOpenGroup,
    closedLeadsTotalPage,
    setClosedLeadsTotalPage,
    closedLeadsPage,
    setClosedLeadsPage,
    closedLeadsPerPage,
    setClosedLeadsPerPage,
    edit,
    setEdit,
    revisionLeads,
    revisionLeadsTotalPage,
    revisionLeadsPage,
    revisionLeadsPerPage,
    emptyLeads,
    emptyLeadsTotalPage,
    emptyLeadsPage,
    emptyLeadsPerPage,
    handleEnter,
    province,
    setProvince,
    bindAgent,
    setBindAgent,
    interest,
    setInterest,
    state,
    setState,
    handleFilter,
    resetFilters
  };
}
