import { useContext, useState } from "react";
import { userContext } from "../context/userContext";
import styled from "styled-components";
import axios from "axios";
import { CircularProgress } from "@mui/material";

export default function HomePage() {
  const {
    userState: { user },
  } = useContext(userContext);
  const [image, setImage] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [imageCover, setImageCover] = useState<any>(null);
  function handleChange(e: any) {
    setImage(e.target.files[0]);
  }

  async function handleSubmit() {
    try {
      if (image) {
        setLoading(true);
        const formData = new FormData();
        formData.append("image", image);

        const options = {
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/uploadCover`,
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=---011000010111000001101001",
          },
          data: formData,
        };
        await axios.request(options);
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend = (e) => {
          setImageCover(reader.result);
        };
        setImage(null);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <Container>
      <Title>Bienvenidos</Title>
      {(user?.roles_id === 1 || user?.roles_id === 2) && (
        <ChangeCoverPageContainer>
          {image ? (
            <Button color="primary" onClick={() => handleSubmit()}>
              Subir
            </Button>
          ) : (
            <>
              <Label htmlFor="files">Cambiar Portada</Label>
              <Input
                id="files"
                type="file"
                accept=".jpeg,.jpg,.png"
                onChange={handleChange}
              />
            </>
          )}
        </ChangeCoverPageContainer>
      )}

      <CoverContainer>
        {loading ? (
          <Loading>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </Loading>
        ) : imageCover ? (
          <CoverImage src={imageCover} alt="cover" />
        ) : (
          <CoverPageContainer
            style={{
              backgroundImage: `url('${process.env.REACT_APP_API_URL}/getCover')`,
            }}
          ></CoverPageContainer>
        )}
      </CoverContainer>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const Button = styled.button`
  background-color: ${({ color }: { color: string }) => {
    switch (color) {
      case "primary":
        return "#128297";
      case "secondary":
        return "#ffffff";
      case "disabled":
        return "#1282974d";
      case "back":
        return "#505050";
      default:
        return "#128297";
    }
  }};
  color: ${({ color }: { color: string }) => {
    switch (color) {
      case "primary":
        return "#ffffff";
      case "secondary":
        return "#128297";
      default:
        return "#ffffff";
    }
  }};
  border: ${({ color }: { color: string }) => {
    switch (color) {
      case "primary":
        return "none";
      case "secondary":
        return "1px solid #128297";
      default:
        return "none";
    }
  }};
  border-radius: 5px;
  text-transform: uppercase;
  font-family: "D-DIN-PRO-Medium";
  font-size: 15px;
  line-height: 15px;
  padding: 20px 30px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
  :focus {
    outline: none;
  }
  @media (max-width: 768px) {
    padding: 15px 30px;
    font-size: 13px;
    line-height: 13px;
    width: 100%;
  }
`;
const ChangeCoverPageContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  width: auto;
  @media (max-width: 768px) {
    position: relative;
    top: 0px;
    right: 0px;
    width: auto;
    padding: 0 10px;
    margin-bottom: 10px;
  }
`;
const CoverContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 20px;
  @media (max-width: 768px) {
    padding-top: 0;
  }
`;
const CoverImage = styled.img`
  height: calc(100vh - 160px);
  width: 80%;
  margin-bottom: -20px;
  object-fit: cover;
  object-position: center;
  @media (max-width: 768px) {
    height: calc(100vh - 110px);
    width: 90%;
  }
`;
const CoverPageContainer = styled.div`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: calc(100vh - 160px);
  width: 80%;
  margin-bottom: -20px;
  @media (max-width: 768px) {
    height: calc(100vh - 110px);
    width: 90%;
  }
`;
const Label = styled.label`
  background-color: #128297;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: "D-DIN-PRO-Medium";
  font-size: 15px;
  line-height: 15px;
  padding: 20px 30px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
  position: absolute;
  right: 0;
  top: 0px;
  width: 184px;
  :focus {
    outline: none;
  }
  @media (max-width: 768px) {
    padding: 15px 15px;
    font-size: 13px;
    line-height: 13px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: auto;
    top: auto;
  }
`;
const Input = styled.input`
  display: none;
`;
const Title = styled.h1`
  font-size: 30px;
  line-height: 30px;
  color: #333333;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-left: 0px;
  width: 100%;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
    text-align: start;
    padding-left: 10px;
  }
`;

const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 130px);
  width: 100%;
  margin-bottom: -20px;
`;
