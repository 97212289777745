import { GoogleMap } from "@react-google-maps/api";
import styled from "styled-components";
import ZonesIcon from "../../assets/img/zonesIcon.svg";
import Button from "../../commonComponents/Button";
import Modal from "../../commonComponents/Modal";
import useZonesMap from "../hooks/useZonesMap";
import { CircularProgress } from "@mui/material";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: -34.6,
  lng: -58.43,
};

const mapOptions = {
  center: center,
  zoom: 6,
  mapTypeId: "roadmap",
  disableDefaultUI: false,
  streetViewControl: false,
  scaleControl: false,
  rotateControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  styles: [
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#444444",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#46bcec",
          visibility: "on",
        },
      ],
    },
  ],
};

export default function ZonesMap() {
  const {
    isLoaded,
    onLoad,
    onUnmount,
    cursorPosition,
    agentName,
    showTooltip,
    contactPhone,
    containerRef,
    exportPdf,
    mapContainer,
    exportJPG,
    isMobile,
    loadingExport,
    loading,
  } = useZonesMap();

  return isLoaded ? (
    <>
      <Tooltip
        style={{
          top: cursorPosition.y + 80,
          left: isMobile ? cursorPosition.x - 50 : cursorPosition.x + 120,
          opacity: showTooltip ? 0.9 : 0,
        }}
      >
        <TooltipName>Razón Social: {agentName}</TooltipName>
        <TooltipPhone>Teléfono: {contactPhone}</TooltipPhone>
      </Tooltip>
      <Container ref={containerRef}>
        <HeaderContainer>
          <Title>Mapa de Cobertura</Title>
          <ButtonsContainer>
            <Button type="primary" onClick={exportPdf}>
              Exportar PDF
            </Button>
            <Button type="primary" onClick={exportJPG}>
              Exportar Jpg
            </Button>
          </ButtonsContainer>
        </HeaderContainer>
        <MainContainer>
          <TopContainer>
            <IconContainer>
              <img src={ZonesIcon} alt="mapIcon" />
            </IconContainer>
          </TopContainer>
          <ContentContainer>
            <MapContainer ref={mapContainer}>
              <GoogleMap
                options={mapOptions}
                mapContainerStyle={containerStyle}
                onLoad={onLoad}
                onUnmount={onUnmount}
              ></GoogleMap>
            </MapContainer>
          </ContentContainer>
        </MainContainer>
      </Container>
      {( loadingExport || loading ) && (
        <Modal type="none" title="">
          <LoadingContainer>
            <TitleModal>{loading? "Cargando zonas de cobertura" :"Exportando"}</TitleModal>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </LoadingContainer>
        </Modal>
      )}
    </>
  ) : (
    <></>
  );
}
const Tooltip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 12px;
  background-color: #128297;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  border-radius: 6px;
  opacity: 0;
  transition: top 0.1s ease, left 0.1s ease;
`;

const TooltipName = styled.p`
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 0;
`;

const TooltipPhone = styled.p`
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 0;
`;

const Container = styled.div`
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 38px;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 23px;
  }
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
  }
`;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 40px;
  @media (max-width: 768px) {
    padding: 0px 40px 30px;
  }
`;
const TitleModal = styled.h2`
  font-size: 20px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;
const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 80vh;
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 40px);
  margin-top: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 50px;
  margin-bottom: 60px;
  position: relative;
  @media (max-width: 768px) {
    width: calc(100% - 20px);
    padding: 40px 10px 20px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
