import { notificationsContext } from "../../context/notificationsContext";
import { User } from "./../../configs/Interfaces";
import useAxios from "../../commonHooks/useAxios";
import { useEffect, useState } from "react";
import { agentFormContext } from "../../context/agentFormContext";
import { useContext } from "react";

export default function useModalSelectEECC({
  setInputEECCselect,
}: {
  setInputEECCselect: (eecc: boolean) => void;
}) {
  const { setEmailEECC, setNameEECC, setLastNameEECC } =
    useContext(agentFormContext);
  const [searchByName, setsearchByName] = useState<string>("");
  const [initialEECC, setInitialEECC] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showCreateEECC, setShowCreateEECC] = useState<boolean>(false);
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmEnabled, setConfirmEnabled] = useState<boolean>(false);
  const [confirmedPassword, setConfirmedPassword] = useState<string>("");
  const [errors, setErrors] = useState<{
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
    password?: string;
    confirmedPassword?: string;
  }>({});
  const { setNotification } = useContext(notificationsContext);
  const fetch = useAxios();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response: User[] = await fetch({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/eecc/`,
        headers: {
          accept: "*/*",
        },
      });
      setInitialEECC(response);
      setUsers(response);
      setLoading(false);
    }
    fetchData();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (searchByName.length > 0) {
      setUsers(
        initialEECC.filter((user) =>
          user.first_name.toLowerCase().includes(searchByName.toLowerCase())
        )
      );
    } else {
      setUsers(initialEECC);
    }
  }, [searchByName, initialEECC]);

  useEffect(() => {
    if (
      firstName &&
      lastName &&
      phone &&
      email &&
      password &&
      confirmedPassword
    ) {
      setConfirmEnabled(true);
    } else {
      setConfirmEnabled(false);
    }
  }, [firstName, lastName, phone, email, password, confirmedPassword]);

  function handleUser(user: any) {
    setEmailEECC(user.email);
    setNameEECC(user.first_name);
    setLastNameEECC(user.last_name);
    setInputEECCselect(false);
  }

  async function handleSubmit() {
    const regexEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regexPassword = /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,}$/;
    const errors: any = {};
    if (!firstName) {
      setErrors((prev: any) => ({
        ...prev,
        firstName: "El nombre es requerido.",
      }));
      errors.firstName = "El nombre es requerido.";
    }
    if (!lastName) {
      setErrors((prev: any) => ({
        ...prev,
        lastName: "El apellido es requerido.",
      }));
      errors.lastName = "El apellido es requerido.";
    }
    if (!phone || !Number.isInteger(Number(phone))) {
      setErrors((prev: any) => ({
        ...prev,
        phone: "El telefono debe ser válido.",
      }));
      errors.phone = "El telefono debe ser válido.";
    }
    if (!regexEmail.test(String(email).toLowerCase())) {
      setErrors((prev: any) => ({
        ...prev,
        email: "El email debe ser válido.",
      }));
      errors.email = "El email debe ser válido.";
    }
    if (!regexPassword.test(String(password))) {
      setErrors((prev: any) => ({
        ...prev,
        password:
          "La contraseña debe contener al menos 8 caracteres, una mayúscula y un número.",
      }));
      errors.password =
        "La contraseña debe contener al menos 8 caracteres, una mayúscula y un número.";
    }
    if (password !== confirmedPassword) {
      setErrors((prev: any) => ({
        ...prev,
        confirmedPassword: "Las contraseñas no coinciden.",
      }));
      errors.confirmedPassword = "Las contraseñas no coinciden.";
    }

    if (Object.keys(errors).length === 0) {
      try {
        setLoading(true);
        const user = await fetch({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/signupEECC`,
          headers: {
            accept: "*/*",
          },
          data: {
            firstName,
            lastName,
            phone,
            email,
            password,
          },
        });
        if (user) {
          setEmailEECC(user.email);
          setNotification({
            theme: "success",
            titleNotification: "Creación exitosa",
            contentNotification: "Se ha creado un EECC con éxito.",
            isVisible: true,
          });
          setLoading(false);
          setInputEECCselect(false);
        } else {
          setNotification({
            theme: "error",
            titleNotification: "Creación fallida",
            contentNotification: "El email ya está en uso.",
            isVisible: true,
          });
          setLoading(false);
        }
      } catch (error) {
        setNotification({
          theme: "error",
          titleNotification: "Creación fallida",
          contentNotification: "El email ya está en uso.",
          isVisible: true,
        });
        setLoading(false);
      }
    } else {
      setNotification({
        theme: "error",
        titleNotification: "Registro fallido",
        contentNotification: errors[Object.keys(errors)[0]],
        isVisible: true,
      });
    }
    setTimeout(() => {
      setErrors({});
    }, 3000);
  }
  return {
    loading,
    showCreateEECC,
    errors,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phone,
    setPhone,
    email,
    setEmail,
    password,
    setPassword,
    confirmedPassword,
    setConfirmedPassword,
    confirmEnabled,
    handleSubmit,
    setShowCreateEECC,
    searchByName,
    setsearchByName,
    users,
    handleUser,
  };
}
