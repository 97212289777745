import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { notificationsContext } from "../../context/notificationsContext";
import useAxios from "../../commonHooks/useAxios";

export default function useResetPassword() {
  const [email, setEmail] = useState("");
  const [resetPasswordConfirmed, setResetPasswordConfirmed] = useState(false);
  const navigate = useNavigate();
  const sendData = useAxios();
  const { setNotification } = useContext(notificationsContext);

  async function confirmResetPassword() {
    const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (regexEmail.test(String(email).toLowerCase())){
      try {
        const user = await sendData({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/recovery`,
          headers: {
            accept: "*/*",
          },
          data: {
            email,
          },
        });
        if (user) {
          setNotification({
            theme: "success",
            titleNotification: "Recupero de contraseña",
            contentNotification:
              "Se envió correo para recuperar tu contraseña.",
            isVisible: true,
          });
          setResetPasswordConfirmed(true);
        } else {
          setNotification({
            theme: "error",
            titleNotification: "Recuperación de contraseña",
            contentNotification: "El email no existe.",
            isVisible: true,
          });
        }
      } catch (error) {
        setNotification({
          theme: "error",
          titleNotification: "Recuperación de contraseña",
          contentNotification: "El email no existe.",
          isVisible: true,
        });
      }
    }else{
      setNotification({
        theme: "error",
        titleNotification: "Recuperación de contraseña",
        contentNotification: "El email no es válido.",
        isVisible: true,
      });
    }
   
  }

  return {
    email,
    setEmail,
    resetPasswordConfirmed,
    confirmResetPassword,
    navigate,
  };
}
