import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";
import { userContext } from "../../context/userContext";
import { Document, DocumentType } from "../Interfaces";

export default function useDocuments() {
  const [document, setDocument] = useState<string>("");
  const [documentType, setDocumentType] = useState<number>(999);
  const [version, setVersion] = useState<string>("");
  const [initialDocuments, setInitialDocuments] = useState<Document[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [documentsFiltered, setDocumentsFiltered] = useState<Document[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [documentsPerPage, setDocumentsPerPage] = useState<number>(10);
  const [refreshDocuments, setRefreshDocuments] = useState<boolean>(true);
  const [documentIdToEdit, setDocumentIdToEdit] = useState<string | null>(
    null
  );
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [documentToDelete, setDocumentToDelete] = useState<Document | null>(null);
  const getData = useAxios();
  const [fieldSorted, setFieldSorted] = useState("name");
  const [orderSorted, setOrderSorted] = useState("desc");
  const [createNewDocument, setCreateNewDocument] = useState<boolean>(false);
  const {
    userState: { user },
  } = useContext(userContext);
  const { id } = useParams();
  const navigate = useNavigate();
  const { setNotification } = useContext(notificationsContext);
  const [optionsType, setOptionsType] = useState<DocumentType[]>([]);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response: Document[] = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/documents`,
        headers: {
          accept: "*/*",
        },
      });
      const typesResponse: DocumentType[] = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/documents/types`,
        headers: {
          accept: "*/*",
        },
      });
      setOptionsType(typesResponse);
      const newDocuments = response;
      const documentsSorted = sortItems(newDocuments, "name", "desc");
      setDocuments(documentsSorted);
      setInitialDocuments(documentsSorted);
      setDocumentsFiltered(documentsSorted);
      setTotalPages(Math.ceil(documentsSorted.length / documentsPerPage));
      handlePage(1, documentsSorted);
      setLoading(false);
      setRefreshDocuments(false);
    }
    if ((documents.length === 0 || refreshDocuments) && user) {
      fetchData();
    }
    if (id) {
      setDocumentIdToEdit(id);
    }
  }, [refreshDocuments, user]); // eslint-disable-line

  useEffect(() => {
    const documentsArray = sortItems(documentsFiltered, fieldSorted, orderSorted);
    handlePage(1, documentsArray);
  }, [fieldSorted, orderSorted]); // eslint-disable-line

  const handleSort = (field: string) => {
    let order = "asc";
    if (fieldSorted === field) {
      order = orderSorted === "asc" ? "desc" : "asc";
      setOrderSorted(order);
    } else {
      setFieldSorted(field);
      setOrderSorted(order);
    }
  };

  function sortItems(
    array: any = documentsFiltered,
    field: string = fieldSorted,
    order: string = orderSorted
  ) {
    if (order === "asc") {
      array.sort(function (a: any, b: any) {
        if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
        if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
        return 0;
      });
    } else {
      array.sort(function (a: any, b: any) {
        if (a[field].toLowerCase() < b[field].toLowerCase()) return 1;
        if (a[field].toLowerCase() > b[field].toLowerCase()) return -1;
        return 0;
      });

    }
    return array;
  }

  function handleDocumentsPerPage(
    documentsPerPage: number,
    documents: Document[] = documentsFiltered
  ) {
    setTotalPages(Math.ceil(documents.length / documentsPerPage));
    handlePage(1, documents, documentsPerPage);
  }

  function handlePage(
    page: number,
    documents = documentsFiltered,
    totalDocumentsPerPage = documentsPerPage
  ) {
    setDocumentsPerPage(totalDocumentsPerPage);
    setPage(page);
    const documentsSorted = sortItems(documents);
    const indexOfLastDocument = page * totalDocumentsPerPage;
    const indexOfFirstDocument = indexOfLastDocument - totalDocumentsPerPage;
    const currentDocuments = documentsSorted.slice(indexOfFirstDocument, indexOfLastDocument);
    setDocuments(currentDocuments);
  }

  function handleFilter() {
    const docFilter = initialDocuments.filter((doc: Document) => {
      if (doc.name) {
        return doc.name
          .toLowerCase()
          .includes(document.toLowerCase());
      }
      return true;
    });
    const typeFilter = docFilter.filter((doc: Document) => {
      if (documentType === 999) {
        return true;
      } else if (documentType === doc.document_types_id) {
        return true;
      } else {
        return false;
      }
    });
    const versionFilter = typeFilter.filter((doc: Document) => {
      if (doc.version) {
        return doc.version.toString()
          .toLowerCase()
          .includes(version.toLowerCase());
      }
      return true;
    });
    handleDocumentsPerPage(documentsPerPage, versionFilter);
    setDocumentsFiltered(versionFilter);
  }
  function handleEnter(e: any) {
    if (e.key === "Enter") {
      handleFilter();
    }
  }

  function setDocumentIdToDelete(document: Document | null) {
    setDocumentToDelete(document);
  }

  async function deleteDocument(document: Document) {
    setLoadingDelete(true);
    try {
      const response: { message: string } = await getData({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/documents/${document.documents_id}`,
        headers: {
          accept: "*/*",
        },
      });
      if (response.message === "Document deleted") {
        setNotification({
          theme: "success",
          titleNotification: "Documento Eliminado",
          contentNotification: "El documento se eliminó correctamente.",
          isVisible: true,
        });
        setRefreshDocuments(true);
        setDocumentToDelete(null);
        setLoadingDelete(false);
      } else {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: "No se pudo eliminar el documento.",
          isVisible: true,
        });
      }
      setDocumentToDelete(null);
      setLoadingDelete(false);
    } catch (error) {
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: "No se pudo eliminar el documento.",
        isVisible: true,
      });
      setDocumentToDelete(null);
      setLoadingDelete(false);
    }
  }

  return {
    document,
    setDocument,
    documentType,
    setDocumentType,
    version,
    setVersion,
    documents,
    loading,
    handleFilter,
    setDocumentIdToEdit,
    documentsPerPage,
    handleDocumentsPerPage,
    page,
    handlePage,
    totalPages,
    documentIdToEdit,
    setRefreshDocuments,
    handleEnter,
    user,
    handleSort,
    orderSorted,
    fieldSorted,
    createNewDocument,
    setCreateNewDocument,
    documentToDelete,
    loadingDelete,
    setDocumentToDelete,
    deleteDocument,
    setDocumentIdToDelete,
    optionsType,
    navigate
  };
}
