import useAxios from "../../commonHooks/useAxios";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { notificationsContext } from "../../context/notificationsContext";
import { DocumentType } from "../Interfaces";
import { userContext } from "../../context/userContext";

export default function useDocumentTypes() {
  const [documentTypes, setDocumentTypes] = useState<DocumentType[]>([]);
  const [initialDocumentTypes, setInitialDocumentTypes] = useState<DocumentType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [typesPerPage, setTypesPerPage] = useState<number>(5);
  const [typeIdToEdit, setTypeIdToEdit] = useState<number | null>(null);
  const [refreshTypes, setRefreshTypes] = useState<boolean>(true);
  const [createNewType, setCreateNewType] = useState<boolean>(false);
  const [typeToDelete, setTypeToDelete] = useState<DocumentType | null>(null);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [fieldSorted, setFieldSorted] = useState("document_types_id");
  const [orderSorted, setOrderSorted] = useState("asc");
  const navigate = useNavigate();
  const getData = useAxios();
  const { setNotification } = useContext(notificationsContext);
  const {
    userState: { user },
  } = useContext(userContext);
  useEffect(() => {
    async function fetchData() {
      const response: DocumentType[] = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/documents/types`,
        headers: {
          accept: "*/*",
        },
      });
      const responseDocumentTypes = response;
      const documentTypesSorted = sortItems(responseDocumentTypes, "document_types_id", "asc");
      setDocumentTypes(documentTypesSorted);
      setInitialDocumentTypes(documentTypesSorted);
      setTotalPages(Math.ceil(documentTypesSorted.length / typesPerPage));
      handlePage(1, documentTypesSorted);
      setLoading(false);
      setRefreshTypes(false);
    }
    if (documentTypes.length === 0 || refreshTypes) {
      fetchData();
    }
  }, [refreshTypes]); // eslint-disable-line

  useEffect(() => {
    const newDocumentTypesArray = sortItems(documentTypes, fieldSorted, orderSorted);
    handlePage(1, newDocumentTypesArray);
  }, [fieldSorted, orderSorted]); // eslint-disable-line

  const handleSort = (field: string) => {
    let order = "asc";
    if (fieldSorted === field) {
      order = orderSorted === "asc" ? "desc" : "asc";
      setOrderSorted(order);
    } else {
      setFieldSorted(field);
      setOrderSorted(order);
    }
  };

  function sortItems(
    array: DocumentType[] = documentTypes,
    field: string = fieldSorted,
    order: string = orderSorted
  ) {
    if (field === "document_types_id") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field] > b[field]) return 1;
          if (a[field] < b[field]) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field] < b[field]) return 1;
          if (a[field] > b[field]) return -1;
          return 0;
        });
      }
    } else {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() < b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() > b[field].toLowerCase()) return -1;
          return 0;
        });
      }
    }

    return array;
  }

  function handleDocumentTypesPerPage(categoriesPerPage: number) {
    setTotalPages(Math.ceil(initialDocumentTypes.length / categoriesPerPage));
    handlePage(1, initialDocumentTypes, categoriesPerPage);
  }

  function handlePage(
    page: number,
    types = initialDocumentTypes,
    totalDocumentTypesPerPage = typesPerPage
  ) {
    setTypesPerPage(totalDocumentTypesPerPage);
    setPage(page);
    const indexOfLastType = page * totalDocumentTypesPerPage;
    const indexOfFirstType = indexOfLastType - totalDocumentTypesPerPage;
    const currentType = types.slice(indexOfFirstType, indexOfLastType);
    setDocumentTypes(currentType);
  }

  function setDocumentTypeIdToDelete(type: DocumentType | null) {
    setTypeToDelete(type);
  }

  async function deleteDocumentType(type: DocumentType) {
    setLoadingDelete(true);
    try {
      const response: { message: string } = await getData({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/documents/types/${type.document_types_id}`,
        headers: {
          accept: "*/*",
        },
      });
      if (response.message === "Document Type deleted") {
        setNotification({
          theme: "success",
          titleNotification: "Tipo de Documento Eliminado",
          contentNotification: "El tipo de documento se eliminó correctamente.",
          isVisible: true,
        });
        setRefreshTypes(true);
        setTypeToDelete(null);
        setLoadingDelete(false);
      } else {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: "No se pudo eliminar el tipo de documento.",
          isVisible: true,
        });
      }
      setTypeToDelete(null);
      setLoadingDelete(false);
    } catch (error) {
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: "No se pudo eliminar el tipo de documento.",
        isVisible: true,
      });
      setTypeToDelete(null);
      setLoadingDelete(false);
    }
  }

  return {
    documentTypes,
    loading,
    navigate,
    handleDocumentTypesPerPage,
    page,
    totalPages,
    typesPerPage,
    handlePage,
    setTypeIdToEdit,
    typeIdToEdit,
    setRefreshTypes,
    createNewType,
    setCreateNewType,
    setDocumentTypeIdToDelete,
    typeToDelete,
    setTypeToDelete,
    deleteDocumentType,
    loadingDelete,
    handleSort,
    orderSorted,
    fieldSorted,
    user
  };
}
