import { getAuth, User } from "firebase/auth";
import { useEffect, useState, useContext } from "react";
import SignIn from "../auth/screens/SignIn";
import Loader from "../commonComponents/Loader";
import { userContext } from "../context/userContext";
import useAxios from "../commonHooks/useAxios";
import { useNavigate } from "react-router";
import { documentsNotContext } from "../context/documentsNotContext";

export default function PrivateRoute({ children }: any) {
  const [user, setUser] = useState<User | null | undefined>();
  const [loading, setLoading] = useState<boolean>(true)
  const auth = getAuth();
  const axiosData = useAxios();
  const navigate = useNavigate();
  const { setNotification } = useContext(documentsNotContext);
  const { userState, setUserState } = useContext(userContext);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user: any) => {
      if (user) {
        if (user.emailVerified) {
          setUser(user);
          if (!userState.user) {
            const userDb = await axiosData({
              method: "POST",
              url: `${process.env.REACT_APP_API_URL}/login`,
              headers: {
                accept: "*/*",
              },
              data: {
                accessToken: user.accessToken,
              },
            });
            setUserState({
              user: userDb,
              originalUser: undefined,
            });
            if(userDb.hasMandatory){
              navigate('/documents/mydocuments')
            }
            if(userDb.hasUnapproved){
              setNotification({
                theme: "warning",
                titleNotification: "Documentos",
                contentNotification: "Tienes documentos pendientes de aprobación.",
                isVisible: true,
              });
            }
          }
        } 
      } 
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userState.user) {
    return children;
  } else if (user === undefined && !loading) {
    return <SignIn />;
  } else {
    return <Loader />;
  }
}
