import { useCallback, useContext, useEffect, useState, useRef } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../commonHooks/useAxios";
import { Agent } from "../Interfaces";
import { agentFormContext } from "../../context/agentFormContext";
import MarkerIcon from "../../assets/img/markerIcon.png";

export default function useCoverageZone({
  submitForm,
}: {
  submitForm: ({ polygon_points }: any) => void;
}) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCymfXuGTA2G-CBaKnXZJHRuswtOCc9uLM",
  });
  const [map, setMap] = useState<null | google.maps.Map>(null);
  const [markers, setMarkers] = useState<any[]>([]);
  const [polygon, setPolygon] = useState<any>(null);
  const [markerDragged, setMarkerDragged] = useState<object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [agent, setAgent] = useState<Agent | null>(null);
  const [markerOperational, setmarkerOperational] = useState<any>(null);
  const [zones, setZones] = useState<any>([])
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const { agentId } = useParams();
  const [newMarker, setNewMarker] = useState<any>(null)
  const getData = useAxios();
  const navigate = useNavigate();
  const containerRef = useRef(null) as any;
  const [agentName, setAgentName] = useState<string | null>(null);
  const [contactPhone, setContactPhone] = useState<string | null>(null)
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [cursorPosition, setCursorPosition] = useState({
    x: 0,
    y: 0,
  });
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const {
    latitude,
    longitude,
    setPolygonCoverageZone,
    setRadiusCoverageZone,
    polygonCoverageZone,
    radiusCoverageZone,
    radius,
    circle,
    setCircle,
    setRadius,
    setPolygonPoints,
  } = useContext(agentFormContext);

  // const colors = ["#7FFFD4","#006400","#7CFC00","#5F9EA0","#20B2AA","#48D1CC","#0000FF","#6495ED","#00008B","#8A2BE2","#8B008B","#4B0082","#FF7F50","#FFA07A","#FF4500","#DC143C","#B22222","#F08080","#FF1493","#C71585","#D87093","#FFD700","#FFA500","#FFFF00"];


  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response: any = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/coveragezone/${agentId}`,
        headers: {
          accept: "*/*",
        },
      });
      if (response.points.length > 0) {
        setPolygonCoverageZone(true);
        setRadiusCoverageZone(false);
      }
      setAgent(response.agent);
      const orderPoints = response.points.sort(
        (a: { order: number }, b: { order: number }) => {
          return a.order - b.order;
        }
      );
      setPolygonPoints(orderPoints);
      createMarkersByArray(orderPoints);
      setLoading(false);
    }

    if (agentId && map && markers.length === 0) {
      fetchData();
    }
    if (latitude && longitude && map) {
      map.setCenter({
        lat: latitude,
        lng: longitude,
      });
      if (radiusCoverageZone) {
        if (markerOperational) {
          markerOperational.setMap(null);
        }
        const marker = new google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map: map,
          draggable: false,
          icon: {
            url: MarkerIcon,
            scaledSize: new window.google.maps.Size(25, 38),
          },
        });
        setmarkerOperational(marker);
        if (radius) {
          const circle = new google.maps.Circle({
            strokeColor: "#FFB953",
            strokeOpacity: 0.9,
            strokeWeight: 1,
            fillColor: "#FFB953",
            fillOpacity: 0.65,
            map: map,
            center: { lat: latitude, lng: longitude },
            radius: +radius * 1000,
          });
          setCircle(circle);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentId, map, latitude, longitude]);

  useEffect(() => {
    if (polygon) {
      polygon.addListener("click", (event: google.maps.MapMouseEvent) => {
        handleClickMap(event);
      });
    }
  }, [polygon]); // eslint-disable-line

  useEffect(() => {
    if (newMarker) {
      handleClickMap(newMarker)
    }
  }, [newMarker]); // eslint-disable-line

  useEffect(() => {
    async function fetchAllZones() {
      setLoading(true);
      const zones: any = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/getallcoveragezones`,
        headers: {
          accept: "*/*",
        },
      });
      const zonesArray = zones.map((zone: any) => {
        if (zone.points) {
          return {
            ...zone,
            points: zone.points.sort(
              (a: { order: number }, b: { order: number }) => {
                return a.order - b.order;
              }
            ),
          };
        } else {
          return zone;
        }
      })
      setZones(zonesArray)
      setLoading(false);
    }

    if (zones.length === 0) {
      fetchAllZones()
    }

    if (zones.length > 0 && map && firstRender) {
      setFirstRender(false)
      zones.forEach((zone: any) => {
        if (zone.agents_id !== agentId) {

          // let rand = Math.random();
          // rand = Math.floor(rand * 24);

          const color = "#51AEA3";
          if (zone.radius) {
            const circle = new google.maps.Circle({
              strokeColor: color,
              strokeOpacity: 0.9,
              strokeWeight: 1,
              fillColor: color,
              fillOpacity: 0.65,
              map: map,
              center: { lat: zone.lat, lng: zone.lng },
              radius: zone.radius * 1000,
            });
            circle.addListener("mouseover", () => {
              setAgentName(zone.agents_name)
              setContactPhone(zone.contact_phone)
              setShowTooltip(true);
            })
            circle.addListener("mouseout", () => {
              setShowTooltip(false);
            })
            circle.addListener("click", (event: google.maps.MapMouseEvent) => {
              setNewMarker(event)
            });
          } else {
            const polygonPoints = zone.points.map((point: any) => {
              return { lat: point.latitude, lng: point.longitude };
            });
            const polygon = new window.google.maps.Polygon({
              paths: polygonPoints,
              strokeColor: color,
              strokeOpacity: 0.9,
              strokeWeight: 1,
              fillColor: color,
              fillOpacity: 0.65,
            });
            polygon.addListener("mouseover", () => {
              setAgentName(zone.agents_name)
              setContactPhone(zone.contact_phone)
              setShowTooltip(true);
            })
            polygon.addListener("mouseout", () => {
              setShowTooltip(false);
            })
            polygon.addListener("click", (event: google.maps.MapMouseEvent) => {
              setNewMarker(event)
            });
            polygon.setMap(map);
          }
        }
      })
    }


  }, [zones, map, firstRender]); // eslint-disable-line

  useEffect(() => {
    if (Object.keys(markerDragged).length !== 0) {
      if (markers.length >= 2) {
        cleanPolygon();
        createPolygon(markers);
      }
    }
  }, [markerDragged]); // eslint-disable-line

  useEffect(() => {
    document.addEventListener("mousemove", (e) => {
      setCursorPosition({
        x: e.offsetX,
        y: e.offsetY
      })
    })
    setIsMobile(window.innerWidth < 768);
    return () => {
      document.removeEventListener("mousemove", () => { })
    }
  }, [])

  function handleReset() {
    // eslint-disable-next-line array-callback-return
    markers.map((marker, index) => {
      markers[index].setMap(null);
    });
    setMarkers([]);
    cleanPolygon();
  }

  function handleClickMap(event: google.maps.MapMouseEvent) {
    if (polygonCoverageZone) {
      if (markers.length >= 30) {
        return;
      }
      let index: number = 1;
      if (markers.length > 0) {
        index = +markers[markers.length - 1].label.text + 1;
      }

      const marker = new window.google.maps.Marker({
        position: event.latLng,
        map: map,
        draggable: true,
        label: {
          text: "" + index,
          color: "#128297",
          className: index >= 10 ? "label-big" : "label-small",
          fontSize: "11px",
          fontFamily: "D-DIN-PRO-Bold",
        },
        icon: {
          url: MarkerIcon,
          scaledSize: new window.google.maps.Size(25, 38),
        },
      });

      marker.addListener("dragend", () => {
        setMarkerDragged({
          mark: marker,
          index: index - 1,
        });
      });

      setMarkers([...markers, marker]);

      if (markers.length >= 2) {
        cleanPolygon();
        createPolygon([...markers, marker]);
      }
    }
  }

  function handleCancel() {
    navigate(-1);
  }

  function handleChange() {
    if (polygonCoverageZone) {
      handleReset();
      if (latitude && longitude) {
        const marker = new google.maps.Marker({
          position: { lat: latitude, lng: longitude },
          map: map,
          draggable: false,
          icon: {
            url: MarkerIcon,
            scaledSize: new window.google.maps.Size(25, 38),
          },
        });
        setmarkerOperational(marker);
      }
      setPolygonCoverageZone(false);
      setRadiusCoverageZone(true);
    } else {
      if (circle) {
        circle.setMap(null);
        setCircle(null);
      }
      if (markerOperational) {
        markerOperational.setMap(null);
      }
      setRadius("");
      setRadiusCoverageZone(false);
      setPolygonCoverageZone(true);
    }
  }

  function handleRadius() {
    if (circle) {
      circle.setMap(null);
    }
    if (latitude && longitude) {
      const newCircle = new google.maps.Circle({
        strokeColor: "#FFB953",
        strokeOpacity: 0.9,
        strokeWeight: 1,
        fillColor: "#FFB953",
        fillOpacity: 0.65,
        map: map,
        center: { lat: latitude, lng: longitude },
        radius: +radius * 1000,
      });
      setCircle(newCircle);
    }
  }

  function cleanPolygon() {
    if (polygon) {
      polygon.setMap(null);
    }
  }

  function createPolygon(pointsMap: any = markers) {
    const polygonPoints = pointsMap.map((marker: any) => {
      return { lat: marker.position.lat(), lng: marker.position.lng() };
    });
    const polygon = new window.google.maps.Polygon({
      paths: polygonPoints,
      strokeColor: "#633586",
      strokeOpacity: 0.8,
      strokeWeight: 0,
      fillColor: "#FFB953",
      fillOpacity: 0.65,
    });
    polygon.setMap(map);
    setPolygon(polygon);
  }

  function deleteMarker(indexMarker: number) {
    const newMarkers = [...markers];
    newMarkers[indexMarker].setMap(null);
    newMarkers.splice(indexMarker, 1);
    setMarkers(newMarkers);

    if (newMarkers.length >= 3) {
      createPolygon(newMarkers);
    }
    if (polygon) {
      polygon.setMap(null);
    }
  }

  function createMarkersByArray(points: any) {
    let markersArray: any[] = [];

    // eslint-disable-next-line array-callback-return
    points.map((point: { latitude: any; longitude: any }, index: number) => {
      const marker = new window.google.maps.Marker({
        position: {
          lat: point.latitude,
          lng: point.longitude,
        },
        map: map,
        draggable: true,
        label: {
          text: "" + (index + 1),
          color: "#128297",
          className: index + 1 >= 10 ? "label-big" : "label-small",
          fontSize: "11px",
          fontFamily: "D-DIN-PRO-Bold",
        },
        icon: {
          url: MarkerIcon,
          scaledSize: new window.google.maps.Size(25, 38),
        },
      });
      marker.addListener("dragend", () => {
        setMarkerDragged({
          mark: marker,
          index: index - 1,
        });
      });
      markersArray.push(marker);
    });
    setMarkers(markersArray);

    if (markersArray.length >= 2) {
      createPolygon(markersArray);
    }
  }
  async function handleSubmit() {
    if (circle) {
      submitForm({ polygon_points: {} });
    } else if (markers.length >= 3) {
      const polygon_points = markers.map((marker: any) => {
        return {
          index: marker.label.text,
          latitude: marker.position.lat(),
          longitude: marker.position.lng(),
        };
      });
      submitForm({ polygon_points });
    }
  }

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  return {
    isLoaded,
    onLoad,
    onUnmount,
    handleClickMap,
    markers,
    deleteMarker,
    handleReset,
    handleSubmit,
    loading,
    agent,
    handleRadius,
    handleChange,
    handleCancel,
    agentId,
    cursorPosition,
    agentName,
    showTooltip,
    contactPhone,
    containerRef,
    isMobile
  };
}
