import { useEffect, useState, useContext } from "react";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";
import { Advisor } from "../Interfaces";

export default function useAdvisorsForm({
  advisorIdToEdit,
  setAdvisorIdToEdit,
  setRefreshAdvisors,
  setCreateNewAdvisor,
}: {
  advisorIdToEdit?: string;
  setAdvisorIdToEdit: (advisorIdToEdit: string | null) => void;
  setRefreshAdvisors: (refresh: boolean) => void;
  setCreateNewAdvisor: (createNewAdvisor: boolean) => void;
}) {
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [initialAdvisorName, setInitialAdvisorName] = useState<string>("");
  const [errors, setErrors] = useState<{
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
  }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const axiosData = useAxios();
  const { setNotification } = useContext(notificationsContext);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response: Advisor = await axiosData({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}/advisors/${advisorIdToEdit}`,
          headers: {
            accept: "*/*",
          },
        });
        setFirstName(response.first_name);
        setLastName(response.last_name);
        setEmail(response.email);
        setPhone(response.phone);
        setInitialAdvisorName(response.first_name);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    if (advisorIdToEdit) {
      fetchData();
    }
  }, []); // eslint-disable-line

  function handleCancel() {
    setAdvisorIdToEdit(null);
    setCreateNewAdvisor(false);
  }

  async function handleSubmit() {
    const errors: any = {};
    const regexEmail =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!firstName) {
      setErrors((prev: any) => ({
        ...prev,
        firstName: "El nombre es requerido.",
      }));
      errors.firstName = "El nombre es requerido.";
    }
    if (!lastName) {
      setErrors((prev: any) => ({
        ...prev,
        lastName: "El apellido es requerido.",
      }));
      errors.lastName = "El apellido es requerido.";
    }
    if (!regexEmail.test(String(email).toLowerCase())) {
      setErrors((prev: any) => ({
        ...prev,
        email: "El mail debe ser válido.",
      }));
      errors.email = "El mail debe ser válido.";
    }
    if (!phone) {
      setErrors((prev: any) => ({
        ...prev,
        phone: "El número de celular es requerido.",
      }));
      errors.phone = "El número de celular es requerido.";
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const data = {
        first_name: firstName,
        last_name: lastName,
        email,
        phone,
      };
      if (advisorIdToEdit) {
        try {
          const advisor = await axiosData({
            method: "PUT",
            url: `${process.env.REACT_APP_API_URL}/advisors/${advisorIdToEdit}`,
            headers: {
              accept: "*/*",
            },
            data,
          });
          if (advisor) {
            setLoading(false);
            setNotification({
              theme: "success",
              titleNotification: "Edición exitosa",
              contentNotification: "El asesor comercial se editó correctamente.",
              isVisible: true,
            });
            setRefreshAdvisors(true);
            setAdvisorIdToEdit(null);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      } else {
        try {
          const advisor = await axiosData({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/advisors`,
            headers: {
              accept: "*/*",
            },
            data,
          });
          if (advisor) {
            setLoading(false);
            setNotification({
              theme: "success",
              titleNotification: "Asesor creado",
              contentNotification: "El asesor comercial se creó correctamente.",
              isVisible: true,
            });
            setRefreshAdvisors(true);
            setCreateNewAdvisor(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    } else {
      if (advisorIdToEdit) {
        setNotification({
          theme: "error",
          titleNotification: "Edición fallida",
          contentNotification: errors[Object.keys(errors)[0]],
          isVisible: true,
        });
      } else {
        setNotification({
          theme: "error",
          titleNotification: "Creación fallida",
          contentNotification: errors[Object.keys(errors)[0]],
          isVisible: true,
        });
      }
    }
    setTimeout(() => {
      setErrors({});
    }, 3000);
  }

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
    errors,
    loading,
    handleSubmit,
    handleCancel,
    initialAdvisorName,
  };
}
