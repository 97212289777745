import { useContext, useState } from "react";
import { userContext } from "../../context/userContext";
import { agentFormContext } from "../../context/agentFormContext";
import { useNavigate, useParams } from "react-router-dom";

export default function useAdminAgentForm() {
  const { arrayUsers, setArrayUsers } = useContext(agentFormContext);
  const [inputEECCselect, setInputEECCselect] = useState<boolean>(false);
  const { agentId } = useParams();
  const navigate = useNavigate();

  const {
    userState: { user },
  } = useContext(userContext);

  function handleCancel() {
    navigate(-1);
  }

  function handleDeleteUser(index: number) {
    const newArray = [...arrayUsers];
    newArray[index] = {
      users_id: null,
      first_name: "",
      last_name: "",
      email: "",
    };
    setArrayUsers(newArray);
  }

  return {
    user,
    handleDeleteUser,
    inputEECCselect,
    setInputEECCselect,
    handleCancel,
    agentId,
  };
}
