import styled from "styled-components";
import DateComponent from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef } from "react";

export default function DatePicker({
  label,
  date,
  setDate,
  disabled,
  empty,
}: {
  label: string;
  date: Date | null;
  setDate: (value: Date) => void;
  disabled?: boolean;
  empty?: boolean;
}) {
  const DatePickerInput = forwardRef<HTMLDivElement>(
    ({ value, onClick }: any, ref) => (
      <FieldContainer
        onClick={onClick}
        ref={ref}
        style={{ cursor: "pointer", width: "280px", zIndex: 1000 }}
      >
        <Input
          style={{ cursor: "pointer", width: "280px", zIndex: 1000 }}
          disabled={disabled}
          required
          value={empty ? "" : date?.toLocaleDateString()}
        />
        <LabelDisabled color={undefined}>{label}</LabelDisabled>
      </FieldContainer>
    )
  );
  return (
    <DateComponentContainer>
      <DateComponent
        selected={date}
        onChange={(date: Date) => setDate(date)}
        customInput={<DatePickerInput />}
        disabled={disabled}
      />
    </DateComponentContainer>
  );
}
const DateComponentContainer = styled.div`
  width: 280px;
  z-index: 5000;
`;
const FieldContainer = styled.div`
  position: relative;
  width: 280px;
`;
const Input = styled.input`
  width: 280px;
  border: 1px solid #424242;
  height: 34px;
  border-radius: 5px;
  outline: none;
  padding: 4px 8px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const LabelDisabled = styled.label`
  font-size: 8px;
  position: absolute;
  pointer-events: none;
  top: 2px;
  left: 8px;
  font-weight: 500;
  color: #424242;
  @media (max-width: 768px) {
    top: 3px;
    left: 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #424242;
  }
`;
