import styled from "styled-components";
export default function CustomTable({
  headers,
  rows,
  setRows,
  disabled,
}: {
  headers: string[];
  rows: string[][];
  setRows: (rows: string[][]) => void;
  disabled?: boolean;
}) {
  function changeCell(row: number, column: number, value: string) {
    const newRows = [...rows];
    newRows[row][column] = value;
    setRows(newRows);
  }

  function addRow() {
    const newRows = [...rows];
    newRows.push(headers.map(() => ""));
    setRows(newRows);
  }

  function deleteRow(index: number) {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);
  }

  return (
    <TableContainer>
      <Table>
        <thead>
          <TRH>
            {headers.map((d: string, index: number) => (
              <TH key={index}>{d}</TH>
            ))}
          </TRH>
        </thead>
        <tbody>
          {rows.map((row: string[], indexRow: number) => (
            <TR key={indexRow}>
              {row.map((cell: string, indexCell: number) => (
                <TD key={indexCell}>
                  <Cell
                    value={cell}
                    type="text"
                    onChange={({ target: { value } }) =>
                      changeCell(indexRow, indexCell, value)
                    }
                    disabled={disabled}
                  />
                </TD>
              ))}
              {!disabled && (
                <DeleteButton onClick={() => deleteRow(indexRow)}>
                  X
                </DeleteButton>
              )}
            </TR>
          ))}
        </tbody>
      </Table>
      {!disabled && <Button onClick={addRow}>Añadir Fila</Button>}
    </TableContainer>
  );
}

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2px;
  @media (max-width: 768px) {
    min-width: 900px;
  }
`;
const TableContainer = styled.div`
  width: 100%;
  padding-right: 24px;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const TR = styled.tr`
  position: relative;
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
`;
const TRH = styled.tr`
  :nth-child(even) {
    background-color: #f9f9f9;
  }
`;
const TD = styled.td`
  padding: 14px 0px 13px 20px;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  text-transform: capitalize;
`;
const TH = styled.th`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  padding: 23px 20px;
  color: #128297;
  text-transform: uppercase;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const Cell = styled.input`
  width: 100%;
  outline: none;
  border: none;
  padding: 8px;
  background: none;
  border-bottom: 1px dotted #128297;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
`;
const Button = styled.button`
  border-radius: 5px;
  text-transform: uppercase;
  font-family: "D-DIN-PRO-Medium";
  font-size: 15px;
  line-height: 15px;
  padding: 8px 16px;
  cursor: pointer;
  margin-top: 16px;
  user-select: none;
  transition: all 0.3s;
  background-color: #fff;
  border: 1px solid #128297;
  color: #128297;
  :focus {
    outline: none;
  }
  position: relative;
  z-index: 10;
  @media (max-width: 768px) {
    padding: 15px 30px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const DeleteButton = styled.button`
  position: absolute;
  right: auto;
  top: auto;
  translate: 8px 12px;
  min-width: 24px;
  min-height: 24px;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: "D-DIN-PRO-Medium";
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
  background-color: #fff;
  border: 1px solid #128297;
  color: #128297;
  display: flex;
  justify-content: center;
  align-items: center;
  :focus {
    outline: none;
  }
  @media (max-width: 768px) {
    padding: 15px 30px;
    font-size: 13px;
    line-height: 13px;
  }
`;
