import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import useEditLead from "../hooks/useEditLead";
import Modal from "../../commonComponents/Modal";
import Button from "../../commonComponents/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ArrowDropDownIcon from "../../assets/img/arrowDropDownIcon.svg";
import { forwardRef } from "react";

export default function EditLead({
  leadIdToEdit,
  setLeadIdToEdit,
  setRefreshLeads,
}: {
  leadIdToEdit: number;
  setLeadIdToEdit: (userIdToEdit: number | null) => void;
  setRefreshLeads: (usersRefresh: boolean) => void;
}) {
  const {
    createdAt,
    setCreatedAt,
    firstName,
    setFirstName,
    lastName,
    setlastName,
    province,
    setProvince,
    email,
    setEmail,
    phone,
    setPhone,
    loading,
    handleSubmit,
    height,
    city,
    setCity,
    selectedProducts,
    contactDate,
    setContactDate,
    showDropDownInterest,
    setShowDropDownInterest,
    interest,
    setInterest,
    showDropDownStatus,
    setShowDropDownStatus,
    status,
    setStatus,
    comments,
    setComments,
  } = useEditLead({
    leadIdToEdit,
    setLeadIdToEdit,
    setRefreshLeads: setRefreshLeads,
  });

  const DatePickerInput = forwardRef<HTMLDivElement>(
    ({ value, onClick }: any, ref) => (
      <FieldContainer
        onClick={onClick}
        ref={ref}
        style={{ cursor: "pointer", width: "100%" }}
      >
        <Input
          style={{ cursor: "pointer", width: "100%" }}
          disabled
          required
          value={contactDate ? contactDate.toLocaleDateString() : ""}
        />
        <LabelDisabled color={undefined}>Contactado</LabelDisabled>
      </FieldContainer>
    )
  );

  const optionsInterest = ["Información", "Cotización"];
  const optionsStatus = [
    "No se logró contacto",
    "Se informó",
    "Cotizado",
    "Vendido",
    "Perdido",
    "Cerrado"
  ];
  return (
    <Modal type="edituser" title={""}>
      <Container>
        {loading ? (
          <LoadingContainer>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </LoadingContainer>
        ) : (
          <>
            <ColumnsContainer>
              <ColumnContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                  />
                  <LabelDisabled>Email</LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={firstName}
                    onChange={({ target: { value } }) => setFirstName(value)}
                  />
                  <LabelDisabled>Nombre</LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={lastName}
                    onChange={({ target: { value } }) => setlastName(value)}
                  />
                  <LabelDisabled>Apellido</LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    type={"tel"}
                    value={phone}
                    onChange={({ target: { value } }) => setPhone(value)}
                  />
                  <LabelDisabled>Celular</LabelDisabled>
                </FieldContainer>
                <div style={{ zIndex: 200, width: "100%" }}>
                  <DatePicker
                    selected={contactDate}
                    onChange={(date: Date) => setContactDate(date)}
                    customInput={<DatePickerInput />}
                  />
                </div>
                <FieldContainer
                  style={{ height: height === 34 ? "34px" : "auto" }}
                >
                  <DropDownField
                    onClick={() =>
                      setShowDropDownInterest(!showDropDownInterest)
                    }
                    style={{
                      height: showDropDownInterest
                        ? `${height * 3 + 10}px`
                        : `${height}px`,
                      borderColor: "#424242",
                    }}
                  >
                    {!interest && (
                      <CurrentValueDropDown
                        style={{
                          paddingTop: "0px",
                          fontSize: "16px",
                          color: "#128297",
                        }}
                      >
                        Interés
                      </CurrentValueDropDown>
                    )}
                    {optionsInterest.map(
                      (d: any, index: number) =>
                        interest === d && (
                          <>
                            <DropDownLabel key={index}>Interés</DropDownLabel>
                            <CurrentValueDropDown key={index + 10}>
                              {d}
                            </CurrentValueDropDown>
                          </>
                        )
                    )}
                    <DropDownItems
                      style={{
                        overflowY: "hidden",
                      }}
                    >
                      {optionsInterest.map((d: any, index: number) => (
                        <Option key={index} onClick={() => setInterest(d)}>
                          {d}
                        </Option>
                      ))}
                    </DropDownItems>

                    <IconConatiner
                      style={{
                        transform: showDropDownInterest
                          ? "rotateZ(-180deg)"
                          : "rotateZ(0deg)",
                      }}
                    >
                      <img src={ArrowDropDownIcon} alt="arrow drop down icon" />
                    </IconConatiner>
                  </DropDownField>
                </FieldContainer>
              </ColumnContainer>
              <ColumnContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={createdAt}
                    onChange={({ target: { value } }) => setCreatedAt(value)}
                  />
                  <LabelDisabled color={undefined}>Fecha</LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={province}
                    onChange={({ target: { value } }) => setProvince(value)}
                  />
                  <LabelDisabled color={undefined}>Provincia</LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={city}
                    onChange={({ target: { value } }) => setCity(value)}
                  />
                  <LabelDisabled color={undefined}>Localidad</LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <ProductsContainer>
                    {selectedProducts.map((product) => (
                      <p style={{ padding: "4px" }}>{product}</p>
                    ))}
                  </ProductsContainer>
                  <LabelDisabled color={undefined}>Productos</LabelDisabled>
                </FieldContainer>
                <FieldContainer
                  style={{ height: height === 34 ? "44px" : "60px" }}
                >
                  <DropDownField
                    onClick={() => setShowDropDownStatus(!showDropDownStatus)}
                    style={{
                      height: showDropDownStatus
                        ? `${35 * 7 + 4}px`
                        : `${height}px`,
                      borderColor: "#424242",
                    }}
                  >
                    {!status && (
                      <CurrentValueDropDown
                        style={{
                          paddingTop: "0px",
                          fontSize: "16px",
                          color: "#128297",
                        }}
                      >
                        Estado
                      </CurrentValueDropDown>
                    )}
                    {optionsStatus.map(
                      (d: any, index: number) =>
                        status === d && (
                          <>
                            <DropDownLabel key={index}>Estado</DropDownLabel>
                            <CurrentValueDropDown key={index + 10}>
                              {d}
                            </CurrentValueDropDown>
                          </>
                        )
                    )}
                    <DropDownItems
                      style={{
                        overflowY: "scroll",
                      }}
                    >
                      {optionsStatus.map((d: any, index: number) => (
                        <Option key={index} onClick={() => setStatus(d)}>
                          {d}
                        </Option>
                      ))}
                    </DropDownItems>

                    <IconConatiner
                      style={{
                        transform: showDropDownInterest
                          ? "rotateZ(-180deg)"
                          : "rotateZ(0deg)",
                      }}
                    >
                      <img src={ArrowDropDownIcon} alt="arrow drop down icon" />
                    </IconConatiner>
                  </DropDownField>
                </FieldContainer>
              </ColumnContainer>
            </ColumnsContainer>
            <FieldContainer style={{ width: "100%" }}>
              <DisabledTextArea
                required
                value={comments}
                onChange={({ target: { value } }) => setComments(value)}
                style={{ width: "100%", backgroundColor: "#ffffff" }}
              />
              <LabelDisabled color={undefined}>Observaciones</LabelDisabled>
            </FieldContainer>
            <ButtonsContainer>
              <Button type="secondary" onClick={() => setLeadIdToEdit(null)}>
                Cancelar
              </Button>
              <Button type="primary" onClick={handleSubmit}>
                Guardar
              </Button>
            </ButtonsContainer>
          </>
        )}
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px 50px 20px 50px;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    height: auto;
    padding: 10px;
  }
`;
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;
const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 320px;
  @media (max-width: 768px) {
    gap: 10px;
    width: calc(100vw - 40px);
  }
`;
const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2px;
  position: relative;
  width: 320px;
  height: auto;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-top: 10px;
  padding-right: 40px;
  @media (max-width: 768px) {
    width: calc(100vw - 50px);
    padding-right: 0px;
  }
`;
const Input = styled.input`
  width: 320px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 12px;
    line-height: 12px;
  }
`;
const Label = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 24.5px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 11.5px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      line-height: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
const DisabledInput = styled.input`
  width: 320px;
  height: 40px;
  background: #f1f1f1;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 26px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  @media (max-width: 768px) {
    width: 100%;
    height: 30px;
    padding: 17px 10px 5px;
    font-size: 12px;
    line-height: 12px;
  }
`;
const LabelDisabled = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 6px;
  left: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  @media (max-width: 768px) {
    top: 3px;
    left: 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #424242;
  }
`;
const DisabledTextArea = styled.textarea`
  width: 320px;
  min-height: 106px;
  background: #f1f1f1;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  resize: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    min-height: auto;
    width: 100%;
    height: 60px;
    padding: 17px 10px 5px;
    font-size: 12px;
    line-height: 12px;
  }
`;
const DropDownField = styled.div`
  border: 1px solid #424242;
  border-radius: 5px;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
  position: absolute;
  color: #128297;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 20px 18px 20px;
  overflow-y: hidden;
  background-color: #fff;
  z-index: 6;
  transition: all 0.4s;
  max-height: 467px;
  @media (max-width: 768px) {
    padding: 0px 10px 18px 10px;
    width: 100%;
  }
`;
const DropDownItems = styled.div`
  cursor: pointer;
  color: #128297;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: hidden;
  z-index: 6;
  transition: all 0.4s;
  max-height: 403px;
  width: 100%;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;
const IconConatiner = styled.div`
  position: absolute;
  right: 26px;
  top: 19px;
  transform: rotateZ("0deg");
  transition: all 0.3s;
  @media (max-width: 768px) {
    right: 16px;
    top: 8px;
  }
`;
const DropDownLabel = styled.p`
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  color: #424242;
  position: absolute;
  top: 6px;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
    top: 3px;
  }
`;
const CurrentValueDropDown = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: #128297;
  cursor: pointer;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 10px;
  @media (max-width: 768px) {
    font-family: "D-DIN-PRO";
    min-height: 34px;
    font-size: 13px;
    line-height: 13px;
    padding-top: 10px;
  }
`;
const Option = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #128297;
  cursor: pointer;
  :hover {
    background-color: #f5f5f5;
  }
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 0 0 20px;
  :nth-last-child() {
    margin-bottom: 18px;
  }
  @media (max-width: 768px) {
    min-height: 34px;
  }
`;
const ProductsContainer = styled.div`
  width: 320px;
  height: 106px;
  background: #f1f1f1;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 20px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  resize: none;
  overflow: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
  min-height: auto;
    width: 100%;
    height: 60px;
    padding: 17px 10px 5px;
    font-size: 12px;
    line-height: 12px;
  }
`;
