import { useCallback, useContext, useEffect, useState } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { agentFormContext } from "../../context/agentFormContext";
import MarkerIcon from "../../assets/img/markerIcon.png";
import { useNavigate, useParams } from "react-router-dom";
import { notificationsContext } from "../../context/notificationsContext";

export default function useOperationalForm({
  submitForm,
}: {
  submitForm: (errors: {}) => void;
}) {
  const [isComplete, setIsComplete] = useState<boolean>(false);
  const {
    operationalStreet,
    operationalAdressNumber,
    operationalCity,
    operationalProvince,
    marker,
    setMarker,
    map,
    setMap,
    setconfirmUbication,
    setErrors,
    currentStep,
    totalSteps,
    setCurrentStep,
    setLatitude,
    setLongitude,
    latitude,
    longitude,
  } = useContext(agentFormContext);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCymfXuGTA2G-CBaKnXZJHRuswtOCc9uLM",
  });
  const { agentId } = useParams();
  const navigate = useNavigate();
  const { setNotification } = useContext(notificationsContext);
  useEffect(() => {
    if (latitude && longitude && map) {
      const marker = new google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        map: map,
        draggable: false,
        icon: {
          url: MarkerIcon,
          scaledSize: new window.google.maps.Size(25, 38),
        },
      });
      setMarker(marker);
      map.setCenter({
        lat: latitude,
        lng: longitude,
      });
      map.setZoom(14);
      setIsComplete(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map]);

  function searchMap() {
    if (marker) {
      marker.setMap(null);
    }
    const service = new google.maps.places.PlacesService(map as any);
    service.textSearch(
      {
        query: `${operationalStreet} ${operationalAdressNumber} ${operationalCity} ${operationalProvince}`,
        location: new google.maps.LatLng(-34.6, -58.43),
        region: "AR",
      },
      (results: any, status: any) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const marker = new window.google.maps.Marker({
            position: {
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            },
            map: map,
            draggable: false,
            icon: {
              url: MarkerIcon,
              scaledSize: new window.google.maps.Size(25, 38),
            },
          });
          setMarker(marker);
          if (map) {
            map.setCenter({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            });
            setLatitude(results[0].geometry.location.lat());
            setLongitude(results[0].geometry.location.lng());
            setIsComplete(true);
            map.setZoom(15);
          }
          setconfirmUbication(results[0].formatted_address);
        }
      }
    );
  }

  function handleCancel() {
    navigate(-1);
  }

  function validateForm() {
    const errors: any = {};
    if (!operationalStreet) {
      setErrors((prev: any) => ({
        ...prev,
        operationalStreet: "La calle es requerida.",
      }));
      errors.operationalStreet = "La calle es requerida.";
    }
    if (!operationalAdressNumber) {
      setErrors((prev: any) => ({
        ...prev,
        operationalAdressNumber: "La altura es requerida.",
      }));
      errors.operationalAdressNumber = "La altura es requerida.";
    }
    if (!operationalCity) {
      setErrors((prev: any) => ({
        ...prev,
        operationalCity: "La localidad es requerida.",
      }));
      errors.operationalCity = "La localidad es requerida.";
    }
    if (!operationalProvince) {
      setErrors((prev: any) => ({
        ...prev,
        operationalProvince: "La provincia es requerida.",
      }));
      errors.operationalProvince = "La provincia es requerida.";
    }
    setTimeout(() => {
      setErrors({});
    }, 3000);
    return errors;
  }

  function handleSubmitOperationalForm() {
    const errors = validateForm();
    if (currentStep !== totalSteps && isComplete) {
      if (Object.keys(errors).length === 0) {
        setCurrentStep(currentStep + 1);
      } else {
        setNotification({
          theme: "error",
          titleNotification: "Registro fallido",
          contentNotification: errors[Object.keys(errors)[0]],
          isVisible: true,
        });
      }
    } else if (isComplete) {
      submitForm({ errors });
    } else {
      setNotification({
        theme: "error",
        titleNotification: "Registro fallido",
        contentNotification: errors[Object.keys(errors)[0]],
        isVisible: true,
      });
    }
  }

  function handleSubmit() {
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      submitForm({ errors });
    } else {
      setNotification({
        theme: "error",
        titleNotification: "Registro fallido",
        contentNotification: errors[Object.keys(errors)[0]],
        isVisible: true,
      });
    }
  }

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoaded,
    onLoad,
    onUnmount,
    handleSubmitOperationalForm,
    searchMap,
    isComplete,
    handleCancel,
    agentId,
    handleSubmit,
  };
}
