import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import UsersIcon from "../../assets/img/usersIcon.svg";
import FilterIcon from "../../assets/img/filterIcon.svg";
import ViewIcon from "../../assets/img/viewIcon.svg";
import Pagination from "../../commonComponents/Pagination";
import useAdminAgents from "./../hooks/useAdminAgents";
import { Agent } from "../Interfaces";
import AddIcon from "../../assets/img/addIcon.svg";
import ButtonComponent from "./../../commonComponents/Button";
import Tooltip from "./../../commonComponents/Tooltip";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ExcelExport from "../../commonComponents/ExcelExport";
// import AgentsToExport from "../components/AgentsToExpot";
import Modal from "../../commonComponents/Modal";
// import PdfIcon from "../../assets/img/pdfIcon.svg";

export default function AdminUsers() {
  const {
    agentName,
    setAgentName,
    tradeName,
    setTradeName,
    city,
    setCity,
    EECC,
    setEECC,
    active,
    setActive,
    agents,
    handleFilter,
    loading,
    agentsPerPage,
    page,
    totalPages,
    handleAgentsPerPage,
    handlePage,
    navigate,
    user,
    handleEnter,
    handleSort,
    orderSorted,
    fieldSorted,
    category,
    setCategory,
    agentsFiltered,
    // visibleExport,
    closeExport,
    // setVisibleExport,
    showModalExport,
    setshowModalExport,
    loadingExport,
    setLoadingExport,
    instalator, 
    setInstalator
  } = useAdminAgents();

  return (
    <Container>
      <Title>
        {user?.isEECC === true &&
        user?.role.name !== "SuperAdmin" &&
        user?.role.name !== "Admin"
          ? "Mis Distribuidores"
          : "Listado de Distribuidores"}
      </Title>
      <CreateAgentContainer>
        {user?.role?.export_agents && (
          <>
            <ButtonComponent
              type="primary"
              onClick={() => setshowModalExport(true)}
            >
              <ButtonTextContainer>EXPORTAR DISTRIBUIDORES</ButtonTextContainer>
            </ButtonComponent>
          </>
        )}
        {user?.role?.create_agent && (
          <>
            <ButtonComponent
              type="primary"
              onClick={() => navigate("/create-agent")}
            >
              <ButtonTextContainer>
                <img src={AddIcon} alt="add icon" /> NUEVO DISTRIBUIDOR
              </ButtonTextContainer>
            </ButtonComponent>
          </>
        )}
      </CreateAgentContainer>
      <MainContainer>
        <TopContainer>
          <IconContainer>
            <img src={UsersIcon} alt="usersIcon" />
          </IconContainer>
        </TopContainer>
        <FilterContainer>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={agentName}
              onChange={({ target: { value } }) => setAgentName(value)}
            />
            <Label>Razón Social</Label>
          </FieldContainer>
          <FieldContainer>
            <InputTradeName
              required
              onKeyDown={handleEnter}
              value={tradeName}
              onChange={({ target: { value } }) => setTradeName(value)}
            />
            <LabelTradeName>Nombre Comercial</LabelTradeName>
          </FieldContainer>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={city}
              onChange={({ target: { value } }) => setCity(value)}
            />
            <Label>Localidad</Label>
          </FieldContainer>
          <FilterSelect>
            <FilterSelectField
              value={active}
              onChange={({ target: { value } }) => setActive(+value)}
            >
              <Option value={2}>Todos</Option>
              <Option value={1}>Activo</Option>
              <Option value={0}>Inactivo</Option>
            </FilterSelectField>
            <LabelSelect>Estado</LabelSelect>
          </FilterSelect>
          {user?.isEECC === true &&
          user?.role.name !== "SuperAdmin" &&
          user?.role.name !== "Admin" ? (
            <FieldContainer>
              <Input required value={EECC} />
              <Label>EECC</Label>
            </FieldContainer>
          ) : (
            <FieldContainer>
              <Input
                required
                value={EECC}
                onChange={({ target: { value } }) => setEECC(value)}
              />
              <Label>EECC</Label>
            </FieldContainer>
          )}
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={category}
              onChange={({ target: { value } }) => setCategory(value)}
            />
            <Label>Tipo</Label>
          </FieldContainer>
          <FilterSelect>
            <FilterSelectField
              value={instalator}
              onChange={({ target: { value } }) => setInstalator(+value)}
            >
              <Option value={2}>Todos</Option>
              <Option value={1}>Si</Option>
              <Option value={0}>No</Option>
            </FilterSelectField>
            <LabelSelect>Instalador VIP</LabelSelect>
          </FilterSelect>
          <FilterButton onClick={handleFilter}>
            <img src={FilterIcon} alt="filter Icon" />
            <FilterButtonText>Filtrar</FilterButtonText>
          </FilterButton>
        </FilterContainer>
        <TableContainer>
          <Table>
            <thead>
              <TRH>
                <TH onClick={() => handleSort("agent_name")}>
                  <THContainer>
                    RAZÓN SOCIAL
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "agent_name" &&
                            orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "agent_name" &&
                            orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("trade_name")}>
                  <THContainer>
                    NOMBRE COMERCIAL
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "trade_name" &&
                            orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "trade_name" &&
                            orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("city")}>
                  <THContainer>
                    LOCALIDAD
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "city" && orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "city" && orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("province")}>
                  <THContainer>
                    PROVINCIA
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "province" && orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "province" && orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("active")}>
                  <THContainer>
                    ESTADO
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "active" && orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "active" && orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("status")}>
                  <THContainer>
                    AUTORIZACIÓN
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "status" && orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "status" && orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("EECC")}>
                  <THContainer>
                    EECC
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "EECC" && orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "EECC" && orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH>TIPO</TH>
                <TH>
                  INST <br /> VIP
                </TH>
                <TH>ACCIONES</TH>
              </TRH>
            </thead>
            <tbody>
              {agents?.map((agent: Agent) => (
                <TR key={agent.agents_id}>
                  {/* <TD>{agent.agents_id}</TD> */}
                  <TD>{agent.agent_name}</TD>
                  <TD>{agent.trade_name}</TD>
                  <TD>{agent.city}</TD>
                  <TD>{agent.province}</TD>
                  <TD>{agent.active ? "Activo" : "Inactivo"}</TD>
                  <TD style={{ textTransform: "capitalize" }}>
                    {agent.status}
                  </TD>
                  <TD>
                    {agent.EECC
                      ? agent.EECC?.first_name + " " + agent.EECC?.last_name
                      : "Sin EECC asociado"}
                  </TD>
                  <TD>
                    {agent.category ? agent.category?.name : "Sin categoría"}
                  </TD>
                  <TD>{agent.instalator ? "Si" : "No"}</TD>
                  <TD>
                    {user?.role?.edit_agents && (
                      <Tooltip text="Editar Distribuidor">
                        <Button
                          onClick={() =>
                            navigate(`/edit-agent/${agent.agents_id}`)
                          }
                        >
                          <img src={ViewIcon} alt="view Icon" />
                        </Button>
                      </Tooltip>
                    )}
                  </TD>
                </TR>
              ))}
            </tbody>
          </Table>
        </TableContainer>
        {loading && (
          <>
            <Loading>
              <CircularProgress
                sx={{
                  color: "#128297",
                }}
                size={100}
              />
            </Loading>
          </>
        )}
        <PaginationContainer>
          <SelectUsersPerPageContainer>
            <SelectUsersPerPage>
              <SelectUsersPerPageField
                value={agentsPerPage}
                onChange={({ target: { value } }) =>
                  handleAgentsPerPage(+value)
                }
              >
                <OptionPage value={10}>10 filas</OptionPage>
                <OptionPage value={25}>25 filas</OptionPage>
                <OptionPage value={50}>50 filas</OptionPage>
                <OptionPage value={100}>100 filas</OptionPage>
              </SelectUsersPerPageField>
            </SelectUsersPerPage>
          </SelectUsersPerPageContainer>
          <Pagination
            page={page}
            handlePage={handlePage}
            totalPages={totalPages}
          />
        </PaginationContainer>
      </MainContainer>
      {/* {visibleExport && (
        <AgentsToExport
          agents={agentsFiltered}
          closeExport={closeExport}
          setLoadingExport={setLoadingExport}
        />
      )} */}
      {showModalExport && (
        <Modal title="Exportar Distribuidores" type={"export"}>
          <ModalContainer>
            {loadingExport ? (
              <LoadingContainer>
                <CircularProgress
                  sx={{
                    color: "#128297",
                  }}
                  size={100}
                />
              </LoadingContainer>
            ) : (
              <>
                {" "}
                <ButtonsContainer>
                  {/* <ExportIcon onClick={() => setVisibleExport(true)}>
                    <img src={PdfIcon} alt="export pdf" />
                  </ExportIcon> */}
                  <ExcelExport
                    agents={agentsFiltered}
                    fileName="Listado de Distribuidores"
                    closeExport={closeExport}
                    setLoadingExport={setLoadingExport}
                  />
                </ButtonsContainer>
                <ButtonComponent
                  type="primary"
                  onClick={() => setshowModalExport(false)}
                >
                  Cerrar
                </ButtonComponent>
              </>
            )}
          </ModalContainer>
        </Modal>
      )}
    </Container>
  );
}
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 40px;
  @media (max-width: 768px) {
    padding: 0px 40px 30px;
  }
`;
// const ExportIcon = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 60px;
//   height: 60px;
//   cursor: pointer;
// `;
const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-top: 80px;
  padding-bottom: 20px;
  min-width: 300px;
  min-height: 260px;
  @media (max-width: 768px) {
    padding-top: 30px;
    min-height: 200px;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
const THContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-family: "D-DIN-PRO-Medium";
  font-size: 10px;
  line-height: 10px;
  color: #128297;
  text-transform: uppercase;
`;
const ArrowsIcon = styled.div`
  display: flex;
  flex-direction: row;
  transform: rotateZ(90deg);
`;
const Container = styled.div`
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  margin-top: 38px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
  }
`;
const CreateAgentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
  gap: 20px;
`;
const ButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 60px 10px 25px 10px;
  margin-bottom: 60px;
  position: relative;
  @media (max-width: 768px) {
    margin-top: 40px;
    padding: 30px 10px 25px 10px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: -17px;
    left: 10px;
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2px;
  @media (max-width: 768px) {
    min-width: 900px;
  }
`;
const TableContainer = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const TR = styled.tr`
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
`;
const TRH = styled.tr`
  :nth-child(even) {
    background-color: #f9f9f9;
  }
`;
const TD = styled.td`
  padding: 14px 0px 13px 4px;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
`;
const TH = styled.th`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  padding: 20px 4px;
  color: #128297;
  text-transform: uppercase;
  text-align: start;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const FilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: calc(20% - 20px);
  height: 55px;
  border: none;
  background: #128297;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  gap: 23px;
  padding: 0 0 0 23px;
  :hover {
    filter: brightness(90%);
  }
  @media (max-width: 768px) {
    min-width: 120px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
    & > img {
      width: 15px;
      height: 15px;
    }
  }
`;
const FilterButtonText = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 13px;
  }
`;
const Button = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #128297;
  :hover {
    filter: brightness(90%);
  }
`;
const FilterSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(20% - 20px);
  height: 55px;
  border-radius: 4px;
  color: #128297;
  font-size: 14px;
  outline: none;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const FilterSelectField = styled.select`
  width: 100%;
  height: 55px;
  border: 1px solid #424242;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  color: #128297;
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const LabelSelect = styled.label`
  position: absolute;
  top: 5px;
  left: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  @media (max-width: 768px) {
    top: 10px;
    left: 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #424242;
  }
`;
const Option = styled.option`
  font-size: 14px;
  color: #128297;
  padding: 10px 0px;
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
`;
const SelectUsersPerPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const SelectUsersPerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OptionPage = styled.option`
  font-size: 14px;
  color: #383838;
`;
const SelectUsersPerPageField = styled.select`
  width: 80px;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  padding: 8px 10px 8px 20px;
  font-size: 14px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  cursor: pointer;
`;
const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const FieldContainer = styled.div`
  position: relative;
  width: calc(20% - 20px);
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #424242;
  height: 55px;
  border-radius: 5px;
  border-radius: 5px;
  outline: none;
  padding: 20px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const Label = styled.label`
  font-size: 15px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 14px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
const InputTradeName = styled.input`
  width: 100%;
  border: 1px solid #424242;
  height: 55px;
  border-radius: 5px;
  border-radius: 5px;
  outline: none;
  padding: 20px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const LabelTradeName = styled.label`
  font-size: 15px;
  position: absolute;
  pointer-events: none;
  top: 11px;
  left: 20px;
  width: 100px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${InputTradeName}:focus ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${InputTradeName}:not(:focus):valid ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 8px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${InputTradeName}:focus ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${InputTradeName}:not(:focus):valid ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
