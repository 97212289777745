import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import useLeadsTracking from "../hooks/useLeadsTracking";
import LeadsIcon from "../../assets/img/leadsIcon.svg";
import Pagination from "../../commonComponents/Pagination";
import ViewIcon from "../../assets/img/viewIcon.svg";
import Tooltip from "../../commonComponents/Tooltip";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Lead } from "../Interfaces";
import ShowLead from "../components/ShowLead";
import EditIcon from "../../assets/img/editRole.svg";
import EditLead from "../components/EditLead";
import FilterIcon from "../../assets/img/filterIcon.svg";
import ButtonComponent from "./../../commonComponents/Button";
import Modal from "../../commonComponents/Modal";
import LeadExcelExport from "../components/LeadExcelExport";
import ArrowIcon from "../../assets/img/arrowAcordeon.svg";

export default function LeadsTracking() {
  const {
    loading,
    handleLeadsPerPage,
    handlePage,
    setLeadIdToEdit,
    leadIdToEdit,
    setRefreshLeads,
    handleSort,
    orderSorted,
    fieldSorted,
    dtf,
    products,
    user,
    showModalExport,
    setShowModalExport,
    loadingExport,
    setLoadingExport,
    closeExport,
    leadsFiltered,
    closedLeads,
    openGroup,
    setOpenGroup,
    closedLeadsTotalPage,
    closedLeadsPage,
    closedLeadsPerPage,
    edit,
    setEdit,
    revisionLeads,
    revisionLeadsTotalPage,
    revisionLeadsPage,
    revisionLeadsPerPage,
    emptyLeads,
    emptyLeadsTotalPage,
    emptyLeadsPage,
    emptyLeadsPerPage,
    handleEnter,
    province,
    setProvince,
    bindAgent,
    setBindAgent,
    interest,
    setInterest,
    state,
    setState,
    handleFilter,
    resetFilters
  } = useLeadsTracking();
  return (
    <Container>
      <Title>Administración de Leads</Title>
      <ExportLeadsContainer>
        <ButtonComponent
          type="primary"
          onClick={() => setShowModalExport(true)}
        >
          <ButtonTextContainer>EXPORTAR LEADS</ButtonTextContainer>
        </ButtonComponent>
      </ExportLeadsContainer>
      <MainContainer>
        <TopContainer>
          <IconContainer>
            <img src={LeadsIcon} alt="usersIcon" />
          </IconContainer>
        </TopContainer>
        {/* <FilterContainer>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={province}
              onChange={({ target: { value } }) => setProvince(value)}
            />
            <Label>Provincia</Label>
          </FieldContainer>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={bindAgent}
              onChange={({ target: { value } }) => setBindAgent(value)}
            />
            <Label>Distribuidor</Label>
          </FieldContainer>
          <FilterSelect>
            <FilterSelectField
              value={interest}
              onChange={({ target: { value } }) => setInterest(+value)}
            >
              <Option value={0}>Todos</Option>
              <Option value={1}>Información</Option>
              <Option value={2}>Cotización</Option>
              <Option value={3}>Incompleto</Option>
            </FilterSelectField>
            <LabelSelect>Interes</LabelSelect>
          </FilterSelect>
          <FilterSelect>
            <FilterSelectField
              value={state}
              onChange={({ target: { value } }) => setState(+value)}
            >
              <Option value={0}>Todos</Option>
              <Option value={1}>No se logró contacto</Option>
              <Option value={2}>Se informó</Option>
              <Option value={3}>Cotizado</Option>
              <Option value={4}>Vendido</Option>
              <Option value={5}>Perdido</Option>
              <Option value={6}>Incompleto</Option>
            </FilterSelectField>
            <LabelSelect>Estado</LabelSelect>
          </FilterSelect>
          <FilterButton onClick={handleFilter}>
            <img src={FilterIcon} alt="filter Icon" />
            <FilterButtonText>Filtrar</FilterButtonText>
          </FilterButton>
        </FilterContainer> */}
        <GroupsContainer>
          <GroupContainer>
            <GroupVisibleContainer className={openGroup === 0 ? "open" : ""}>
              <VisibleLeft>
                <VisibleText>Cerrado</VisibleText>
              </VisibleLeft>
              <VisibleRight>
                <OpenButton
                  onClick={() => {
                    if (openGroup === 0) {
                      setOpenGroup(null);
                    } else {
                      setOpenGroup(0);
                      resetFilters();
                    }
                  }}
                  className={openGroup === 0 ? "open" : ""}
                >
                  <img src={ArrowIcon} alt="arrow Icon" />
                </OpenButton>
              </VisibleRight>
            </GroupVisibleContainer>
            <QuestionsContainer className={openGroup === 0 ? "open" : ""}>
              <FilterContainer>
                <FieldContainer>
                  <Input
                    required
                    onKeyDown={handleEnter}
                    value={province}
                    onChange={({ target: { value } }) => setProvince(value)}
                  />
                  <Label>Provincia</Label>
                </FieldContainer>
                <FieldContainer>
                  <Input
                    required
                    onKeyDown={handleEnter}
                    value={bindAgent}
                    onChange={({ target: { value } }) => setBindAgent(value)}
                  />
                  <Label>Distribuidor</Label>
                </FieldContainer>
                <FilterSelect>
                  <FilterSelectField
                    value={interest}
                    onChange={({ target: { value } }) => setInterest(+value)}
                  >
                    <Option value={0}>Todos</Option>
                    <Option value={1}>Información</Option>
                    <Option value={2}>Cotización</Option>
                    <Option value={3}>Incompleto</Option>
                  </FilterSelectField>
                  <LabelSelect>Interes</LabelSelect>
                </FilterSelect>
                <FilterSelect>
                  <FilterSelectField
                    value={state}
                    onChange={({ target: { value } }) => setState(+value)}
                  >
                    <Option value={0}>Todos</Option>
                    <Option value={1}>No se logró contacto</Option>
                    <Option value={2}>Se informó</Option>
                    <Option value={3}>Cotizado</Option>
                    <Option value={4}>Vendido</Option>
                    <Option value={5}>Perdido</Option>
                    <Option value={6}>Incompleto</Option>
                  </FilterSelectField>
                  <LabelSelect>Estado</LabelSelect>
                </FilterSelect>
                <FilterButton onClick={handleFilter}>
                  <img src={FilterIcon} alt="filter Icon" />
                  <FilterButtonText>Filtrar</FilterButtonText>
                </FilterButton>
              </FilterContainer>
              <TableContainer>
                <Table>
                  <thead>
                    <TRH>
                      <TH onClick={() => handleSort("created_at")}>
                        <THContainer>
                          Fecha
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "created_at" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "created_at" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("first_name")}>
                        <THContainer>
                          Nombre
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "first_name" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "first_name" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("last_name")}>
                        <THContainer>
                          Apellido
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "last_name" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "last_name" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("province")}>
                        <THContainer>
                          Provincia
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "province" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "province" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("city")}>
                        <THContainer>
                          Localidad
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "city" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "city" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("email")}>
                        <THContainer>
                          Mail
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "email" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "email" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("phone")}>
                        <THContainer>
                          Celular
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "phone" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "phone" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH>Productos</TH>
                      <TH>Representante</TH>
                      {user?.roles_id !== 3 && <TH>EECC</TH>}
                      <TH>Contactado</TH>
                      <TH>Interés</TH>
                      <TH>Estado</TH>
                      <TH>Acciones</TH>
                    </TRH>
                  </thead>
                  <tbody>
                    {closedLeads?.map((lead: Lead) => (
                      <TR key={lead.leads_id}>
                        <TD>{dtf.format(new Date(lead.created_at))}</TD>
                        <TD>{lead.first_name}</TD>
                        <TD>{lead.last_name}</TD>
                        <TD>{lead.province}</TD>
                        <TD>{lead.city}</TD>
                        <TD>{lead.email}</TD>
                        <TD>{lead.phone}</TD>
                        <TD style={{ minWidth: "300px" }}>
                          {lead.products.split(",").map((product: string) => {
                            return (
                              <>
                                <Product style={{ padding: "4px" }}>
                                  {products[+product].description
                                    ? products[+product].description
                                    : products[+product].name}
                                </Product>
                              </>
                            );
                          })}
                        </TD>
                        <TD>
                          {lead.agent
                            ? lead.agent.trade_name
                            : "Sin distribuidor"}
                        </TD>
                        {user?.roles_id !== 3 && (
                          <TD>
                            {lead.agent?.EECC
                              ? lead.agent?.EECC?.first_name +
                                " " +
                                lead.agent?.EECC?.last_name
                              : "Asesor Comercial"}
                          </TD>
                        )}
                        <TD>
                          {lead.contacted_date
                            ? dtf.format(new Date(lead.contacted_date))
                            : "-"}
                        </TD>
                        <TD>{lead.interest ? lead.interest : "-"}</TD>
                        <TD>{lead.status ? lead.status : "-"}</TD>
                        <TD>
                          <ActionsContainer>
                            {(user?.roles_id === 3 ||
                              lead.status === "Cerrado") && (
                              <Tooltip text="Ver Lead">
                                <Button
                                  onClick={() => {
                                    setEdit(false);
                                    setLeadIdToEdit(lead.leads_id);
                                  }}
                                >
                                  <img src={ViewIcon} alt="view Icon" />
                                </Button>
                              </Tooltip>
                            )}
                            {user?.role.edit_leads &&
                              lead.status !== "Cerrado" && (
                                <Tooltip text="Editar Lead">
                                  <Button
                                    onClick={() => {
                                      setEdit(true);
                                      setLeadIdToEdit(lead.leads_id);
                                    }}
                                  >
                                    <img src={EditIcon} alt="view Icon" />
                                  </Button>
                                </Tooltip>
                              )}
                          </ActionsContainer>
                        </TD>
                      </TR>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>

              {loading && (
                <>
                  <Loading>
                    <CircularProgress
                      sx={{
                        color: "#128297",
                      }}
                      size={100}
                    />
                  </Loading>
                </>
              )}
              <PaginationContainer>
                <SelectLeadsPerPageContainer>
                  <SelectLeadsPerPage>
                    <SelectLeadsPerPageField
                      value={closedLeadsPerPage}
                      onChange={({ target: { value } }) =>
                        handleLeadsPerPage(+value)
                      }
                    >
                      <OptionPage value={10}>10 filas</OptionPage>
                      <OptionPage value={25}>25 filas</OptionPage>
                      <OptionPage value={50}>50 filas</OptionPage>
                      <OptionPage value={100}>100 filas</OptionPage>
                    </SelectLeadsPerPageField>
                  </SelectLeadsPerPage>
                </SelectLeadsPerPageContainer>
                <Pagination
                  page={closedLeadsPage}
                  handlePage={handlePage}
                  totalPages={closedLeadsTotalPage}
                />
              </PaginationContainer>
            </QuestionsContainer>
          </GroupContainer>
          <GroupContainer>
            <GroupVisibleContainer className={openGroup === 1 ? "open" : ""}>
              <VisibleLeft>
                <VisibleText>En Revisión</VisibleText>
              </VisibleLeft>
              <VisibleRight>
                <OpenButton
                  onClick={() => {
                    if (openGroup === 1) {
                      setOpenGroup(null);
                    } else {
                      setOpenGroup(1);
                      resetFilters();
                    }
                  }}
                  className={openGroup === 1 ? "open" : ""}
                >
                  <img src={ArrowIcon} alt="arrow Icon" />
                </OpenButton>
              </VisibleRight>
            </GroupVisibleContainer>
            <QuestionsContainer className={openGroup === 1 ? "open" : ""}>
              <FilterContainer>
                <FieldContainer>
                  <Input
                    required
                    onKeyDown={handleEnter}
                    value={province}
                    onChange={({ target: { value } }) => setProvince(value)}
                  />
                  <Label>Provincia</Label>
                </FieldContainer>
                <FieldContainer>
                  <Input
                    required
                    onKeyDown={handleEnter}
                    value={bindAgent}
                    onChange={({ target: { value } }) => setBindAgent(value)}
                  />
                  <Label>Distribuidor</Label>
                </FieldContainer>
                <FilterSelect>
                  <FilterSelectField
                    value={interest}
                    onChange={({ target: { value } }) => setInterest(+value)}
                  >
                    <Option value={0}>Todos</Option>
                    <Option value={1}>Información</Option>
                    <Option value={2}>Cotización</Option>
                    <Option value={3}>Incompleto</Option>
                  </FilterSelectField>
                  <LabelSelect>Interes</LabelSelect>
                </FilterSelect>
                <FilterSelect>
                  <FilterSelectField
                    value={state}
                    onChange={({ target: { value } }) => setState(+value)}
                  >
                    <Option value={0}>Todos</Option>
                    <Option value={1}>No se logró contacto</Option>
                    <Option value={2}>Se informó</Option>
                    <Option value={3}>Cotizado</Option>
                    <Option value={4}>Vendido</Option>
                    <Option value={5}>Perdido</Option>
                    <Option value={6}>Incompleto</Option>
                  </FilterSelectField>
                  <LabelSelect>Estado</LabelSelect>
                </FilterSelect>
                <FilterButton onClick={handleFilter}>
                  <img src={FilterIcon} alt="filter Icon" />
                  <FilterButtonText>Filtrar</FilterButtonText>
                </FilterButton>
              </FilterContainer>
              <TableContainer>
                <Table>
                  <thead>
                    <TRH>
                      <TH onClick={() => handleSort("created_at")}>
                        <THContainer>
                          Fecha
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "created_at" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "created_at" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("first_name")}>
                        <THContainer>
                          Nombre
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "first_name" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "first_name" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("last_name")}>
                        <THContainer>
                          Apellido
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "last_name" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "last_name" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("province")}>
                        <THContainer>
                          Provincia
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "province" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "province" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("city")}>
                        <THContainer>
                          Localidad
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "city" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "city" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("email")}>
                        <THContainer>
                          Mail
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "email" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "email" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("phone")}>
                        <THContainer>
                          Celular
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "phone" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "phone" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH>Productos</TH>
                      <TH>Representante</TH>
                      {user?.roles_id !== 3 && <TH>EECC</TH>}
                      <TH>Contactado</TH>
                      <TH>Interés</TH>
                      <TH>Estado</TH>
                      <TH>Acciones</TH>
                    </TRH>
                  </thead>
                  <tbody>
                    {revisionLeads?.map((lead: Lead) => (
                      <TR key={lead.leads_id}>
                        <TD>{dtf.format(new Date(lead.created_at))}</TD>
                        <TD>{lead.first_name}</TD>
                        <TD>{lead.last_name}</TD>
                        <TD>{lead.province}</TD>
                        <TD>{lead.city}</TD>
                        <TD>{lead.email}</TD>
                        <TD>{lead.phone}</TD>
                        <TD style={{ minWidth: "300px" }}>
                          {lead.products.split(",").map((product: string) => {
                            return (
                              <>
                                <Product style={{ padding: "4px" }}>
                                  {products[+product].description
                                    ? products[+product].description
                                    : products[+product].name}
                                </Product>
                              </>
                            );
                          })}
                        </TD>
                        <TD>
                          {lead.agent
                            ? lead.agent.trade_name
                            : "Sin distribuidor"}
                        </TD>
                        {user?.roles_id !== 3 && (
                          <TD>
                            {lead.agent?.EECC
                              ? lead.agent?.EECC?.first_name +
                                " " +
                                lead.agent?.EECC?.last_name
                              : "Asesor Comercial"}
                          </TD>
                        )}
                        <TD>
                          {lead.contacted_date
                            ? dtf.format(new Date(lead.contacted_date))
                            : "-"}
                        </TD>
                        <TD>{lead.interest ? lead.interest : "-"}</TD>
                        <TD>{lead.status ? lead.status : "-"}</TD>
                        <TD>
                          <ActionsContainer>
                            {(user?.roles_id === 3 ||
                              lead.status === "Cerrado") && (
                              <Tooltip text="Ver Lead">
                                <Button
                                  onClick={() => {
                                    setEdit(false);
                                    setLeadIdToEdit(lead.leads_id);
                                  }}
                                >
                                  <img src={ViewIcon} alt="view Icon" />
                                </Button>
                              </Tooltip>
                            )}
                            {user?.role.edit_leads &&
                              lead.status !== "Cerrado" && (
                                <Tooltip text="Editar Lead">
                                  <Button
                                    onClick={() => {
                                      setEdit(true);
                                      setLeadIdToEdit(lead.leads_id);
                                    }}
                                  >
                                    <img src={EditIcon} alt="view Icon" />
                                  </Button>
                                </Tooltip>
                              )}
                          </ActionsContainer>
                        </TD>
                      </TR>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>

              {loading && (
                <>
                  <Loading>
                    <CircularProgress
                      sx={{
                        color: "#128297",
                      }}
                      size={100}
                    />
                  </Loading>
                </>
              )}
              <PaginationContainer>
                <SelectLeadsPerPageContainer>
                  <SelectLeadsPerPage>
                    <SelectLeadsPerPageField
                      value={revisionLeadsPerPage}
                      onChange={({ target: { value } }) =>
                        handleLeadsPerPage(+value)
                      }
                    >
                      <OptionPage value={10}>10 filas</OptionPage>
                      <OptionPage value={25}>25 filas</OptionPage>
                      <OptionPage value={50}>50 filas</OptionPage>
                      <OptionPage value={100}>100 filas</OptionPage>
                    </SelectLeadsPerPageField>
                  </SelectLeadsPerPage>
                </SelectLeadsPerPageContainer>
                <Pagination
                  page={revisionLeadsPage}
                  handlePage={handlePage}
                  totalPages={revisionLeadsTotalPage}
                />
              </PaginationContainer>
            </QuestionsContainer>
          </GroupContainer>
          <GroupContainer>
            <GroupVisibleContainer className={openGroup === 2 ? "open" : ""}>
              <VisibleLeft>
                <VisibleText>Pendiente</VisibleText>
              </VisibleLeft>
              <VisibleRight>
                <OpenButton
                  onClick={() => {
                    if (openGroup === 2) {
                      setOpenGroup(null);
                    } else {
                      setOpenGroup(2);
                      resetFilters();
                    }
                  }}
                  className={openGroup === 2 ? "open" : ""}
                >
                  <img src={ArrowIcon} alt="arrow Icon" />
                </OpenButton>
              </VisibleRight>
            </GroupVisibleContainer>
            <QuestionsContainer className={openGroup === 2 ? "open" : ""}>
              <FilterContainer>
                <FieldContainer>
                  <Input
                    required
                    onKeyDown={handleEnter}
                    value={province}
                    onChange={({ target: { value } }) => setProvince(value)}
                  />
                  <Label>Provincia</Label>
                </FieldContainer>
                <FieldContainer>
                  <Input
                    required
                    onKeyDown={handleEnter}
                    value={bindAgent}
                    onChange={({ target: { value } }) => setBindAgent(value)}
                  />
                  <Label>Distribuidor</Label>
                </FieldContainer>
                <FilterSelect>
                  <FilterSelectField
                    value={interest}
                    onChange={({ target: { value } }) => setInterest(+value)}
                  >
                    <Option value={0}>Todos</Option>
                    <Option value={1}>Información</Option>
                    <Option value={2}>Cotización</Option>
                    <Option value={3}>Incompleto</Option>
                  </FilterSelectField>
                  <LabelSelect>Interes</LabelSelect>
                </FilterSelect>
                <FilterSelect>
                  <FilterSelectField
                    value={state}
                    onChange={({ target: { value } }) => setState(+value)}
                  >
                    <Option value={0}>Todos</Option>
                    <Option value={1}>No se logró contacto</Option>
                    <Option value={2}>Se informó</Option>
                    <Option value={3}>Cotizado</Option>
                    <Option value={4}>Vendido</Option>
                    <Option value={5}>Perdido</Option>
                    <Option value={6}>Incompleto</Option>
                  </FilterSelectField>
                  <LabelSelect>Estado</LabelSelect>
                </FilterSelect>
                <FilterButton onClick={handleFilter}>
                  <img src={FilterIcon} alt="filter Icon" />
                  <FilterButtonText>Filtrar</FilterButtonText>
                </FilterButton>
              </FilterContainer>
              <TableContainer>
                <Table>
                  <thead>
                    <TRH>
                      <TH onClick={() => handleSort("created_at")}>
                        <THContainer>
                          Fecha
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "created_at" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "created_at" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("first_name")}>
                        <THContainer>
                          Nombre
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "first_name" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "first_name" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("last_name")}>
                        <THContainer>
                          Apellido
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "last_name" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "last_name" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("province")}>
                        <THContainer>
                          Provincia
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "province" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "province" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("city")}>
                        <THContainer>
                          Localidad
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "city" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "city" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("email")}>
                        <THContainer>
                          Mail
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "email" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "email" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH onClick={() => handleSort("phone")}>
                        <THContainer>
                          Celular
                          <ArrowsIcon>
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                transform: "rotateZ(180deg)",
                                color:
                                  fieldSorted === "phone" &&
                                  orderSorted === "desc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                            <PlayArrowIcon
                              style={{
                                transition: "all 0.3s ease-in-out",
                                width: "14px",
                                height: "14px",
                                color:
                                  fieldSorted === "phone" &&
                                  orderSorted === "asc"
                                    ? "#128297"
                                    : "#9797975f",
                              }}
                            />
                          </ArrowsIcon>
                        </THContainer>
                      </TH>
                      <TH>Productos</TH>
                      <TH>Representante</TH>
                      {user?.roles_id !== 3 && <TH>EECC</TH>}
                      <TH>Contactado</TH>
                      <TH>Interés</TH>
                      <TH>Estado</TH>
                      <TH>Acciones</TH>
                    </TRH>
                  </thead>
                  <tbody>
                    {emptyLeads?.map((lead: Lead) => (
                      <TR key={lead.leads_id}>
                        <TD>{dtf.format(new Date(lead.created_at))}</TD>
                        <TD>{lead.first_name}</TD>
                        <TD>{lead.last_name}</TD>
                        <TD>{lead.province}</TD>
                        <TD>{lead.city}</TD>
                        <TD>{lead.email}</TD>
                        <TD>{lead.phone}</TD>
                        <TD style={{ minWidth: "300px" }}>
                          {lead.products.split(",").map((product: string) => {
                            return (
                              <>
                                <Product style={{ padding: "4px" }}>
                                  {products[+product].description
                                    ? products[+product].description
                                    : products[+product].name}
                                </Product>
                              </>
                            );
                          })}
                        </TD>
                        <TD>
                          {lead.agent
                            ? lead.agent.trade_name
                            : "Sin distribuidor"}
                        </TD>
                        {user?.roles_id !== 3 && (
                          <TD>
                            {lead.agent?.EECC
                              ? lead.agent?.EECC?.first_name +
                                " " +
                                lead.agent?.EECC?.last_name
                              : "Asesor Comercial"}
                          </TD>
                        )}
                        <TD>
                          {lead.contacted_date
                            ? dtf.format(new Date(lead.contacted_date))
                            : "-"}
                        </TD>
                        <TD>{lead.interest ? lead.interest : "-"}</TD>
                        <TD>{lead.status ? lead.status : "-"}</TD>
                        <TD>
                          <ActionsContainer>
                            {(user?.roles_id === 3 ||
                              lead.status === "Cerrado") && (
                              <Tooltip text="Ver Lead">
                                <Button
                                  onClick={() => {
                                    setEdit(false);
                                    setLeadIdToEdit(lead.leads_id);
                                  }}
                                >
                                  <img src={ViewIcon} alt="view Icon" />
                                </Button>
                              </Tooltip>
                            )}
                            {user?.role.edit_leads &&
                              lead.status !== "Cerrado" && (
                                <Tooltip text="Editar Lead">
                                  <Button
                                    onClick={() => {
                                      setEdit(true);
                                      setLeadIdToEdit(lead.leads_id);
                                    }}
                                  >
                                    <img src={EditIcon} alt="view Icon" />
                                  </Button>
                                </Tooltip>
                              )}
                          </ActionsContainer>
                        </TD>
                      </TR>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>

              {loading && (
                <>
                  <Loading>
                    <CircularProgress
                      sx={{
                        color: "#128297",
                      }}
                      size={100}
                    />
                  </Loading>
                </>
              )}
              <PaginationContainer>
                <SelectLeadsPerPageContainer>
                  <SelectLeadsPerPage>
                    <SelectLeadsPerPageField
                      value={emptyLeadsPerPage}
                      onChange={({ target: { value } }) =>
                        handleLeadsPerPage(+value)
                      }
                    >
                      <OptionPage value={10}>10 filas</OptionPage>
                      <OptionPage value={25}>25 filas</OptionPage>
                      <OptionPage value={50}>50 filas</OptionPage>
                      <OptionPage value={100}>100 filas</OptionPage>
                    </SelectLeadsPerPageField>
                  </SelectLeadsPerPage>
                </SelectLeadsPerPageContainer>
                <Pagination
                  page={emptyLeadsPage}
                  handlePage={handlePage}
                  totalPages={emptyLeadsTotalPage}
                />
              </PaginationContainer>
            </QuestionsContainer>
          </GroupContainer>
        </GroupsContainer>
      </MainContainer>
      {leadIdToEdit && !edit && (
        <ShowLead
          leadIdToEdit={leadIdToEdit}
          setLeadIdToEdit={setLeadIdToEdit}
          setRefreshLeads={setRefreshLeads}
        />
      )}
      {leadIdToEdit && edit && (
        <EditLead
          leadIdToEdit={leadIdToEdit}
          setLeadIdToEdit={setLeadIdToEdit}
          setRefreshLeads={setRefreshLeads}
        />
      )}
      {showModalExport && (
        <Modal title="Exportar Leads" type={"export"}>
          <ModalContainer>
            {loadingExport ? (
              <LoadingContainer>
                <CircularProgress
                  sx={{
                    color: "#128297",
                  }}
                  size={100}
                />
              </LoadingContainer>
            ) : (
              <>
                {" "}
                <ButtonsContainer>
                  {/* <ExportIcon onClick={() => setVisibleExport(true)}>
                    <img src={PdfIcon} alt="export pdf" />
                  </ExportIcon> */}
                  <LeadExcelExport
                    leads={leadsFiltered}
                    fileName="Listado de Leads"
                    closeExport={closeExport}
                    setLoadingExport={setLoadingExport}
                    products={products}
                  />
                </ButtonsContainer>
                <ButtonComponent
                  type="primary"
                  onClick={() => setShowModalExport(false)}
                >
                  Cerrar
                </ButtonComponent>
              </>
            )}
          </ModalContainer>
        </Modal>
      )}
    </Container>
  );
}
const THContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  color: #128297;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const ArrowsIcon = styled.div`
  display: flex;
  flex-direction: row;
  transform: rotateZ(90deg);
`;
const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const Container = styled.div`
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  margin-top: 38px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
  }
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 60px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 60px 10px 25px 10px;
  margin-bottom: 60px;
  position: relative;
  @media (max-width: 768px) {
    margin-top: 40px;
    padding: 30px 10px 25px 10px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: -17px;
    left: 10px;
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2px;
  @media (max-width: 768px) {
    min-width: 700px;
  }
`;
const TableContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #128297;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #0e616f;
  }
  @media (max-width: 768px) {
    width: 100%;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const TR = styled.tr`
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
`;
const TRH = styled.tr`
  :nth-child(even) {
    background-color: #f9f9f9;
  }
`;
const TD = styled.td`
  padding: 14px 0px 13px 20px;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
`;
const TH = styled.th`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  padding: 23px 20px;
  color: #128297;
  text-transform: uppercase;
  text-align: start;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const Button = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #128297;
  :hover {
    filter: brightness(90%);
  }
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
`;
const SelectLeadsPerPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const SelectLeadsPerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OptionPage = styled.option`
  font-size: 14px;
  color: #383838;
`;
const SelectLeadsPerPageField = styled.select`
  width: 80px;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  padding: 8px 10px 8px 20px;
  font-size: 14px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  cursor: pointer;
`;
const Product = styled.p`
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const FilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: calc(20% - 20px);
  height: 55px;
  border: none;
  background: #128297;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  gap: 23px;
  padding: 0 0 0 23px;
  :hover {
    filter: brightness(90%);
  }
  @media (max-width: 768px) {
    min-width: 120px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
    & > img {
      width: 15px;
      height: 15px;
    }
  }
`;
const FilterButtonText = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 13px;
  }
`;
const FilterSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(20% - 20px);
  height: 55px;
  border-radius: 4px;
  color: #128297;
  font-size: 14px;
  outline: none;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const FilterSelectField = styled.select`
  width: 100%;
  height: 55px;
  border: 1px solid #424242;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  color: #128297;
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const LabelSelect = styled.label`
  position: absolute;
  top: 5px;
  left: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  @media (max-width: 768px) {
    top: 10px;
    left: 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #424242;
  }
`;
const Option = styled.option`
  font-size: 14px;
  color: #128297;
  padding: 10px 0px;
`;
const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const FieldContainer = styled.div`
  position: relative;
  width: calc(20% - 20px);
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #424242;
  height: 55px;
  border-radius: 5px;
  border-radius: 5px;
  outline: none;
  padding: 20px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const Label = styled.label`
  font-size: 15px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 14px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
const ExportLeadsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 40px;
  gap: 20px;
`;
const ButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;
const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding-top: 80px;
  padding-bottom: 20px;
  min-width: 300px;
  min-height: 260px;
  @media (max-width: 768px) {
    padding-top: 30px;
    min-height: 200px;
  }
`;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 40px;
  @media (max-width: 768px) {
    padding: 0px 40px 30px;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;

const GroupsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const GroupVisibleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 66px;
  border-radius: 8px;
  background-color: #e7e7e7;
  padding: 8px 16px;
  &.open {
    border-radius: 8px 8px 0 0;
  }
`;
const VisibleLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;
const VisibleText = styled.h3`
  font-size: 15px;
  font-weight: 500;
  font-family: "Roboto";
  color: #424242;
  @media (max-width: 768px) {
    font-size: 13px;
  }
`;
const VisibleRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
`;
const OpenButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #128297;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  transform: rotate(0deg);
  &.open {
    transform: rotate(180deg);
  }
  display: flex;
  justify-content: center;
  align-items: center;
`;
const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  max-height: 0px;
  opacity: 0;
  border-radius: 0 0 8px 8px;
  padding: 0 16px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
  &.open {
    border: 1px solid #e7e7e7;
    padding: 24px 16px;
    max-height: 100%;
    opacity: 1;
  }
`;
