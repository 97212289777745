import { useEffect, useState } from "react";
import useAxios from "../../commonHooks/useAxios";
import { User } from "./../../configs/Interfaces";

export default function useModalSelectUser({
    inputUserSelect,
    setInputUserSelect,
    setArrayUsers,
    arrayUsers,
  }: {
    inputUserSelect: number | null;
    setInputUserSelect: (user: number | null) => void;
    setArrayUsers: (users: string[]) => void;
    arrayUsers: any[];
  }) {
    const [searchByName, setsearchByName] = useState<string>("");
    const [initialUsers, setInitialUsers] = useState<User[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const getData = useAxios();
  
    useEffect(() => {
      async function fetchData() {
        setLoading(true);
        const response: User[] = await getData({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}/agentusers/`,
          headers: {
            accept: "*/*",
          },
        });
        const emailsUsersSelected = arrayUsers.map((user) => user.email);
        const newResponse = response.filter((user) => {
          return !emailsUsersSelected.includes(user.email);
        });
        setInitialUsers(newResponse);
        setUsers(newResponse);
        setLoading(false);
      }
      fetchData();
    }, []); // eslint-disable-line
  
    useEffect(() => {
      if (searchByName.length > 0) {
        setUsers(
          initialUsers.filter((user) =>
            user.first_name.toLowerCase().includes(searchByName.toLowerCase())
          )
        );
      } else {
        setUsers(initialUsers);
      }
    }, [searchByName, initialUsers]);
  
    function handleUser(user: any) {
      if (inputUserSelect !== null) {
        const oldArrayUsers = arrayUsers;
        oldArrayUsers[inputUserSelect] = {
          users_id: user.users_id,
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        };
        if (inputUserSelect === arrayUsers.length - 1) {
          setArrayUsers([
            ...oldArrayUsers,
            {
              users_id: null,
              first_name: "",
              last_name: "",
              email: "",
            },
          ]);
        } else {
          setArrayUsers(oldArrayUsers);
        }
      }
      setInputUserSelect(null);
    }
    return{
        loading,
        searchByName,
        setsearchByName,
        users,
        handleUser,
    }
}
