import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import Pagination from "../../commonComponents/Pagination";
import EditIcon from "../../assets/img/editRole.svg";
import Tooltip from "../../commonComponents/Tooltip";
import ButtonComponent from "../../commonComponents/Button";
import AddIcon from "../../assets/img/addIcon.svg";
import Modal from "../../commonComponents/Modal";
import TrashIcon from "../../assets/img/trashIcon.svg";
import FeedIcon from "../../assets/img/questionnaireIcon.svg";
import FilterIcon from "../../assets/img/filterIcon.svg";
import CheckIcon from "../../assets/img/checkOrders.svg";
import CertificationIcon from "../../assets/img/certificationIcon.svg";
import CertificationForm from "../components/CertificationForm";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Logo from "../../assets/img/logoDashboard.svg";
import useCertificationAllOrders from "../hooks/useCertificationAllOrders";
import ViewIcon from "../../assets/img/viewIcon.svg";
export default function CertificationAllOrders() {
  const {
    certificationOrders,
    loading,
    certificationOrdersPerPage,
    handleDocumentsPerPage,
    page,
    handlePage,
    totalPages,
    setRefreshCertificationOrders,
    createNewCertificationOrder,
    setCreateNewCertificationOrder,
    certificationOrderToDelete,
    loadingDelete,
    setCertificationOrderToDelete,
    setQuestionnaireIdToDelete,
    deleteQuestionnaire,
    navigate,
    EECCS,
    certifiers,
    agentName,
    setAgentName,
    EECCName,
    setEECCName,
    orderType,
    setOrderType,
    site,
    setSite,
    state,
    setState,
    handleEnter,
    handleFilter,
    certificationOrderToPrint,
    setCertificationOrderToPrint,
    exportPdf,
    fontContainer,
    fontTitle,
    fontSignature,
    printContainer,
    fontDate,
    handleSort,
    orderSorted,
    fieldSorted,
    user,
  } = useCertificationAllOrders();

  return (
    <Container>
      <Title>Listado de Órdenes de Certificación</Title>
      <CreateDocumentContainer>
        <ButtonComponent
          type="primary"
          onClick={() => setCreateNewCertificationOrder(true)}
        >
          <ButtonTextContainer>
            <img src={AddIcon} alt="add icon" /> NUEVA ORDEN DE CERTIFICACIÓN
          </ButtonTextContainer>
        </ButtonComponent>
      </CreateDocumentContainer>
      <MainContainer>
        <TopContainer>
          <IconContainer>
            <img src={FeedIcon} alt="questionnaire icon" />
          </IconContainer>
        </TopContainer>
        <FilterContainer>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={agentName}
              onChange={({ target: { value } }) => setAgentName(value)}
            />
            <Label>Razón Social</Label>
          </FieldContainer>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={EECCName}
              onChange={({ target: { value } }) => setEECCName(value)}
            />
            <Label>EECC</Label>
          </FieldContainer>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={orderType}
              onChange={({ target: { value } }) => setOrderType(value)}
            />
            <Label>Tipo de certificado</Label>
          </FieldContainer>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={site}
              onChange={({ target: { value } }) => setSite(value)}
            />
            <Label>Obra</Label>
          </FieldContainer>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={state}
              onChange={({ target: { value } }) => setState(value)}
            />
            <Label>Estado</Label>
          </FieldContainer>
          <FilterButton onClick={handleFilter}>
            <img src={FilterIcon} alt="filter Icon" />
            <FilterButtonText>Filtrar</FilterButtonText>
          </FilterButton>
        </FilterContainer>
        <TableContainer>
          {!loading && (
            <Table>
              <thead>
                <TRH>
                  <TH onClick={() => handleSort("orders_id")}>
                    <THContainer>
                      ID
                      <ArrowsIcon>
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            transform: "rotateZ(180deg)",
                            color:
                              fieldSorted === "orders_id" &&
                              orderSorted === "desc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            color:
                              fieldSorted === "orders_id" &&
                              orderSorted === "asc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                      </ArrowsIcon>
                    </THContainer>
                  </TH>
                  <TH onClick={() => handleSort("agent_name")}>
                    <THContainer>
                      RAZÓN SOCIAL
                      <ArrowsIcon>
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            transform: "rotateZ(180deg)",
                            color:
                              fieldSorted === "agent_name" &&
                              orderSorted === "desc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            color:
                              fieldSorted === "agent_name" &&
                              orderSorted === "asc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                      </ArrowsIcon>
                    </THContainer>
                  </TH>
                  <TH onClick={() => handleSort("EECC")}>
                    <THContainer>
                      EECC
                      <ArrowsIcon>
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            transform: "rotateZ(180deg)",
                            color:
                              fieldSorted === "EECC" && orderSorted === "desc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            color:
                              fieldSorted === "EECC" && orderSorted === "asc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                      </ArrowsIcon>
                    </THContainer>
                  </TH>
                  <TH onClick={() => handleSort("type")}>
                    <THContainer>
                      TIPO
                      <ArrowsIcon>
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            transform: "rotateZ(180deg)",
                            color:
                              fieldSorted === "type" && orderSorted === "desc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            color:
                              fieldSorted === "type" && orderSorted === "asc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                      </ArrowsIcon>
                    </THContainer>
                  </TH>
                  <TH onClick={() => handleSort("work")}>
                    <THContainer>
                      OBRA
                      <ArrowsIcon>
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            transform: "rotateZ(180deg)",
                            color:
                              fieldSorted === "work" && orderSorted === "desc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            color:
                              fieldSorted === "work" && orderSorted === "asc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                      </ArrowsIcon>
                    </THContainer>
                  </TH>
                  <TH onClick={() => handleSort("state")}>
                    <THContainer>
                      ESTADO
                      <ArrowsIcon>
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            transform: "rotateZ(180deg)",
                            color:
                              fieldSorted === "state" && orderSorted === "desc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            color:
                              fieldSorted === "state" && orderSorted === "asc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                      </ArrowsIcon>
                    </THContainer>
                  </TH>
                  <TH onClick={() => handleSort("CERTIFIER")}>
                    <THContainer>
                      CERTIFICADOR
                      <ArrowsIcon>
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            transform: "rotateZ(180deg)",
                            color:
                              fieldSorted === "CERTIFIER" &&
                              orderSorted === "desc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            color:
                              fieldSorted === "CERTIFIER" &&
                              orderSorted === "asc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                      </ArrowsIcon>
                    </THContainer>
                  </TH>
                  <TH onClick={() => handleSort("creation_date")}>
                    <THContainer>
                      FECHA ALTA
                      <ArrowsIcon>
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            transform: "rotateZ(180deg)",
                            color:
                              fieldSorted === "creation_date" &&
                              orderSorted === "desc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            color:
                              fieldSorted === "creation_date" &&
                              orderSorted === "asc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                      </ArrowsIcon>
                    </THContainer>
                  </TH>
                  <TH onClick={() => handleSort("certification_date")}>
                    <THContainer>
                      FECHA CERTIFICACIÓN
                      <ArrowsIcon>
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            transform: "rotateZ(180deg)",
                            color:
                              fieldSorted === "certification_date" &&
                              orderSorted === "desc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            color:
                              fieldSorted === "certification_date" &&
                              orderSorted === "asc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                      </ArrowsIcon>
                    </THContainer>
                  </TH>
                  <TH onClick={() => handleSort("percentageAproved")}>
                    <THContainer>
                      % Aprobación
                      <ArrowsIcon>
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            transform: "rotateZ(180deg)",
                            color:
                              fieldSorted === "percentageAproved" &&
                              orderSorted === "desc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                        <PlayArrowIcon
                          style={{
                            transition: "all 0.3s ease-in-out",
                            width: "14px",
                            height: "14px",
                            color:
                              fieldSorted === "percentageAproved" &&
                              orderSorted === "asc"
                                ? "#128297"
                                : "#9797975f",
                          }}
                        />
                      </ArrowsIcon>
                    </THContainer>
                  </TH>
                  <TH>ACCIONES</TH>
                </TRH>
              </thead>
              <tbody>
                {certificationOrders?.map((quest: any) => (
                  <TR key={quest.orders_id}>
                    <TD>{quest.orders_id.slice(0, 10)}</TD>
                    <TD>{quest.agent_name}</TD>
                    <TD>
                      {EECCS[quest.EECC_id]
                        ? `${EECCS[quest.EECC_id].first_name} ${
                            EECCS[quest.EECC_id].last_name
                          }`
                        : ""}
                    </TD>
                    <TD>{quest.type}</TD>
                    <TD>{quest.work}</TD>
                    <TD>{quest.state}</TD>
                    <TD>
                      {certifiers[quest.certifier_id]
                        ? `${certifiers[quest.certifier_id].first_name} ${
                            certifiers[quest.certifier_id].last_name
                          }`
                        : ""}
                    </TD>
                    <TD>
                      {quest.creation_date?._seconds
                        ? new Date(
                            quest.creation_date._seconds * 1000 +
                              quest.creation_date._nanoseconds / 1000000
                          ).toLocaleDateString()
                        : ""}
                    </TD>
                    <TD>{quest?.certification_date}</TD>
                    <TD>
                      {quest?.percentageAproved
                        ? `${quest?.percentageAproved}%`
                        : "0%"}
                    </TD>
                    <TD>
                      <ActionsContainer>
                        {(quest.state === "aprobado" ||
                          quest.state === "rechazado" ||
                          quest.state === "con observaciones" ||
                          quest.state === "enviada") && (
                          <Tooltip text="Ver Orden" width={106}>
                            <Button
                              onClick={() =>
                                navigate(
                                  `/certification/orders/view/${quest.orders_id}`
                                )
                              }
                            >
                              <img src={ViewIcon} alt="edit Icon" />
                            </Button>
                          </Tooltip>
                        )}
                        {quest.state === "pendiente" && (
                          <Tooltip text="Modificar Orden" width={106}>
                            <Button
                              onClick={() =>
                                navigate(
                                  `/certification/orders/${quest.orders_id}`
                                )
                              }
                            >
                              <img src={EditIcon} alt="edit Icon" />
                            </Button>
                          </Tooltip>
                        )}
                        {(quest.state === "con observaciones" ||
                          quest.state === "enviada") && (
                          <Tooltip text="Revisar Orden" width={106}>
                            <Button
                              onClick={() =>
                                navigate(
                                  `/certification/orders/admin/${quest.orders_id}`
                                )
                              }
                            >
                              <img src={CheckIcon} alt="edit Icon" />
                            </Button>
                          </Tooltip>
                        )}
                        {(quest.state === "pendiente" ||
                          user?.roles_id === 1) && (
                          <Tooltip text="Eliminar Orden">
                            <Button
                              onClick={() => setQuestionnaireIdToDelete(quest)}
                            >
                              <img src={TrashIcon} alt="trash Icon" />
                            </Button>
                          </Tooltip>
                        )}
                        {quest.state === "aprobado" ? (
                          <Tooltip text="Ver Certificado">
                            <Button
                              onClick={() => {
                                setCertificationOrderToPrint(quest);
                              }}
                            >
                              <img
                                src={CertificationIcon}
                                alt="certification Icon"
                              />
                            </Button>
                          </Tooltip>
                        ) : (
                          <></>
                        )}
                      </ActionsContainer>
                    </TD>
                  </TR>
                ))}
              </tbody>
            </Table>
          )}
        </TableContainer>
        {loading && (
          <>
            <Loading>
              <CircularProgress
                sx={{
                  color: "#128297",
                }}
                size={100}
              />
            </Loading>
          </>
        )}
        <PaginationContainer>
          <SelectQuestionnairesPerPageContainer>
            <SelectQuestionnairesPerPage>
              <SelectQuestionnairesPerPageField
                value={certificationOrdersPerPage}
                onChange={({ target: { value } }) =>
                  handleDocumentsPerPage(+value)
                }
              >
                <OptionPage value={10}>10 filas</OptionPage>
                <OptionPage value={25}>25 filas</OptionPage>
                <OptionPage value={50}>50 filas</OptionPage>
                <OptionPage value={100}>100 filas</OptionPage>
              </SelectQuestionnairesPerPageField>
            </SelectQuestionnairesPerPage>
          </SelectQuestionnairesPerPageContainer>
          <Pagination
            page={page}
            handlePage={handlePage}
            totalPages={totalPages}
          />
        </PaginationContainer>
      </MainContainer>
      {createNewCertificationOrder && (
        <CertificationForm
          setCreateNewCertificationOrder={setCreateNewCertificationOrder}
          setRefreshCertificationOrders={setRefreshCertificationOrders}
        />
      )}
      {certificationOrderToDelete && (
        <Modal type="type" title={certificationOrderToDelete.type}>
          <ModalContainer
            style={{
              justifyContent: loadingDelete ? "center" : "space-between",
            }}
          >
            {loadingDelete ? (
              <Loading>
                <CircularProgress
                  sx={{
                    color: "#128297",
                  }}
                  size={100}
                />
              </Loading>
            ) : (
              <>
                <DeleteDocumentText>
                  Está seguro que desea eliminar la orden de certificación{" "}
                  {certificationOrderToDelete.type}?
                </DeleteDocumentText>
                <ButtonsModal>
                  <ButtonComponent
                    type="secondary"
                    onClick={() => setCertificationOrderToDelete(null)}
                  >
                    VOLVER
                  </ButtonComponent>
                  <ButtonComponent
                    type="primary"
                    onClick={() =>
                      deleteQuestionnaire(certificationOrderToDelete)
                    }
                  >
                    ACEPTAR
                  </ButtonComponent>
                </ButtonsModal>
              </>
            )}
          </ModalContainer>
        </Modal>
      )}
      {certificationOrderToPrint && (
        <Modal type="type" title={certificationOrderToPrint.type}>
          <PrintContainer
            style={{
              justifyContent: loadingDelete ? "center" : "space-between",
            }}
            ref={printContainer}
          >
            <TopCertificationContainer>
              <img src={Logo} alt="logo" />
            </TopCertificationContainer>
            <BottomCertificationContainer>
              <TitleCertificationContainer ref={fontTitle}>
                Certificación {certificationOrderToPrint.type}
              </TitleCertificationContainer>
              <ContentContainer ref={fontContainer}>
                <Bold>YPF SOLAR</Bold> certifica que la instalación{" "}
                <Bold>{certificationOrderToPrint.type}</Bold> realizada en{" "}
                <Bold>{certificationOrderToPrint.work}</Bold> ubicada en{" "}
                <Bold>{certificationOrderToPrint.ubication}</Bold> y ejecutada
                por <Bold>{certificationOrderToPrint.agent_name}</Bold> cumple
                con las reglas del buen arte y documentación de Producto
                establecidas en{" "}
                <Link href="www.ypfsolar.com" target="_blank">
                  www.ypfsolar.com
                </Link>{" "}
                .<br />
                <br />Por este motivo YPF SOLAR expide el presente Certificado validando el cumplimiento de los términos y condiciones requeridos por la Garantía del equipamiento suministrado para la instalación{" "}
                {certificationOrderToPrint.type}.
              </ContentContainer>
            </BottomCertificationContainer>
            <BottomContent>
              <CertificatedDate ref={fontDate}>
                {String(certificationOrderToPrint?.certification_date)}
              </CertificatedDate>
              <Signature ref={fontSignature}>
                Ing. Christian Martin <br />
                <span>Gerente Ingeniería</span>
              </Signature>
            </BottomContent>
          </PrintContainer>
          <ButtonsModal>
            <ButtonComponent
              type="secondary"
              onClick={() => setCertificationOrderToPrint(null)}
            >
              VOLVER
            </ButtonComponent>
            <ButtonComponent
              type="primary"
              onClick={() => {
                exportPdf();
              }}
            >
              DESCARGAR
            </ButtonComponent>
          </ButtonsModal>
        </Modal>
      )}
    </Container>
  );
}
const Container = styled.div`
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  margin-top: 38px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
  }
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 60px 10px 25px 10px;
  margin-bottom: 60px;
  position: relative;
  margin-top: 50px;
  @media (max-width: 768px) {
    margin-top: 40px;
    padding: 30px 10px 25px 10px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: -17px;
    left: 10px;
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2px;
  @media (max-width: 768px) {
    min-width: 900px;
  }
`;
const TableContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #128297;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #0e616f;
  }
  @media (max-width: 768px) {
    width: 100%;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const TR = styled.tr`
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
`;
const TRH = styled.tr`
  :nth-child(even) {
    background-color: #f9f9f9;
  }
`;
const TD = styled.td`
  padding: 14px 0px 13px 20px;
  font-size: 11px;
  line-height: 11px;
  color: #424242;
  text-transform: capitalize;
`;
const TH = styled.th`
  font-family: "D-DIN-PRO-Medium";
  font-size: 11px;
  line-height: 11px;
  padding: 23px 20px;
  color: #128297;
  text-transform: uppercase;
  text-align: start;
  :last-of-type {
    text-align: end;
    width: 150px;
  }
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const Button = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #128297;
  :hover {
    filter: brightness(90%);
  }
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
`;
const SelectQuestionnairesPerPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const SelectQuestionnairesPerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OptionPage = styled.option`
  font-size: 14px;
  color: #383838;
`;
const SelectQuestionnairesPerPageField = styled.select`
  width: 80px;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  padding: 8px 10px 8px 20px;
  font-size: 14px;
  outline: none;
  text-indent: 1px;
  cursor: pointer;
`;
const CreateDocumentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
  gap: 20px;
`;
const ButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;
const ModalContainer = styled.div`
  width: 450px;
  height: 331px;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
  }
`;
const DeleteDocumentText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #3c4858;
  margin-top: 110px;
  @media (max-width: 768px) {
    text-align: center;
  }
`;
const ButtonsModal = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
  gap: 20px;
`;
const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;

const FilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: calc(20% - 20px);
  height: 55px;
  border: none;
  background: #128297;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  gap: 23px;
  padding: 0 0 0 23px;
  :hover {
    filter: brightness(90%);
  }
  @media (max-width: 768px) {
    min-width: 120px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
    & > img {
      width: 15px;
      height: 15px;
    }
  }
`;
const FilterButtonText = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 13px;
  }
`;

const FilterSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(20% - 20px);
  height: 55px;
  border-radius: 4px;
  color: #128297;
  font-size: 14px;
  outline: none;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const FilterSelectField = styled.select`
  width: 100%;
  height: 55px;
  border: 1px solid #424242;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  color: #128297;
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const LabelSelect = styled.label`
  position: absolute;
  top: 5px;
  left: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  @media (max-width: 768px) {
    top: 10px;
    left: 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #424242;
  }
`;
const Option = styled.option`
  font-size: 14px;
  color: #128297;
  padding: 10px 0px;
`;
const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const FieldContainer = styled.div`
  position: relative;
  width: calc(20% - 20px);
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #424242;
  height: 55px;
  border-radius: 5px;
  border-radius: 5px;
  outline: none;
  padding: 20px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const Label = styled.label`
  font-size: 15px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 14px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;

const InputTradeName = styled.input`
  width: 100%;
  border: 1px solid #424242;
  height: 55px;
  border-radius: 5px;
  border-radius: 5px;
  outline: none;
  padding: 20px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const PrintContainer = styled.div`
  width: calc(90vw - 261px);
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 30px;
  margin-top: 50px;
  margin-bottom: 20px;
  align-items: center;
  border: 1px solid #c2c2c2;
  background-image: url("/certificado.png");
  background-size: contain;
  background-position: bottom;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
  }
`;
const TopCertificationContainer = styled.div`
  background-color: #019cb4;
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 30px;
`;
const BottomCertificationContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 30px;
  align-items: flex-start;
`;
const TitleCertificationContainer = styled.div`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  margin-top: 20px;
  width: 100%;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
  }
`;
const ContentContainer = styled.div`
  font-size: 16px;
  line-height: 16px;
  color: #333333;
  margin-top: 38px;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
    color: #333333;
    margin-top: 23px;
  }
`;
const BottomContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 30px 80px;
  align-items: flex-end;
`;
const CertificatedDate = styled.div`
  font-size: 15px;
  line-height: 15px;
  color: #333333;
  margin-top: 38px;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
    color: #333333;
    margin-top: 23px;
  }
`;
const Signature = styled.div`
  & > span {
    font-weight: 600;
    color: black;
  }
  font-size: 15px;
  line-height: 15px;
  color: #333333;
  text-align: center;
  background-image: url(/signature.png);
  background-size: contain;
  background-position: bottom;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  justify-content: flex-end;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
    color: #333333;
    margin-top: 23px;
  }
`;
const Bold = styled.span`
  font-weight: 600;
  color: black;
`;
const Link = styled.a`
  text-decoration: none;
  color: #128297;
  font-weight: 600;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;
const THContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-family: "D-DIN-PRO-Medium";
  font-size: 10px;
  line-height: 10px;
  color: #128297;
  text-transform: uppercase;
`;
const ArrowsIcon = styled.div`
  display: flex;
  flex-direction: row;
  transform: rotateZ(90deg);
`;
