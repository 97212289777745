import { useContext, useState } from "react";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";
import { userContext } from "../../context/userContext";

export default function useBindingRequestModal({
  setShowBindingRequestModal,
}: {
  setShowBindingRequestModal: (show: boolean) => void;
}) {
  const [agentId, setAgentId] = useState<string>("");
  const [errors, setErrors] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const axiosData = useAxios();
  const { setNotification } = useContext(notificationsContext);
  const {
    userState: { user },
    setUserState,
  } = useContext(userContext);
  async function handleSubmit() {
    const errors: any = {};
    if (!agentId) {
      setErrors(true);
      errors.status = "El tipo de operación es requerido.";
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const data = {
        agents_id: agentId,
        users_id: user?.users_id,
      };

      try {
        const response = await axiosData({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/bindingrequest/`,
          headers: {
            accept: "*/*",
          },
          data,
        });
        if (response.agent_name) {
          setNotification({
            theme: "success",
            titleNotification: "Solicitud enviada",
            contentNotification: `Solicitud enviada exitosamente.`,
            isVisible: true,
          });
          setUserState((prev: { user: any }) => ({
            ...prev,
            user: {
              ...prev.user,
              agent: response,
              binding_request_status: "pendiente",
            },
          }));
          setShowBindingRequestModal(false);
          setLoading(false);
        } else {
          setNotification({
            theme: "error",
            titleNotification: "Error",
            contentNotification: `No se encontró el distribuidor.`,
            isVisible: true,
          });
          setLoading(false);
        }
      } catch (error) {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: `No se encontró el distribuidor.`,
          isVisible: true,
        });
        setLoading(false);
      }
    } else {
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: `El ID del distribuidor es requerido.`,
        isVisible: true,
      });
    }
    setTimeout(() => {
      setErrors(false);
    }, 3000);
  }
  return {
    loading,
    errors,
    agentId,
    setAgentId,
    handleSubmit,
  };
}
