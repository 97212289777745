import { useContext, useEffect, useState } from "react";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";
import { Document, DocumentType } from "../Interfaces";

export default function useDocumentsForm({
  documentIdToEdit,
  setDocumentIdToEdit,
  setRefreshDocuments,
  setCreateNewDocument,
}: {
  documentIdToEdit?: string | null;
  setDocumentIdToEdit: (agentId: string | null) => void;
  setRefreshDocuments: (refresh: boolean) => void;
  setCreateNewDocument: (createNewAgent: boolean) => void;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [document, setDocument] = useState<string>("");
  const [link, setLink] = useState<string>("");
  const [version, setVersion] = useState<string>("");
  const [showDropDownType, setShowDropDownType] = useState<boolean>(false);
  const [documentType, setDocumentType] = useState<string | null>(null);
  const axiosData = useAxios();
  const [errors, setErrors] = useState<{
    document?: string;
    link?: string;
    version?: string;
    documentType?: string;
  }>({});
  const [height, setHeight] = useState<number>(window.innerWidth < 768 ? 34 : 55);
  const { setNotification } = useContext(notificationsContext);
  const [options, setOptions] = useState<DocumentType[]>([]);
  
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const documentResponse: Document = await axiosData({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}/documents/${documentIdToEdit}`,
          headers: {
            accept: "*/*",
          },
        });
        const typesResponse: DocumentType[] = await axiosData({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}/documents/types`,
          headers: {
            accept: "*/*",
          },
        });
        setOptions(typesResponse);
        setDocument(documentResponse.name);
        setLink(documentResponse.link);
        setVersion(String(documentResponse.version));
        setDocumentType(String(documentResponse.document_types_id));
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    async function fetchTypes() {
      const types: DocumentType[] = await axiosData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/documents/types`,
        headers: {
          accept: "*/*",
        },
      });
      setOptions(types);
    }
    if (documentIdToEdit) {
      fetchData();
    } else {
      fetchTypes();
    }
    if (window.innerWidth < 768) {
      setHeight(34);
    }
    window.addEventListener("resize", (e: any) => {
      if (e.target.window.innerWidth < 768) {
        setHeight(34);
      } else {
        setHeight(55);
      }
    });
    return () => {
      window.removeEventListener("resize", (e: any) => {
        if (e.target.window.innerWidth < 768) {
          setHeight(34);
        } else {
          setHeight(55);
        }
      });
    };
  }, []); // eslint-disable-line

  async function handleSubmit() {
    const errors: any = {};
    if (!document) {
      setErrors((prev: any) => ({
        ...prev,
        document: "El nombre del documento es requerido.",
      }));
      errors.document = "El nombre del documento es requerido.";
    }
    if (!documentType) {
      setErrors((prev: any) => ({
        ...prev,
        documentType: "El tipo de documento es requerido.",
      }));
      errors.documentType = "El tipo de documento es requerido.";
    }else if (documentType === "null") {
      setErrors((prev: any) => ({
        ...prev,
        documentType: "El tipo de documento es requerido.",
      }));
      errors.documentType = "El tipo de documento es requerido.";
    }
    if (!link) {
      setErrors((prev: any) => ({
        ...prev,
        link: "El link es requerido.",
      }));
      errors.link = "El link es requerido.";
    }
    if (!version) {
      setErrors((prev: any) => ({
        ...prev,
        version: "La versión es requerida.",
      }));
      errors.version = "La versión es requerida.";
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const data = {
        name: document,
        "document_types_id": documentType,
        version,
        link,
      };
      if (documentIdToEdit) {
        try {
          const documentResponse = await axiosData({
            method: "PUT",
            url: `${process.env.REACT_APP_API_URL}/documents/${documentIdToEdit}`,
            headers: {
              accept: "*/*",
            },
            data,
          });
          if (documentResponse) {
            setLoading(false);
            setNotification({
              theme: "success",
              titleNotification: "Edición exitosa",
              contentNotification: "El documento se editó correctamente.",
              isVisible: true,
            });
            setRefreshDocuments(true);
            setDocumentIdToEdit(null);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      } else {
        try {
          const documentResponse = await axiosData({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/documents`,
            headers: {
              accept: "*/*",
            },
            data,
          });
          if (documentResponse) {
            setLoading(false);
            setNotification({  
              theme: "success",
              titleNotification: "Documento creado",
              contentNotification: "El documento se creó correctamente.",
              isVisible: true,
            });
            setRefreshDocuments(true);
            setCreateNewDocument(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    } else {
      if (documentIdToEdit) {
        setNotification({
          theme: "error",
          titleNotification: "Edición fallida",
          contentNotification: errors[Object.keys(errors)[0]],
          isVisible: true,
        });
      } else {
        setNotification({
          theme: "error",
          titleNotification: "Creación fallida",
          contentNotification: errors[Object.keys(errors)[0]],
          isVisible: true,
        });
      }
    }
    setTimeout(() => {
      setErrors({});
    }, 3000);
  }


  return {
    loading,
    document,
    setDocument,
    link,
    setLink,
    version,
    setVersion,
    showDropDownType,
    setShowDropDownType,
    documentType,
    setDocumentType,
    handleSubmit,
    options,
    height,
    errors
  };
}
