import styled from "styled-components";
export default function NumericInput({
  label,
  value,
  setValue,
  disabled,
}: {
  label: string;
  value: number | string;
  setValue: (value: string) => void;
  disabled?: boolean;
}) {
  function isNumeric(value: string) {
    return /^-?\d+$/.test(value);
  }
  function isFloat(value: string) {
    return /^-?\d*(\.\d+)?$/.test(value);
  }
  function finishWithDot(value: string) {
    return /^-?\d*(\.)$/.test(value);
  }
  function onChange({ target: { value } }: { target: { value: string } }) { 
    if (isNumeric(value) || isFloat(value)) {
      setValue(value);
    }else  if (finishWithDot(value)) {
      setValue(value);
    }
  }

  return (
    <FieldContainer>
      <Input
        required
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
      {disabled ? (
        <LabelDisabled>{label}</LabelDisabled>
      ) : (
        <Label>{label}</Label>
      )}
    </FieldContainer>
  );
}

const FieldContainer = styled.div`
  position: relative;
  width: 280px;
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #424242;
  height: 34px;
  border-radius: 5px;
  outline: none;
  padding: 4px 8px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const Label = styled.label`
  font-size: 13px;
  position: absolute;
  pointer-events: none;
  top: 8px;
  left: 8px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 3px;
    left: 8px;
    font-weight: 500;
    font-size: 8px;
    line-height: 8px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 3px;
    left: 8px;
    font-weight: 500;
    font-size: 8px;
    line-height: 8px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 14px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
const LabelDisabled = styled.label`
  font-size: 13px;
  position: absolute;
  pointer-events: none;
  top: 8px;
  left: 8px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  top: 3px;
  left: 8px;
  font-weight: 500;
  font-size: 8px;
  line-height: 8px;
  color: #424242;
`;
