import { useContext, useEffect, useState } from "react";
import useAxios from "../../commonHooks/useAxios";
import { userContext } from "../../context/userContext";
import { Category } from "../../agents/Interfaces";
import { Document } from "../Interfaces";
import { useNavigate, useParams } from "react-router-dom";
import { BindingDocument } from '../Interfaces';

export default function useStatusAllBindings() {
  const [agentName, setAgentName] = useState<string>("");
  const [category, setCategory] = useState<number>(999);
  const [eecc, setEecc] = useState<string>("");
  const [province, setProvince] = useState<string>("")
  const [documentSearch, setDocumentSearch] = useState<string>("");
  const [instalator, setInstalator] = useState<number>(999)
  const [initialBindingDocuments, setInitialBindingDocuments] = useState<BindingDocument[]>([]);
  const [bindingDocumentsFiltered, setBindingDocumentsFiltered] = useState<BindingDocument[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [agentsPerPage, setAgentsPerPage] = useState<number>(10);
  const [fieldSorted, setFieldSorted] = useState("agent_name");
  const [orderSorted, setOrderSorted] = useState("asc");
  const [document, setDocument] = useState<Document>({} as Document)
  const {
    userState: { user },
  } = useContext(userContext);
  const [optionsType, setOptionsType] = useState<Category[]>([]);
  const { id } = useParams<{ id: string }>();
  const getData = useAxios();
  const navigate = useNavigate();
  const [bindingDocuments, setBindingDocuments] = useState<BindingDocument[]>([])

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response: BindingDocument[] = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/bindingdocuments/`,
        headers: {
          accept: "*/*",
        },
      });
      const categoriesResponse: Category[] = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/categories`,
        headers: {
          accept: "*/*",
        },
      });
      const documentResponse: Document = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/documents`,
        headers: {
          accept: "*/*",
        },
      });
      setDocument(documentResponse);
      setOptionsType(categoriesResponse);
      const newBindingDocuments = response;
      const bindingDocumentsSroted = sortItems(newBindingDocuments, "agent_name", "asc");
      setBindingDocuments(bindingDocumentsSroted);
      setInitialBindingDocuments(bindingDocumentsSroted);
      setBindingDocumentsFiltered(bindingDocumentsSroted);
      setTotalPages(Math.ceil(bindingDocumentsSroted.length / agentsPerPage));
      handlePage(1, bindingDocumentsSroted);
      setLoading(false);
    }
    fetchData();
  }, []); // eslint-disable-line

  useEffect(() => {
    const documentsArray = sortItems(bindingDocumentsFiltered, fieldSorted, orderSorted);
    handlePage(1, documentsArray);
  }, [fieldSorted, orderSorted]); // eslint-disable-line

  const handleSort = (field: string) => {
    let order = "asc";
    if (fieldSorted === field) {
      order = orderSorted === "asc" ? "desc" : "asc";
      setOrderSorted(order);
    } else {
      setFieldSorted(field);
      setOrderSorted(order);
    }
  };

  function sortItems(
    array: BindingDocument[] = bindingDocumentsFiltered,
    field: string = fieldSorted,
    order: string = orderSorted
  ) {
    if (field === "EECC") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          const aEECC = a?.agent?.EECC?.first_name ? a?.agent?.EECC.first_name : "";
          const bEECC = b?.agent?.EECC?.first_name ? b?.agent?.EECC.first_name : "";
          if (aEECC.toLowerCase() > bEECC.toLowerCase()) return 1;
          if (aEECC.toLowerCase() < bEECC.toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          const aEECC = a?.agent?.EECC?.first_name ? a?.agent?.EECC.first_name : "";
          const bEECC = b?.agent?.EECC?.first_name ? b?.agent?.EECC.first_name : "";
          if (aEECC.toLowerCase() < bEECC.toLowerCase()) return 1;
          if (aEECC.toLowerCase() > bEECC.toLowerCase()) return -1;
          return 0;
        });
      }
    } else if (field === "document") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          const aEECC = a?.document?.name ? a?.document?.name: "";
          const bEECC = b?.document?.name ? b?.document?.name: "";
          if (aEECC.toLowerCase() > bEECC.toLowerCase()) return 1;
          if (aEECC.toLowerCase() < bEECC.toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          const aEECC = a?.document?.name ? a?.document?.name: "";
          const bEECC = b?.document?.name ? b?.document?.name: "";
          if (aEECC.toLowerCase() < bEECC.toLowerCase()) return 1;
          if (aEECC.toLowerCase() > bEECC.toLowerCase()) return -1;
          return 0;
        });
      }
    } else if (field === "category") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          const aCategory = a?.agent?.category?.name ? a?.agent?.category.name : "";
          const bCategory = b?.agent?.category?.name ? b?.agent?.category.name : "";
          if (aCategory.toLowerCase() > bCategory.toLowerCase()) return 1;
          if (aCategory.toLowerCase() < bCategory.toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          const aCategory = a?.agent?.category?.name ? a?.agent?.category.name : "";
          const bCategory = b?.agent?.category?.name ? b?.agent?.category.name : "";
          if (aCategory.toLowerCase() < bCategory.toLowerCase()) return 1;
          if (aCategory.toLowerCase() > bCategory.toLowerCase()) return -1;
          return 0;
        });
      }
    } else if (field === "instalator") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a?.agent?.instalator > b?.agent?.instalator) return 1;
          if (a?.agent?.instalator < b?.agent?.instalator) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a?.agent?.instalator < b?.agent?.instalator) return 1;
          if (a?.agent?.instalator > b?.agent?.instalator) return -1;
          return 0;
        });
      }
    } else if (field === "approved") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field] > b[field]) return 1;
          if (a[field] < b[field]) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field] < b[field]) return 1;
          if (a[field] > b[field]) return -1;
          return 0;
        });
      }
    } else {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a?.agent?.[field].toLowerCase() > b?.agent?.[field].toLowerCase()) return 1;
          if (a?.agent?.[field].toLowerCase() < b?.agent?.[field].toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a?.agent?.[field].toLowerCase() < b?.agent?.[field].toLowerCase()) return 1;
          if (a?.agent?.[field].toLowerCase() > b?.agent?.[field].toLowerCase()) return -1;
          return 0;
        });
      }
    }

    return array;
  }

  function handleDocumentsPerPage(
    documentsPerPage: number,
    bindingDocuments: BindingDocument[] = bindingDocumentsFiltered
  ) {
    setTotalPages(Math.ceil(bindingDocuments.length / documentsPerPage));
    handlePage(1, bindingDocuments, documentsPerPage);
  }

  function handlePage(
    page: number,
    bindingDocuments = bindingDocumentsFiltered,
    totalDocumentsPerPage = agentsPerPage
  ) {
    setAgentsPerPage(totalDocumentsPerPage);
    setPage(page);
    const bindingDocumentsSorted = sortItems(bindingDocuments);
    const indexOfLastBindingDocument = page * totalDocumentsPerPage;
    const indexOfFirstBindingDocument = indexOfLastBindingDocument - totalDocumentsPerPage;
    const currentBindingDocuments = bindingDocumentsSorted.slice(indexOfFirstBindingDocument, indexOfLastBindingDocument);
    setBindingDocuments(currentBindingDocuments);
  }

  function handleFilter() {
    const agentNameFiltered = initialBindingDocuments.filter((bindingDocument: BindingDocument) => {
      if (bindingDocument.agent?.agent_name) {
        return bindingDocument?.agent?.agent_name
          .toLowerCase()
          .includes(agentName.toLowerCase());
      }
      return true;
    });
    const provinceFilter = agentNameFiltered.filter((bindingDocument: BindingDocument) => {
      if (bindingDocument?.agent?.province) {
        return bindingDocument?.agent?.province.toString()
          .toLowerCase()
          .includes(province.toLowerCase());
      }
      return true;
    });
    const eeccFilter = provinceFilter.filter((bindingDocument: BindingDocument) => {
      if (bindingDocument?.agent?.EECC) {
        const nameEECC = bindingDocument?.agent?.EECC.first_name + " " + bindingDocument?.agent?.EECC.last_name;
        return nameEECC.toLowerCase().includes(eecc.toLowerCase());
      } else if (!eecc) {
        return true;
      }
      else {
        return false;
      }
    });
    const typeFilter = eeccFilter.filter((bindingDocument: BindingDocument) => {
      if (category === 999) {
        return true;
      } else if (category === bindingDocument?.agent?.categories_id) {
        return true;
      } else {
        return false;
      }
    });
    const documentFilter = typeFilter.filter((bindingDocument: BindingDocument) => {
      if (bindingDocument?.document?.name) {
        return bindingDocument?.document?.name.toLowerCase().includes(documentSearch.toLowerCase());
      } else if (!documentSearch) {
        return true;
      }
      else {
        return false;
      }
    });
    handleDocumentsPerPage(agentsPerPage, documentFilter);
    setBindingDocumentsFiltered(documentFilter);
  }

  function handleEnter(e: any) {
    if (e.key === "Enter") {
      handleFilter();
    }
  }

  return {
    agentName,
    setAgentName,
    category,
    setCategory,
    eecc,
    setEecc,
    province,
    setProvince,
    instalator,
    setInstalator,
    loading,
    handleFilter,
    agentsPerPage,
    handleDocumentsPerPage,
    page,
    handlePage,
    totalPages,
    handleEnter,
    user,
    handleSort,
    orderSorted,
    fieldSorted,
    optionsType,
    document,
    navigate,
    bindingDocuments,
    documentSearch, 
    setDocumentSearch
  };
}
