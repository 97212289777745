import useAxios from "../../commonHooks/useAxios";
import { useEffect, useState, useContext } from "react";
import { User } from "../Interfaces";
import { useNavigate } from "react-router-dom";
import { userContext } from "../../context/userContext";

export default function useAdminUsers() {
  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [active, setActive] = useState<number>(1);
  const [initialUsers, setInitialUsers] = useState<User[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [usersFiltered, setUsersFiltered] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [usersPerPage, setUsersPerPage] = useState<number>(10);
  const [userIdToEdit, setUserIdToEdit] = useState<string | null>(null);
  const [refreshUsers, setRefreshUsers] = useState<boolean>(true);
  const [userIdToImpersonate, setuserIdToImpersonate] = useState<string>("");
  const [userEmailToImpersonate, setUserEmailToImpersonate] =
    useState<string>("");
  const navigation = useNavigate();
  const getData = useAxios();
  const { userState, setUserState } = useContext(userContext);
  const [fieldSorted, setFieldSorted] = useState("email");
  const [orderSorted, setOrderSorted] = useState("asc");

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response: User[] = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/users`,
        headers: {
          accept: "*/*",
        },
      });
      const responseUser = response;
      const usersSorted = sortItems(responseUser, "email", "asc");
      setInitialUsers(usersSorted);
      setUsers(usersSorted);
      setUsersFiltered(usersSorted);
      setTotalPages(Math.ceil(usersSorted.length / usersPerPage));
      handlePage(1, usersSorted);
      setLoading(false);
      setRefreshUsers(false);
    }
    if (users.length === 0 || refreshUsers) {
      fetchData();
    }
  }, [refreshUsers]); // eslint-disable-line

  useEffect(() => {
    const newUsersArray = sortItems(usersFiltered, fieldSorted, orderSorted);
    handlePage(1, newUsersArray);
  }, [fieldSorted, orderSorted]); // eslint-disable-line

  const handleSort = (field: string) => {
    let order = "asc";
    if (fieldSorted === field) {
      order = orderSorted === "asc" ? "desc" : "asc";
      setOrderSorted(order);
    } else {
      setFieldSorted(field);
      setOrderSorted(order);
    }
  };

  function sortItems(
    array: any = usersFiltered,
    field: string = fieldSorted,
    order: string = orderSorted
  ) {
    if (field === "role") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field].name.toLowerCase() > b[field].name.toLowerCase())
            return 1;
          if (a[field].name.toLowerCase() < b[field].name.toLowerCase())
            return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field].name.toLowerCase() < b[field].name.toLowerCase())
            return 1;
          if (a[field].name.toLowerCase() > b[field].name.toLowerCase())
            return -1;
          return 0;
        });
      }
    } else {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() < b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() > b[field].toLowerCase()) return -1;
          return 0;
        });
      }
    }

    return array;
  }

  function handlePage(
    page: number,
    users = usersFiltered,
    totalUsersPerPage = usersPerPage
  ) {
    setUsersPerPage(totalUsersPerPage);
    setPage(page);
    const usersSorted = sortItems(users);
    const indexOfLastUser = page * totalUsersPerPage;
    const indexOfFirstUser = indexOfLastUser - totalUsersPerPage;
    const currentUsers = usersSorted.slice(indexOfFirstUser, indexOfLastUser);
    setUsers(currentUsers);
  }

  function handleUserPerPage(
    userPerPage: number,
    users: User[] = usersFiltered
  ) {
    setTotalPages(Math.ceil(users.length / userPerPage));
    handlePage(1, users, userPerPage);
  }

  function handleFilter() {
    const emailFilter = initialUsers.filter((user: User) => {
      return user.email.toLowerCase().includes(email.toLowerCase());
    });
    const nameFilter = emailFilter.filter((user: User) => {
      return user.first_name.toLowerCase().includes(name.toLowerCase());
    });
    const lastNameFilter = nameFilter.filter((user: User) => {
      return user.last_name.toLowerCase().includes(lastName.toLowerCase());
    });
    const roleFilter = lastNameFilter.filter((user: User) => {
      return user.role.name.toLowerCase().includes(role.toLowerCase());
    });
    const activeFilter = roleFilter.filter((user: User) => {
      if (active === 1) {
        return user.active === true;
      } else {
        return user.active === false;
      }
    });
    handleUserPerPage(usersPerPage, activeFilter);
    setUsersFiltered(activeFilter);
  }

  async function impersonate(userIdToImpersonate: string) {
    setLoading(true);
    try {
      const userToImpersonate: User = await getData({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/impersonate`,
        headers: {
          accept: "*/*",
        },
        data: {
          userId: userIdToImpersonate,
        },
      });
      setUserState(
        (prevState: {
          user: User | undefined;
          originalUser: User | undefined;
        }) => ({
          user: {
            ...userToImpersonate,
          },
          originalUser: prevState.user,
        })
      );
      setLoading(false);
      setuserIdToImpersonate("");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  function handleReset() {
    setEmail("");
    setName("");
    setLastName("");
    setRole("");
    setActive(1);
    handleUserPerPage(usersPerPage, initialUsers);
    setUsersFiltered(initialUsers);
  }

  function handleEnter(e: any) {
    if (e.key === "Enter") {
      handleFilter();
    }
  }

  return {
    email,
    setEmail,
    name,
    setName,
    lastName,
    setLastName,
    role,
    setRole,
    active,
    setActive,
    users,
    handleFilter,
    handleReset,
    loading,
    usersPerPage,
    page,
    totalPages,
    handleUserPerPage,
    handlePage,
    navigation,
    setUserIdToEdit,
    userIdToEdit,
    setRefreshUsers,
    userState,
    impersonate,
    userIdToImpersonate,
    setuserIdToImpersonate,
    userEmailToImpersonate,
    setUserEmailToImpersonate,
    handleEnter,
    handleSort,
    orderSorted,
    fieldSorted,
  };
}
