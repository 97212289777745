import useAxios from "../../commonHooks/useAxios";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { notificationsContext } from "../../context/notificationsContext";
import { Advisor } from "../Interfaces";
import { User } from "../../configs/Interfaces";

export default function useAdvisors() {
  const [advisors, setAdvisors] = useState<Advisor[]>([]);
  const [initialAdvisors, setInitialAdvisors] = useState<Advisor[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [advisorsPerPage, setAdvisorsPerPage] = useState<number>(5);
  const [advisorIdToEdit, setAdvisorIdToEdit] = useState<string | null>(null);
  const [refreshAdvisors, setRefreshAdvisors] = useState<boolean>(true);
  const [createNewAdvisor, setCreateNewAdvisor] = useState<boolean>(false);
  const [advisorToDelete, setAdvisorToDelete] = useState<User | null>(null);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [fieldSorted, setFieldSorted] = useState("advisors_id");
  const [orderSorted, setOrderSorted] = useState("asc");
  const navigate = useNavigate();
  const getData = useAxios();
  const { setNotification } = useContext(notificationsContext);

  useEffect(() => {
    async function fetchData() {
      const response: Advisor[] = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/advisors`,
        headers: {
          accept: "*/*",
        },
      });
      const responseAdvisors = response;
      const advisorsSorted = sortItems(responseAdvisors, "advisors_id", "asc");
      setAdvisors(advisorsSorted);
      setInitialAdvisors(advisorsSorted);
      setTotalPages(Math.ceil(advisorsSorted.length / advisorsPerPage));
      handlePage(1, advisorsSorted);
      setLoading(false);
      setRefreshAdvisors(false);
    }
    if (advisors.length === 0 || refreshAdvisors) {
      fetchData();
    }
  }, [refreshAdvisors]); // eslint-disable-line

  useEffect(() => {
    const newAdvisorsArray = sortItems(advisors, fieldSorted, orderSorted);
    handlePage(1, newAdvisorsArray);
  }, [fieldSorted, orderSorted]); // eslint-disable-line

  const handleSort = (field: string) => {
    let order = "asc";
    if (fieldSorted === field) {
      order = orderSorted === "asc" ? "desc" : "asc";
      setOrderSorted(order);
    } else {
      setFieldSorted(field);
      setOrderSorted(order);
    }
  };

  function sortItems(
    array: any = advisors,
    field: string = fieldSorted,
    order: string = orderSorted
  ) {
    if (field === "advisors_id") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field] > b[field]) return 1;
          if (a[field] < b[field]) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field] < b[field]) return 1;
          if (a[field] > b[field]) return -1;
          return 0;
        });
      }
    } else {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() < b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() > b[field].toLowerCase()) return -1;
          return 0;
        });
      }
    }

    return array;
  }

  function handleAdvisorsPerPage(advisorsPerPage: number) {
    setTotalPages(Math.ceil(initialAdvisors.length / advisorsPerPage));
    handlePage(1, initialAdvisors, advisorsPerPage);
  }

  function handlePage(
    page: number,
    advisors = initialAdvisors,
    totalAdvisorsPerPage = advisorsPerPage
  ) {
    setAdvisorsPerPage(totalAdvisorsPerPage);
    setPage(page);
    const indexOfLastAdvisor = page * totalAdvisorsPerPage;
    const indexOfFirstAdvisor = indexOfLastAdvisor - totalAdvisorsPerPage;
    const currentAdvisors = advisors.slice(indexOfFirstAdvisor, indexOfLastAdvisor);
    setAdvisors(currentAdvisors);
  }

  function setAdvisorIdToDelete(advisor: User | null) {
    setAdvisorToDelete(advisor);
  }

  async function editAdvisor(users_id: string, roles_id: number) {
    setLoading(true);
    try {
      const response: { message: string } = await getData({
        method: "PUT",
        url: `${process.env.REACT_APP_API_URL}/advisors/${users_id}`,
        headers: {
          accept: "*/*",
        },
        data: {
          activated: roles_id === 6 ? false : true,
        },
      });
      if (response.message === "Advisor edited") {
        setNotification({
          theme: "success",
          titleNotification: "Asesor modificado",
          contentNotification: "El asesor comercial se editó correctamente.",
          isVisible: true,
        });
        setRefreshAdvisors(true);
        setLoading(false);
      } else {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: "No se pudo editar el asesor comercial.",
          isVisible: true,
        });
      }
      setLoading(false);
    } catch (error) {
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: "No se pudo editar el asesor comercial.",
        isVisible: true,
      });
      setLoading(false);
    }
  }

  async function deleteAdvisor(advisor: User) {
    setLoadingDelete(true);
    try {
      const response: { message: string } = await getData({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/advisors/${advisor.users_id}`,
        headers: {
          accept: "*/*",
        },
      });
      if (response.message === "Advisor deleted") {
        setNotification({
          theme: "success",
          titleNotification: "Asesor Eliminado",
          contentNotification: "El asesor comercial se eliminó correctamente.",
          isVisible: true,
        });
        setRefreshAdvisors(true);
        setAdvisorToDelete(null);
        setLoadingDelete(false);
      } else {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: "No se pudo eliminar el asesor comercial.",
          isVisible: true,
        });
      }
      setAdvisorToDelete(null);
      setLoadingDelete(false);
    } catch (error) {
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: "No se pudo eliminar el asesor comercial.",
        isVisible: true,
      });
      setAdvisorToDelete(null);
      setLoadingDelete(false);
    }
  }

  return {
    advisors,
    loading,
    navigate,
    handleAdvisorsPerPage,
    page,
    totalPages,
    advisorsPerPage,
    handlePage,
    setAdvisorIdToEdit,
    advisorIdToEdit,
    setRefreshAdvisors,
    createNewAdvisor,
    setCreateNewAdvisor,
    setAdvisorIdToDelete,
    advisorToDelete,
    setAdvisorToDelete,
    deleteAdvisor,
    loadingDelete,
    handleSort,
    orderSorted,
    fieldSorted,
    editAdvisor
  };
}
