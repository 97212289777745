import { useContext } from "react";
import { userContext } from "../context/userContext";
import { Navigate, useParams } from "react-router-dom";

export default function OwnAgentRoute({ children }: { children: any }) {
  const {
    userState: { user },
  } = useContext(userContext);
  const { agentId } = useParams();
  if (user) {
    if (user?.role.edit_agents === true) {
      return children;
    } else if (String(user?.agents_id) === agentId) {
      return children;
    } else {
      return <Navigate to="/" />;
    }
  }
}
