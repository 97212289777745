import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../commonComponents/Button";
import Modal from "../../commonComponents/Modal";
import useAxios from "../../commonHooks/useAxios";

export default function BindingLogs({
  userIdToViewLogs,
  setUserIdToViewLogs,
}: {
  userIdToViewLogs: string | null;
  setUserIdToViewLogs: (agentId: string | null) => void;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [logs, setLogs] = useState<any[]>([]);
  const axiosData = useAxios();
  const dtf = new Intl.DateTimeFormat("es-ES", {
    calendar: "gregory",
    numberingSystem: "latn",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await axiosData({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}/logsbinding/${userIdToViewLogs}`,
          headers: {
            accept: "*/*",
          },
        });
        setLogs(response.reverse());
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []); // eslint-disable-line

  return (
    <Modal type="edituser" title={"Detalle de Log"}>
      <Container>
        <TableContainer>
          <Table>
            <TRH>
              <TH>Nombre</TH>
              <TH>Apellido</TH>
              <TH>Email</TH>
              <TH>Fecha Log</TH>
              <TH>Estado Inicial</TH>
              <TH>Estado Final</TH>
              <TH>Usuario Admin</TH>
            </TRH>
            <tbody>
              {logs?.map((log: any) => (
                <TR key={log.logs_id}>
                  <TD>{log?.user?.first_name}</TD>
                  <TD>{log?.user?.last_name}</TD>
                  <TD>{log?.user?.email}</TD>
                  <TD>{dtf.format(new Date(log.created_at))}</TD>
                  <TD style={{ textTransform: "capitalize" }}>
                    {log?.initial_state}
                  </TD>
                  <TD style={{ textTransform: "capitalize" }}>
                    {log?.final_state}
                  </TD>
                  <TD>{log?.admin_email}</TD>
                </TR>
              ))}
            </tbody>
          </Table>
          {loading && (
            <>
              <Loading>
                <CircularProgress
                  sx={{
                    color: "#128297",
                  }}
                  size={100}
                />
              </Loading>
            </>
          )}
        </TableContainer>
        <ButtonContainer>
          <Button type="primary" onClick={() => setUserIdToViewLogs(null)}>
            Cerrar
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
}
const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`;
const Container = styled.div`
  width: 1000px;
  height: 500px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  max-width: 100%;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    padding: 10px;
  }
`;
const TableContainer = styled.div`
  height: 298px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  margin-top: 60px;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  position: relative;
  @media (max-width: 768px) {
    min-width: 880px;
  }
`;
const TRH = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #fff;
`;

const TR = styled.tr`
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
  :hover {
    background-color: #1283971d;
  }
`;
const TD = styled.td`
  padding: 14px 0px 13px 20px;
  font-size: 13px;
  line-height: 12px;
  color: #424242;
  height: 60px;
`;
const TH = styled.th`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  padding: 23px 20px;
  color: #128297;
  text-transform: uppercase;
  text-align: start;
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 298px;
  width: 100%;
`;
