import { useState } from "react";
import styled from "styled-components";
import Modal from "../../commonComponents/Modal";
import Button from "../../commonComponents/Button";
import ObservationsIcon from "../../assets/img/observationsIcon.svg";

export default function Observations({
  label,
  value,
  setValue,
  disabled,
  orderWithObservations,
}: {
  label: string;
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  orderWithObservations?: boolean;
}) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [newObservation, setNewObservation] = useState<string>("");
  return (
    <>
      <FieldContainer
        style={{
          width: "280px",
          justifyContent: "flex-end",
        }}
      >
        <ButtonImage disabled={false} onClick={() => setOpenModal(true)}>
          <MiddleContainer orderWithObservations={orderWithObservations || false}>
            <img src={ObservationsIcon} alt="observations icon" />
          </MiddleContainer>
        </ButtonImage>
      </FieldContainer>
      {openModal && (
        <Modal type="document" title={"Observaciones"}>
          <Container>
            <HTMLContainer>
              <HtmlContainer
                dangerouslySetInnerHTML={{ __html: value }}
              ></HtmlContainer>
            </HTMLContainer>
            {!disabled && (
              <FieldContainer>
                <Input
                  required
                  value={newObservation}
                  onChange={({ target: { value } }) => setNewObservation(value)}
                  disabled={disabled}
                />
                {disabled ? (
                  <LabelDisabled>{"Observación"}</LabelDisabled>
                ) : (
                  <Label>{"Observación"}</Label>
                )}
                <Button
                  type={newObservation ? "primary" : "disabled"}
                  onClick={() => {
                    if (newObservation) {
                      setValue(newObservation);
                      setNewObservation("");
                    }
                  }}
                >
                  Enviar
                </Button>
              </FieldContainer>
            )}
            <ButtonsContainer>
              <Button
                type="secondary"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Cerrar
              </Button>
            </ButtonsContainer>
          </Container>
        </Modal>
      )}
    </>
  );
}

const FieldContainer = styled.div`
  position: relative;
  width: 80%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;
const HTMLContainer = styled.div`
  position: relative;
  width: 80%;
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #424242;
  height: 55px;
  border-radius: 5px;
  outline: none;
  padding: 4px 8px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const Label = styled.label`
  font-size: 13px;
  position: absolute;
  pointer-events: none;
  top: 8px;
  left: 8px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 3px;
    left: 8px;
    font-weight: 500;
    font-size: 8px;
    line-height: 8px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 3px;
    left: 8px;
    font-weight: 500;
    font-size: 8px;
    line-height: 8px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 14px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
const ButtonImage = styled.div<{ disabled: boolean }>`
  width: 34px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--gray-02, #e7e7e7);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;
const MiddleContainer = styled.div<{ orderWithObservations: boolean }>`
  border-radius: 5px;
  /* border: 1px dashed var(--paleta-principal-corporative-primario-01, #128297); */
  background: ${(props) =>
    props.orderWithObservations ? "#ffe26c" : "#E7E7E7"};
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  width: 60vw;
  height: 510px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 73px 40px 50px 40px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background: #128297;
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #0e616f;
  }
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    padding: 20px 10px 50px 10px;
    height: auto;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  margin-top: 10px;
`;
const LabelDisabled = styled.label`
  font-size: 13px;
  position: absolute;
  pointer-events: none;
  top: 8px;
  left: 8px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  top: 3px;
  left: 8px;
  font-weight: 500;
  font-size: 8px;
  line-height: 8px;
  color: #424242;
`;
const HtmlContainer = styled.div`
  width: 100%;
  height: 40vh;
  border: 1px solid #424242;
  border-radius: 5px;
  padding: 4px 8px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  padding: 16px;
  margin-bottom: 32px;
  overflow-y: scroll;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: #e7e7e7;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #128297;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #128297;
  }
  strong {
    font-size: 15px;
    font-weight: 700;
    font-family: fangsong;
    padding-right: 8px;
  }
  p {
    padding-bottom: 8px;
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
