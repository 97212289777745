import styled from "styled-components";
import CreateAgentIcon from "../../assets/img/createAgentIcon.svg";
import CheckIcon from "../../assets/img/checkPermissionIcon.svg";
import ArrowDropDownIcon from "../../assets/img/arrowDropDownIcon.svg";
import Button from "../../commonComponents/Button";
import { useContext } from "react";
import { agentFormContext } from "../../context/agentFormContext";
import useTaxDataForm from "../hooks/useTaxDataForm";

const SCOPES = ["Solar FV", "Solar Térmica", "Solar FV y Térmica"];

export default function TaxDataForm({
  submitForm,
}: {
  submitForm: (errors: {}) => void;
}) {
  const {
    agentName,
    setAgentName,
    sameAgentName,
    setSameAgentName,
    tradeName,
    setTradeName,
    showDropDownRole,
    setshowDropDownRole,
    showDropDownCategory,
    setshowDropDownCategory,
    documentType,
    setDocumentType,
    documentTypes,
    documentNumber,
    setDocumentNumber,
    taxStreet,
    setTaxStreet,
    taxCity,
    setTaxCity,
    taxProvince,
    setTaxProvince,
    contactName,
    setContactName,
    contactPhone,
    setContactPhone,
    contactPhoneOptional,
    setContactPhoneOptional,
    contactEmail,
    setContactEmail,
    errors,
    agents_id,
    category,
    setCategory,
    categories,
    instalator,
    setInstalator,
    logo,
    previewImage,
    website,
    setWebsite,
    instagram,
    setInstagram,
    scope,
    setScope,
    has_showroom,
    setHas_showroom,
    showDorpdownScope,
    setShowDorpdownScope,
  } = useContext(agentFormContext);
  const {
    handleNextPage,
    isComplete,
    height,
    agentId,
    handleCancel,
    handleSubmit,
    changeLogo,
  } = useTaxDataForm({ submitForm });
  return (
    <>
      <MainContainer>
        <TopContainer>
          <IconContainer>
            <img src={CreateAgentIcon} alt="Create Agent Icon" />
          </IconContainer>
        </TopContainer>
        <ColumnsContainer>
          <Column>
            <FieldsContainer>
              <TitleSection>Datos Administrativos y Fiscales</TitleSection>
              <FieldContainer>
                <DisabledInput required value={agents_id} />
                <LabelDisabled>ID Distribuidor</LabelDisabled>
              </FieldContainer>
              <FieldContainer>
                <Input
                  color={errors.agentName ? "#FF3D00" : undefined}
                  required
                  value={agentName}
                  onChange={({ target: { value } }) => setAgentName(value)}
                />
                <Label color={errors.agentName ? "#FF3D00" : undefined}>
                  Razón Social *
                </Label>
              </FieldContainer>
              <CheckboxContainer>
                <CheckboxText>
                  Usar Razón Social como Nombre Comercial
                </CheckboxText>
                <Checkbox onClick={() => setSameAgentName(!sameAgentName)}>
                  {sameAgentName && <img src={CheckIcon} alt="check icon" />}
                </Checkbox>
              </CheckboxContainer>
              {!sameAgentName && (
                <FieldContainer>
                  <Input
                    color={errors.tradeName ? "#FF3D00" : undefined}
                    required
                    value={tradeName}
                    onChange={({ target: { value } }) => setTradeName(value)}
                  />
                  <Label color={errors.tradeName ? "#FF3D00" : undefined}>
                    Nombre Comercial *
                  </Label>
                </FieldContainer>
              )}
              <FieldContainer>
                <DropDownField
                  onClick={() => setshowDropDownRole(!showDropDownRole)}
                  style={{
                    height: showDropDownRole
                      ? `${documentTypes.length * height + height + 18}px`
                      : `${height}px`,
                  }}
                >
                  {!documentType && (
                    <CurrentValueDropDownFirst>
                      Tipo de Documento *
                    </CurrentValueDropDownFirst>
                  )}
                  {documentTypes.map(
                    (d: any, index: number) =>
                      documentType === d && (
                        <>
                          <DropDownLabel key={index}>
                            Tipo de Documento *
                          </DropDownLabel>
                          <CurrentValueDropDown key={index + 10}>
                            {d}
                          </CurrentValueDropDown>
                        </>
                      )
                  )}
                  <DropDownItems
                    style={{
                      overflowY:
                        showDropDownRole && documentTypes.length > 7
                          ? "scroll"
                          : "hidden",
                    }}
                  >
                    {documentTypes.map((d: any, index: number) => (
                      <Option key={index} onClick={() => setDocumentType(d)}>
                        {d}
                      </Option>
                    ))}
                  </DropDownItems>

                  <IconConatiner
                    style={{
                      transform: showDropDownRole
                        ? "rotateZ(-180deg)"
                        : "rotateZ(0deg)",
                    }}
                  >
                    <img src={ArrowDropDownIcon} alt="arrow drop down icon" />
                  </IconConatiner>
                </DropDownField>
              </FieldContainer>
              <FieldContainer>
                <Input
                  color={errors.documentNumber ? "#FF3D00" : undefined}
                  required
                  value={documentNumber}
                  onChange={({ target: { value } }) => setDocumentNumber(value)}
                />
                <Label color={errors.documentNumber ? "#FF3D00" : undefined}>
                  Número de Documento *
                </Label>
              </FieldContainer>
              <TitleSection
                style={{ marginTop: height === 55 ? "20px" : "10px" }}
              >
                Domicilio Fiscal
              </TitleSection>
              <FieldContainer>
                <Input
                  color={errors.taxStreet ? "#FF3D00" : undefined}
                  required
                  value={taxStreet}
                  onChange={({ target: { value } }) => setTaxStreet(value)}
                />
                <Label color={errors.taxStreet ? "#FF3D00" : undefined}>
                  Dirección *
                </Label>
              </FieldContainer>
              <FieldContainer>
                <Input
                  color={errors.taxCity ? "#FF3D00" : undefined}
                  required
                  value={taxCity}
                  onChange={({ target: { value } }) => setTaxCity(value)}
                />
                <Label color={errors.taxCity ? "#FF3D00" : undefined}>
                  Localidad *
                </Label>
              </FieldContainer>
              <FieldContainer>
                <Input
                  color={errors.taxProvince ? "#FF3D00" : undefined}
                  required
                  value={taxProvince}
                  onChange={({ target: { value } }) => setTaxProvince(value)}
                />
                <Label color={errors.taxProvince ? "#FF3D00" : undefined}>
                  Provincia *
                </Label>
              </FieldContainer>
            </FieldsContainer>
          </Column>
          <Column >
            <FieldsContainer>
              <TitleSection>Contacto Principal</TitleSection>
              <FieldContainer>
                <Input
                  color={errors.contactName ? "#FF3D00" : undefined}
                  required
                  value={contactName}
                  onChange={({ target: { value } }) => setContactName(value)}
                />
                <Label color={errors.contactName ? "#FF3D00" : undefined}>
                  Nombre y Apellido *
                </Label>
              </FieldContainer>
              <FieldContainer>
                <Input
                  color={errors.contactPhone ? "#FF3D00" : undefined}
                  required
                  value={contactPhone}
                  onChange={({ target: { value } }) => setContactPhone(value)}
                />
                <Label color={errors.contactPhone ? "#FF3D00" : undefined}>
                  Teléfono Principal *
                </Label>
              </FieldContainer>
              <FieldContainer>
                <Input
                  required
                  value={contactPhoneOptional}
                  onChange={({ target: { value } }) =>
                    setContactPhoneOptional(value)
                  }
                />
                <Label>Teléfono Alternativo</Label>
              </FieldContainer>
              <FieldContainer>
                <Input
                  color={errors.contactEmail ? "#FF3D00" : undefined}
                  required
                  value={contactEmail}
                  onChange={({ target: { value } }) => setContactEmail(value)}
                />
                <Label color={errors.contactEmail ? "#FF3D00" : undefined}>
                  Email *
                </Label>
              </FieldContainer>
              <FieldContainer>
                <DropDownField
                  onClick={() => setshowDropDownCategory(!showDropDownCategory)}
                  style={{
                    height: showDropDownCategory
                      ? `${categories.length * height + height + 18}px`
                      : `${height}px`,
                    zIndex: 6,
                  }}
                >
                  {!category && (
                    <CurrentValueDropDownFirst>
                      Tipo de Distribuidor *
                    </CurrentValueDropDownFirst>
                  )}
                  {categories.map(
                    (cat: any, index: number) =>
                      category === cat.categories_id && (
                        <>
                          <DropDownLabel key={index}>
                            Tipo de Distribuidor *
                          </DropDownLabel>
                          <CurrentValueDropDown key={index + 10}>
                            {cat.name}
                          </CurrentValueDropDown>
                        </>
                      )
                  )}
                  <DropDownItems
                    style={{
                      overflowY:
                        showDropDownCategory && categories.length > 7
                          ? "scroll"
                          : "hidden",
                    }}
                  >
                    {categories.map((d: any, index: number) => (
                      <Option
                        key={index}
                        onClick={() => setCategory(d.categories_id)}
                      >
                        {d.name}
                      </Option>
                    ))}
                  </DropDownItems>

                  <IconConatiner
                    style={{
                      transform: showDropDownCategory
                        ? "rotateZ(-180deg)"
                        : "rotateZ(0deg)",
                    }}
                  >
                    <img src={ArrowDropDownIcon} alt="arrow drop down icon" />
                  </IconConatiner>
                </DropDownField>
              </FieldContainer>
              <CheckboxContainer>
                <CheckboxText>Instalador VIP</CheckboxText>
                <Checkbox onClick={() => setInstalator(!instalator)}>
                  {instalator && <img src={CheckIcon} alt="check icon" />}
                </Checkbox>
              </CheckboxContainer>
            </FieldsContainer>
            <LogoContainer>
              {!logo ? (
                <>
                  <LabelLogo htmlFor="files">Subir Logo</LabelLogo>
                  <InputLogo
                    id="files"
                    type="file"
                    accept=".jpeg,.jpg,.png"
                    onChange={changeLogo}
                  />
                  {previewImage && <Logo src={previewImage} alt="logo" />}
                </>
              ) : (
                <>
                  <LabelLogo htmlFor="files">Cambiar Logo</LabelLogo>
                  <InputLogo
                    id="files"
                    type="file"
                    accept=".jpeg,.jpg,.png"
                    onChange={changeLogo}
                  />
                  <Logo
                    src={
                      previewImage ||
                      `${process.env.REACT_APP_API_URL}/getLogo/${agents_id}`
                    }
                    alt="logo"
                  />
                </>
              )}
            </LogoContainer>
            <FieldsContainer>
              <FieldContainer>
                <DropDownField
                  onClick={() => setShowDorpdownScope(!showDorpdownScope)}
                  style={{
                    height: showDorpdownScope
                      ? `${SCOPES.length * height + height + 18}px`
                      : `${height}px`,
                    zIndex: 6,
                  }}
                >
                  {!scope && (
                    <CurrentValueDropDownFirst>
                      Alcance
                    </CurrentValueDropDownFirst>
                  )}
                  {SCOPES.map(
                    (sco: any, index: number) =>
                      scope === sco && (
                        <>
                          <DropDownLabel key={index}>Alcance</DropDownLabel>
                          <CurrentValueDropDown key={index + 10}>
                            {sco}
                          </CurrentValueDropDown>
                        </>
                      )
                  )}
                  <DropDownItems
                    style={{
                      overflowY:
                        showDorpdownScope && categories.length > 7
                          ? "scroll"
                          : "hidden",
                    }}
                  >
                    {SCOPES.map((sco: any, index: number) => (
                      <Option key={index} onClick={() => setScope(sco)}>
                        {sco}
                      </Option>
                    ))}
                  </DropDownItems>

                  <IconConatiner
                    style={{
                      transform: showDorpdownScope
                        ? "rotateZ(-180deg)"
                        : "rotateZ(0deg)",
                    }}
                  >
                    <img src={ArrowDropDownIcon} alt="arrow drop down icon" />
                  </IconConatiner>
                </DropDownField>
              </FieldContainer>
              <FieldContainer>
                <Input
                  required
                  value={website}
                  onChange={({ target: { value } }) => setWebsite(value)}
                />
                <Label>Página Web</Label>
              </FieldContainer>
              <FieldContainer>
                <Input
                  required
                  value={instagram}
                  onChange={({ target: { value } }) => setInstagram(value)}
                />
                <Label>Instagram @</Label>
              </FieldContainer>
              <CheckboxContainer>
                <CheckboxText>Local con Showroom</CheckboxText>
                <Checkbox onClick={() => setHas_showroom(!has_showroom)}>
                  {has_showroom && <img src={CheckIcon} alt="check icon" />}
                </Checkbox>
              </CheckboxContainer>
            </FieldsContainer>
            {agentId && (
              <EditButtonContainer>
                <Button type={"secondary"} onClick={() => handleCancel()}>
                  CANCELAR
                </Button>
                <Button
                  type={isComplete ? "primary" : "disabled"}
                  onClick={() => handleSubmit()}
                >
                  GUARDAR
                </Button>
              </EditButtonContainer>
            )}
          </Column>
        </ColumnsContainer>
      </MainContainer>
      <ButtonContainer>
        <Button
          type={isComplete ? "primary" : "disabled"}
          onClick={() => handleNextPage()}
        >
          SIGUIENTE
        </Button>
      </ButtonContainer>
    </>
  );
}
const EditButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 40px;
  @media (max-width: 768px) {
    gap: 10px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  padding: 0px 20px;
  @media (max-width: 768px) {
    padding: 0px 10px;
  }
`;
const Option = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #128297;
  cursor: pointer;
  :hover {
    background-color: #f5f5f5;
  }
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 0 0 20px;
  :nth-last-child() {
    margin-bottom: 18px;
  }
  @media (max-width: 768px) {
    min-height: 34px;
  }
`;
const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
  @media (max-width: 768px) {
    gap: 10px;
  }
`;
const DropDownField = styled.div`
  border: 1px solid #424242;
  border-radius: 5px;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
  position: absolute;
  color: #128297;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 20px 18px 20px;
  overflow-y: hidden;
  background-color: #fff;
  z-index: 10;
  transition: all 0.4s;
  max-height: 467px;
  @media (max-width: 768px) {
    padding: 0px 10px 18px 10px;
    width: 100%;
  }
`;
const DropDownItems = styled.div`
  cursor: pointer;
  color: #128297;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: hidden;
  z-index: 6;
  transition: all 0.4s;
  max-height: 403px;
  width: 100%;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;
const IconConatiner = styled.div`
  position: absolute;
  right: 26px;
  top: 19px;
  transform: rotateZ("0deg");
  transition: all 0.3s;
  @media (max-width: 768px) {
    right: 16px;
    top: 8px;
  }
`;
const DropDownLabel = styled.p`
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  color: #424242;
  position: absolute;
  top: 6px;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
    top: 3px;
  }
`;

const CurrentValueDropDownFirst = styled.div`
  font-size: 16px;
  line-height: 14px;
  color: #128297;
  cursor: pointer;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 10px;
  padding-top: 0;
  @media (max-width: 768px) {
    font-family: "D-DIN-PRO";
    min-height: 34px;
    font-size: 13px;
    line-height: 13px;
    padding-top: 0px;
  }
`;

const CurrentValueDropDown = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: #128297;
  cursor: pointer;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 10px;
  @media (max-width: 768px) {
    font-family: "D-DIN-PRO";
    min-height: 34px;
    font-size: 13px;
    line-height: 13px;
    padding-top: 10px;
  }
`;
const Checkbox = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid #424242;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  gap: 9px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const CheckboxText = styled.p`
  padding-left: 20px;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  color: #128297;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
    padding-left: 0px;
  }
`;
const FieldContainer = styled.div`
  position: relative;
  height: 55px;
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
  }
`;
const Input = styled.input`
  width: 400px;
  height: 55px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const Label = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 11.5px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      line-height: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
const TitleSection = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #3c4858;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }
`;
const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;
const Column = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  @media (max-width: 768px) {
    width: 100%;
    gap: 10px;
    &:nth-child(2) {
      margin-top: 20px;
    }
  }
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 20px);
  max-width: 1140px;
  margin-top: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 50px 50px 50px 50px;
  position: relative;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    width: calc(100% - 20px);
    padding: 40px 10px 20px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: -17px;
    left: 10px;
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const DisabledInput = styled.input`
  width: 400px;
  height: 55px;
  background: #f1f1f1;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  user-select: none;
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const LabelDisabled = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 10px;
  left: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  user-select: none;
  @media (max-width: 768px) {
    top: 3px;
    left: 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #424242;
  }
`;
const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
`;

const LabelLogo = styled.label`
  background-color: #128297;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: "D-DIN-PRO-Medium";
  font-size: 15px;
  line-height: 15px;
  padding: 20px 30px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
  right: 0;
  top: 0px;
  :focus {
    outline: none;
  }
  @media (max-width: 768px) {
    padding: 15px 15px;
    font-size: 13px;
    line-height: 13px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: auto;
    top: auto;
  }
`;
const InputLogo = styled.input`
  display: none;
`;
const Logo = styled.img`
  width: 100%;
  max-height: 120px;
  object-fit: contain;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;
