import { useContext, useEffect, useState } from "react";
import { userContext } from "../context/userContext";
import { getAuth, signOut } from "firebase/auth";
import SignIn from "../auth/screens/SignIn";

export default function BindingRequestRoute({ children }: { children: any}) {
  const {
    userState: { user },
    setUserState
  } = useContext(userContext);
  const [isAdmin, setIsAdmin] = useState(false);
  const auth = getAuth();

  useEffect(() => {

    if(user){
        if(user?.role["bind_agents"]===false){
            setIsAdmin(false);
            handleSignOut();
            window.location.reload();
        }else{
            setIsAdmin(true);
        }
    }


  }, [user]) // eslint-disable-line react-hooks/exhaustive-deps
  

  async function handleSignOut() {
    await signOut(auth);
    setUserState({
      user: undefined,
      originalUser: undefined,
    });
    window.localStorage.removeItem("accessToken");
  }

  if (user) {
    if (!isAdmin) {
        return <SignIn />;
    } else {
      return children;
    }
  }
}
