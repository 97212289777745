import { useState, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { notificationsContext } from "../../context/notificationsContext";
import useAxios from "../../commonHooks/useAxios";

export default function useSignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigation = useNavigate();
  const auth = getAuth();
  const { setNotification } = useContext(notificationsContext);
  const path = window.location.pathname;
  const { useremail } = useParams();
  const axiosData = useAxios();

  useEffect(() => {
    async function fetchData() {
      await axiosData({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/welcomeemail/`,
        headers: {
          accept: "*/*",
        },
        data: {
          email: useremail,
        },
      });
    }
    if (useremail) {
      setEmail(useremail);
      fetchData();
    }
  }, [useremail]); // eslint-disable-line react-hooks/exhaustive-deps

  async function signIn() {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
      if (user) {
        if (user.emailVerified) {
          if (path === "/login" || useremail) {
            navigation("/");
          }
        } else {
          setNotification({
            theme: "warning",
            titleNotification: "Login",
            contentNotification: "Por favor verifica tu correo.",
            isVisible: true,
          });
        }
      }
    } catch (error) {
      setNotification({
        theme: "error",
        titleNotification: "Login",
        contentNotification: "Email o contraseña incorrectos.",
        isVisible: true,
      });
    }
  }

  function handleEnter(e: any) {
    if (e.key === "Enter") {
      signIn();
    }
  }

  return {
    email,
    setEmail,
    password,
    setPassword,
    signIn,
    navigation,
    handleEnter,
  };
}
