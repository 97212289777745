import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAxios from "../../commonHooks/useAxios";
import { userContext } from "../../context/userContext";
import { Agent } from "./../Interfaces";

export default function useAgentRequests() {
  const [creatorName, setCreatorName] = useState<string>("");
  const [creatorLastName, setCreatorLastName] = useState<string>("");
  const [creatorEmail, setCreatorEmail] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [initialAgents, setInitialAgents] = useState<Agent[]>([]);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [agentsFiltered, setAgentsFiltered] = useState<Agent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [agentsPerPage, setAgentsPerPage] = useState<number>(10);
  const [refreshagents, setRefreshAgents] = useState<boolean>(true);
  const [agentIdToAuthorize, setAgentIdToAuthorize] = useState<string | null>(
    null
  );
  const [agentIdToViewLogs, setAgentIdToViewLogs] = useState<string | null>(
    null
  );
  const getData = useAxios();
  const [fieldSorted, setFieldSorted] = useState("created_at");
  const [orderSorted, setOrderSorted] = useState("desc");
  const {
    userState: { user },
  } = useContext(userContext);
  const dtf = new Intl.DateTimeFormat("es-ES", {
    calendar: "gregory",
    numberingSystem: "latn",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      if (
        user?.isEECC === true &&
        user?.role.name !== "SuperAdmin" &&
        user?.role.name !== "Admin"
      ) {
        const response: Agent[] = await getData({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/agentsEECC`,
          headers: {
            accept: "*/*",
          },
          data: {
            users_id: user.users_id,
          },
        });
        const newAgents = response;
        const agentsSorted = sortItems(newAgents, "created_at", "desc");
        setAgents(agentsSorted);
        setInitialAgents(agentsSorted);
        setAgentsFiltered(agentsSorted);
        setTotalPages(Math.ceil(agentsSorted.length / agentsPerPage));
        handlePage(1, agentsSorted);
      } else {
        const response: Agent[] = await getData({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}/agents`,
          headers: {
            accept: "*/*",
          },
        });
        const newAgents = response;
        const agentsSorted = sortItems(newAgents, "created_at", "desc");
        setAgents(agentsSorted);
        setInitialAgents(agentsSorted);
        setAgentsFiltered(agentsSorted);
        setTotalPages(Math.ceil(agentsSorted.length / agentsPerPage));
        handlePage(1, agentsSorted);
      }

      setLoading(false);
      setRefreshAgents(false);
    }
    if ((agents.length === 0 || refreshagents) && user) {
      fetchData();
    }
    if (id) {
      setAgentIdToAuthorize(id);
    }
  }, [refreshagents, user]); // eslint-disable-line

  useEffect(() => {
    const newUsersArray = sortItems(agentsFiltered, fieldSorted, orderSorted);
    handlePage(1, newUsersArray);
  }, [fieldSorted, orderSorted]); // eslint-disable-line

  const handleSort = (field: string) => {
    let order = "asc";
    if (fieldSorted === field) {
      order = orderSorted === "asc" ? "desc" : "asc";
      setOrderSorted(order);
    } else {
      setFieldSorted(field);
      setOrderSorted(order);
    }
  };

  function sortItems(
    array: any = agentsFiltered,
    field: string = fieldSorted,
    order: string = orderSorted
  ) {
    if (field === "role") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field].name.toLowerCase() > b[field].name.toLowerCase())
            return 1;
          if (a[field].name.toLowerCase() < b[field].name.toLowerCase())
            return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field].name.toLowerCase() < b[field].name.toLowerCase())
            return 1;
          if (a[field].name.toLowerCase() > b[field].name.toLowerCase())
            return -1;
          return 0;
        });
      }
    } else {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() < b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() > b[field].toLowerCase()) return -1;
          return 0;
        });
      }
    }

    return array;
  }

  function handleAgentsPerPage(
    userPerPage: number,
    agents: Agent[] = agentsFiltered
  ) {
    setTotalPages(Math.ceil(agents.length / userPerPage));
    handlePage(1, agents, userPerPage);
  }

  function handlePage(
    page: number,
    agents = agentsFiltered,
    totalagentsPerPage = agentsPerPage
  ) {
    setAgentsPerPage(totalagentsPerPage);
    setPage(page);
    const agentsSorted = sortItems(agents);
    const indexOfLastUser = page * totalagentsPerPage;
    const indexOfFirstUser = indexOfLastUser - totalagentsPerPage;
    const currentagents = agentsSorted.slice(indexOfFirstUser, indexOfLastUser);
    setAgents(currentagents);
  }

  function handleFilter() {
    const firstNameFilter = initialAgents.filter((agent: Agent) => {
      if (agent.creator_first_name) {
        return agent.creator_first_name
          .toLowerCase()
          .includes(creatorName.toLowerCase());
      }
      return true;
    });
    const lastNameFilter = firstNameFilter.filter((agent: Agent) => {
      if (agent.creator_last_name) {
        return agent.creator_last_name
          .toLowerCase()
          .includes(creatorLastName.toLowerCase());
      }
      return true;
    });
    const creatorEmailFilter = lastNameFilter.filter((agent: Agent) => {
      if (agent.creator_email) {
        return agent.creator_email
          .toLowerCase()
          .includes(creatorEmail.toLowerCase());
      }
      return true;
    });
    const statusFilter = creatorEmailFilter.filter((agent: Agent) => {
      if (status === "todos") {
        return true;
      } else if (agent.status) {
        return agent.status.toLowerCase().includes(status.toLowerCase());
      }
      return true;
    });
    handleAgentsPerPage(agentsPerPage, statusFilter);
    setAgentsFiltered(statusFilter);
  }
  function handleEnter(e: any) {
    if (e.key === "Enter") {
      handleFilter();
    }
  }
  return {
    creatorName,
    setCreatorName,
    creatorLastName,
    setCreatorLastName,
    creatorEmail,
    setCreatorEmail,
    status,
    setStatus,
    agents,
    dtf,
    loading,
    handleFilter,
    setAgentIdToAuthorize,
    agentsPerPage,
    handleAgentsPerPage,
    page,
    handlePage,
    totalPages,
    agentIdToAuthorize,
    setRefreshAgents,
    agentIdToViewLogs,
    setAgentIdToViewLogs,
    handleEnter,
    user,
    handleSort,
    orderSorted,
    fieldSorted,
  };
}
