import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import ArticleIcon from "../../assets/img/articleIcon.svg";
import FilterIcon from "../../assets/img/filterIcon.svg";
import Pagination from "../../commonComponents/Pagination";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Category } from "../../agents/Interfaces";
import ButtonComponent from "../../commonComponents/Button";
import useStatusAllBindings from "../hooks/useStatusAllBindings";
import { BindingDocument } from "../Interfaces";

export default function StatusAllBindings() {
  const {
    agentName,
    setAgentName,
    category,
    setCategory,
    eecc,
    setEecc,
    province,
    setProvince,
    bindingDocuments,
    loading,
    handleFilter,
    agentsPerPage,
    handleDocumentsPerPage,
    page,
    handlePage,
    totalPages,
    handleEnter,
    handleSort,
    orderSorted,
    fieldSorted,
    optionsType,
    navigate,
    documentSearch,
    setDocumentSearch,
  } = useStatusAllBindings();
  return (
    <Container>
      <Title>Estado de Vinculación de Documentos</Title>
      <MainContainer>
        <TopContainer>
          <IconContainer>
            <img src={ArticleIcon} alt="articleIcon" />
          </IconContainer>
        </TopContainer>
        <FilterContainer>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={agentName}
              onChange={({ target: { value } }) => setAgentName(value)}
            />
            <Label>Razón Social</Label>
          </FieldContainer>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={province}
              onChange={({ target: { value } }) => setProvince(value)}
            />
            <Label>Provincia</Label>
          </FieldContainer>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={eecc}
              onChange={({ target: { value } }) => setEecc(value)}
            />
            <Label>Vendedor</Label>
          </FieldContainer>
          <FilterSelect>
            <FilterSelectField
              value={category}
              onChange={({ target: { value } }) => setCategory(+value)}
            >
              <Option value={999}>Todos</Option>
              {optionsType?.map((option: Category) => (
                <Option value={option.categories_id}>{option.name}</Option>
              ))}
            </FilterSelectField>
            <LabelSelect>Tipo</LabelSelect>
          </FilterSelect>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={documentSearch}
              onChange={({ target: { value } }) => setDocumentSearch(value)}
            />
            <Label>Documento</Label>
          </FieldContainer>
          <FilterButton onClick={handleFilter}>
            <img src={FilterIcon} alt="filter Icon" />
            <FilterButtonText>Filtrar</FilterButtonText>
          </FilterButton>
        </FilterContainer>

        <TableContainer>
          <Table>
            <thead>
              <TRH>
                <TH onClick={() => handleSort("agent_name")}>
                  <THContainer>
                    Razón Social
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "agent_name" &&
                            orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "agent_name" &&
                            orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("province")}>
                  <THContainer>
                    Provincia
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "province" && orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "province" && orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("EECC")}>
                  <THContainer>
                    Vendedor
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "EECC" && orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "EECC" && orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("category")}>
                  <THContainer>
                    Tipo
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "category" && orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "category" && orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("document")}>
                  <THContainer>
                    Documento
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "document" && orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "document" && orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("approved")}>
                  <THContainer>
                    Estado
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "approved" && orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "approved" && orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
              </TRH>
            </thead>
            <tbody>
              {!loading &&
                bindingDocuments?.map((bindingDocument: BindingDocument) => (
                  <TR key={bindingDocument.binding_documents_id}>
                    <TD>{bindingDocument?.agent?.agent_name}</TD>
                    <TD>{bindingDocument?.agent?.province}</TD>
                    <TD>
                      {bindingDocument?.agent?.EECC
                        ? bindingDocument?.agent?.EECC.first_name +
                          " " +
                          bindingDocument?.agent?.EECC.last_name
                        : "-"}
                    </TD>
                    <TD>
                      {bindingDocument?.agent?.category
                        ? bindingDocument?.agent?.category.name
                        : "-"}
                    </TD>
                    <TD>
                      {bindingDocument?.document?.name
                        ? bindingDocument?.document.name
                        : "-"}
                    </TD>
                    <TD>
                      <StatusContainer
                        status={bindingDocument.approved ? "active" : "pending"}
                      >
                        {bindingDocument.approved ? "Aceptado" : "Pendiente"}{" "}
                      </StatusContainer>{" "}
                    </TD>
                  </TR>
                ))}
            </tbody>
          </Table>
        </TableContainer>
        {loading && (
          <>
            <Loading>
              <CircularProgress
                sx={{
                  color: "#128297",
                }}
                size={100}
              />
            </Loading>
          </>
        )}
        <PaginationContainer>
          <SelectAgentsPerPageContainer>
            <SelectAgentssPerPage>
              <SelectAgentsPerPageField
                value={agentsPerPage}
                onChange={({ target: { value } }) =>
                  handleDocumentsPerPage(+value)
                }
              >
                <OptionPage value={10}>10 filas</OptionPage>
                <OptionPage value={25}>25 filas</OptionPage>
                <OptionPage value={50}>50 filas</OptionPage>
                <OptionPage value={100}>100 filas</OptionPage>
              </SelectAgentsPerPageField>
            </SelectAgentssPerPage>
          </SelectAgentsPerPageContainer>
          <Pagination
            page={page}
            handlePage={handlePage}
            totalPages={totalPages}
          />
        </PaginationContainer>
      </MainContainer>
      <BottomContainer>
        <ButtonComponent
          type="secondary"
          onClick={() => navigate("/documents/list")}
        >
          Volver
        </ButtonComponent>
      </BottomContainer>
    </Container>
  );
}
const THContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  color: #128297;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const ArrowsIcon = styled.div`
  display: flex;
  flex-direction: row;
  transform: rotateZ(90deg);
`;
const Container = styled.div`
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  margin-top: 38px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
  }
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 60px 10px 25px 10px;
  margin-bottom: 0px;
  position: relative;
  margin-top: 50px;
  @media (max-width: 768px) {
    margin-top: 40px;
    padding: 30px 10px 25px 10px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: -17px;
    left: 10px;
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2px;
  @media (max-width: 768px) {
    min-width: 900px;
  }
`;
const TableContainer = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const TR = styled.tr`
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
`;
const TRH = styled.tr`
  :nth-child(even) {
    background-color: #f9f9f9;
  }
`;
const TD = styled.td`
  padding: 14px 0px 13px 20px;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  text-transform: capitalize;
`;
const TH = styled.th`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  padding: 23px 20px;
  color: #128297;
  text-transform: uppercase;
  text-align: start;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const FilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 167px;
  height: 55px;
  border: none;
  background: #128297;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  gap: 23px;
  padding: 0 0 0 23px;
  :hover {
    filter: brightness(90%);
  }
  @media (max-width: 768px) {
    min-width: 120px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
    & > img {
      width: 15px;
      height: 15px;
    }
  }
`;
const FilterButtonText = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 13px;
  }
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
`;
const SelectAgentsPerPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const SelectAgentssPerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OptionPage = styled.option`
  font-size: 14px;
  color: #383838;
`;
const SelectAgentsPerPageField = styled.select`
  width: 80px;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  padding: 8px 10px 8px 20px;
  font-size: 14px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  cursor: pointer;
`;
const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const FieldContainer = styled.div`
  position: relative;
  width: 100%;
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #424242;
  height: 55px;
  border-radius: 5px;
  border-radius: 5px;
  outline: none;
  padding: 20px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const Label = styled.label`
  font-size: 15px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 14px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
const FilterSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  border-radius: 4px;
  color: #128297;
  font-size: 14px;
  outline: none;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const FilterSelectField = styled.select`
  width: 100%;
  height: 55px;
  border: 1px solid #424242;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  color: #128297;
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const LabelSelect = styled.label`
  position: absolute;
  top: 5px;
  left: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  @media (max-width: 768px) {
    top: 10px;
    left: 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #424242;
  }
`;
const Option = styled.option`
  font-size: 14px;
  color: #128297;
  padding: 10px 0px;
`;
const DocumentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
  gap: 10px;
`;
const DocumentHeader = styled.h4`
  font-size: 20px;
  font-weight: 500;
  color: #128297;
  text-transform: uppercase;
`;
const DocumentName = styled.h4`
  font-size: 20px;
  font-weight: 500;
  color: #128297;
`;
const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
const StatusContainer = styled.div<{ status: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 90px;
  background: ${({ status }) => {
    switch (status) {
      case "active":
        return "rgba(120, 205, 128, 0.2)";
      case "pending":
        return "rgba(255, 185, 83, 0.2)";
      default:
        return "rgba(120, 205, 128, 0.2)";
    }
  }};
  border: 1px solid
    ${({ status }) => {
      switch (status) {
        case "active":
          return "#78CD80";
        case "pending":
          return "#FFB953";
        default:
          return "#78CD80";
      }
    }};
  border-radius: 5px;
  padding: 10px 10px;
`;
