import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import Button from "../../commonComponents/Button";
import Modal from "../../commonComponents/Modal";
import useModalSelectEECC from "./../hooks/useModalSelectEECC";

export default function ModalSelectEECC({
  setInputEECCselect,
}: {
  setInputEECCselect: (eecc: boolean) => void;
}) {
  const {
    loading,
    showCreateEECC,
    errors,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phone,
    setPhone,
    email,
    setEmail,
    password,
    setPassword,
    confirmedPassword,
    setConfirmedPassword,
    confirmEnabled,
    handleSubmit,
    setShowCreateEECC,
    searchByName,
    setsearchByName,
    users,
    handleUser,
  } = useModalSelectEECC({ setInputEECCselect });

  return (
    <Modal type="edituser" title={"Selector de EECC"}>
      <Container>
        {loading ? (
          <Loading>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </Loading>
        ) : showCreateEECC ? (
          <>
            <RegisterFormContainer>
              <RegisterContainer>
                <SubtitleSection>
                  Ingrese los siguientes datos para crear un EECC
                </SubtitleSection>
                <ColumnsContainer>
                  <Column>
                    <FieldContainer>
                      <Input
                        color={errors.firstName ? "#FF3D00" : undefined}
                        required
                        value={firstName}
                        onChange={({ target: { value } }) =>
                          setFirstName(value)
                        }
                      />
                      <Label color={errors.firstName ? "#FF3D00" : undefined}>
                        Nombre *
                      </Label>
                    </FieldContainer>
                    <FieldContainer>
                      <Input
                        color={errors.lastName ? "#FF3D00" : undefined}
                        required
                        value={lastName}
                        onChange={({ target: { value } }) => setLastName(value)}
                      />
                      <Label color={errors.lastName ? "#FF3D00" : undefined}>
                        Apellido *
                      </Label>
                    </FieldContainer>
                    <FieldContainer>
                      <Input
                        color={errors.phone ? "#FF3D00" : undefined}
                        required
                        type={"tel"}
                        value={phone}
                        onChange={({ target: { value } }) => setPhone(value)}
                      />
                      <Label color={errors.phone ? "#FF3D00" : undefined}>
                        Celular *
                      </Label>
                    </FieldContainer>
                  </Column>
                  <Column>
                    <FieldContainer>
                      <Input
                        color={errors.email ? "#FF3D00" : undefined}
                        required
                        value={email}
                        onChange={({ target: { value } }) => setEmail(value)}
                      />
                      <Label color={errors.email ? "#FF3D00" : undefined}>
                        Email *
                      </Label>
                    </FieldContainer>
                    <FieldContainer>
                      <Input
                        color={errors.password ? "#FF3D00" : undefined}
                        required
                        type={"password"}
                        value={password}
                        onChange={({ target: { value } }) => setPassword(value)}
                      />
                      <Label color={errors.password ? "#FF3D00" : undefined}>
                        Contraseña *
                      </Label>
                    </FieldContainer>
                    <FieldContainer>
                      <Input
                        color={errors.confirmedPassword ? "#FF3D00" : undefined}
                        required
                        type={"password"}
                        value={confirmedPassword}
                        onChange={({ target: { value } }) =>
                          setConfirmedPassword(value)
                        }
                      />
                      <Label
                        color={errors.confirmedPassword ? "#FF3D00" : undefined}
                      >
                        Confirmar Contraseña *
                      </Label>
                    </FieldContainer>
                  </Column>
                </ColumnsContainer>
              </RegisterContainer>
              <TextContainer>* Campos requeridos</TextContainer>
              <ButtonContainer>
                <Button
                  type={"secondary"}
                  onClick={() => setShowCreateEECC(false)}
                >
                  CANCELAR
                </Button>
                <Button
                  type={confirmEnabled ? "primary" : "disabled"}
                  onClick={() => handleSubmit()}
                >
                  CREAR EECC
                </Button>
              </ButtonContainer>
            </RegisterFormContainer>
          </>
        ) : (
          <>
            <SearchContainer>
              <FieldContainerSearch>
                <Input
                  required
                  value={searchByName}
                  onChange={({ target: { value } }) => setsearchByName(value)}
                />
                <Label>Buscar EECC por nombre</Label>
              </FieldContainerSearch>
              <Button type={"primary"} onClick={() => setShowCreateEECC(true)}>
                CREAR EECC
              </Button>
            </SearchContainer>
            <TableContainer>
              <Table>
                <TRH>
                  <TH>Nombre</TH>
                  <TH>Apellido</TH>
                  <TH>E-mail</TH>
                </TRH>
              </Table>
              <Table>
                <tbody>
                  {users?.map((user: any) => (
                    <TR
                      key={user.users_id}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleUser(user)}
                    >
                      <TD>{user.first_name}</TD>
                      <TD>{user.last_name}</TD>
                      <TD>{user.email}</TD>
                    </TR>
                  ))}
                  {loading && (
                    <>
                      <Loading>
                        <CircularProgress
                          sx={{
                            color: "#128297",
                          }}
                          size={100}
                        />
                      </Loading>
                    </>
                  )}
                </tbody>
              </Table>
            </TableContainer>
            <ButtonContainer>
              <Button
                type={"primary"}
                onClick={() => setInputEECCselect(false)}
              >
                CERRAR
              </Button>
            </ButtonContainer>
          </>
        )}
      </Container>
    </Modal>
  );
}

const TextContainer = styled.p`
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  width: 100%;
  text-align: flex-start;
  margin-bottom: 40px;
`;

const SubtitleSection = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 21px;
  color: #3c4858;
  margin-bottom: 20px;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  @media (max-width: 768px) {
    gap: 10px;
    width: 100%;
  }
`;
const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 40px;
`;
const Container = styled.div`
  width: 800px;
  height: 556px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    padding: 10px 10px;
    height: auto;
  }
`;
const TableContainer = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    align-items: flex-start;
    height: 300px;
  }
`;
const SearchContainer = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  margin-top: 40px;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    flex-direction: column;
    height: auto;
    gap: 20px;
  }
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2px;
  table-layout: fixed;
  @media (max-width: 768px) {
    min-width: 500px;
  }
`;
const TRH = styled.tr``;

const TR = styled.tr`
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
  :hover {
    background-color: #1283971d;
  }
`;
const TD = styled.td`
  padding: 14px 0px 13px 20px;
  font-size: 13px;
  line-height: 12px;
  color: #424242;
`;
const TH = styled.th`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  padding: 23px 20px;
  color: #128297;
  text-transform: uppercase;
  text-align: start;
  :last-of-type {
    text-align: end;
    width: 150px;
  }
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  @media (max-width: 768px) {
    height: 300px;
  }
`;
const RegisterFormContainer = styled.div`
  width: 100%;
  height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const RegisterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
`;
const FieldContainerSearch = styled.div`
  width: 440px;
  position: relative;
  @media (max-width: 768px) {
    width: 80%;
    flex-direction: column;
  }
`;
const FieldContainer = styled.div`
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Input = styled.input`
  width: 364px;
  height: 64px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const Label = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 24.5px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 11.5px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      line-height: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
