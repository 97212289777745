import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import UsersIcon from "../../assets/img/usersIcon.svg";
import FilterIcon from "../../assets/img/filterIcon.svg";
import ViewIcon from "../../assets/img/viewIcon.svg";
import Pagination from "../../commonComponents/Pagination";
import { Agent } from "../Interfaces";
import EditIcon from "../../assets/img/editRole.svg";
import AgentAuthorization from "../components/AgentAuthorization";
import useAgentRequests from "../hooks/useAgentRequests";
import AgentsLogs from "../components/AgentsLogs";
import Tooltip from "./../../commonComponents/Tooltip";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

export default function AgentRequests() {
  const {
    creatorName,
    setCreatorName,
    creatorLastName,
    setCreatorLastName,
    creatorEmail,
    setCreatorEmail,
    status,
    setStatus,
    agents,
    dtf,
    loading,
    handleFilter,
    setAgentIdToAuthorize,
    agentsPerPage,
    handleAgentsPerPage,
    page,
    handlePage,
    totalPages,
    agentIdToAuthorize,
    setRefreshAgents,
    agentIdToViewLogs,
    setAgentIdToViewLogs,
    handleEnter,
    user,
    handleSort,
    orderSorted,
    fieldSorted,
  } = useAgentRequests();
  return (
    <Container>
      <Title>Listado de Distribuidores por Autorizar</Title>
      <MainContainer>
        <TopContainer>
          <IconContainer>
            <img src={UsersIcon} alt="usersIcon" />
          </IconContainer>
        </TopContainer>
        <FilterContainer>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={creatorName}
              onChange={({ target: { value } }) => setCreatorName(value)}
            />
            <Label>Nombre</Label>
          </FieldContainer>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={creatorLastName}
              onChange={({ target: { value } }) => setCreatorLastName(value)}
            />
            <Label>Apellido</Label>
          </FieldContainer>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={creatorEmail}
              onChange={({ target: { value } }) => setCreatorEmail(value)}
            />
            <Label>Usuario</Label>
          </FieldContainer>
          <FilterSelect>
            <FilterSelectField
              value={status}
              onChange={({ target: { value } }) => setStatus(value)}
            >
              <Option value="" disabled hidden>
                Autorización
              </Option>
              <Option value={"todos"}>Todos</Option>
              <Option value={"pendiente"}>Pendiente</Option>
              <Option value={"autorizado"}>Autorizado</Option>
              <Option value={"denegado"}>Denegado</Option>
            </FilterSelectField>
          </FilterSelect>

          <FilterButton onClick={handleFilter}>
            <img src={FilterIcon} alt="filter Icon" />
            <FilterButtonText>Filtrar</FilterButtonText>
          </FilterButton>
        </FilterContainer>
        <TableContainer>
          <Table>
            <thead>
              <TRH>
                <TH onClick={() => handleSort("created_at")}>
                  <THContainer>
                    Fecha
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "created_at" &&
                            orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "created_at" &&
                            orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("agent_name")}>
                  <THContainer>
                  Distribuidor
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "agent_name" &&
                            orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "agent_name" &&
                            orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("creator_first_name")}>
                  <THContainer>
                    Nombre
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "creator_first_name" &&
                            orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "creator_first_name" &&
                            orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("creator_last_name")}>
                  <THContainer>
                    Apellido
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "creator_last_name" &&
                            orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "creator_last_name" &&
                            orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("creator_email")}>
                  <THContainer>
                    Usuario
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "creator_email" &&
                            orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "creator_email" &&
                            orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH onClick={() => handleSort("status")}>
                  <THContainer>
                    Autorización
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "status" && orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "status" && orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH>ACCIONES</TH>
                <TH>LOG CAMBIO DE ESTADOS</TH>
              </TRH>
            </thead>
            <tbody>
              {agents?.map((agent: Agent) => (
                <TR key={agent.agents_id}>
                  <TD>{dtf.format(new Date(agent.created_at))}</TD>
                  <TD>{agent.agent_name}</TD>
                  <TD>{agent.creator_first_name}</TD>
                  <TD>{agent.creator_last_name}</TD>
                  <TD>{agent.creator_email}</TD>
                  <TD>{agent.status}</TD>
                  <TD>
                    {(user?.role?.agent_to_activated ||
                      user?.role?.inactivated ||
                      user?.role?.agent_to_suspended) && (
                      <Tooltip text="Modificar Autorización" width={106}>
                        <Button
                          onClick={() =>
                            setAgentIdToAuthorize("" + agent.agents_id)
                          }
                        >
                          <img src={EditIcon} alt="edit Icon" />
                        </Button>
                      </Tooltip>
                    )}
                  </TD>
                  <TD>
                    {user?.role?.see_logs && (
                      <Tooltip text="Ver Logs">
                        <Button
                          onClick={() =>
                            setAgentIdToViewLogs("" + agent.agents_id)
                          }
                        >
                          <img src={ViewIcon} alt="view Icon" />
                        </Button>
                      </Tooltip>
                    )}
                  </TD>
                </TR>
              ))}
            </tbody>
          </Table>
        </TableContainer>
        {loading && (
          <>
            <Loading>
              <CircularProgress
                sx={{
                  color: "#128297",
                }}
                size={100}
              />
            </Loading>
          </>
        )}
        <PaginationContainer>
          <SelectUsersPerPageContainer>
            <SelectUsersPerPage>
              <SelectUsersPerPageField
                value={agentsPerPage}
                onChange={({ target: { value } }) =>
                  handleAgentsPerPage(+value)
                }
              >
                <OptionPage value={10}>10 filas</OptionPage>
                <OptionPage value={25}>25 filas</OptionPage>
                <OptionPage value={50}>50 filas</OptionPage>
                <OptionPage value={100}>100 filas</OptionPage>
              </SelectUsersPerPageField>
            </SelectUsersPerPage>
          </SelectUsersPerPageContainer>
          <Pagination
            page={page}
            handlePage={handlePage}
            totalPages={totalPages}
          />
        </PaginationContainer>
      </MainContainer>
      {agentIdToAuthorize && (
        <AgentAuthorization
          agentIdToAuthorize={agentIdToAuthorize}
          setAgentIdToAuthorize={setAgentIdToAuthorize}
          setRefreshAgents={setRefreshAgents}
        />
      )}
      {agentIdToViewLogs && (
        <AgentsLogs
          agentIdToViewLogs={agentIdToViewLogs}
          setAgentIdToViewLogs={setAgentIdToViewLogs}
        />
      )}
    </Container>
  );
}
const THContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  color: #128297;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const ArrowsIcon = styled.div`
  display: flex;
  flex-direction: row;
  transform: rotateZ(90deg);
`;
const Container = styled.div`
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  margin-top: 38px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
  }
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 60px 10px 25px 10px;
  margin-bottom: 60px;
  position: relative;
  margin-top: 50px;
  @media (max-width: 768px) {
    margin-top: 40px;
    padding: 30px 10px 25px 10px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: -17px;
    left: 10px;
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2px;
  @media (max-width: 768px) {
    min-width: 900px;
  }
`;
const TableContainer = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const TR = styled.tr`
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
`;
const TRH = styled.tr`
  :nth-child(even) {
    background-color: #f9f9f9;
  }
`;
const TD = styled.td`
  padding: 14px 0px 13px 20px;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  text-transform: capitalize;
`;
const TH = styled.th`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  padding: 23px 20px;
  color: #128297;
  text-transform: uppercase;
  text-align: start;
  :last-of-type {
    text-align: end;
    width: 150px;
  }
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const FilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 167px;
  height: 55px;
  border: none;
  background: #128297;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  gap: 23px;
  padding: 0 0 0 23px;
  :hover {
    filter: brightness(90%);
  }
  @media (max-width: 768px) {
    min-width: 120px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
    & > img {
      width: 15px;
      height: 15px;
    }
  }
`;
const FilterButtonText = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 13px;
  }
`;
const Button = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #128297;
  :hover {
    filter: brightness(90%);
  }
`;
const FilterSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  border-radius: 4px;
  color: #128297;
  font-size: 14px;
  outline: none;
`;
const FilterSelectField = styled.select`
  width: 100%;
  height: 55px;
  border: 1px solid #424242;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  color: #128297;
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const Option = styled.option`
  font-size: 14px;
  color: #128297;
  padding: 10px 0px;
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
`;
const SelectUsersPerPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const SelectUsersPerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OptionPage = styled.option`
  font-size: 14px;
  color: #383838;
`;
const SelectUsersPerPageField = styled.select`
  width: 80px;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  padding: 8px 10px 8px 20px;
  font-size: 14px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  cursor: pointer;
`;
const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const FieldContainer = styled.div`
  position: relative;
  width: 100%;
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #424242;
  height: 55px;
  border-radius: 5px;
  border-radius: 5px;
  outline: none;
  padding: 20px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const Label = styled.label`
  font-size: 15px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 14px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
