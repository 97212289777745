import styled from "styled-components";
import CreateAgentIcon from "../../assets/img/createAgentIcon.svg";
import TrashIcon from "../../assets/img/trashIcon.svg";
import AddIcon from "../../assets/img/addIcon.svg";
import Button from "../../commonComponents/Button";
import ModalSelectUser from "../components/ModalSelectUser";
import useInheritance from "../hooks/useInheritance";
import { CircularProgress } from "@mui/material";

export default function Inheritance() {
  const {
    selectOriginUser,
    setSelectOriginUser,
    originUser,
    setOriginUser,
    destinationUser,
    setDestinationUser,
    handleSubmit,
    users,
    loading,
    seletDestinationUser,
    setSeletDestinationUser,
    enabledSubmit
  } = useInheritance();

  return (
    <>
      <Container>
        <Title>Herencia de Usuarios</Title>
        <MainContainer>
          <TopContainer>
            <IconContainer>
              <img src={CreateAgentIcon} alt="Create Agent Icon" />
            </IconContainer>
          </TopContainer>
          <ColumnsContainer style={{ flexDirection: "column" }}>
            {loading ? (
              <LoadingContainer>
                <CircularProgress
                  sx={{
                    color: "#128297",
                  }}
                  size={100}
                />
              </LoadingContainer>
            ) : (
              <>
                <SubtitleSection>
                  Usuario de Origen:
                </SubtitleSection>
                <FieldContainer style={{ marginTop: "0px" }}>
                  <UserInput>
                    <UserData>
                      {!originUser ? (
                        "Ingrese Usuario"
                      ) : (
                        <SpanUser>
                          {originUser.first_name + " " + originUser.last_name}
                        </SpanUser>
                      )}
                    </UserData>
                  </UserInput>
                  {originUser ? (
                    <ButtonsContainer onClick={() => setOriginUser(null)}>
                      <ButtonItem>
                        <img src={TrashIcon} alt="trash icon" />
                      </ButtonItem>
                      <TooltipContainer>
                        <TooltipText>Remover Usuario</TooltipText>
                      </TooltipContainer>
                    </ButtonsContainer>
                  ) : (
                    <ButtonsContainer onClick={() => setSelectOriginUser(true)}>
                      <ButtonItem>
                        <img src={AddIcon} alt="add icon" />
                      </ButtonItem>
                      <TooltipContainer>
                        <TooltipText>Asignar Usuario</TooltipText>
                      </TooltipContainer>
                    </ButtonsContainer>
                  )}
                </FieldContainer>
                <SubtitleSection>
                  Usuario de Destino:
                </SubtitleSection>
                <FieldContainer style={{ marginTop: "0px" }}>
                  <UserInput>
                    <UserData>
                      {!destinationUser ? (
                        "Ingrese Usuario"
                      ) : (
                        <SpanUser>
                          {destinationUser.first_name +
                            " " +
                            destinationUser.last_name}
                        </SpanUser>
                      )}
                    </UserData>
                  </UserInput>
                  {destinationUser ? (
                    <ButtonsContainer onClick={() => setDestinationUser(null)}>
                      <ButtonItem>
                        <img src={TrashIcon} alt="trash icon" />
                      </ButtonItem>
                      <TooltipContainer>
                        <TooltipText>Remover Usuario</TooltipText>
                      </TooltipContainer>
                    </ButtonsContainer>
                  ) : (
                    <ButtonsContainer
                      onClick={() => setSeletDestinationUser(true)}
                    >
                      <ButtonItem>
                        <img src={AddIcon} alt="add icon" />
                      </ButtonItem>
                      <TooltipContainer>
                        <TooltipText>Asignar Usuario</TooltipText>
                      </TooltipContainer>
                    </ButtonsContainer>
                  )}
                </FieldContainer>
              </>
            )}
          </ColumnsContainer>
        </MainContainer>
      </Container>
      {selectOriginUser && (
        <ModalSelectUser
          setInputUserselect={setSelectOriginUser}
          users={users}
          excludeUser={destinationUser}
          setUser={setOriginUser}
        />
      )}
      {seletDestinationUser && (
        <ModalSelectUser
          setInputUserselect={setSeletDestinationUser}
          users={users}
          excludeUser={originUser}
          setUser={setDestinationUser}
        />
      )}
      <BottomContainer>
        <ButtonContainer>
          <Button
            type={enabledSubmit ? "primary" : "disabled"}
            onClick={handleSubmit}
          >
            CONFIRMAR
          </Button>
        </ButtonContainer>
      </BottomContainer>
    </>
  );
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 40px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0px;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
  padding: 0px 20px;
  @media (max-width: 768px) {
    padding: 0px 10px;
    align-items: center;
    justify-content: center;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  position: absolute;
  right: -46px;
  top: 12px;
  background: #128297;
  border-radius: 5px;
  cursor: pointer;
  @media (max-width: 768px) {
    top: 2px;
    width: 30px;
    height: 30px;
  }
`;
const SubtitleSection = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 21px;
  color: #3c4858;
  margin: 20px 0;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
    margin: 10px 0 20px;
  }
`;
const FieldContainer = styled.div`
  position: relative;
  height: 55px;
  @media (max-width: 768px) {
    width: calc(100% - 50px);
    height: 34px;
  }
`;
const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1140px;
  margin-top: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 50px 50px 50px 50px;
  position: relative;
  margin-bottom: 30px;
  width: 500px;
  padding: 50px 30px;
  @media (max-width: 768px) {
    width: calc(100% - 20px);
    padding: 40px 10px 20px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: -17px;
    left: 10px;
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const ButtonItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const TooltipContainer = styled.div`
  background: #424242;
  position: absolute;
  height: 20px;
  width: 88px;
  border-radius: 3px;
  display: flex;
  top: -30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  left: calc(50% - 44px);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  ::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    margin-left: -9px;
    width: 15px;
    height: 9px;
    background: #424242;
    transform: rotate(45deg);
  }
  ${ButtonItem}:hover ~ & {
    opacity: 1;
  }
`;
const TooltipText = styled.p`
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  color: #ffffff;
  z-index: 10;
`;
const UserInput = styled.div`
  width: 360px;
  height: 55px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const UserData = styled.p`
  font-size: 15px;
  line-height: 15px;
  color: #128297;
  position: absolute;
  top: 21px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    top: 10px;
    max-width: 90%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const SpanUser = styled.span`
  font-family: "D-DIN-PRO-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 12px;
  text-align: center;
  color: #128297;
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  margin-top: 38px;
  width: 100%;
  text-align: start;
  padding: 0 30px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
  }
`;
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;
