import styled from "styled-components";
export default function MultiLineInput({
  label,
  value,
  setValue,
  disabled,
}: {
  label: string;
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
}) {
  return (
    <TextAreaObservations
      value={value}
      onChange={({ target: { value } }) => setValue(value)}
      placeholder={label}
      disabled={disabled}
    />
  );
}
const TextAreaObservations = styled.textarea`
  background: #ffffff;
  border: 1px solid #424242;
  border-radius: 5px;
  font-size: 15px;
  line-height: 15px;
  color: #128297;
  width: 280px;
  height: 200px;
  padding: 15px;
  margin-top: 0px;
  resize: none;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
    outline: none;
  }
  ::placeholder {
    font-size: 15px;
    line-height: 15px;
    color: #128297;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;