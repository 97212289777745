import { useCallback, useEffect, useState, useRef } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import useAxios from "../../commonHooks/useAxios";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";

export default function useZonesMap() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCymfXuGTA2G-CBaKnXZJHRuswtOCc9uLM",
  });
  const [map, setMap] = useState<null | google.maps.Map>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [zones, setZones] = useState<any>([])
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [cursorPosition, setCursorPosition] = useState({
    x: 0,
    y: 0,
  });
  const containerRef = useRef(null) as any;
  const mapContainer = useRef(null) as any;
  const [agentName, setAgentName] = useState<string | null>(null);
  const [contactPhone, setContactPhone] = useState<string | null>(null)
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const getData = useAxios();
  const colors: string[] = ["#7FFFD4", "#006400", "#7CFC00", "#5F9EA0", "#20B2AA", "#48D1CC", "#0000FF", "#6495ED", "#00008B", "#8A2BE2", "#8B008B", "#4B0082", "#FF7F50", "#FFA07A", "#FF4500", "#DC143C", "#B22222", "#F08080", "#FF1493", "#C71585", "#D87093", "#FFD700", "#FFA500", "#FFFF00"];
  const [loadingExport, setLoadingExport] = useState<boolean>(false);

  useEffect(() => {
    document.addEventListener("mousemove", (e) => {
      setCursorPosition({
        x: e.offsetX,
        y: e.offsetY
      })
    })
    setIsMobile(window.innerWidth < 768);
    return () => {
      document.removeEventListener("mousemove", () => { })
    }
  }, [])

  useEffect(() => {
    async function fetchAllZones() {
      setLoading(true);
      const zones: any = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/getallcoveragezones`,
        headers: {
          accept: "*/*",
        },
      });
      const zonesArray = zones.map((zone: any) => {
        if (zone.points) {
          return {
            ...zone,
            points: zone.points.sort(
              (a: { order: number }, b: { order: number }) => {
                return a.order - b.order;
              }
            ),
          };
        } else {
          return zone;
        }
      })
      setZones(zonesArray)
      setLoading(false);
    }

    if (zones.length === 0) {
      fetchAllZones()
    }

    if (zones.length > 0 && map && firstRender) {
      setLoading(true);
      setFirstRender(false)
      zones.forEach((zone: any, index: any) => {
        setTimeout(function () {
          let rand = Math.random();
          rand = Math.floor(rand * 24);

          const color = colors[rand];
          if (zone.radius) {
            const circle = new google.maps.Circle({
              strokeColor: color,
              strokeOpacity: 0.9,
              strokeWeight: 1,
              fillColor: color,
              fillOpacity: 0.65,
              map: map,
              center: { lat: zone.lat, lng: zone.lng },
              radius: zone.radius * 1000,
            });
            circle.addListener("mouseover", () => {
              setAgentName(zone.agents_name)
              setContactPhone(zone.contact_phone)
              setShowTooltip(true);
            })
            circle.addListener("mouseout", () => {
              setShowTooltip(false);
            })
          } else {
            const polygonPoints = zone.points.map((point: any) => {
              return { lat: point.latitude, lng: point.longitude };
            });
            const polygon = new window.google.maps.Polygon({
              paths: polygonPoints,
              strokeColor: color,
              strokeOpacity: 0.9,
              strokeWeight: 1,
              fillColor: color,
              fillOpacity: 0.65,
              map: map,
            });
            polygon.addListener("mouseover", () => {
              setAgentName(zone.agents_name)
              setContactPhone(zone.contact_phone)
              setShowTooltip(true);
            })
            polygon.addListener("mouseout", () => {
              setShowTooltip(false);
            })
          }
        }, 100 * (index + 1));

      })
      setLoading(false);
    }


  }, [zones, map, firstRender]); // eslint-disable-line

  function exportPdf() {
    setLoadingExport(true);
    let f = new Date();
    mapContainer.current.style.width = "297mm";
    mapContainer.current.style.height = "210mm";
    map?.setOptions({ disableDefaultUI: true });
    setTimeout(() => {
      html2canvas(mapContainer.current, {
        useCORS: true,
      }).then(function (canvas) {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a4");
        pdf.addImage(imgData, "PNG", 0, 0, 297, 210);
        pdf.save("Mapa_de_calor_" + f.getDate() + "_" + (f.getMonth() + 1) + "_" + f.getFullYear() + ".pdf");
        mapContainer.current.style.width = "100%";
        mapContainer.current.style.height = "80vh";
        map?.setOptions({ disableDefaultUI: false });
        setLoadingExport(false);
      });
    }, 200)
  }

  function exportJPG() {
    setLoadingExport(true);
    let f = new Date();
    mapContainer.current.style.width = "297mm";
    mapContainer.current.style.height = "210mm";
    map?.setOptions({ disableDefaultUI: true });
    setTimeout(() => {
      html2canvas(mapContainer.current, {
        useCORS: true,
      }).then(function (canvas) {
        const imgData = canvas.toDataURL("image/jpg");
        const link = document.createElement("a");
        link.download = "Mapa_de_calor_" + f.getDate() + "_" + (f.getMonth() + 1) + "_" + f.getFullYear() + ".jpg";
        link.href = imgData;
        link.click();
        mapContainer.current.style.width = "100%";
        mapContainer.current.style.height = "80vh";
        map?.setOptions({ disableDefaultUI: false });
        setLoadingExport(false);
      });

    }, 200)
  }

  const onLoad = useCallback(function callback(map: google.maps.Map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  return {
    isLoaded,
    onLoad,
    onUnmount,
    loading,
    cursorPosition,
    agentName,
    showTooltip,
    contactPhone,
    containerRef,
    exportPdf,
    mapContainer,
    exportJPG,
    isMobile,
    loadingExport
  };
}
