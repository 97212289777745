import styled from "styled-components";
import Modal from "../../commonComponents/Modal";
import Button from "../../commonComponents/Button";
import { CircularProgress } from "@mui/material";
import useBindingRequestModal from "../hooks/useBindingRequestModal";

export default function BindingRequestModal({
  setShowBindingRequestModal,
}: {
  setShowBindingRequestModal: (show: boolean) => void;
}) {
  const { loading, errors, agentId, setAgentId, handleSubmit } =
    useBindingRequestModal({ setShowBindingRequestModal });
  return (
    <Modal type="roles" title="Vincular Distribuidor">
      <Container>
        {loading ? (
          <>
            <Loading>
              <CircularProgress
                sx={{
                  color: "#128297",
                }}
                size={100}
              />
            </Loading>
          </>
        ) : (
          <>
            <FieldContainer>
              <Input
                color={errors ? "#FF3D00" : undefined}
                required
                value={agentId}
                onChange={({ target: { value } }) => setAgentId(value)}
              />
              <Label color={errors ? "#FF3D00" : undefined}>ID Distribuidor</Label>
            </FieldContainer>
            <ButtonsContainer>
              <Button
                type="secondary"
                onClick={() => setShowBindingRequestModal(false)}
              >
                Cancelar
              </Button>
              <Button type="primary" onClick={() => handleSubmit()}>
                Aceptar
              </Button>
            </ButtonsContainer>
          </>
        )}
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  width: 415px;
  height: 280px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 73px 40px 50px 40px;
  @media (max-width: 768px) {
    height: auto;
    padding: 73px 10px 50px 10px;
    width: calc(100vw - 20px);
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  margin-top: 50px;
  @media (max-width: 768px) {
    margin-top: 40px;
    gap: 20px;
  }
`;
const FieldContainer = styled.div`
  position: relative;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
  }
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #424242;
  height: 55px;
  border-radius: 5px;
  border-radius: 5px;
  outline: none;
  padding: 20px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  border-color: ${(props) => props.color || "#424242"};
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const Label = styled.label`
  font-size: 15px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 11.5px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      line-height: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 350px;
  height: 280px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;
