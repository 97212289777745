import { useEffect, useState } from "react";
import useAxios from "../../commonHooks/useAxios";
import { Agent } from "../../agents/Interfaces";

export default function useModalSelectAgent({
  inputAgentSelect,
  setInputAgentSelect,
  setAgentSelected,
  agentSelected,
}: {
  inputAgentSelect: number | null;
  setInputAgentSelect: (user: number | null) => void;
  setAgentSelected: (users: any) => void;
  agentSelected: any[];
}) {
  const [searchByName, setsearchByName] = useState<string>("");
  const [initialAgents, setInitialAgents] = useState<Agent[]>([]);
  const [agents, setAgents] = useState<Agent[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const getData = useAxios();

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response: Agent[] = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/agents/`,
        headers: {
          accept: "*/*",
        },
      });
      setInitialAgents(response);
      setAgents(response);
      setLoading(false);
    }
    fetchData();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (searchByName.length > 0) {
      setAgents(
        initialAgents.filter((user) =>
          user.agent_name.toLowerCase().includes(searchByName.toLowerCase())
        )
      );
    } else {
      setAgents(initialAgents);
    }
  }, [searchByName, initialAgents]);

  function handleUser(agent: Agent) {
      setAgentSelected({
        agents_id: agent.agents_id,
        agent_name: agent.agent_name,
        trade_name: agent.trade_name,
      });
    setInputAgentSelect(null);
  }
  return {
    loading,
    searchByName,
    setsearchByName,
    agents,
    handleUser,
  }
}
