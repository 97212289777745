import { useState, useEffect, useContext } from "react";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";
import { User } from "../Interfaces";

export default function useInheritanceEECC() {
    const [selectOriginUser, setSelectOriginUser] = useState<boolean>(false);
    const [seletDestinationUser, setSeletDestinationUser] = useState<boolean>(false);
    const [originUser, setOriginUser] = useState<null | User>(null);
    const [destinationUser, setDestinationUser] = useState<null | User>(null);
    const [users, serUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [enabledSubmit, setEnabledSubmit] = useState<boolean>(false);
    const axios = useAxios();
    const { setNotification } = useContext(notificationsContext);
    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            const response: User[] = await axios({
                method: "GET",
                url: `${process.env.REACT_APP_API_URL}/users/`,
                headers: {
                    accept: "*/*",
                },
            });
            serUsers(response);
            setLoading(false);
        }
        fetchData();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (originUser && destinationUser) {
            setEnabledSubmit(true);
        } else {
            setEnabledSubmit(false);
        }
    }, [originUser, destinationUser]);

    async function handleSubmit() {
        if (!enabledSubmit) return;
        setLoading(true);
        try {
            const { message } = await axios({
                method: "POST",
                url: `${process.env.REACT_APP_API_URL}/inheritance`,
                data: {
                    originId: originUser?.users_id,
                    destinationId: destinationUser?.users_id,
                },
            });
            if (message === "Inheritance completed") {
                setOriginUser(null);
                setDestinationUser(null);
                setEnabledSubmit(false);
                setNotification({
                    theme: "success",
                    titleNotification: "Herencia lograda",
                    contentNotification: "El usuario de destino heredó correctamente los datos del usuario de origen",
                    isVisible: true,
                });
            } else {
                setNotification({
                    theme: "error",
                    titleNotification: "Error",
                    contentNotification: "No se pudo realizar la herencia",
                    isVisible: true,
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setNotification({
                theme: "error",
                titleNotification: "Error",
                contentNotification: "No se pudo realizar la herencia",
                isVisible: true,
            });
        }
    }

    return {
        selectOriginUser,
        setSelectOriginUser,
        originUser,
        setOriginUser,
        destinationUser,
        setDestinationUser,
        handleSubmit,
        users,
        loading,
        seletDestinationUser,
        setSeletDestinationUser,
        enabledSubmit
    };
}
