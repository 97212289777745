import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import useEditUser from "../hooks/useEditUser";
import CheckIcon from "../../assets/img/checkIcon.svg";
import ArrowDropDownIcon from "../../assets/img/arrowDropDownIcon.svg";
import Modal from "../../commonComponents/Modal";
import Button from "../../commonComponents/Button";

export default function EditUser({
  userIdToEdit,
  setUserIdToEdit,
  setRefreshUsers,
}: {
  userIdToEdit: string;
  setUserIdToEdit: (userIdToEdit: string | null) => void;
  setRefreshUsers: (usersRefresh: boolean) => void;
}) {
  const {
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setlastName,
    phone,
    setPhone,
    role,
    setRole,
    createdAt,
    setCreatedAt,
    registerType,
    setRegisterType,
    lastLogin,
    setLastLogin,
    isEECC,
    setIsEECC,
    loading,
    handleSubmit,
    roles,
    errors,
    showDropDownRole,
    setshowDropDownRole,
    initialEmail,
    height,
  } = useEditUser({ userIdToEdit, setUserIdToEdit, setRefreshUsers });

  return (
    <Modal type="edituser" title={initialEmail}>
      <Container>
        {loading ? (
          <LoadingContainer>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </LoadingContainer>
        ) : (
          <>
            <TopContainer>
              <CheckboxContainer>
                <CheckboxText>¿Usuario EECC?</CheckboxText>
                <Checkbox onClick={() => setIsEECC((prev) => !prev)}>
                  {isEECC && <img src={CheckIcon} alt="check icon" />}
                </Checkbox>
              </CheckboxContainer>
            </TopContainer>
            <ColumnsContainer>
              <ColumnContainer>
                <FieldContainer>
                  <Input
                    color={errors.email ? "#FF3D00" : undefined}
                    required
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                  />
                  <Label color={errors.email ? "#FF3D00" : undefined}>
                    Email
                  </Label>
                </FieldContainer>
                <FieldContainer>
                  <Input
                    color={errors.firstName ? "#FF3D00" : undefined}
                    required
                    value={firstName}
                    onChange={({ target: { value } }) => setFirstName(value)}
                  />
                  <Label color={errors.firstName ? "#FF3D00" : undefined}>
                    Nombre
                  </Label>
                </FieldContainer>
                <FieldContainer>
                  <Input
                    color={errors.lastName ? "#FF3D00" : undefined}
                    required
                    value={lastName}
                    onChange={({ target: { value } }) => setlastName(value)}
                  />
                  <Label color={errors.lastName ? "#FF3D00" : undefined}>
                    Apellido
                  </Label>
                </FieldContainer>
                <FieldContainer>
                  <Input
                    color={errors.phone ? "#FF3D00" : undefined}
                    required
                    type={"tel"}
                    value={phone}
                    onChange={({ target: { value } }) => setPhone(value)}
                  />
                  <Label color={errors.phone ? "#FF3D00" : undefined}>
                    Celular
                  </Label>
                </FieldContainer>
              </ColumnContainer>
              <ColumnContainer>
                <FieldContainer>
                  <DropDownField
                    onClick={() => setshowDropDownRole(!showDropDownRole)}
                    style={{
                      height: showDropDownRole
                        ? `${roles.length * height + height + 18}px`
                        : `${height}px`,
                    }}
                  >
                    {roles.map(
                      (r: any) =>
                        role === r.roles_id && (
                          <CurrentValueDropDown key={r.roles_id}>
                            {r.name}
                          </CurrentValueDropDown>
                        )
                    )}
                    <DropDownItems
                      style={{
                        overflowY:
                          showDropDownRole && roles.length > 6
                            ? "scroll"
                            : "hidden",
                      }}
                    >
                      {roles.map((role: any) => (
                        <Option
                          key={role.roles_id}
                          onClick={() => setRole(+role.roles_id)}
                        >
                          {role.name}
                        </Option>
                      ))}
                    </DropDownItems>

                    <IconConatiner
                      style={{
                        transform: showDropDownRole
                          ? "rotateZ(-180deg)"
                          : "rotateZ(0deg)",
                      }}
                    >
                      <img src={ArrowDropDownIcon} alt="arrow drop down icon" />
                    </IconConatiner>
                    <DropDownLabel>Rol</DropDownLabel>
                  </DropDownField>
                </FieldContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={createdAt}
                    onChange={({ target: { value } }) => setCreatedAt(value)}
                  />
                  <LabelDisabled color={undefined}>
                    Fecha de Registro
                  </LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={registerType}
                    onChange={({ target: { value } }) => setRegisterType(value)}
                  />
                  <LabelDisabled color={undefined}>
                    Registrado con
                  </LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={
                      lastLogin === "31/12/1969, 21:00:00" ? "-" : lastLogin
                    }
                    onChange={({ target: { value } }) => setLastLogin(value)}
                  />
                  <LabelDisabled color={undefined}>
                    Fecha Último Login
                  </LabelDisabled>
                </FieldContainer>
              </ColumnContainer>
            </ColumnsContainer>
            <ButtonsContainer>
              <Button type="secondary" onClick={() => setUserIdToEdit(null)}>
                Cancelar
              </Button>
              <Button type="primary" onClick={handleSubmit}>
                Guardar
              </Button>
            </ButtonsContainer>
          </>
        )}
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  width: 760px;
  height: 556px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    height: auto;
    padding-bottom: 10px;
  }
`;
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;
const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 320px;
  @media (max-width: 768px) {
    gap: 10px;
    width: calc(100vw - 40px);
  }
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  width: 100%;
  height: 34px;
  position: relative;
  padding-right: 40px;
  margin-top: 32px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;
const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  width: 320px;
  height: 64px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
  }
`;
const Checkbox = styled.div`
  width: 34px;
  height: 34px;
  border: 1px solid #424242;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 30px;
`;
const CheckboxText = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-top: 30px;
  padding-right: 40px;
  @media (max-width: 768px) {
    width: calc(100vw - 50px);
    padding-right: 0px;
  }
`;
const Option = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #128297;
  cursor: pointer;
  :hover {
    background-color: #f5f5f5;
  }
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 0 0 20px;
  :nth-last-child() {
    margin-bottom: 18px;
  }
  @media (max-width: 768px) {
    min-height: 34px;
  }
`;
const DropDownField = styled.div`
  border: 1px solid #424242;
  border-radius: 5px;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
  position: absolute;
  color: #128297;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 20px 18px 20px;
  overflow-y: hidden;
  background-color: #fff;
  z-index: 6;
  transition: all 0.4s;
  max-height: 467px;
  @media (max-width: 768px) {
    padding: 0px 10px 18px 10px;
    width: 100%;
  }
`;
const DropDownItems = styled.div`
  cursor: pointer;
  color: #128297;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: hidden;
  z-index: 6;
  transition: all 0.4s;
  max-height: 403px;
  width: 100%;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;
const IconConatiner = styled.div`
  position: absolute;
  right: 26px;
  top: 24px;
  transform: rotateZ("0deg");
  transition: all 0.3s;
  @media (max-width: 768px) {
    right: 16px;
    top: 8px;
  }
`;
const DropDownLabel = styled.p`
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  color: #424242;
  position: absolute;
  top: 6px;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
    top: 3px;
  }
`;
const CurrentValueDropDown = styled.div`
  font-family: "D-DIN-PRO-Medium";
  font-size: 14px;
  line-height: 14px;
  color: #128297;
  cursor: pointer;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @media (max-width: 768px) {
    font-family: "D-DIN-PRO";
    min-height: 34px;
    font-size: 13px;
    line-height: 13px;
    padding-top: 10px;
  }
`;
const Input = styled.input`
  width: 320px;
  height: 64px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const Label = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 24.5px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 11.5px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      line-height: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
const DisabledInput = styled.input`
  width: 320px;
  height: 64px;
  background: #f1f1f1;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const LabelDisabled = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 10px;
  left: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  @media (max-width: 768px) {
    top: 3px;
    left: 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #424242;
  }
`;
