import styled from "styled-components";
import { CenterContainerColumn } from "../../commonComponents/Layouts";
import Logo from "../../assets/img/logo.svg";
import LogoMobile from "../../assets/img/logoMobile.svg";
import BackgroundLogin from "../../assets/img/backgroundLogin.jpg";
import EmailIcon from "../../assets/img/emailIcon.svg";
import PasswordIcon from "../../assets/img/passwordIcon.svg";
import useSignIn from "./../hooks/useSignIn";

export default function SignIn() {
  const {
    email,
    setEmail,
    password,
    setPassword,
    signIn,
    navigation,
    handleEnter,
  } = useSignIn();
  return (
    <Container>
      <BackgroundContainer></BackgroundContainer>
      <LoginContainer>
        <LogoContainer>
          <img src={Logo} alt="Logo YPF SOLAR" />
        </LogoContainer>
        <LogoContainerMobile>
          <img src={LogoMobile} alt="Logo YPF SOLAR" />
        </LogoContainerMobile>
        <LoginForm>
          <CenterContainerColumn>
            <Title>Ingresar</Title>
          </CenterContainerColumn>
          <BlankSpace height={16} />
          <InputContainer>
            <Image src={EmailIcon} alt="Email Icon" />
            <Input
              required
              onKeyDown={handleEnter}
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
            />
            <Label>Email</Label>
          </InputContainer>
          <BlankSpace height={50} />
          <InputContainer>
            <ImagePasword src={PasswordIcon} alt="Password Icon" />
            <Input
              required
              onKeyDown={handleEnter}
              type={"password"}
              value={password}
              onChange={({ target: { value } }) => setPassword(value)}
            />
            <Label>Contraseña</Label>
          </InputContainer>
          <BlankSpace height={40} />
          <SignInButton onClick={signIn}>INGRESAR</SignInButton>
          <ResetPassword onClick={() => navigation("/reset-password")}>
            Olvidé mi contraseña
          </ResetPassword>
          <RegisterContainer>
            <RegisterText>¿No tienes cuenta?</RegisterText>
            <RegisterLink onClick={() => navigation("/signup")}>
              Regístrate aquí
            </RegisterLink>
          </RegisterContainer>
        </LoginForm>
      </LoginContainer>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 485px;
  overflow-x: hidden;
  position: relative;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${window.innerHeight}px;
  }
`;
const BackgroundContainer = styled.div`
  display: flex;
  background-image: url(${BackgroundLogin});
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    position: absolute;
    width: 100%;
    min-height: ${window.innerHeight}px;
    z-index: 2;
  }
`;
const LoginContainer = styled.div`
  padding: 20px 0px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-height: 100vh;
  @media (max-width: 768px) {
    position: absolute;
    width: calc(100% - 20px);
    min-height: calc(${window.innerHeight}px - 20px);
    z-index: 2;
    background: #ffffff;
    border-radius: 3px;
  }
`;
const LogoContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;
export const BlankSpace = styled.div<{ height?: number; width?: number }>`
  height: ${({ height }) => `${height}px` || "100%"};
  width: ${({ width }) => `${width}px` || "100%"};
  @media (max-width: 768px) {
    display: none;
  }
`;
const LogoContainerMobile = styled.div`
  width: 100%;
  height: 100px;
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const LoginForm = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    min-height: calc(${window.innerHeight}px - 200px);
    justify-content: space-between;
  }
`;

const Title = styled.h1`
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  color: #128297;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 20px;
  }
`;
const InputContainer = styled.div`
  position: relative;
`;
const Image = styled.img`
  position: absolute;
  top: 23px;
  left: 20px;
  width: 24px;
  @media (max-width: 768px) {
    top: 13px;
    left: 13px;
    width: 24px;
  }
`;

const ImagePasword = styled.img`
  position: absolute;
  top: 23px;
  left: 20px;
  width: 24px;
  @media (max-width: 768px) {
    top: 10px;
    left: 13px;
    width: 24px;
  }
`;
const SignInButton = styled.button`
  width: 200px;
  height: 55px;
  padding: 20px;
  background: #128297;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  cursor: pointer;
  margin-bottom: 80px;
  &:focus {
    outline: 1px solid #29707d;
  }
  @media (max-width: 768px) {
    width: calc(100% - 20px);
    height: 43px;
    padding: 11px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const ResetPassword = styled.p`
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #128297;
  cursor: pointer;
  margin-bottom: 40px;
`;
const RegisterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
`;
const RegisterText = styled.p`
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #128297;
`;
const RegisterLink = styled.p`
  font-family: "D-DIN-PRO-Bold";
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #128297;
  cursor: pointer;
  text-decoration: underline;
`;
const Input = styled.input`
  width: 364px;
  height: 64px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: 16px;
  font-size: 15px;
  line-height: 15px;
  padding: 0px 20px 0px 54px;
  outline: none;
  color: #128297;
  ::placeholder {
    color: #128297;
  }
  :focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: calc(100vw - 40px);
    height: 44px;
    border-radius: 5px;
    font-size: 12px;
    font-size: 12px;
    margin-bottom: 20px;
  }
`;
const Label = styled.label`
  font-size: 15px;
  position: absolute;
  pointer-events: none;
  top: 24px;
  left: 65px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 5px;
    left: 65px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 5px;
    left: 65px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    font-size: 13px;
    top: 15.5px;
    left: 54px;
    ${Input}:focus ~ & {
      top: 3px;
      left: 54px;
      font-weight: 500;
      font-size: 9px;
      line-height: 9px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 3px;
      left: 54px;
      font-weight: 500;
      font-size: 9px;
      line-height: 9px;
      color: #424242;
    }
  }
`;
