import styled from "styled-components";
import CameraIcon from "../../assets/img/cameraIcon.svg";
import CameraIconLoaded from "../../assets/img/cameraIconLoaded.svg";
import Modal from "../../commonComponents/Modal";
import { useState, useContext, useEffect } from "react";
import Button from "../../commonComponents/Button";
import { Question } from "../Interfaces";
import { useParams } from "react-router-dom";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";
import Loader from "../../commonComponents/Loader";
import { CircularProgress } from "@mui/material";
export default function ImageInput({
  question,
  label,
  setValue,
  value,
  disabled,
  groupId,
}: {
  question: Question;
  label: string;
  setValue: (value: string) => void;
  value: any;
  disabled?: boolean;
  groupId?: number;
}) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [image, setImage] = useState<any>(null);
  const [imgSrc, setImgSrc] = useState<any>(null);
  const [loadImage, setLoadImage] = useState<boolean>(true);
  const [withoutImage, setwithoutImage] = useState<boolean>(false);
  const [uploadButton, setUploadButton] = useState<boolean>(false);
  const [loadNewImage, setloadNewImage] = useState<boolean>(false);
  const [reload, Setreload] = useState(false);
  const { id } = useParams();
  const sendData = useAxios();
  const { setNotification } = useContext(notificationsContext);
  useEffect(() => {
    if (id && groupId && question.id) {
      const name = `${id}${groupId}${question.id}.png`;
      const src = `${
        process.env.REACT_APP_API_URL
      }/getquestionimage/${name}?${new Date().getTime()}`;
      setImgSrc(src);
    }
  }, [id, groupId, question.id, reload]);

  function handleChange(e: any) {
    setloadNewImage(true);
    setImage(e.target.files[0]);
    console.log("e.target.files[0]", e.target.files[0]);
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = (e) => {
      console.log("onloaded", e);
      setImgSrc(reader.result);
      setTimeout(() => {
        setLoadImage(true);
        setloadNewImage(false);
      }, 300);
    };
  }

  async function handleUpload() {
    //change name of image
    const newName = `${id}${groupId}${question.id}`;
    const newImage = new File([image], newName, { type: image.type });
    setImage(newImage);
    if (image) {
      const formData = new FormData();
      formData.append("image", newImage);
      await sendData({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/uploadquestion`,
        headers: {
          accept: "*/*",
        },
        data: formData,
      });
    }
    setNotification({
      theme: "success",
      titleNotification: "Imagen Cargada",
      contentNotification: "La imagen se ha cargado correctamente.",
      isVisible: true,
    });
    setOpenModal(false);
    Setreload(!reload);
    setwithoutImage(false);
  }

  return (
    <>
      <FieldContainer>
        <ButtonImage
          disabled={false}
          onClick={() => (!withoutImage || !disabled) && setOpenModal(true)}
        >
          <MiddleContainer>
            {!withoutImage ? (
              <img src={CameraIconLoaded} alt="camera icon" />
            ) : (
              <img src={CameraIcon} alt="camera icon" />
            )}
          </MiddleContainer>
        </ButtonImage>
        <CoverImageHidden
          src={imgSrc}
          alt="cover"
          onError={() => {
            setwithoutImage(true);
          }}
        />
      </FieldContainer>
      {openModal && (
        <Modal type="document" title={"Cargar Imagen"}>
          <Container>
            {loadNewImage ? (
              <CircularProgress
                sx={{
                  color: "#88e9f6",
                }}
                size={100}
              />
            ) : loadImage ? (
              <CoverImage
                src={imgSrc}
                alt="cover"
                onError={() => {
                  setLoadImage(false);
                  setUploadButton(true);
                }}
              />
            ) : (
              <>
                <Label htmlFor="image">Agregar Imagen</Label>
                <Input
                  id="image"
                  type="file"
                  accept=".jpeg,.jpg,.png"
                  onChange={handleChange}
                  disabled={disabled}
                />
              </>
            )}

            <ButtonsContainer>
              <Button
                type="secondary"
                onClick={() => {
                  setOpenModal(false);
                }}
              >
                Cancelar
              </Button>
              {uploadButton && !disabled && (
                <Button
                  type="primary"
                  onClick={() => {
                    handleUpload();
                  }}
                >
                  Cargar
                </Button>
              )}
            </ButtonsContainer>
          </Container>
        </Modal>
      )}
    </>
  );
}

const FieldContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  width: 34px;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;
const ButtonImage = styled.div<{ disabled: boolean }>`
  width: 34px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 5px;
  background: var(--gray-02, #e7e7e7);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
`;
const MiddleContainer = styled.div`
  border-radius: 5px;
  border: 1px dashed var(--paleta-principal-corporative-primario-01, #128297);
  background: var(--gray-02, #e7e7e7);
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Container = styled.div`
  width: 415px;
  height: 510px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 73px 40px 50px 40px;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    padding: 20px 10px 50px 10px;
    height: auto;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  margin-top: 50px;
`;
const Label = styled.label`
  background-color: #128297;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: "D-DIN-PRO-Medium";
  font-size: 15px;
  line-height: 15px;
  padding: 20px 30px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
  right: 0;
  top: 0px;
  width: 184px;
  :focus {
    outline: none;
  }
  @media (max-width: 768px) {
    padding: 15px 15px;
    font-size: 13px;
    line-height: 13px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: auto;
    top: auto;
  }
`;
const Input = styled.input`
  display: none;
`;
const CoverImage = styled.img`
  height: calc(100vh - 160px);
  width: 80%;
  margin-bottom: -20px;
  object-fit: cover;
  object-position: center;
  @media (max-width: 768px) {
    height: calc(100vh - 110px);
    width: 90%;
  }
`;
const CoverImageHidden = styled.img`
  height: 0px;
  width: 0px;
  margin-bottom: -20px;
  object-fit: cover;
  object-position: center;
  opacity: 0;
  @media (max-width: 768px) {
    height: calc(100vh - 110px);
    width: 90%;
  }
`;
