import { useState } from "react";
import styled from "styled-components";
import useAxios from "../../commonHooks/useAxios";
import { Question } from "../Interfaces";
import { CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
export default function PdfInput({
  label,
  setValue,
  value,
  disabled,
  question,
  groupId,
}: {
  label: string;
  setValue: (value: string) => void;
  value: any;
  disabled?: boolean;
  question: Question;
  groupId?: number;
}) {
  const [uploadFile, setuploadFile] = useState(false);
  const sendData = useAxios();
  const { id } = useParams();

  async function changeInput(e: any) {
    const newName = `${id}${groupId}${question.id}.${
      e.target.files[0].name.split(".")[
        e.target.files[0].name.split(".").length - 1
      ]
    }`;
    const newFile = new File([e.target.files[0]], newName, {
      type: e.target.files[0].type,
    });
    setuploadFile(true);
    try {
      const formData = new FormData();
      formData.append("file", newFile);
      await sendData({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/uploadfile`,
        headers: {
          accept: "*/*",
        },
        data: formData,
      });
      setValue(newName);
      setuploadFile(false);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <FieldContainer>
      {value && (
        <PdfName
          target="_blank"
          href={`${process.env.REACT_APP_API_URL}/getquestionfile/${value}`}
        >
          {value}
        </PdfName>
      )}
      {uploadFile ? (
        <CircularProgress
          sx={{
            color: "#88e9f6",
          }}
          size={20}
        />
      ) : (
        <>
          <LabelInput htmlFor="files">{label}</LabelInput>
          <InputPdf
            id="files"
            type="file"
            onChange={changeInput}
            disabled={disabled}
          />
        </>
      )}
    </FieldContainer>
  );
}

const FieldContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  width: 280px;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;
const LabelInput = styled.label`
  background-color: transparent;
  color: #128297;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: "D-DIN-PRO-Medium";
  font-size: 15px;
  line-height: 15px;
  padding: 8px 16px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
  right: 0;
  top: 0px;
  border: 1px solid #128297;
  :focus {
    outline: none;
  }
  @media (max-width: 768px) {
    padding: 15px 15px;
    font-size: 13px;
    line-height: 13px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: auto;
    top: auto;
  }
`;
const InputPdf = styled.input`
  display: none;
`;
const PdfName = styled.a`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
`;
