import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import useShowLead from "../hooks/useShowLead";
import Modal from "../../commonComponents/Modal";
import Button from "../../commonComponents/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { forwardRef } from "react";

export default function ShowLead({
  leadIdToEdit,
  setLeadIdToEdit,
  setRefreshLeads,
}: {
  leadIdToEdit: number;
  setLeadIdToEdit: (userIdToEdit: number | null) => void;
  setRefreshLeads: (usersRefresh: boolean) => void;
}) {
  const {
    createdAt,
    setCreatedAt,
    firstName,
    setFirstName,
    lastName,
    setlastName,
    province,
    setProvince,
    email,
    setEmail,
    phone,
    setPhone,
    loading,
    city,
    setCity,
    selectedProducts,
    contactDate,
    setContactDate,
    interest,
    setInterest,
    status,
    setStatus,
    comments,
    setComments,
  } = useShowLead({
    leadIdToEdit,
    setLeadIdToEdit,
    setRefreshLeads: setRefreshLeads,
  });

  const DatePickerInput = forwardRef<HTMLDivElement>(
    ({ value, onClick }: any, ref) => (
      <FieldContainer ref={ref}>
        <DisabledInput
          disabled
          required
          value={contactDate ? contactDate.toLocaleDateString() : ""}
        />
        <LabelDisabled color={undefined}>Contactado</LabelDisabled>
      </FieldContainer>
    )
  );

  return (
    <Modal type="edituser" title={""}>
      <Container>
        {loading ? (
          <LoadingContainer>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </LoadingContainer>
        ) : (
          <>
            <ColumnsContainer>
              <ColumnContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                  />
                  <LabelDisabled>Email</LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={firstName}
                    onChange={({ target: { value } }) => setFirstName(value)}
                  />
                  <LabelDisabled>Nombre</LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={lastName}
                    onChange={({ target: { value } }) => setlastName(value)}
                  />
                  <LabelDisabled>Apellido</LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    type={"tel"}
                    value={phone}
                    onChange={({ target: { value } }) => setPhone(value)}
                  />
                  <LabelDisabled>Celular</LabelDisabled>
                </FieldContainer>
                <div style={{ zIndex: 200, width: "100%" }}>
                  <DatePicker
                    selected={contactDate}
                    onChange={(date: Date) => setContactDate(date)}
                    customInput={<DatePickerInput />}
                  />
                </div>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={interest}
                    onChange={({ target: { value } }) => setInterest(value)}
                  />
                  <LabelDisabled color={undefined}>Interés</LabelDisabled>
                </FieldContainer>
              </ColumnContainer>
              <ColumnContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={createdAt}
                    onChange={({ target: { value } }) => setCreatedAt(value)}
                  />
                  <LabelDisabled color={undefined}>Fecha</LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={province}
                    onChange={({ target: { value } }) => setProvince(value)}
                  />
                  <LabelDisabled color={undefined}>Provincia</LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={city}
                    onChange={({ target: { value } }) => setCity(value)}
                  />
                  <LabelDisabled color={undefined}>Localidad</LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <ProductsContainer>
                    {
                      selectedProducts.map((product) => (
                        <p style={{padding:"4px"}}>{product}</p>
                      ))
                    }
                  </ProductsContainer>
                  <LabelDisabled color={undefined}>Productos</LabelDisabled>
                </FieldContainer>
                <FieldContainer>
                  <DisabledInput
                    disabled
                    required
                    value={status}
                    onChange={({ target: { value } }) => setStatus(value)}
                  />
                  <LabelDisabled color={undefined}>Estado</LabelDisabled>
                </FieldContainer>
              </ColumnContainer>
            </ColumnsContainer>
            <FieldContainer style={{ width: "100%", marginTop: "10px" }}>
              <DisabledTextArea
                style={{ width: "100%" }}
                disabled
                required
                value={comments}
                onChange={({ target: { value } }) => setComments(value)}
              />
              <LabelDisabled color={undefined}>Observaciones</LabelDisabled>
            </FieldContainer>
            <ButtonsContainer>
              <Button type="secondary" onClick={() => setLeadIdToEdit(null)}>
                Cerrar
              </Button>
            </ButtonsContainer>
          </>
        )}
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 60px 50px 20px 50px;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    height: auto;
    padding: 10px 10px 20px 10px;
  }
`;
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;
const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 320px;
  @media (max-width: 768px) {
    gap: 10px;
    width: calc(100vw - 40px);
  }
`;
const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2px;
  position: relative;
  width: 320px;
  height: auto;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  margin-top: 10px;
  padding-right: 40px;
  @media (max-width: 768px) {
    width: calc(100vw - 50px);
    padding-right: 0px;
  }
`;
const DisabledInput = styled.input`
  width: 320px;
  height: 40px;
  background: #f1f1f1;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 26px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  @media (max-width: 768px) {
    width: 100%;
    height: 30px;
    padding: 17px 10px 5px;
    font-size: 12px;
    line-height: 12px;
  }
`;
const LabelDisabled = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 6px;
  left: 20px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  @media (max-width: 768px) {
    top: 3px;
    left: 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #424242;
  }
`;
const DisabledTextArea = styled.textarea`
  width: 320px;
  min-height: 100px;
  background: #f1f1f1;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  resize: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
  min-height: auto;
    width: 100%;
    height: 60px;
    padding: 17px 10px 5px;
    font-size: 12px;
    line-height: 12px;
  }
`;
const ProductsContainer = styled.div`
  width: 320px;
  height: 100px;
  background: #f1f1f1;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 20px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  resize: none;
  overflow: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
  min-height: auto;
    width: 100%;
    height: 60px;
    padding: 17px 10px 5px;
    font-size: 12px;
    line-height: 12px;
  }
`;
