import styled from "styled-components";
import { useState } from 'react';
export default function LinkInput({
  label,
  value,
  setValue,
  disabled,
}: {
  label: string;
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
}) {
  const [link, setLink] = useState<boolean>(false);
  function isLink(value: string) {
    return /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/.test(
      value
    );
  }
  function onChange({ target: { value } }: { target: { value: string } }) {
    setValue(value);
    if (isLink(value)) {
      setLink(true);
    }else{
      setLink(false);
    }
  }

  return (
    <FieldContainer>
      <Input
        required
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={link ? "link" : ""}
      />
      <Label>{label}</Label>
    </FieldContainer>
  );
}

const FieldContainer = styled.div`
  position: relative;
  width: 280px;
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #424242;
  height: 34px;
  border-radius: 5px;
  outline: none;
  padding: 4px 8px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  &.link{
    border: 1px solid #128297;
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const Label = styled.label`
  font-size: 13px;
  position: absolute;
  pointer-events: none;
  top: 8px;
  left: 8px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 3px;
    left: 8px;
    font-weight: 500;
    font-size: 8px;
    line-height: 8px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 3px;
    left: 8px;
    font-weight: 500;
    font-size: 8px;
    line-height: 8px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 14px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
