import { useState } from "react";
import { User } from "../Interfaces";

export default function useModalSelectUser({
  setInputUserselect,
  setUser,
  users,
}: {
  setInputUserselect: (eecc: boolean) => void;
  setUser: (userId: User) => void;
  users: User[];
}) {
  const [searchByName, setsearchByName] = useState<string>("");
  const [arrayUsers, setArrayUsers] = useState<User[]>(users);

  function handleUser(user: User) {
    setUser(user);
    setInputUserselect(false);
  }

  function handleSearch(name: string) {
    setsearchByName(name);
    if (name === "") {
      setArrayUsers(users);
      return;
    }
    const array = users?.filter((user:User) => (user.first_name + " " + user.last_name).toLowerCase().includes(name.toLowerCase()) );
    setArrayUsers(array);
  }

  return {
    handleUser,
    searchByName,
    handleSearch,
    arrayUsers
  };
}
