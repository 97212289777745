import { GoogleMap } from "@react-google-maps/api";
import styled from "styled-components";
import useCoverageZone from "../hooks/useCoverageZone";
import AgentsIcon from "../../assets/img/agentsIcon.svg";
import DetelePointIcon from "../../assets/img/deletePointIcon.svg";
import Button from "../../commonComponents/Button";
import { useContext } from "react";
import { agentFormContext } from "../../context/agentFormContext";
import { CircularProgress } from "@mui/material";
import MarkerIcon from "../../assets/img/markerIcon.png";

const containerStyle = {
  width: "100%",
  height: "60vh",
};

const center = {
  lat: -34.6,
  lng: -58.43,
};

const mapOptions = {
  center: center,
  zoom: 10,
  mapTypeId: "roadmap",
  disableDefaultUI: false,
  streetViewControl: false,
  scaleControl: false,
  rotateControl: false,
  fullscreenControl: false,
  mapTypeControl: false,
  styles: [
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#444444",
        },
      ],
    },
    // {
    //   featureType: "landscape",
    //   elementType: "all",
    //   stylers: [
    //     {
    //       color: "#f2f2f2",
    //     },
    //   ],
    // },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    // {
    //   featureType: "road",
    //   elementType: "all",
    //   stylers: [
    //     {
    //       saturation: -100,
    //       lightness: 45,
    //     },
    //   ],
    // },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    // {
    //   featureType: "road.arterial",
    //   elementType: "labels.icon",
    //   stylers: [
    //     {
    //       visibility: "off",
    //     },
    //   ],
    // },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#46bcec",
          visibility: "on",
        },
      ],
    },
  ],
};

export default function CoverageZone({
  submitForm,
}: {
  submitForm: ({ polygon_points }: any) => void;
}) {
  const {
    currentStep,
    setCurrentStep,
    radiusCoverageZone,
    radius,
    setRadius,
    circle,
  } = useContext(agentFormContext);
  const {
    isLoaded,
    onLoad,
    onUnmount,
    handleClickMap,
    markers,
    deleteMarker,
    handleReset,
    handleSubmit,
    agent,
    loading,
    handleRadius,
    handleChange,
    handleCancel,
    agentId,
    cursorPosition,
    agentName,
    showTooltip,
    contactPhone,
    containerRef,
    isMobile,
  } = useCoverageZone({ submitForm });

  return isLoaded ? (
    <>
      <MainContainer ref={containerRef}>
        <Tooltip
          style={{
            top: cursorPosition.y + 80,
            left: isMobile ? cursorPosition.x - 50 : cursorPosition.x + 120,
            opacity: showTooltip ? 0.9 : 0,
            display: showTooltip ? "block" : "none",
          }}
        >
          <TooltipName>Razón Social: {agentName}</TooltipName>
          <TooltipPhone>Teléfono: {contactPhone}</TooltipPhone>
        </Tooltip>
        <TopContainer>
          <IconContainer>
            <MarkerPoint>
              <img src={MarkerIcon} alt="markerPoint" />
            </MarkerPoint>
            <img src={AgentsIcon} alt="usersIcon" />
          </IconContainer>
        </TopContainer>
        <AgentContainer>
          {agent ? "Zona de cobertura de : " + agent.agent_name : " "}
        </AgentContainer>
        <ChangeButton>
          <Button type={"primary"} onClick={() => handleChange()}>
            {radiusCoverageZone ? "Crear Polígono" : "Crear Radio"}
          </Button>
        </ChangeButton>
        <TitleContainer>
          {radiusCoverageZone
            ? ""
            : "Haz click sobre el Mapa y comienza a dibujar la Zona de Cobertura"}
        </TitleContainer>
        <ContentContainer>
          <MapContainer>
            <GoogleMap
              options={mapOptions}
              mapContainerStyle={containerStyle}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onClick={handleClickMap}
            ></GoogleMap>
          </MapContainer>
          <PointsTitle>
            {radiusCoverageZone
              ? "Indique en kilómetros el radio de cobertura"
              : "Puntos según tu selección"}
          </PointsTitle>
          {loading && (
            <Loading>
              <CircularProgress
                sx={{
                  color: "#128297",
                }}
                size={100}
              />
            </Loading>
          )}
          {radiusCoverageZone && (
            <RadiusContainer>
              <FieldContainer>
                <Input
                  required
                  type={"number"}
                  value={radius}
                  onChange={({ target: { value } }) => setRadius(value)}
                />
                <Label>Radio en Km</Label>
              </FieldContainer>
              <Button type={"primary"} onClick={() => handleRadius()}>
                Visualizar Radio de Cobertura
              </Button>
            </RadiusContainer>
          )}
          <CardsContainer>
            {markers?.map((marker, index) => {
              return (
                <Card key={index}>
                  <InfoPoint>
                    <PointTitle>Punto N°{marker.label.text}</PointTitle>
                    <PointContainer>
                      <LatLngPoint>
                        <PointText>
                          LAT: {marker.position.lat().toFixed(2)}
                        </PointText>
                        <PointText>
                          LNG: {marker.position.lng().toFixed(2)}
                        </PointText>
                      </LatLngPoint>
                    </PointContainer>
                  </InfoPoint>
                  <DeleteButton onClick={() => deleteMarker(index)}>
                    <img src={DetelePointIcon} alt="deletePointIcon" />
                  </DeleteButton>
                </Card>
              );
            })}
          </CardsContainer>
        </ContentContainer>
        <ButtonsContainer>
          {!radiusCoverageZone && (
            <Button type="secondary" onClick={handleReset}>
              BORRAR SELECCIÓN
            </Button>
          )}

          <ConfirmContainer
            style={{ width: radiusCoverageZone ? "100%" : "auto" }}
          >
            {agentId ? (
              <>
                <Button type={"secondary"} onClick={() => handleCancel()}>
                  CANCELAR
                </Button>
                <Button
                  type={
                    markers.length >= 3 || (circle && radius)
                      ? "primary"
                      : "disabled"
                  }
                  onClick={handleSubmit}
                >
                  Guardar Cabmios
                </Button>
              </>
            ) : (
              <>
                <TermsText>
                  Al aceptar y continuar estás aceptando las Condiciones de la{" "}
                  <Span>Carta Oferta</Span> y los{" "}
                  <Span>Términos de Privacidad</Span>
                </TermsText>
                <Button
                  type={
                    markers.length >= 3 || (circle && radius)
                      ? "primary"
                      : "disabled"
                  }
                  onClick={handleSubmit}
                >
                  Confirmar zona y continuar
                </Button>
              </>
            )}
          </ConfirmContainer>
        </ButtonsContainer>
        <ButtonsContainerMobile>
          {!agentId && (
            <TermsText>
              Al aceptar y continuar estás aceptando las Condiciones de la{" "}
              <Span>Carta Oferta</Span> y los{" "}
              <Span>Términos de Privacidad</Span>
            </TermsText>
          )}

          <ConfirmContainer>
            {agentId ? (
              <>
                {!radiusCoverageZone && (
                  <Button type="secondary" onClick={handleReset}>
                    <img src={DetelePointIcon} alt="deletePointIcon" />
                  </Button>
                )}
                <Button
                  type={
                    markers.length >= 3 || (circle && radius)
                      ? "primary"
                      : "disabled"
                  }
                  onClick={handleSubmit}
                >
                  GUARDAR CAMBIOS
                </Button>
                <Button type={"secondary"} onClick={() => handleCancel()}>
                  CANCELAR
                </Button>
              </>
            ) : (
              <>
                {!radiusCoverageZone && (
                  <Button type="secondary" onClick={handleReset}>
                    <img src={DetelePointIcon} alt="deletePointIcon" />
                  </Button>
                )}
                <Button
                  type={
                    markers.length >= 3 || (circle && radius)
                      ? "primary"
                      : "disabled"
                  }
                  onClick={handleSubmit}
                >
                  Confirmar zona y continuar
                </Button>
              </>
            )}
          </ConfirmContainer>
        </ButtonsContainerMobile>
      </MainContainer>
      <ButtonContainer>
        <Button
          type={"primary"}
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          ATRAS
        </Button>
      </ButtonContainer>
    </>
  ) : (
    <></>
  );
}
const Tooltip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 12px;
  background-color: #128297;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  border-radius: 6px;
  opacity: 0;
  transition: top 0.1s ease, left 0.1s ease;
`;

const TooltipName = styled.p`
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 0;
`;

const TooltipPhone = styled.p`
  font-size: 12px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 0;
`;
const ChangeButton = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -10px;
  margin-right: 30px;
  @media (max-width: 768px) {
    position: relative;
    margin-top: 0px;
    margin-right: 0px;
  }
`;
const ConfirmContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
  margin-top: -40px;
  padding: 0px 20px;
`;
const AgentContainer = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  width: 100%;
  color: #3c4858;
  margin-bottom: 20px;
  min-height: 21px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;
const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
const TitleContainer = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  width: 100%;
  color: #3c4858;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 20px;
    width: 80%;
    padding-top: 10px;
  }
`;
const PointsTitle = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  width: 100%;
  color: #3c4858;
  margin-bottom: 20px;
  margin-top: 20px;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 20px;
  }
`;
const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px;
  position: relative;
  width: 114px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  height: 51px;
  transition: 0.2s;
  &:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  }
  margin-left: 50px;
  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;
const InfoPoint = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;
const MarkerPoint = styled.div`
  position: absolute;
  visibility: hidden;
`;
const PointTitle = styled.p`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  color: #424242;
`;
const LatLngPoint = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 5px;
`;
const PointText = styled.p`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  color: #128297;
`;
const PointContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const MapContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
`;
const DeleteButton = styled.div`
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #128297;
  position: absolute;
  right: 10px;
  top: 0px;
  opacity: 0;
  transition: all 0.2s;
  ${Card}:hover & {
    opacity: 1;
  }
  @media (max-width: 768px) {
    opacity: 1;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  gap: 20px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const ButtonsContainerMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    gap: 20px;
  }
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 30px 50px 25px 50px;
  margin-bottom: 60px;
  position: relative;
  min-height: 650px;
  @media (max-width: 768px) {
    width: calc(100% - 20px);
    padding: 40px 10px 20px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const TermsText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #424242;
  width: 320px;
  text-align: end;
  @media (max-width: 768px) {
    padding: 0px 10px;
    width: 100%;
    text-align: center;
  }
`;
const Span = styled.span`
  font-family: "D-DIN-PRO-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #128297;
`;
const RadiusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  margin-bottom: 30px;
  margin-top: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 20px;
    gap: 10px;
  }
`;
const FieldContainer = styled.div`
  position: relative;
  max-width: 350px;
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #424242;
  height: 55px;
  border-radius: 5px;
  border-radius: 5px;
  outline: none;
  padding: 20px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
`;
const Label = styled.label`
  font-size: 15px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
`;
