import styled from "styled-components";
import { CenterContainerColumn } from "../../commonComponents/Layouts";
import useRegisterForm from "../hooks/useRegisterForm";
import Logo from "../../assets/img/logo.svg";
import BackgroundLogin from "../../assets/img/backgroundLogin.jpg";
import LogoMobile from "../../assets/img/logoMobile.svg";
import { CircularProgress } from "@mui/material";

export default function RegisterForm() {
  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    phone,
    setPhone,
    email,
    setEmail,
    password,
    setPassword,
    confirmedPassword,
    setConfirmedPassword,
    errors,
    handleSubmit,
    navigate,
    registrationConfirmed,
    loading,
    handleEnter,
  } = useRegisterForm();

  return (
    <Container>
      <BackgroundContainer></BackgroundContainer>
      <SideContainer>
        <LogoContainer>
          <img src={Logo} alt="Logo YPF SOLAR" />
        </LogoContainer>
        <LogoContainerMobile>
          <img src={LogoMobile} alt="Logo YPF SOLAR" />
        </LogoContainerMobile>
        <RegisterFormContainer>
          {loading ? (
            <Loading>
              <CircularProgress
                sx={{
                  color: "#128297",
                }}
                size={100}
              />
            </Loading>
          ) : !registrationConfirmed ? (
            <>
              <CenterContainerColumn>
                <Title>Regístrate aquí</Title>
              </CenterContainerColumn>
              <RegisterContainer>
                <FieldContainer>
                  <Input
                    color={errors.firstName ? "#FF3D00" : undefined}
                    required
                    onKeyDown={handleEnter}
                    value={firstName}
                    onChange={({ target: { value } }) => setFirstName(value)}
                  />
                  <Label color={errors.firstName ? "#FF3D00" : undefined}>
                    Nombre
                  </Label>
                </FieldContainer>
                <FieldContainer>
                  <Input
                    color={errors.lastName ? "#FF3D00" : undefined}
                    required
                    onKeyDown={handleEnter}
                    value={lastName}
                    onChange={({ target: { value } }) => setLastName(value)}
                  />
                  <Label color={errors.lastName ? "#FF3D00" : undefined}>
                    Apellido
                  </Label>
                </FieldContainer>
                <FieldContainer>
                  <Input
                    color={errors.phone ? "#FF3D00" : undefined}
                    required
                    onKeyDown={handleEnter}
                    type={"tel"}
                    value={phone}
                    onChange={({ target: { value } }) => setPhone(value)}
                  />
                  <Label color={errors.phone ? "#FF3D00" : undefined}>
                    Celular
                  </Label>
                </FieldContainer>
                <FieldContainer>
                  <Input
                    color={errors.email ? "#FF3D00" : undefined}
                    required
                    onKeyDown={handleEnter}
                    value={email}
                    onChange={({ target: { value } }) => setEmail(value)}
                  />
                  <Label color={errors.email ? "#FF3D00" : undefined}>
                    Email
                  </Label>
                </FieldContainer>
                <FieldContainer>
                  <Input
                    color={errors.password ? "#FF3D00" : undefined}
                    required
                    onKeyDown={handleEnter}
                    type={"password"}
                    value={password}
                    onChange={({ target: { value } }) => setPassword(value)}
                  />
                  <Label color={errors.password ? "#FF3D00" : undefined}>
                    Contraseña
                  </Label>
                </FieldContainer>
                <FieldContainer>
                  <Input
                    color={errors.confirmedPassword ? "#FF3D00" : undefined}
                    required
                    type={"password"}
                    onKeyDown={handleEnter}
                    value={confirmedPassword}
                    onChange={({ target: { value } }) =>
                      setConfirmedPassword(value)
                    }
                  />
                  <Label
                    color={errors.confirmedPassword ? "#FF3D00" : undefined}
                  >
                    Confirmar Contraseña
                  </Label>
                </FieldContainer>
              </RegisterContainer>
              <SignInButton onClick={handleSubmit}>REGISTRARSE</SignInButton>
              <LoginContainer>
                <LoginText>¿Tienes cuenta?</LoginText>
                <LoginLink onClick={() => navigate("/login")}>
                  Ingresa aquí
                </LoginLink>
              </LoginContainer>
            </>
          ) : (
            <>
              <CenterContainerColumn>
                <Title>Registro exitoso</Title>
              </CenterContainerColumn>
              <TextContainer>
                <TextRegistrationConfirmed>
                  Hemos registrado tu información con éxito, por favor revisa tu
                  bandeja de entrada y confirma tu cuenta.
                </TextRegistrationConfirmed>
              </TextContainer>
              <LoginText>¿Tienes cuenta?</LoginText>
              <LoginButton onClick={() => navigate("/login")}>
                Ingresar aquí
              </LoginButton>
            </>
          )}
        </RegisterFormContainer>
      </SideContainer>
    </Container>
  );
}
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
`;
const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 485px;
  overflow-x: hidden;
  position: relative;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${window.innerHeight}px;
  }
`;
const BackgroundContainer = styled.div`
  display: flex;
  background-image: url(${BackgroundLogin});
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    position: absolute;
    width: 100%;
    min-height: ${window.innerHeight}px;
    z-index: 2;
  }
`;
const SideContainer = styled.div`
  padding: 20px 0px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 768px) {
    position: absolute;
    width: calc(100% - 20px);
    min-height: calc(${window.innerHeight}px - 20px);
    z-index: 2;
    background: #ffffff;
    border-radius: 3px;
  }
`;
const LogoContainer = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    display: none;
  }
`;
const LogoContainerMobile = styled.div`
  width: 100%;
  height: 100px;
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 30px;
    margin-bottom: 20px;
  }
`;
const RegisterFormContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    min-height: calc(100vh - 160px);
    justify-content: space-between;
  }
`;
const Title = styled.h1`
  font-size: 30px;
  line-height: 30px;
  text-align: center;
  color: #128297;
  margin-bottom: 80px;
  @media (max-width: 768px) {
    margin-bottom: 8px;
    font-size: 20px;
    line-height: 20px;
  }
`;
const TextRegistrationConfirmed = styled.p`
  font-size: 15px;
  line-height: 25px;
  text-align: center;
  color: #128297;
`;
const TextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 43px;
  margin-bottom: 80px;
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`;
const RegisterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    gap: 6px;
    margin-bottom: 0px;
  }
`;
const FieldContainer = styled.div`
  position: relative;
`;
const Input = styled.input`
  width: 364px;
  height: 64px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: calc(100vw - 40px);
    height: 44px;
    border-radius: 5px;
    font-size: 12px;
    font-size: 12px;
  }
`;
const Label = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 24.5px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    font-size: 13px;
    top: 15.5px;
    left: 20px;
    ${Input}:focus ~ & {
      top: 6px;
      left: 20px;
      font-weight: 500;
      font-size: 9px;
      line-height: 9px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 6px;
      left: 20px;
      font-weight: 500;
      font-size: 9px;
      line-height: 9px;
      color: #424242;
    }
  }
`;
const SignInButton = styled.button`
  width: 200px;
  height: 55px;
  padding: 20px;
  background: #128297;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  cursor: pointer;
  margin-bottom: 50px;
  &:focus {
    outline: 1px solid #29707d;
  }
  @media (max-width: 768px) {
    width: calc(100% - 20px);
    height: 43px;
    padding: 11px;
    font-size: 13px;
    line-height: 13px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;
const LoginButton = styled.button`
  width: 200px;
  height: 55px;
  padding: 20px;
  background: #128297;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  cursor: pointer;
  margin-top: 20px;
  &:focus {
    outline: 1px solid #29707d;
  }
  @media (max-width: 768px) {
    width: calc(100% - 20px);
    height: 43px;
    padding: 11px;
    font-size: 13px;
    line-height: 13px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;
const LoginContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;
const LoginText = styled.p`
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #128297;
`;
const LoginLink = styled.p`
  font-family: "D-DIN-PRO-Bold";
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #128297;
  cursor: pointer;
  text-decoration: underline;
`;
