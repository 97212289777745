import useAxios from "../../commonHooks/useAxios";
import { useEffect, useState, useContext } from "react";
import { Lead } from "../Interfaces";
import { Product } from '../Interfaces';
import { userContext } from "../../context/userContext";
export default function useMyLeads() {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [leadsFiltered, setLeadsFiltered] = useState<Lead[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [leadsPerPage, setLeadsPerPage] = useState<number>(10);
  const [leadIdToEdit, setLeadIdToEdit] = useState<number | null>(null);
  const [refreshLeads, setRefreshLeads] = useState<boolean>(true);
  const getData = useAxios();
  const [fieldSorted, setFieldSorted] = useState("created_at");
  const [orderSorted, setOrderSorted] = useState("desc");
  const [products, setProducts] = useState<Product[]>([]);
  const dtf = new Intl.DateTimeFormat("es-ES", {
    calendar: "gregory",
    numberingSystem: "latn",
    day: "numeric",
    month: "numeric",
    year: "numeric",
  });
  const { userState } = useContext(userContext);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response: any = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/agentleads/${userState.user?.agents_id}`,
        headers: {
          accept: "*/*",
        },
      });
      const responseLeads: Lead[] = response?.leads;
      let arrayProducts: Product[] = [];
      response?.products.forEach((product: Product) => {
        arrayProducts[product.products_id] = product;
      });
      setProducts(arrayProducts);
      const leadsSorted = sortItems(responseLeads, "created_at", "desc");
      setLeads(leadsSorted);
      setLeadsFiltered(leadsSorted);
      setTotalPages(Math.ceil(leadsSorted.length / leadsPerPage));
      handlePage(1, leadsSorted);
      setLoading(false);
      setRefreshLeads(false);
    }
    if ((leads.length === 0 || refreshLeads) && userState) {
      fetchData();
    }
  }, [refreshLeads, userState]); // eslint-disable-line

  useEffect(() => {
    const newLeadsArray = sortItems(leadsFiltered, fieldSorted, orderSorted);
    handlePage(1, newLeadsArray);
  }, [fieldSorted, orderSorted]); // eslint-disable-line

  const handleSort = (field: string) => {
    let order = "asc";
    if (fieldSorted === field) {
      order = orderSorted === "asc" ? "desc" : "asc";
      setOrderSorted(order);
    } else {
      setFieldSorted(field);
      setOrderSorted(order);
    }
  };

  function sortItems(
    array: any = leadsFiltered,
    field: string = fieldSorted,
    order: string = orderSorted
  ) {
    if (field === "created_at") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field] > b[field]) return 1;
          if (a[field] < b[field]) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field] < b[field]) return 1;
          if (a[field] > b[field]) return -1;
          return 0;
        });
      }
    } else {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() < b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() > b[field].toLowerCase()) return -1;
          return 0;
        });
      }
    }

    return array;
  }

  function handlePage(
    page: number,
    leads = leadsFiltered,
    totalLeadsPerPage = leadsPerPage
  ) {
    setLeadsPerPage(totalLeadsPerPage);
    setPage(page);
    const leadsSorted = sortItems(leads);
    const indexOfLastLead = page * totalLeadsPerPage;
    const indexOfFirstLead = indexOfLastLead - totalLeadsPerPage;
    const currentLeads = leadsSorted.slice(indexOfFirstLead, indexOfLastLead);
    setLeads(currentLeads);
  }

  function handleLeadsPerPage(
    leadsPerPage: number,
    leads: Lead[] = leadsFiltered
  ) {
    setTotalPages(Math.ceil(leads.length / leadsPerPage));
    handlePage(1, leads, leadsPerPage);
  }

  return {
    leads,
    loading,
    leadsPerPage,
    page,
    totalPages,
    handleLeadsPerPage,
    handlePage,
    setLeadIdToEdit,
    leadIdToEdit,
    setRefreshLeads,
    handleSort,
    orderSorted,
    fieldSorted,
    dtf,
    products
  };
}
