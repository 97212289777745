import useAxios from "../../commonHooks/useAxios";
import { useEffect, useState, useContext } from "react";
import { Role } from "../Interfaces";
import { useNavigate } from "react-router-dom";
import { notificationsContext } from "../../context/notificationsContext";

export default function useRolesAndPermissions() {
  const [roles, setRoles] = useState<Role[]>([]);
  const [initialRoles, setInitialRoles] = useState<Role[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [rolesPerPage, setRolesPerPage] = useState<number>(5);
  const [roleIdToEdit, setRoleIdToEdit] = useState<number | null>(null);
  const [refreshRoles, setRefreshRoles] = useState<boolean>(true);
  const [createNewRole, setCreateNewRole] = useState<boolean>(false);
  const [roleToDelete, setRoleToDelete] = useState<Role | null>(null);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [fieldSorted, setFieldSorted] = useState("roles_id");
  const [orderSorted, setOrderSorted] = useState("asc");
  const navigate = useNavigate();
  const getData = useAxios();
  const { setNotification } = useContext(notificationsContext);

  useEffect(() => {
    async function fetchData() {
      const response: Role[] = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/roles`,
        headers: {
          accept: "*/*",
        },
      });
      const responseRoles = response;
      const rolesSorted = sortItems(responseRoles, "roles_id", "asc");
      setRoles(rolesSorted);
      setInitialRoles(rolesSorted);
      setTotalPages(Math.ceil(rolesSorted.length / rolesPerPage));
      handlePage(1, rolesSorted);
      setLoading(false);
      setRefreshRoles(false);
    }
    if (roles.length === 0 || refreshRoles) {
      fetchData();
    }
  }, [refreshRoles]); // eslint-disable-line

  useEffect(() => {
    const newRolesArray = sortItems(roles, fieldSorted, orderSorted);
    handlePage(1, newRolesArray);
  }, [fieldSorted, orderSorted]); // eslint-disable-line

  const handleSort = (field: string) => {
    let order = "asc";
    if (fieldSorted === field) {
      order = orderSorted === "asc" ? "desc" : "asc";
      setOrderSorted(order);
    } else {
      setFieldSorted(field);
      setOrderSorted(order);
    }
  };

  function sortItems(
    array: any = roles,
    field: string = fieldSorted,
    order: string = orderSorted
  ) {
    if (field === "roles_id") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field] > b[field]) return 1;
          if (a[field] < b[field]) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field] < b[field]) return 1;
          if (a[field] > b[field]) return -1;
          return 0;
        });
      }
    } else {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() < b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() > b[field].toLowerCase()) return -1;
          return 0;
        });
      }
    }

    return array;
  }

  function handleRolesPerPage(rolesPerPage: number) {
    setTotalPages(Math.ceil(initialRoles.length / rolesPerPage));
    handlePage(1, initialRoles, rolesPerPage);
  }

  function handlePage(
    page: number,
    roles = initialRoles,
    totalrolesPerPage = rolesPerPage
  ) {
    setRolesPerPage(totalrolesPerPage);
    setPage(page);
    const indexOfLastUser = page * totalrolesPerPage;
    const indexOfFirstUser = indexOfLastUser - totalrolesPerPage;
    const currentUsers = roles.slice(indexOfFirstUser, indexOfLastUser);
    setRoles(currentUsers);
  }

  function setRoleIdToDelete(role: Role | null) {
    setRoleToDelete(role);
  }

  async function deleteRole(role: Role) {
    setLoadingDelete(true);
    try {
      const response: { message: string } = await getData({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/roles/${role.roles_id}`,
        headers: {
          accept: "*/*",
        },
      });
      if (response.message === "Role deleted") {
        setNotification({
          theme: "success",
          titleNotification: "Rol Eliminado",
          contentNotification: "El rol se eliminó correctamente.",
          isVisible: true,
        });
        setRefreshRoles(true);
        setRoleToDelete(null);
        setLoadingDelete(false);
      } else {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: "No se pudo eliminar el rol.",
          isVisible: true,
        });
      }
      setRoleToDelete(null);
      setLoadingDelete(false);
    } catch (error) {
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: "No se pudo eliminar el rol.",
        isVisible: true,
      });
      setRoleToDelete(null);
      setLoadingDelete(false);
    }
  }

  return {
    roles,
    loading,
    navigate,
    handleRolesPerPage,
    page,
    totalPages,
    rolesPerPage,
    handlePage,
    setRoleIdToEdit,
    roleIdToEdit,
    setRefreshRoles,
    createNewRole,
    setCreateNewRole,
    setRoleIdToDelete,
    roleToDelete,
    setRoleToDelete,
    deleteRole,
    loadingDelete,
    handleSort,
    orderSorted,
    fieldSorted,
  };
}
