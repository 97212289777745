import { Outlet, useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { getAuth, signOut } from "firebase/auth";
import BackgroundLogin from "../src/assets/img/backgroundLogin.jpg";
import Logo from "../src/assets/img/logoDashboard.svg";
import EmptyUser from "../src/assets/img/emptyUser.png";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { useContext, useState } from "react";
import { userContext } from "./context/userContext";
import ImpersonateNotification from "./commonComponents/ImpersonateNotification";
import Footer from "./commonComponents/Footer";
import MenuIcon from "./assets/img/menuIcon.svg";
import CloseIcon from "./assets/img/closeIcon.svg";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import AssessmentIcon from "@mui/icons-material/Assessment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ClassIcon from "@mui/icons-material/Class";
import ArticleIcon from "@mui/icons-material/Article";
import ViewTimelineOutlinedIcon from "@mui/icons-material/ViewTimelineOutlined";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import GradingIcon from "@mui/icons-material/Grading";
import VerifiedIcon from "@mui/icons-material/Verified";
import FeedIcon from "@mui/icons-material/Feed";
import BadgeIcon from "@mui/icons-material/Badge";
export default function Home() {
  const navigation = useNavigate();
  const auth = getAuth();
  const path = window.location.pathname;
  const { userState, setUserState } = useContext(userContext);
  const [showMenuResponsive, setShowMenuResponsive] = useState<boolean>(false);
  const [showReportingMenu, setShowReportingMenu] = useState<boolean>(false);
  const [showDocumentsMenu, setShowDocumentsMenu] = useState<boolean>(false);
  const [showParameters, setShowParameters] = useState<boolean>(false);
  const [showAgents, setShowAgents] = useState<boolean>(false);
  const [showUsers, setShowUsers] = useState<boolean>(false);
  const [showLeads, setShowLeads] = useState<boolean>(false);
  const [showCertificated, setShowCertificated] = useState<boolean>(false);
  async function handleSignOut() {
    await signOut(auth);
    setUserState({
      user: undefined,
      originalUser: undefined,
    });
    window.localStorage.removeItem("accessToken");
    navigation("/login");
  }

  const itemsMenu = [
    {
      name: "Distribuidores",
      path: "/agents",
      icon: <PeopleAltIcon />,
      show:
        userState.user?.role.create_agent ||
        (userState.user?.role.manage_agents && userState.user?.roles_id !== 3),
      menu: true,
      onClick: () => {
        setShowAgents(!showAgents);
        setShowParameters(false);
        setShowReportingMenu(false);
        setShowDocumentsMenu(false);
        setShowUsers(false);
        setShowLeads(false);
        setShowCertificated(false);
      },
      active: showAgents,
      items: [
        {
          name:
            userState.user?.isEECC === true &&
            userState.user?.role.name !== "SuperAdmin" &&
            userState.user?.role.name !== "Admin"
              ? "Mis Distribuidores"
              : "Listado de Distribuidores",
          path: "/admin-agents",
          icon: <PeopleAltIcon />,
          show: userState.user?.role.create_agent,
          menu: false,
        },
        {
          name: "Autorización de Distribuidores",
          path: "/agent-requests",
          icon: <PeopleAltIcon />,
          show:
            userState.user?.role.manage_agents &&
            userState.user?.roles_id !== 3,
          menu: false,
        },
      ],
    },
    {
      name: "Mi Distribuidor",
      path: "/my-agent",
      icon: <PeopleAltIcon />,
      show:
        userState.user &&
        userState.user?.role.see_my_agent &&
        !userState.user?.hasMandatory,
      menu: false,
    },
    {
      name: "Leads",
      path: "/leads",
      icon: <PermContactCalendarIcon />,
      show: userState.user?.role.see_leads,
      menu: true,
      onClick: () => {
        setShowLeads(!showLeads);
        setShowDocumentsMenu(false);
        setShowReportingMenu(false);
        setShowParameters(false);
        setShowAgents(false);
        setShowUsers(false);
        setShowCertificated(false);
      },
      active: showLeads,
      items: [
        {
          name: "Administración de Leads",
          path: "/leads/listing",
          icon: <AllInboxIcon />,
          show:
            userState.user?.role.see_leads &&
            userState.user?.roles_id !== 5 &&
            userState.user?.roles_id !== 4,
          menu: false,
        },
        {
          name: "Mis Leads",
          path: "/leads/myleads",
          icon: <AllInboxIcon />,
          show:
            (userState.user?.roles_id === 5 ||
              userState.user?.roles_id === 4) &&
            userState?.user?.agent,
          menu: false,
        },
      ],
    },
    {
      name: "Reporting",
      path: "/reporting",
      icon: <AssessmentIcon />,
      show:
        userState.user?.role.name === "SuperAdmin" ||
        userState.user?.role.name === "Admin",
      menu: true,
      onClick: () => {
        setShowReportingMenu(!showReportingMenu);
        setShowDocumentsMenu(false);
        setShowParameters(false);
        setShowAgents(false);
        setShowUsers(false);
        setShowLeads(false);
        setShowCertificated(false);
      },
      active: showReportingMenu,
      items: [
        {
          name: "Mapa de Cobertura",
          path: "/reporting/zonesmap",
          icon: <CrisisAlertIcon />,
          show: true,
          menu: false,
        },
      ],
    },
    {
      name: "Certificación",
      path: "/certification",
      icon: <VerifiedIcon />,
      show:
        userState.user?.role.see_questionnaire ||
        userState.user?.role.edit_questionnaire ||
        userState.user?.role.see_order ||
        userState.user?.role.approve_order ||
        (userState.user?.role.review_own_order && userState?.user?.agent),
      menu: true,
      onClick: () => {
        setShowCertificated(!showCertificated);
        setShowReportingMenu(false);
        setShowDocumentsMenu(false);
        setShowParameters(false);
        setShowAgents(false);
        setShowUsers(false);
        setShowLeads(false);
      },
      active: showCertificated,
      items: [
        {
          name: "Cuestionarios",
          path: "/certification/questtionaires",
          icon: <FeedIcon />,
          show:
            userState.user?.role.see_questionnaire ||
            userState.user?.role.edit_questionnaire,
          menu: false,
        },
        {
          name: "Mis Ordenes",
          path: "/certification/orders",
          icon: <BadgeIcon />,
          show:
            userState.user?.role.see_order ||
            userState.user?.role.approve_order,
          menu: false,
        },
        {
          name: "Ordenes En Revisión",
          path: "/certification/orderssent",
          icon: <BadgeIcon />,
          show:
            userState.user?.role.see_order ||
            userState.user?.role.approve_order,
          menu: false,
        },
        {
          name: "Ordenes Cargadas",
          path: "/certification/allorders",
          icon: <BadgeIcon />,
          show:
            userState.user?.role.see_order ||
            userState.user?.role.approve_order,
          menu: false,
        },
        {
          name: "Mis Ordenes",
          path: "/certification/ownorders",
          icon: <BadgeIcon />,
          show: userState.user?.role.review_own_order && userState?.user?.agent,
          menu: false,
        },
      ],
    },
    {
      name: "Gestión de Documentos",
      path: "/documents",
      icon: <AssignmentIcon />,
      show:
        userState.user?.role.see_documents ||
        userState.user?.role.edit_documents ||
        userState.user?.roles_id === 5,
      menu: true,
      onClick: () => {
        setShowDocumentsMenu(!showDocumentsMenu);
        setShowReportingMenu(false);
        setShowParameters(false);
        setShowAgents(false);
        setShowUsers(false);
        setShowLeads(false);
        setShowCertificated(false);
      },
      active: showDocumentsMenu,
      items: [
        {
          name: "Documentos",
          path: "/documents/list",
          icon: <ArticleIcon />,
          show: userState.user?.role.see_documents,
          menu: false,
        },
        {
          name: "Estado de Vinculaciones",
          path: "/documents/status-all",
          icon: <GradingIcon />,
          show: userState.user?.role.edit_documents,
          menu: false,
        },
        {
          name: "Documentos Vinculados",
          path: "/documents/mydocuments",
          icon: <ArticleIcon />,
          show: userState.user?.roles_id === 5 && userState.user?.agent,
          menu: false,
        },
      ],
    },
    {
      name: "Usuarios",
      path: "/users",
      icon: <PersonPinIcon />,
      show: userState.user?.role.see_users || userState.user?.role.bind_agents,
      menu: true,
      onClick: () => {
        setShowUsers(!showUsers);
        setShowParameters(false);
        setShowReportingMenu(false);
        setShowDocumentsMenu(false);
        setShowAgents(false);
        setShowLeads(false);
        setShowCertificated(false);
      },
      active: showUsers,
      items: [
        {
          name: "Administrar Usuarios",
          path: "/admin-users",
          icon: <PersonPinIcon />,
          show: userState.user?.role.see_users,
          menu: false,
        },
        {
          name: "Herencia de Usuarios",
          path: "/inheritance",
          icon: <AssignmentIndIcon />,
          show: userState.user?.roles_id === 1,
          menu: false,
        },
        {
          name: "Vinculación de Usuarios",
          path: "/binding-requests",
          icon: <PersonPinIcon />,
          show: userState.user?.role.bind_agents,
          menu: false,
        },
      ],
    },

    {
      name: "Parametrizar",
      path: "/parameters",
      icon: <ViewTimelineOutlinedIcon />,
      show: userState.user?.roles_id === 1 || userState.user?.roles_id === 2,
      menu: true,
      onClick: () => {
        setShowParameters(!showParameters);
        setShowReportingMenu(false);
        setShowDocumentsMenu(false);
        setShowAgents(false);
        setShowUsers(false);
        setShowLeads(false);
        setShowCertificated(false);
      },
      active: showParameters,
      items: [
        {
          name: "Formulario de Leads",
          path: "/parameters/config-leads-form",
          icon: <PermContactCalendarIcon />,
          show: userState.user?.roles_id === 1,
          menu: false,
        },
        {
          name: "Permisos y Roles",
          path: "/role-permission",
          icon: <AssignmentIndIcon />,
          show: userState.user?.roles_id === 1,
          menu: false,
        },
        {
          name: "Tipos de Documentos",
          path: "/parameters/types",
          icon: <ClassIcon />,
          show: userState.user?.role.see_documents,
          menu: false,
        },
        {
          name: "Asesores Comerciales",
          path: "/parameters/advisors",
          icon: <ContactPhoneIcon />,
          show:
            userState.user?.roles_id === 1 || userState.user?.roles_id === 2,
          menu: false,
        },
      ],
    },
  ];

  return (
    <Container>
      <MenuMobileIconContainer
        onClick={() => setShowMenuResponsive(!showMenuResponsive)}
      >
        <img src={MenuIcon} alt="Menu Icon" />
      </MenuMobileIconContainer>
      <ResponsiveMenuContainer
        style={{ display: showMenuResponsive ? "flex" : "none" }}
      >
        <ResponsiveMenu>
          <CloseButtonMenu
            onClick={() => setShowMenuResponsive(!showMenuResponsive)}
          >
            <img src={CloseIcon} alt="Menu Icon" />
          </CloseButtonMenu>
          <BackgroundContainer></BackgroundContainer>
          <ContentContainer>
            <LogoContainer
              onClick={() => {
                navigation("/");
                setShowMenuResponsive(false);
              }}
            >
              <img src={Logo} alt="Logo YPF SOLAR" />
            </LogoContainer>
            <UserContainer>
              <Image src={EmptyUser} alt="User Icon" />
              <UserName>
                {userState.user?.first_name &&
                  userState.user?.first_name + " " + userState.user?.last_name}
              </UserName>
            </UserContainer>
            <OptionsContainer>
              {itemsMenu.map((item, index) => {
                if (item.menu && item.show) {
                  return (
                    <ItemsContainer
                      style={{
                        maxHeight: item.active
                          ? `${item.items.length * 46 + 46}px`
                          : "46px",
                      }}
                    >
                      <MainMenu
                        theme={
                          path.includes(item.path) ? "inactive" : "inactive"
                        }
                        onClick={item.onClick}
                      >
                        {item.icon}
                        <TextItem
                          color={
                            path.includes(item.path) ? "#FFFFFF" : "#FFFFFF"
                          }
                        >
                          {item.name}
                        </TextItem>
                      </MainMenu>
                      {item?.items?.map((subItem, index) => {
                        return subItem.show ? (
                          <SubMenu
                            theme={
                              path === subItem.path ? "active" : "inactive"
                            }
                            onClick={() => {
                              navigation(subItem.path);
                              setShowMenuResponsive(false);
                            }}
                          >
                            {subItem.icon}
                            <TextItem
                              color={
                                path === subItem.path ? "#128297" : "#FFFFFF"
                              }
                            >
                              {subItem.name}
                            </TextItem>
                          </SubMenu>
                        ) : null;
                      })}
                    </ItemsContainer>
                  );
                } else {
                  return item.show ? (
                    <Item
                      key={index}
                      theme={path === item.path ? "active" : "inactive"}
                      onClick={() => {
                        navigation(item.path);
                        setShowMenuResponsive(false);
                      }}
                    >
                      {item.icon}
                      <TextItem
                        color={path === item.path ? "#128297" : "#FFFFFF"}
                      >
                        {item.name}
                      </TextItem>
                    </Item>
                  ) : null;
                }
              })}

              <Item onClick={handleSignOut}>
                <ExitToAppIcon />
                <TextItem>Logout</TextItem>
              </Item>
            </OptionsContainer>
          </ContentContainer>
        </ResponsiveMenu>
      </ResponsiveMenuContainer>
      <SideBar>
        <BackgroundContainer></BackgroundContainer>
        <ContentContainer>
          <LogoContainer onClick={() => navigation("/")}>
            <img src={Logo} alt="Logo YPF SOLAR" />
          </LogoContainer>
          <UserContainer>
            <Image src={EmptyUser} alt="User Icon" />
            <UserName>
              {userState.user?.first_name &&
                userState.user?.first_name + " " + userState.user?.last_name}
            </UserName>
          </UserContainer>
          <OptionsContainer>
            {itemsMenu.map((item, index) => {
              if (item.menu && item.show) {
                return (
                  <ItemsContainer
                    style={{
                      maxHeight: item.active
                        ? `${item.items.length * 46 + 46}px`
                        : "46px",
                    }}
                  >
                    <MainMenu
                      theme={path.includes(item.path) ? "inactive" : "inactive"}
                      onClick={item.onClick}
                    >
                      {item.icon}
                      <TextItem
                        color={path.includes(item.path) ? "#FFFFFF" : "#FFFFFF"}
                      >
                        {item.name}
                      </TextItem>
                    </MainMenu>
                    {item?.items?.map((subItem, index) => {
                      return subItem.show ? (
                        <SubMenu
                          theme={path === subItem.path ? "active" : "inactive"}
                          onClick={() => {
                            navigation(subItem.path);
                          }}
                        >
                          {subItem.icon}
                          <TextItem
                            color={
                              path === subItem.path ? "#128297" : "#FFFFFF"
                            }
                          >
                            {subItem.name}
                          </TextItem>
                        </SubMenu>
                      ) : null;
                    })}
                  </ItemsContainer>
                );
              } else {
                return item.show ? (
                  <Item
                    key={index}
                    theme={path === item.path ? "active" : "inactive"}
                    onClick={() => {
                      navigation(item.path);
                    }}
                  >
                    {item.icon}
                    <TextItem
                      color={path === item.path ? "#128297" : "#FFFFFF"}
                    >
                      {item.name}
                    </TextItem>
                  </Item>
                ) : null;
              }
            })}
            <Item onClick={handleSignOut}>
              <ExitToAppIcon />
              <TextItem>Logout</TextItem>
            </Item>
          </OptionsContainer>
        </ContentContainer>
      </SideBar>
      <Main>
        {userState.originalUser?.email && (
          <ImpersonateNotification
            admin={userState.originalUser}
            userToImpersonate={userState.user}
          />
        )}
        <Outlet />
        <Footer />
      </Main>
    </Container>
  );
}
const Entrance = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }`;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  position: relative;
  @media (max-width: 768px) {
    width: 100vw;
    max-width: 100vw;
    overflow-x: hidden;
  }
`;
const MenuMobileIconContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    position: fixed;
    top: 25px;
    right: 13px;
    z-index: 30;
  }
`;
const CloseButtonMenu = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 3px;
  background: #e7e7e7;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 20px;
  cursor: pointer;
`;
const ResponsiveMenuContainer = styled.div`
  display: none;
  @media (max-width: 768px) {
    width: 100vw;
    min-width: 60px;
    max-height: 100vh;
    overflow-x: hidden;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    z-index: 30;
    background-color: #0000009f;
    -webkit-animation: ${Entrance} 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: ${Entrance} 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
`;
const ResponsiveMenu = styled.div`
  max-width: 230px;
  min-width: 60px;
  max-height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const SideBar = styled.div`
  width: 261px;
  min-width: 60px;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 6;
  @media (max-width: 768px) {
    display: none;
  }
`;
const BackgroundContainer = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundLogin});
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  z-index: -1;
  filter: brightness(0.6);
  @media (max-width: 768px) {
    width: 230px;
  }
`;
const ContentContainer = styled.div`
  width: 261px;
  position: absolute;
  overflow-y: auto;
  padding-left: 15px;
  padding-right: 16px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0px 10px;
    width: 230px;
    position: relative;
    overflow-y: auto;
  }
`;
const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 29px 15px 20px;
  border-bottom: 1px solid rgba(181, 181, 181, 0.3);
  cursor: pointer;
  @media (max-width: 768px) {
    width: 230px;
    padding: 15px 15px 15px;
  }
`;
const UserContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 19px 0px 18px;
  border-bottom: 1px solid rgba(181, 181, 181, 0.3);
  gap: 11.5px;
`;
const Image = styled.img`
  width: 34px;
  height: 34px;
  border-radius: 50%;
`;
const UserName = styled.p`
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const Main = styled.div`
  width: calc(100% - 261px);
  display: flex;
  flex-direction: column;
  margin-left: 261px;
  position: relative;
  justify-content: space-between;
  min-height: 100vh;
  /* overflow: hidden; */
  @media (max-width: 768px) {
    width: 100vw;
    margin-left: 0;
    padding: 0px;
  }
`;
const OptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
  margin-top: 14px;
`;
const Item = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  color: ${(props) => (props.theme === "active" ? "#128297" : "#FFFFFF")};
  padding: 16px 10px;
  background-color: ${(props) =>
    props.theme === "active" ? "#FFFFFF" : "rgba(255, 255, 255, 0)"};
  gap: 22px;
  border-radius: 3px;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background: ${(props) =>
      props.theme === "active" ? "#FFFFFF" : "rgba(255, 255, 255, 0.2)"};
  }
`;
const TextItem = styled.p`
  font-size: 14px;
  line-height: 14px;
  color: ${(props) => props.color};
  user-select: none;
`;
const ItemsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  max-height: 46px;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
`;
const MainMenu = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  color: ${(props) => (props.theme === "active" ? "#128297" : "#FFFFFF")};
  padding: 16px 10px;
  background-color: ${(props) =>
    props.theme === "active" ? "#fffffff0" : "rgba(255, 255, 255, 0)"};
  gap: 22px;
  border-radius: 3px 3px 0 0;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background: ${(props) =>
      props.theme === "active" ? "#fffffff0" : "rgba(255, 255, 255, 0.2)"};
  }
`;
const SubMenu = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  color: ${(props) => (props.theme === "active" ? "#128297" : "#FFFFFF")};
  padding: 16px 10px 16px 40px;
  background-color: ${(props) =>
    props.theme === "active" ? "#FFFFFF" : "rgba(255, 255, 255, 0)"};
  gap: 22px;
  border-radius: 0 0 3px 3px;
  cursor: pointer;
  transition: 0.4s;
  &:hover {
    background: ${(props) =>
      props.theme === "active" ? "#FFFFFF" : "rgba(255, 255, 255, 0.2)"};
  }
`;
