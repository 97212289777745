import { useEffect, useState, useContext } from "react";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";

export default function useDocumentTypesForm({
  typeIdToEdit,
  setTypeIdToEdit,
  setRefreshTypes,
  setCreateNewType,
}: {
  typeIdToEdit?: number;
  setTypeIdToEdit: (roleIdToEdit: number | null) => void;
  setRefreshTypes: (refresh: boolean) => void;
  setCreateNewType: (createNewRole: boolean) => void;
}) {
  const [documentType, setDocumentType] = useState<string>("");
  const [initialDocumentTypeName, setInitialDocumentTypeName] = useState<string>("");
  const [errors, setErrors] = useState<{
    documentType?: string;
  }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const axiosData = useAxios();
  const { setNotification } = useContext(notificationsContext);

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await axiosData({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}/documents/types/${typeIdToEdit}`,
          headers: {
            accept: "*/*",
          },
        });
        setDocumentType(response.name);
        setInitialDocumentTypeName(response.name);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    if (typeIdToEdit) {
      fetchData();
    }
  }, []); // eslint-disable-line

  function handleCancel() {
    setTypeIdToEdit(null);
    setCreateNewType(false);
  }

  async function handleSubmit() {
    const errors: any = {};
    if (!documentType) {
      setErrors((prev: any) => ({
        ...prev,
        documentType: "El nombre del tipo de documento es requerido.",
      }));
      errors.documentType = "El nombre del tipo de documento es requerido.";
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const data = {
        name: documentType,
      };
      if (typeIdToEdit) {
        try {
          const category = await axiosData({
            method: "PUT",
            url: `${process.env.REACT_APP_API_URL}/documents/types/${typeIdToEdit}`,
            headers: {
              accept: "*/*",
            },
            data,
          });
          if (category) {
            setLoading(false);
            setNotification({
              theme: "success",
              titleNotification: "Edición exitosa",
              contentNotification: "El tipo de documento se editó correctamente.",
              isVisible: true,
            });
            setRefreshTypes(true);
            setTypeIdToEdit(null);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      } else {
        try {
          const category = await axiosData({
            method: "POST",
            url: `${process.env.REACT_APP_API_URL}/documents/types`,
            headers: {
              accept: "*/*",
            },
            data,
          });
          if (category) {
            setLoading(false);
            setNotification({
              theme: "success",
              titleNotification: "Tipo de documento creado",
              contentNotification: "El tipo de documento se creó correctamente.",
              isVisible: true,
            });
            setRefreshTypes(true);
            setCreateNewType(false);
          }
        } catch (error) {
          console.log(error);
          setLoading(false);
        }
      }
    } else {
      if (typeIdToEdit) {
        setNotification({
          theme: "error",
          titleNotification: "Edición fallida",
          contentNotification: errors[Object.keys(errors)[0]],
          isVisible: true,
        });
      } else {
        setNotification({
          theme: "error",
          titleNotification: "Creación fallida",
          contentNotification: errors[Object.keys(errors)[0]],
          isVisible: true,
        });
      }
    }
    setTimeout(() => {
      setErrors({});
    }, 3000);
  }

  return {
    documentType,
    setDocumentType,
    errors,
    loading,
    handleSubmit,
    handleCancel,
    initialDocumentTypeName,
  };
}
