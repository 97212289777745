import { useEffect, useState } from "react";
import styled from "styled-components";
import ClassIcon from "../../assets/img/permContactIcon.svg";
import Button from "../../commonComponents/Button";
import { CircularProgress } from "@mui/material";
import useAxios from "../../commonHooks/useAxios";
import CheckIcon from "../../assets/img/checkPermissionIcon.svg";

export default function ConfigLeadsForm() {
  const [imageLogo, setImageLogo] = useState<any>(null);
  const [previewImage, setPreviewImage] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [search_by_distance, setSearch_by_distance] = useState<boolean>(false);
  const getData = useAxios();

  useEffect(() => {
    async function fetchData() {
      const response: any = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/parameters/agents-by-distance`,
        headers: {
          accept: "*/*",
        },
      });
      setSearch_by_distance(response.value);
    }
    fetchData();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (imageLogo) {
      const newImage = new File([imageLogo], "imageLead", {
        type: imageLogo.type,
      });
      setImageLogo(newImage);
    }
  }, [imageLogo]);

  function changeLogo(e: any) {
    // cange file name
    setImageLogo(e.target.files[0]);

    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
  }

  async function saveChanges() {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", imageLogo);
    try {
      await fetch(`${process.env.REACT_APP_API_URL}/uploadLeadImage`, {
        method: "POST",
        body: formData,
      });
      await getData({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/parameters/agents-by-distance`,
        data: {
          value: search_by_distance,
        },
        headers: {
          accept: "*/*",
        },
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  return (
    <Container>
      <Title>Formulario de Leads</Title>
      <MainContainer>
        <TopContainer>
          <IconContainer>
            <img src={ClassIcon} alt="DocumentType Icon" />
          </IconContainer>
        </TopContainer>
        {!loading ? (
          <>
            <Subtitle>Imagen que aparecerá en el formulario de leads</Subtitle>
            <LogoContainer>
              <Logo
                src={
                  previewImage ||
                  `${process.env.REACT_APP_API_URL}/getLeadImage/`
                }
                alt="logo"
              />
              <LabelLogo htmlFor="files">Cambiar Imagen</LabelLogo>
              <InputLogo
                id="files"
                type="file"
                accept=".jpeg,.jpg,.png,.webp"
                onChange={changeLogo}
              />
            </LogoContainer>
            <CheckboxContainer>
              <CheckboxText>Rango de distribuidores en formulario de leads</CheckboxText>
              <Checkbox
                onClick={() => setSearch_by_distance(!search_by_distance)}
              >
                {search_by_distance && <img src={CheckIcon} alt="check icon" />}
              </Checkbox>
            </CheckboxContainer>
            <ButtonContainer>
              <Button type="primary" onClick={saveChanges}>
                Guardar
              </Button>
            </ButtonContainer>
          </>
        ) : (
          <LoadingContainer>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </LoadingContainer>
        )}
      </MainContainer>
    </Container>
  );
}

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: flex-end;
  width: 100%;
  padding-right: 20px;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    justify-content: center;
    align-items: center;
    padding-right: 0px;
    margin-bottom: 0px;
  }
`;

const Checkbox = styled.div`
  width: 24px;
  height: 24px;
  border: 1px solid #424242;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 400px;
  gap: 20px;
  margin-bottom: 40px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const CheckboxText = styled.p`
  font-family: "D-DIN-PRO-Medium";
  padding-left: 20px;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  color: #128297;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 14px;
    padding-left: 0px;
  }
`;
const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 40px;
  @media (max-width: 768px) {
    padding: 0px 40px 30px;
  }
`;
const Subtitle = styled.p`
  font-family: "D-DIN-PRO-Medium";
  font-size: 18px;
  line-height: 18px;
  color: #128297;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 16px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: 100%;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }
`;
const LabelLogo = styled.label`
  background-color: #128297;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-family: "D-DIN-PRO-Medium";
  font-size: 15px;
  line-height: 15px;
  padding: 20px 30px;
  cursor: pointer;
  user-select: none;
  transition: all 0.3s;
  right: 0;
  top: 0px;
  :focus {
    outline: none;
  }
  @media (max-width: 768px) {
    padding: 15px 15px;
    font-size: 13px;
    line-height: 13px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    right: auto;
    top: auto;
  }
`;
const InputLogo = styled.input`
  display: none;
`;
const Logo = styled.img`
  width: auto;
  max-height: 120px;
  object-fit: contain;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

const Container = styled.div`
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  margin-top: 38px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
  }
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 100px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 60px 10px 25px 10px;
  margin-bottom: 60px;
  position: relative;
  @media (max-width: 768px) {
    margin-top: 40px;
    padding: 30px 10px 25px 10px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: -17px;
    left: 10px;
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
