import styled from "styled-components";
import useAgentForm from "../hooks/useAgentForm";
import TaxDataForm from "../components/TaxDataForm";
import OperationalForm from "../components/OperationalForm";
import AdminAgentForm from "../components/AdminAgentForm";
import CoverageZone from "./../components/CoverageZone";
import { useContext } from "react";
import { agentFormContext } from "../../context/agentFormContext";
import { CircularProgress } from "@mui/material";

export default function AgentForm() {
  const { user, agent, currentStep, totalSteps, submitForm } = useAgentForm();
  const { loadingSubmit } = useContext(agentFormContext);

  return (
    <>
      {loadingSubmit ? (
        <Container style={{ justifyContent: "center" }}>
          <Loading>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </Loading>
        </Container>
      ) : (
        <Container>
          <Title>
            {agent?.agents_id ? (
              <>
                Editando distribuidor - {agent.agent_name} - Paso {currentStep} de{" "}
                {totalSteps}
              </>
            ) : (
              <>
                Creación de Distribuidores - Paso {currentStep} de {totalSteps}
              </>
            )}
          </Title>

          <SliderContainer style={{ left: `-${(currentStep - 1) * 100}%` }}>
            <ItemContainer>
              <TaxDataForm submitForm={submitForm}  />
            </ItemContainer>

            {(user?.roles_id === 1 ||
              user?.roles_id === 2 ||
              user?.roles_id === 3) && (
              <ItemContainer>
                <AdminAgentForm submitForm={submitForm} />
              </ItemContainer>
            )}
            <ItemContainer>
              <OperationalForm submitForm={submitForm} />
            </ItemContainer>
            {user?.role?.coverage_zone && (
              <ItemContainer>
                <CoverageZone submitForm={submitForm} />
              </ItemContainer>
            )}
          </SliderContainer>
        </Container>
      )}
    </>
  );
}
const ItemContainer = styled.div`
  min-width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;
const SliderContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  left: 0%;
  transition: left 1s;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: calc(100vh - 66px);
  width: 100%;
  overflow-x: hidden;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  margin-top: 38px;
  width: 100%;
  text-align: start;
  padding: 0 20px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
    padding: 0 10px;
  }
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
