import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";
import { userContext } from "../../context/userContext";

export default function useAgentAuthorization({
  agentIdToAuthorize,
  setAgentIdToAuthorize,
  setRefreshAgents,
}: {
  agentIdToAuthorize: string | null;
  setAgentIdToAuthorize: (agentId: string | null) => void;
  setRefreshAgents: (refresh: boolean) => void;
}) {
  const [loading, setLoading] = useState<boolean>(false);
  const [dateOfRequest, setDateOfRequest] = useState<string>("");
  const [agentName, setAgentName] = useState<string>("");
  const [tradeName, setTradeName] = useState<string>("");
  const [showDropDownRole, setShowDropDownRole] = useState<boolean>(false);
  const [status, setStatus] = useState<string | null>(null);
  const axiosData = useAxios();
  const [errorStatus, setErrorStatus] = useState<boolean>(false);
  const dtf = new Intl.DateTimeFormat("es-ES", {
    calendar: "gregory",
    numberingSystem: "latn",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
  const [height, setHeight] = useState<number>(window.innerWidth < 768 ? 34 : 55);
  const { setNotification } = useContext(notificationsContext);
  const [options, setOptions] = useState<string[]>([]);
  const {
    userState: { user },
  } = useContext(userContext);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await axiosData({
          method: "GET",
          url: `${process.env.REACT_APP_API_URL}/agents/${agentIdToAuthorize}`,
          headers: {
            accept: "*/*",
          },
        });
        setDateOfRequest(dtf.format(new Date(response.created_at)));
        setAgentName(response.agent_name);
        setTradeName(response.trade_name);
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
    if (user) {
      let options = [];
      user.role?.agent_to_activated && options.push("autorizado");
      user.role?.agent_to_inactivated && options.push("denegado");
      user.role?.agent_to_suspended && options.push("suspendido");
      setOptions(options);
    }
    if (window.innerWidth < 768) {
      setHeight(34);
    }
    window.addEventListener("resize", (e: any) => {
      if (e.target.window.innerWidth < 768) {
        setHeight(34);
      } else {
        setHeight(55);
      }
    });
    return () => {
      window.removeEventListener("resize", (e: any) => {
        if (e.target.window.innerWidth < 768) {
          setHeight(34);
        } else {
          setHeight(55);
        }
      });
    };
  }, []); // eslint-disable-line

  async function handleSubmit() {
    const errors: any = {};
    if (!status) {
      setErrorStatus(true);
      errors.status = "El tipo de operación es requerido.";
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      const data = {
        agents_id: agentIdToAuthorize,
        status,
        admin_email: user?.email,
      };

      try {
        const response = await axiosData({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL}/authorization/`,
          headers: {
            accept: "*/*",
          },
          data,
        });
        if (response.agents_id) {
          setNotification({
            theme: "success",
            titleNotification: "Autorización exitosa",
            contentNotification: `El distribuidor cambió su estado a ${status}.`,
            isVisible: true,
          });
          setLoading(false);
          setAgentIdToAuthorize(null);
          setRefreshAgents(true);
          if (id) {
            navigate(`/agent-requests`);
          }
        } else {
          setNotification({
            theme: "error",
            titleNotification: "Error",
            contentNotification: `El distribuidor no se pudo autorizar.`,
            isVisible: true,
          });
          setLoading(false);
          if (id) {
            navigate(`/agent-requests`);
          }
        }
      } catch (error) {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: `El distribuidor no se pudo autorizar.`,
          isVisible: true,
        });
        setLoading(false);
        if (id) {
          navigate(`/agent-requests`);
        }
      }
    } else {
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: `El tipo de operación es requerido.`,
        isVisible: true,
      });
    }
    setTimeout(() => {
      setErrorStatus(false);
    }, 3000);
  }

  return {
    loading,
    dateOfRequest,
    agentName,
    tradeName,
    showDropDownRole,
    setShowDropDownRole,
    errorStatus,
    status,
    setStatus,
    handleSubmit,
    options,
    height,
  };
}
