import { useState } from "react";
import styled from "styled-components";
import ArrowDropDownIcon from "../../assets/img/arrowDropDownIcon.svg";
export default function Combobox({
  label,
  value,
  setValue,
  disabled,
  options,
  height = 32,
  width = 280,
  values = false,
  index,
  style
}: {
  label: string;
  value: string;
  setValue: (value: string) => void;
  disabled?: boolean;
  options: any;
  height?: number;
  width?: number;
  values?: boolean;
  index: number;
  style?: any;
}) {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  return (
    <FieldContainer width={width}>
      <DropDownField
        onClick={() => setShowDropdown(disabled ? false : !showDropdown)}
        style={{
          height: showDropdown ? `${height * 4 + 18}px` : `${height}px`,
          width: `${width}px`,
          zIndex: index,
        }}
      >
        {!value && (
          <CurrentValueDropDown
            style={{
              paddingTop: "0px",
              fontSize: "13px",
              minHeight: `${height}px`,
            }}
          >
            {label}
          </CurrentValueDropDown>
        )}
        {!values && options.map(
          (d: any, index: number) =>
            value === d && (
              <>
                <DropDownLabel key={index}>{label}</DropDownLabel>
                <CurrentValueDropDown
                  style={{
                    paddingTop: "0px",
                    fontSize: "13px",
                    minHeight: `${height}px`,
                  }}
                  key={index + 10}
                >
                  {d}
                </CurrentValueDropDown>
              </>
            )
        )}
        {values && options.map(
          (d: any, index: number) =>
            value === d.value && (
              <>
                <DropDownLabel key={index}>{label}</DropDownLabel>
                <CurrentValueDropDown
                  style={{
                    paddingTop: "0px",
                    fontSize: "13px",
                    minHeight: `${height}px`,
                  }}
                  key={index + 10}
                >
                  {d.label}
                </CurrentValueDropDown>
              </>
            )
        )}
        <DropDownItems
          style={{
            overflowY: "scroll",
          }}
        >
          {!values &&
            options.map((d: any, index: number) => (
              <Option key={index} onClick={() => setValue(d)}>
                {d}
              </Option>
            ))}
          {values &&
            options.map((option: any, index: number) => (
              <Option key={index} onClick={() => setValue(option.value)}>
                {option.label}
              </Option>
            ))}
        </DropDownItems>

        <IconConatiner
          style={{
            transform: showDropdown ? "rotateZ(-180deg)" : "rotateZ(0deg)",
            top: `${height / 2 - 10}px`,
          }}
        >
          <img src={ArrowDropDownIcon} alt="arrow drop down icon" />
        </IconConatiner>
      </DropDownField>
    </FieldContainer>
  );
}

const FieldContainer = styled.div<{ width?: number }>`
  position: relative;
  width: ${(props) => props.width}px;
  min-height: 32px;
`;

const DropDownField = styled.div`
  border: 1px solid #424242;
  border-radius: 5px;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
  position: absolute;
  color: #128297;
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 20px 18px 8px;
  overflow-y: hidden;
  background-color: #fff;
  z-index: 6;
  transition: all 0.4s;
  max-height: 467px;
  @media (max-width: 768px) {
    padding: 0px 10px 18px 10px;
    width: 100%;
  }
`;
const DropDownItems = styled.div`
  cursor: pointer;
  color: #128297;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: hidden;
  z-index: 6;
  transition: all 0.4s;
  max-height: 403px;
  width: 100%;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;
const IconConatiner = styled.div`
  position: absolute;
  right: 16px;
  top: 8px;
  transform: rotateZ("0deg");
  transition: all 0.3s;
`;
const DropDownLabel = styled.p`
    font-size: 8px;
    line-height: 8px;
  font-weight: 400;
  color: #424242;
  position: absolute;
  top: 3px;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
    top: 3px;
  }
`;
const CurrentValueDropDown = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: #128297;
  cursor: pointer;
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 10px;
  @media (max-width: 768px) {
    font-family: "D-DIN-PRO";
    min-height: 34px;
    font-size: 13px;
    line-height: 13px;
    padding-top: 10px;
  }
`;
const Option = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #128297;
  cursor: pointer;
  :hover {
    background-color: #f5f5f5;
  }
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 0 0 20px;
  :nth-last-child() {
    margin-bottom: 18px;
  }
  text-transform: capitalize;
  @media (max-width: 768px) {
    min-height: 34px;
  }
`;
