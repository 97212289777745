import styled from "styled-components";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Agent } from "../agents/Interfaces";
import ExcelIcon from "../assets/img/excelIcon.svg";

export default function ExcelExport({
  agents,
  fileName,
  closeExport,
  setLoadingExport,
}: any) {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  interface AgentToExport {
    "ID DISTRIBUIDOR": string;
    "RAZÓN SOCIAL": string;
    "NOMBRE COMERCIAL": string;
    LOCALIDAD: string;
    PROVINCIA: string;
    ESTADO: string;
    AUTORIZACIÓN: string;
    EECC: string;
    TIPO: string;
    "INSTALADOR VIP": string;
  }

  function setExportAgents(agents: Agent[]) {
    const newAgentsToExport: AgentToExport[] = [];
    agents.forEach((agent) => {
      const newAgent: AgentToExport = {
        "ID DISTRIBUIDOR": agent.agents_id,
        "RAZÓN SOCIAL": agent.trade_name,
        "NOMBRE COMERCIAL": agent.agent_name,
        LOCALIDAD: agent.city,
        PROVINCIA: agent.province,
        ESTADO: Number(agent.active) === 1 ? "Activo" : "Inactivo",
        AUTORIZACIÓN: agent.status,
        EECC: agent.EECC
          ? agent.EECC?.first_name + " " + agent.EECC?.last_name
          : "Sin EECC asociado",
        TIPO: agent.category ? agent.category?.name : "Sin categoría",
        "INSTALADOR VIP": agent.instalator ? "Si" : "No",
      };
      newAgentsToExport.push(newAgent);
    });
    return newAgentsToExport;
  }

  const exportToExcel = async () => {
    setLoadingExport(true);
    const newAgentsToExport = setExportAgents(agents);
    const ws = XLSX.utils.json_to_sheet(newAgentsToExport);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    closeExport();
    setLoadingExport(false);
  };

  return (
    <ExportIcon onClick={exportToExcel}>
      <img src={ExcelIcon} alt="export pdf" />
    </ExportIcon>
  );
}

const ExportIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  cursor: pointer;
  img {
    max-width: 80px;
    max-height: 80px;
  }
`;
