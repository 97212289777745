import { GoogleMap } from "@react-google-maps/api";
import styled from "styled-components";
import CreateAgentIcon from "../../assets/img/createAgentIcon.svg";
import Button from "../../commonComponents/Button";
import { agentFormContext } from "../../context/agentFormContext";
import { useContext } from "react";
import useOperationalForm from "./../hooks/useOperationalForm";

const containerStyle = {
  width: "100%",
  height: "190px",
};

const center = {
  lat: -34.6,
  lng: -58.43,
};

const mapOptions = {
  center: center,
  zoom: 10,
  mapTypeId: "roadmap",
  disableDefaultUI: false,
  styles: [
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#444444",
        },
      ],
    },
    // {
    //   featureType: "landscape",
    //   elementType: "all",
    //   stylers: [
    //     {
    //       color: "#f2f2f2",
    //     },
    //   ],
    // },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    // {
    //   featureType: "road",
    //   elementType: "all",
    //   stylers: [
    //     {
    //       saturation: -100,
    //       lightness: 45,
    //     },
    //   ],
    // },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    // {
    //   featureType: "road.arterial",
    //   elementType: "labels.icon",
    //   stylers: [
    //     {
    //       visibility: "off",
    //     },
    //   ],
    // },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          color: "#46bcec",
          visibility: "on",
        },
      ],
    },
  ],
};

export default function OperationalForm({
  submitForm,
}: {
  submitForm: (errors: {}) => void;
}) {
  const {
    errors,
    operationalStreet,
    setOperationalStreet,
    operationalAdressNumber,
    setOperationalAdressNumber,
    operationalCity,
    setOperationalCity,
    operationalProvince,
    setOperationalProvince,
    confirmUbication,
    setCurrentStep,
    currentStep,
    totalSteps,
  } = useContext(agentFormContext);
  const {
    isLoaded,
    onLoad,
    onUnmount,
    handleSubmitOperationalForm,
    searchMap,
    isComplete,
    handleCancel,
    agentId,
    handleSubmit,
  } = useOperationalForm({ submitForm });
  return (
    <>
      <MainContainer>
        <TopContainer>
          <IconContainer>
            <img src={CreateAgentIcon} alt="Create Agent Icon" />
          </IconContainer>
        </TopContainer>
        <ColumnsContainer>
          <Column>
            <FieldsContainer>
              <TitleSection>Datos Operativos</TitleSection>
              <FieldContainer>
                <Input
                  color={errors.operationalStreet ? "#FF3D00" : undefined}
                  required
                  value={operationalStreet}
                  onChange={({ target: { value } }) =>
                    setOperationalStreet(value)
                  }
                />
                <Label color={errors.operationalStreet ? "#FF3D00" : undefined}>
                  Calle
                </Label>
              </FieldContainer>
              <FieldContainer>
                <Input
                  color={errors.operationalAdressNumber ? "#FF3D00" : undefined}
                  required
                  value={operationalAdressNumber}
                  onChange={({ target: { value } }) =>
                    setOperationalAdressNumber(value)
                  }
                />
                <Label
                  color={errors.operationalAdressNumber ? "#FF3D00" : undefined}
                >
                  Altura
                </Label>
              </FieldContainer>
              <FieldContainer>
                <Input
                  color={errors.operationalCity ? "#FF3D00" : undefined}
                  required
                  value={operationalCity}
                  onChange={({ target: { value } }) =>
                    setOperationalCity(value)
                  }
                />
                <Label color={errors.operationalCity ? "#FF3D00" : undefined}>
                  Localidad
                </Label>
              </FieldContainer>
              <FieldContainer>
                <Input
                  color={errors.operationalProvince ? "#FF3D00" : undefined}
                  required
                  value={operationalProvince}
                  onChange={({ target: { value } }) =>
                    setOperationalProvince(value)
                  }
                />
                <Label
                  color={errors.operationalProvince ? "#FF3D00" : undefined}
                >
                  Provincia
                </Label>
              </FieldContainer>
              <ButtonContainerSearch style={{ justifyContent: "flex-end" }}>
                <Button type="primary" onClick={() => searchMap()}>
                  BUSCAR
                </Button>
              </ButtonContainerSearch>
              <ButtonContainerMobile style={{ justifyContent: "flex-end" }}>
                <ButtonMobile onClick={() => searchMap()}>BUSCAR</ButtonMobile>
              </ButtonContainerMobile>
            </FieldsContainer>
          </Column>
          <Column>
            <ColumnContainer>
              <TitleSection>Ubicación</TitleSection>
              <MapContainer>
                {isLoaded && (
                  <GoogleMap
                    options={mapOptions}
                    mapContainerStyle={containerStyle}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                  ></GoogleMap>
                )}
              </MapContainer>
              <UbicationText>¿Es esta tu ubicación?</UbicationText>
              <Text>
                Necesitamos confirmar tu dirección para una mejor experiencia.
              </Text>
              <UbicationText
                style={{ marginBottom: "28px", minHeight: "12px" }}
              >
                {confirmUbication}
              </UbicationText>
              {currentStep !== totalSteps ? (
                <ButtonContainer style={{ justifyContent: "center" }}>
                  <Button
                    type={isComplete ? "primary" : "disabled"}
                    onClick={() => handleSubmitOperationalForm()}
                  >
                    CONFIRMAR UBICACIÓN
                  </Button>
                </ButtonContainer>
              ) : (
                <ConfirmContainer>
                  <RequiredText>* Campos requeridos</RequiredText>
                  <TermsText>
                    Al aceptar y continuar estás aceptando las Condiciones de la{" "}
                    <Span>Carta Oferta</Span> y los{" "}
                    <Span>Términos de Privacidad</Span>
                  </TermsText>
                  <Button
                    type={isComplete ? "primary" : "disabled"}
                    onClick={() => handleSubmitOperationalForm()}
                  >
                    ACEPTAR Y CONTINUAR
                  </Button>
                </ConfirmContainer>
              )}
              {agentId && (
                <ButtonContainerSubmitMobile>
                  <Button type={"secondary"} onClick={() => handleCancel()}>
                    CANCELAR
                  </Button>
                  <Button type={"primary"} onClick={() => handleSubmit()}>
                    GUARDAR
                  </Button>
                </ButtonContainerSubmitMobile>
              )}
            </ColumnContainer>
          </Column>
        </ColumnsContainer>
      </MainContainer>
      <BottomContainer>
        {agentId && (
          <ButtonContainerSubmit>
            <Button type={"secondary"} onClick={() => handleCancel()}>
              CANCELAR
            </Button>
            <Button type={"primary"} onClick={() => handleSubmit()}>
              GUARDAR
            </Button>
          </ButtonContainerSubmit>
        )}
        <ButtonsContainer>
          <Button
            type={"primary"}
            onClick={() => setCurrentStep(currentStep === 3 ? 2 : 1)}
          >
            ATRAS
          </Button>
          {currentStep !== totalSteps && (
            <Button
              type={isComplete ? "primary" : "disabled"}
              onClick={() => handleSubmitOperationalForm()}
            >
              SIGUIENTE
            </Button>
          )}
        </ButtonsContainer>
      </BottomContainer>
    </>
  );
}
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  gap: 20px;
  padding: 0px 20px;
  @media (max-width: 768px) {
    @media (max-width: 768px) {
      padding: 0px 10px;
      align-items: center;
      justify-content: center;
    }
  }
`;
const ButtonContainerSubmitMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 20px;
    margin-top: 20px;
  }
`;
const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #424242;
  margin-top: 16px;
`;
const UbicationText = styled.p`
  font-family: "D-DIN-PRO-Bold";
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #128297;
  margin-top: 16px;
`;
const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const MapContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`;
const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const ButtonContainerSearch = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: 768px) {
    display: none;
  }
`;
const ButtonContainerSubmit = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
  padding: 0px 20px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const ButtonContainerMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
`;

const ButtonMobile = styled.button`
  width: 200px;
  height: 55px;
  padding: 20px;
  background: #128297;
  border-radius: 5px;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  cursor: pointer;
  &:focus {
    outline: 1px solid #29707d;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 43px;
    padding: 11px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const RequiredText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #424242;
`;
const TermsText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #424242;
  margin-top: 40px;
  margin-bottom: 20px;
`;
const Span = styled.span`
  font-family: "D-DIN-PRO-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  color: #128297;
`;
const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
  @media (max-width: 768px) {
    gap: 10px;
  }
`;
const FieldContainer = styled.div`
  position: relative;
  height: 55px;
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
  }
`;
const Input = styled.input`
  width: 400px;
  height: 55px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const Label = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 11.5px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      line-height: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
const TitleSection = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #3c4858;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
  }
`;
const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;
const Column = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
  @media (max-width: 768px) {
    width: 100%;
    gap: 10px;
    &:nth-child(2) {
      margin-top: 20px;
    }
  }
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 20px);
  max-width: 1140px;
  margin-top: 50px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 50px 50px 50px 50px;
  position: relative;
  margin-bottom: 30px;
  @media (max-width: 768px) {
    width: calc(100% - 20px);
    padding: 40px 10px 20px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: -17px;
    left: 10px;
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
