import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import Modal from "../../commonComponents/Modal";
import Button from "../../commonComponents/Button";
import useCertificationForm from "../hooks/useCertificationForm";
import Combobox from "../../questionnaires/components/Combobox";
import ModalSelectAgent from "./ModalSelectAgent";
import TrashIcon from "../../assets/img/trashIcon.svg";
import AddIcon from "../../assets/img/addIcon.svg";

export default function CertificationForm({
  setCreateNewCertificationOrder,
  setRefreshCertificationOrders,
}: {
  setCreateNewCertificationOrder: (refresh: boolean) => void;
  setRefreshCertificationOrders: (create: boolean) => void;
}) {

  const {
    loading,
    type,
    setType,
    handleSubmit,
    errors,
    questionnaires,
    newCertificaationType,
    setNewCertificaationType,
    agentSelected,
    setAgentSelected,
    inputAgentSelect,
    setInputAgentSelect
  } = useCertificationForm({
    setCreateNewCertificationOrder,
    setRefreshCertificationOrders,
  });
  return (
    <Modal type="document" title={"Nuevo Certificado"}>
      <Container>
        {loading ? (
          <LoadingContainer>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </LoadingContainer>
        ) : (
          <>
            <TopContainer>
              <SubtitleSection>Asignación de Distribuidor</SubtitleSection>
              <FieldContainer
                style={{ marginTop: "0px", flexDirection: "row" }}
              >
                <UserInput>
                  <UserData>
                    {agentSelected.agents_id === null ? (
                      "Ingrese Distribuidor"
                    ) : (
                      <SpanUser>{agentSelected.agent_name}</SpanUser>
                    )}
                  </UserData>
                </UserInput>
                {agentSelected.agents_id !== null ? (
                  <ButtonsContainer2
                    onClick={() =>
                      setAgentSelected({
                        agents_id: null,
                        agent_name: "",
                        trade_name: "",
                      })
                    }
                  >
                    <ButtonItem>
                      <img src={TrashIcon} alt="trash icon" />
                    </ButtonItem>
                    <TooltipContainer>
                      <TooltipText>Remover Distribuidor</TooltipText>
                    </TooltipContainer>
                  </ButtonsContainer2>
                ) : (
                  <ButtonsContainer2
                    style={{ width: "50px", marginTop: "0px" }}
                    onClick={() => setInputAgentSelect(1)}
                  >
                    <ButtonItem>
                      <img src={AddIcon} alt="add icon" />
                    </ButtonItem>
                    <TooltipContainer>
                      <TooltipText>Asignar Distribuidor</TooltipText>
                    </TooltipContainer>
                  </ButtonsContainer2>
                )}
              </FieldContainer>
              <FieldsContainer>
                <FieldContainer
                  style={{
                    zIndex: 3,
                  }}
                >
                  <Combobox
                    options={questionnaires}
                    label="Tipo de certificado"
                    value={newCertificaationType}
                    setValue={setNewCertificaationType}
                    width={335}
                    height={55}
                    values={true}
                    index={10}
                  />
                </FieldContainer>
              </FieldsContainer>
            </TopContainer>
            <ButtonsContainer>
              <Button
                type="secondary"
                onClick={() => {
                  setCreateNewCertificationOrder(false);
                  setRefreshCertificationOrders(false);
                }}
              >
                Cancelar
              </Button>
              <Button type="primary" onClick={() => handleSubmit()}>
                Aceptar
              </Button>
            </ButtonsContainer>
          </>
        )}
        {inputAgentSelect !== null && (
          <ModalSelectAgent
            agentSelected={agentSelected}
            inputAgentSelect={inputAgentSelect}
            setInputAgentSelect={setInputAgentSelect}
            setAgentSelected={setAgentSelected}
          />
        )}
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  width: 415px;
  height: 510px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 73px 40px 50px 40px;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    padding: 20px 10px 50px 10px;
    height: auto;
  }
`;
const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  width: 335px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  margin-top: 50px;
`;
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;
const Input = styled.input`
  width: 335px;
  height: 55px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const Label = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 11.5px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      line-height: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
const SubtitleSection = styled.p`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 21px;
  color: #3c4858;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 14px;
    margin: 10px 0 20px;
  }
`;
const SpanUser = styled.span`
  font-family: "D-DIN-PRO-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 12px;
  text-align: center;
  color: #128297;
`;
const UserData = styled.p`
  font-size: 15px;
  line-height: 15px;
  color: #128297;
  position: absolute;
  top: 21px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 768px) {
    top: 10px;
    max-width: 90%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const UserInput = styled.div`
  width: 250px;
  height: 55px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
const ButtonItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
const TooltipContainer = styled.div`
  background: #424242;
  position: absolute;
  height: 20px;
  width: 88px;
  border-radius: 3px;
  display: flex;
  top: -30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 5;
  left: calc(50% - 44px);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  ::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 50%;
    margin-left: -9px;
    width: 15px;
    height: 9px;
    background: #424242;
    transform: rotate(45deg);
  }
  ${ButtonItem}:hover ~ & {
    opacity: 1;
  }
`;
const TooltipText = styled.p`
  font-size: 10px;
  line-height: 10px;
  text-align: center;
  color: #ffffff;
  z-index: 10;
`;
const ButtonsContainer2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  position: absolute;
  right: 0px;
  top: 12px;
  background: #128297;
  border-radius: 5px;
  cursor: pointer;
  @media (max-width: 768px) {
    top: 2px;
    width: 30px;
    height: 30px;
  }
`;
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  @media (max-width: 768px) {
    gap: 10px;
  }
`;