import { Group, Question } from "../Interfaces";
import LineInput from "./LineInput";
import MultiLineInput from "./MultilineInput";
import NumericInput from "./NumericInput";
import LinkInput from "./LinkInput";
import DatePicker from "./DatePicker";
import Checkbox from "./Checkbox";
import Combobox from "./Combobox";
import PdfInput from "./PdfInput";
import ImageInput from "./ImageInput";
import styled from "styled-components";
import Observations from "./Observations";
import { useContext } from "react";
import { userContext } from "../../context/userContext";
import { Orders } from "../../orders/Interfaces";

export default function TypeInput({
  question,
  disabled,
  groups,
  setGroups,
  groupId,
  order,
  setNewObservation,
}: {
  order?: Orders;
  question: Question;
  disabled?: boolean;
  groups?: Group[];
  setGroups?: React.Dispatch<React.SetStateAction<Group[]>>;
  groupId?: number;
  setNewObservation?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {
    userState: { user },
  } = useContext(userContext);
  if (question.type === "line") {
    return (
      <>
        <LineInput
          label={"Escribe aquí" || "Question"}
          value={question.value || ""}
          setValue={(e) => {
            const newValue = e;
            const newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            const questions = newGroups?.[groupIndex]?.questions;
            if (questions) {
              questions[questionIndex!].value = newValue;
            }
            newGroups[groupIndex].questions = questions;
            setGroups!(newGroups);
          }}
          disabled={disabled}
        />
        <Observations
          label={"Observaciones"}
          value={question.observations || ""}
          orderWithObservations={
            (order?.state === "con observaciones" ||
              order?.state === "enviada") &&
            Boolean(question.observations) &&
            question.status !== "aprobado"
          }
          setValue={(e) => {
            const newValue = `<p><strong>${
              user?.roles_id === 5
                ? "Distribuidor"
                : user?.roles_id === 1
                ? "Admin"
                : "Certificador"
            }:</strong>${e}</p>`;
            const newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            const questions = newGroups?.[groupIndex]?.questions;
            if (questions) {
              questions[questionIndex!].observations =
                questions[questionIndex!].observations + newValue;
            }
            newGroups[groupIndex].questions = questions;
            setGroups!(newGroups);
            setNewObservation!(true);
          }}
          disabled={
            order?.state !== "aprobado"
              ? false
              : true
          }
        />
        {Number(question.quantityImages) > 0 ? (
          <ImageInput
            question={question}
            label={"Imagen"}
            setValue={(e) => {}}
            value={""}
            disabled={disabled}
            groupId={groupId}
          />
        ) : (
          <Space></Space>
        )}
      </>
    );
  } else if (question.type === "multiline") {
    return (
      <>
        <MultiLineInput
          label={"Ingresa un texto" || "Question"}
          value={question.value || ""}
          setValue={(e) => {
            const newValue = e;
            const newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            const questions = newGroups?.[groupIndex]?.questions;
            if (questions) {
              questions[questionIndex!].value = newValue;
            }
            newGroups[groupIndex].questions = questions;
            setGroups!(newGroups);
          }}
          disabled={disabled}
        />
        <Observations
          label={"Observaciones"}
          value={question.observations || ""}
          orderWithObservations={
            (order?.state === "con observaciones" ||
              order?.state === "enviada") &&
            Boolean(question.observations) &&
            question.status !== "aprobado"
          }
          setValue={(e) => {
            const newValue = `<p><strong>${
              user?.roles_id === 5
                ? "Distribuidor"
                : user?.roles_id === 1
                ? "Admin"
                : "Certificador"
            }:</strong>${e}</p>`;
            const newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            const questions = newGroups?.[groupIndex]?.questions;
            if (questions) {
              questions[questionIndex!].observations =
                questions[questionIndex!].observations + newValue;
            }
            newGroups[groupIndex].questions = questions;
            setGroups!(newGroups);
            setNewObservation!(true);
          }}
          disabled={
            order?.state !== "aprobado"
              ? false
              : true
          }
        />
        {Number(question.quantityImages) > 0 ? (
          <ImageInput
            question={question}
            label={"Imagen"}
            setValue={(e) => {}}
            value={""}
            disabled={disabled}
            groupId={groupId}
          />
        ) : (
          <Space></Space>
        )}
      </>
    );
  } else if (question.type === "numeric") {
    return (
      <>
        <NumericInput
          label={"Ingresa un valor" || "Question"}
          value={question.number || ""}
          setValue={(e) => {
            const newValue = e;
            const newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            const questions = newGroups?.[groupIndex]?.questions;
            if (questions) {
              questions[questionIndex!].number = newValue;
            }
            newGroups[groupIndex].questions = questions;
            setGroups!(newGroups);
          }}
          disabled={disabled}
        />
        <Observations
          label={"Observaciones"}
          value={question.observations || ""}
          orderWithObservations={
            (order?.state === "con observaciones" ||
              order?.state === "enviada") &&
            Boolean(question.observations) &&
            question.status !== "aprobado"
          }
          setValue={(e) => {
            const newValue = `<p><strong>${
              user?.roles_id === 5
                ? "Distribuidor"
                : user?.roles_id === 1
                ? "Admin"
                : "Certificador"
            }:</strong>${e}</p>`;
            const newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            const questions = newGroups?.[groupIndex]?.questions;
            if (questions) {
              questions[questionIndex!].observations =
                questions[questionIndex!].observations + newValue;
            }
            newGroups[groupIndex].questions = questions;
            setGroups!(newGroups);
            setNewObservation!(true);
          }}
          disabled={
            order?.state !== "aprobado"
              ? false
              : true
          }
        />
        {Number(question.quantityImages) > 0 ? (
          <ImageInput
            question={question}
            label={"Imagen"}
            setValue={(e) => {}}
            value={""}
            disabled={disabled}
            groupId={groupId}
          />
        ) : (
          <Space></Space>
        )}
      </>
    );
  } else if (question.type === "link") {
    return (
      <>
        <LinkInput
          label={"Link" || "Question"}
          value={question.link || ""}
          setValue={(e) => {
            const newValue = e;
            const newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            const questions = newGroups?.[groupIndex]?.questions;
            if (questions) {
              questions[questionIndex!].link = newValue;
            }
            newGroups[groupIndex].questions = questions;
            setGroups!(newGroups);
          }}
          disabled={disabled}
        />
        <Observations
          label={"Observaciones"}
          value={question.observations || ""}
          orderWithObservations={
            (order?.state === "con observaciones" ||
              order?.state === "enviada") &&
            Boolean(question.observations) &&
            question.status !== "aprobado"
          }
          setValue={(e) => {
            const newValue = `<p><strong>${
              user?.roles_id === 5
                ? "Distribuidor"
                : user?.roles_id === 1
                ? "Admin"
                : "Certificador"
            }:</strong>${e}</p>`;
            const newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            const questions = newGroups?.[groupIndex]?.questions;
            if (questions) {
              questions[questionIndex!].observations =
                questions[questionIndex!].observations + newValue;
            }
            newGroups[groupIndex].questions = questions;
            setGroups!(newGroups);
            setNewObservation!(true);
          }}
          disabled={
            order?.state !== "aprobado"
              ? false
              : true
          }
        />
        {Number(question.quantityImages) > 0 ? (
          <ImageInput
            question={question}
            label={"Imagen"}
            setValue={(e) => {}}
            value={""}
            disabled={disabled}
            groupId={groupId}
          />
        ) : (
          <Space></Space>
        )}
      </>
    );
  } else if (question.type === "date") {
    return (
      <>
        <DatePicker
          label={"Fecha" || "Question"}
          date={question.date || new Date()}
          setDate={(e) => {
            const newValue = e;
            let newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            let questions = newGroups?.[groupIndex]?.questions;
            if (questions && questionIndex) {
              questions[questionIndex + 1].date = newValue;
              questions[questionIndex + 1].empty = false;
              const questionEdited = questions[questionIndex];
              const groupsEdited = newGroups.map((group) => {
                if (group.id === groupId) {
                  return {
                    ...group,
                    questions: group.questions?.map((question) => {
                      if (question.order === questionEdited.order) {
                        return questionEdited;
                      } else {
                        return question;
                      }
                    }),
                  };
                } else {
                  return group;
                }
              });
              newGroups = groupsEdited;
              setGroups!(newGroups);
            }
          }}
          disabled={disabled}
          empty={question.empty || false}
        />
        <Observations
          label={"Observaciones"}
          value={question.observations || ""}
          orderWithObservations={
            (order?.state === "con observaciones" ||
              order?.state === "enviada") &&
            Boolean(question.observations) &&
            question.status !== "aprobado"
          }
          setValue={(e) => {
            const newValue = `<p><strong>${
              user?.roles_id === 5
                ? "Distribuidor"
                : user?.roles_id === 1
                ? "Admin"
                : "Certificador"
            }:</strong>${e}</p>`;
            const newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            const questions = newGroups?.[groupIndex]?.questions;
            if (questions) {
              questions[questionIndex!].observations =
                questions[questionIndex!].observations + newValue;
            }
            newGroups[groupIndex].questions = questions;
            setGroups!(newGroups);
            setNewObservation!(true);
          }}
          disabled={
            order?.state !== "aprobado"
              ? false
              : true
          }
        />
        {Number(question.quantityImages) > 0 ? (
          <ImageInput
            question={question}
            label={"Imagen"}
            setValue={(e) => {}}
            value={""}
            disabled={disabled}
            groupId={groupId}
          />
        ) : (
          <Space></Space>
        )}
      </>
    );
  } else if (question.type === "checkbox") {
    return (
      <>
        <Checkbox
          value={question.checked || false}
          setValue={(e) => {
            const newValue = e;
            const newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            const questions = newGroups?.[groupIndex]?.questions;
            if (questions) {
              questions[questionIndex!].checked = newValue;
              questions[questionIndex!].empty = false;
            }
            newGroups[groupIndex].questions = questions;
            setGroups!(newGroups);
          }}
          disabled={disabled}
          empty={question.empty || false}
        />
        <Observations
          label={"Observaciones"}
          value={question.observations || ""}
          orderWithObservations={
            (order?.state === "con observaciones" ||
              order?.state === "enviada") &&
            Boolean(question.observations) &&
            question.status !== "aprobado"
          }
          setValue={(e) => {
            const newValue = `<p><strong>${
              user?.roles_id === 5
                ? "Distribuidor"
                : user?.roles_id === 1
                ? "Admin"
                : "Certificador"
            }:</strong>${e}</p>`;
            const newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            const questions = newGroups?.[groupIndex]?.questions;
            if (questions) {
              questions[questionIndex!].observations =
                questions[questionIndex!].observations + newValue;
            }
            newGroups[groupIndex].questions = questions;
            setGroups!(newGroups);
            setNewObservation!(true);
          }}
          disabled={
            order?.state !== "aprobado"
              ? false
              : true
          }
        />
        {Number(question.quantityImages) > 0 ? (
          <ImageInput
            question={question}
            label={"Imagen"}
            setValue={(e) => {}}
            value={""}
            disabled={disabled}
            groupId={groupId}
          />
        ) : (
          <Space></Space>
        )}
      </>
    );
  } else if (question.type === "combobox") {
    return (
      <>
        <Combobox
          label={"Elige una opción" || "Question"}
          value={question.value || ""}
          options={question.options || []}
          index={100 - question.order}
          setValue={(e) => {
            const newValue = e;
            const newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            const questions = newGroups?.[groupIndex]?.questions;
            if (questions) {
              questions[questionIndex!].value = newValue;
            }
            newGroups[groupIndex].questions = questions;
            setGroups!(newGroups);
          }}
          disabled={disabled}
        />
        <Observations
          label={"Observaciones"}
          value={question.observations || ""}
          orderWithObservations={
            (order?.state === "con observaciones" ||
              order?.state === "enviada") &&
            Boolean(question.observations) &&
            question.status !== "aprobado"
          }
          setValue={(e) => {
            const newValue = `<p><strong>${
              user?.roles_id === 5
                ? "Distribuidor"
                : user?.roles_id === 1
                ? "Admin"
                : "Certificador"
            }:</strong>${e}</p>`;
            const newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            const questions = newGroups?.[groupIndex]?.questions;
            if (questions) {
              questions[questionIndex!].observations =
                questions[questionIndex!].observations + newValue;
            }
            newGroups[groupIndex].questions = questions;
            setGroups!(newGroups);
            setNewObservation!(true);
          }}
          disabled={
            order?.state !== "aprobado"
              ? false
              : true
          }
        />
        {Number(question.quantityImages) > 0 ? (
          <ImageInput
            question={question}
            label={"Imagen"}
            setValue={(e) => {}}
            value={""}
            disabled={disabled}
            groupId={groupId}
          />
        ) : (
          <Space></Space>
        )}
      </>
    );
  } else if (question.type === "pdfinput") {
    return (
      <>
        <PdfInput
          label={"Sube un archivo" || "Question"}
          value={question.value || ""}
          setValue={(e) => {
            const newValue = e;
            const newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            const questions = newGroups?.[groupIndex]?.questions;
            if (questions) {
              questions[questionIndex!].value = newValue;
            }
            newGroups[groupIndex].questions = questions;
            setGroups!(newGroups);
          }}
          disabled={disabled}
          question={question}
          groupId={groupId}
        />
        <Observations
          label={"Observaciones"}
          value={question.observations || ""}
          orderWithObservations={
            (order?.state === "con observaciones" ||
              order?.state === "enviada") &&
            Boolean(question.observations) &&
            question.status !== "aprobado"
          }
          setValue={(e) => {
            const newValue = `<p><strong>${
              user?.roles_id === 5
                ? "Distribuidor"
                : user?.roles_id === 1
                ? "Admin"
                : "Certificador"
            }:</strong>${e}</p>`;
            const newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            const questions = newGroups?.[groupIndex]?.questions;
            if (questions) {
              questions[questionIndex!].observations =
                questions[questionIndex!].observations + newValue;
            }
            newGroups[groupIndex].questions = questions;
            setGroups!(newGroups);
            setNewObservation!(true);
          }}
          disabled={
            order?.state !== "aprobado"
              ? false
              : true
          }
        />
        {Number(question.quantityImages) > 0 ? (
          <ImageInput
            question={question}
            label={"Imagen"}
            setValue={(e) => {}}
            value={""}
            disabled={disabled}
            groupId={groupId}
          />
        ) : (
          <Space></Space>
        )}
      </>
    );
  } else {
    return (
      <>
        <Observations
          label={"Observaciones"}
          value={question.observations || ""}
          orderWithObservations={
            (order?.state === "con observaciones" ||
              order?.state === "enviada") &&
            Boolean(question.observations) &&
            question.status !== "aprobado"
          }
          setValue={(e) => {
            const newValue = `<p><strong>${
              user?.roles_id === 5
                ? "Distribuidor"
                : user?.roles_id === 1
                ? "Admin"
                : "Certificador"
            }:</strong>${e}</p>`;
            const newGroups = [...groups!];
            const groupIndex = newGroups.findIndex(
              (group) => group.id === groupId
            );
            const questionIndex = newGroups?.[groupIndex]?.questions?.findIndex(
              (question2) => question2.id === question.id
            );
            const questions = newGroups?.[groupIndex]?.questions;
            if (questions) {
              questions[questionIndex!].observations =
                questions[questionIndex!].observations + newValue;
            }
            newGroups[groupIndex].questions = questions;
            setGroups!(newGroups);
            setNewObservation!(true);
          }}
          disabled={
            order?.state !== "aprobado"
              ? false
              : true
          }
        />
        {Number(question.quantityImages) > 0 ? (
          <ImageInput
            question={question}
            label={"Imagen"}
            setValue={(e) => {}}
            value={""}
            disabled={disabled}
            groupId={groupId}
          />
        ) : (
          <Space></Space>
        )}
      </>
    );
  }
}
const Space = styled.div`
  min-height: 34px;
  min-width: 34px;
`;
