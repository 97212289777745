import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";
import { userContext } from "../../context/userContext";
import { Group, Question, Questionnaire } from "../Interfaces";
import { db } from "../../App";
import { collection, deleteDoc, doc, getDoc, getDocs, writeBatch } from "firebase/firestore";

export default function useQuestionnaireDesign() {
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
  const [loading, setLoading] = useState<boolean>(true);
  const getData = useAxios();
  const {
    userState: { user },
  } = useContext(userContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const { setNotification } = useContext(notificationsContext);
  const [showModalGroup, setShowModalGroup] = useState<boolean>(false);
  const [newGroupName, setNewGroupName] = useState<string>("");
  const [showModalQuestion, setShowModalQuestion] = useState<boolean>(false);
  const [newQuestionName, setNewQuestionName] = useState<string>("");
  const [newQuestionType, setNewQuestionType] = useState<string>("");
  const [groupSelected, setGroupSelected] = useState<string>("");
  const [checkedImages, setCheckedImages] = useState<boolean>(true);
  const [options, setOptions] = useState<string[]>([""]);
  const [opened, setOpened] = useState<number[]>([]);
  const [columns, setColumns] = useState<string[]>([""]);
  const [rows, setRows] = useState<string[]>([""]);
  const [editGroupId, setEditGroupId] = useState<number | null>(null);
  const [editQuestionId, setEditQuestionId] = useState<number | null>(null);
  const [groups, setGroups] = useState<Group[]>([]);
  useEffect(() => {
    async function fetchData() {
      const response: Questionnaire = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/questionnaires/${id}`,
        headers: {
          accept: "*/*",
        },
      });
      setQuestionnaire(response);
    }
    async function getQuestionnaire() {
      setLoading(true);
      const docRef = doc(db, "questionnaires", `${id}`);
      // get doc with collection groups inside
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const groupCollection = await getDocs(
          collection(
            doc(db, "questionnaires", `${id}`),
            "groups"
          )
        );
        let newGroups: any[] = [];
        const groups = groupCollection.docs.map((doc) => {
          return { ...doc.data() };
        });
        for (let i = 0; i < groups.length; i++) {
          const questions = groups[i].questions.map((doc: any) => {
            const data = doc;
            if (data.type === "statictable") {
              data.rows = JSON.parse(data.rows);
              if (typeof data.rows === 'string') {
                data.rows = JSON.parse(data.rows);
              }
            } else if (data.type === "date") {
              data.date = new Date(data.date);
            }
            return { ...data };
          });
          questions.sort((a: any, b: any) => a.order - b.order);
          newGroups.push({ ...groups[i], questions });
        }
        setGroups(newGroups.sort((a, b) => a.order - b.order));
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    if (id) {
      fetchData();
      getQuestionnaire();

    }
  }, [id]); // eslint-disable-line

  const TYPEQUESTION = [
    { value: "line", label: "Línea" },
    { value: "multiline", label: "Multilínea" },
    { value: "numeric", label: "Numérico" },
    { value: "link", label: "Link" },
    { value: "date", label: "Fecha" },
    { value: "checkbox", label: "Checkbox" },
    { value: "combobox", label: "Combobox" },
    { value: "pdfinput", label: "PDF" },
    { value: "freetable", label: "Tabla libre" },
    { value: "statictable", label: "Tabla estática" },
  ];

  async function handleSave() {
    setLoading(true);
    const groupsRef = collection(db, "questionnaires", `${id}`, "groups");
    const groupsDocs = await getDocs(groupsRef);
    for (let i = 0; i < groupsDocs.docs.length; i++) {
      deleteDoc(doc(groupsRef, groupsDocs.docs[i].id));
    }
    const batch = writeBatch(db);
    for (let i = 0; i < groups.length; i++) {
      const groupRef = doc(groupsRef, `${groups[i].groups_id}`);
      const questionsCopy = groups?.[i]?.questions?.map((question: any) => {
        return { ...question };
      });
      const questions = questionsCopy?.map((question: any) => {
        if (question.type === "statictable") {
          question.rows = JSON.stringify(question.rows);
        } else if (question.type === "date") {
          question.date = question.date.toISOString();
        }
        return { ...question };
      });
      batch.set(groupRef, {
        ...groups[i],
        questions: questions
      });

    }
    await batch.commit();
    setNotification({
      theme: "success",
      titleNotification: "Guardado",
      contentNotification: "Cuestionario guardado correctamente",
      isVisible: true,
    });
    setLoading(false);
  }

  function toggleGroup(id: number) {
    if (opened.includes(id)) {
      setOpened(opened.filter((item) => item !== id));
    } else {
      setOpened([...opened, id]);
    }
  }

  function upGroup(id: number) {
    const index = groups.findIndex((item) => item.id === id);
    if (index > 0) {
      const newGroups = [...groups];
      const aux = newGroups[index];
      newGroups[index] = newGroups[index - 1];
      newGroups[index - 1] = aux;
      //change order
      newGroups[index].order = index + 1;
      newGroups[index - 1].order = index;
      setGroups(newGroups);
    }
  }

  function downGroup(id: number) {
    const index = groups.findIndex((item) => item.id === id);
    if (index < groups.length - 1) {
      const newGroups = [...groups];
      const aux = newGroups[index];
      newGroups[index] = newGroups[index + 1];
      newGroups[index + 1] = aux;
      //change order
      newGroups[index].order = index + 1;
      newGroups[index + 1].order = index + 2;
      setGroups(newGroups);
    }
  }

  function upQuestion(groupId: number, questionId: number) {
    const groupIndex = groups.findIndex((item) => item.id === groupId);
    const questions = groups[groupIndex].questions;
    const questionIndex =
      questions && questions.findIndex((item) => item.id === questionId);
    if (questionIndex && questionIndex > 0) {
      const newQuestions = [...questions];
      const aux = newQuestions[questionIndex];
      newQuestions[questionIndex] = newQuestions[questionIndex - 1];
      newQuestions[questionIndex - 1] = aux;
      //change order
      newQuestions[questionIndex].order = questionIndex + 1;
      newQuestions[questionIndex - 1].order = questionIndex;
      const newGroups = [...groups];
      newGroups[groupIndex].questions = newQuestions;
      setGroups(newGroups);
    }
  }

  function downQuestion(groupId: number, questionId: number) {
    const groupIndex = groups.findIndex((item) => item.id === groupId);
    const questions = groups[groupIndex].questions;
    const questionIndex =
      questions && questions.findIndex((item) => item.id === questionId);
    if (questionIndex && questionIndex < questions.length - 1) {
      const newQuestions = [...questions];
      const aux = newQuestions[questionIndex];
      newQuestions[questionIndex] = newQuestions[questionIndex + 1];
      newQuestions[questionIndex + 1] = aux;
      //change order
      newQuestions[questionIndex].order = questionIndex + 1;
      newQuestions[questionIndex + 1].order = questionIndex + 2;
      const newGroups = [...groups];
      newGroups[groupIndex].questions = newQuestions;
      setGroups(newGroups);
    }
    if (questionIndex === 0 && questions?.length) {
      const newQuestions = [...questions];
      const aux = newQuestions[questionIndex];
      newQuestions[questionIndex] = newQuestions[questionIndex + 1];
      newQuestions[questionIndex + 1] = aux;
      //change order
      newQuestions[questionIndex].order = questionIndex + 1;
      newQuestions[questionIndex + 1].order = questionIndex + 2;
      const newGroups = [...groups];
      newGroups[groupIndex].questions = newQuestions;
      setGroups(newGroups);
    }
  }

  function createGroup(name: string) {
    if (!name) {
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: "El nombre de la sección no puede estar vacío",
        isVisible: true,
      });
      return;
    }
    const newGroups = [...groups];
    const newGroup = {
      groups_id: "" + groups.length + 1,
      id: groups.length + 1,
      order: groups.length + 1,
      name,
      questions: [],
    };
    newGroups.push(newGroup);
    setNewGroupName("");
    setGroups(newGroups);
    setShowModalGroup(false);
  }

  function deleteGroup(id: number) {
    const newGroups = [...groups];
    const index = newGroups.findIndex((item) => item.id === id);
    newGroups.splice(index, 1);
    // change order all groups
    newGroups.forEach((item, index) => {
      item.order = index + 1;
    });
    setGroups(newGroups);
  }

  function deleteQuestion(groupId: number, questionId: number) {
    const groupIndex = groups.findIndex((item) => item.id === groupId);
    const questions: any = groups[groupIndex].questions;
    const newQuestions = [...questions];
    const index = newQuestions.findIndex((item) => item.id === questionId);
    newQuestions.splice(index, 1);
    // change order all questions
    newQuestions.forEach((item, index) => {
      item.order = index + 1;
    });
    const newGroups = [...groups];
    newGroups[groupIndex].questions = newQuestions;
    setGroups(newGroups);
  }

  function handleEditGroup(id: number) {
    const group: Group | undefined = groups.find((item) => item.id === id);
    if (group) {
      setNewGroupName(group.name || "");
      setEditGroupId(group.id);
    }
  }

  function confirmEditGroup() {
    if (!newGroupName) {
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: "El nombre de la sección no puede estar vacío",
        isVisible: true,
      });
      return;
    }
    const newGroups = [...groups];
    const index = newGroups.findIndex((item) => item.id === editGroupId);
    newGroups[index].name = newGroupName;
    setNewGroupName("");
    setEditGroupId(null);
    setGroups(newGroups);
    setShowModalGroup(false);
  }

  function handleEditQuestion(groupId: number, questionId: number) {
    const group: Group | undefined = groups.find((item) => item.id === groupId);
    const question: Question | undefined = group?.questions?.find(
      (item) => item.id === questionId
    );
    if (question) {
      setGroupSelected(group?.name || "");
      setNewQuestionName(question.name || "");
      setNewQuestionType(question.type || "");
      setColumns(question.headers || [""]);
      setOptions(question.options || [""]);
      setCheckedImages(question.quantityImages === 1);
      setEditQuestionId(question.id);
      setRows(question.nameRows || [""]);
    }
  }

  function cancelEditQuestion() {
    setShowModalQuestion(false);
    setEditQuestionId(null);
    setNewQuestionName("");
    setNewQuestionType("");
    setOptions([""]);
    setCheckedImages(false);
    setColumns([""]);
    setRows([""]);
    setGroupSelected("");
  }
  function cancelEditGroup() {
    setShowModalGroup(false);
    setEditGroupId(null);
    setNewGroupName("");
  }

  function confirmEditQuestion() {
    if (!newQuestionName || !newQuestionType || !groupSelected) {
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: "Todos los campos son obligatorios",
        isVisible: true,
      });
      return;
    }
    const group: Group | undefined = groups.find(
      (item) => item.name === groupSelected
    );
    const groupId = group?.id || 0;
    const questions: any = group?.questions;
    const newQuestions = [...questions];
    const index = newQuestions.findIndex((item) => item.id === editQuestionId);
    const order = newQuestions[index].order;
    let newQuestion: Question = {
      questions_id: newQuestions[index].questions_id,
      id: Number(editQuestionId),
      order: Number(order),
      name: newQuestionName,
      type: newQuestionType,
      quantityImages: checkedImages ? 1 : 0,
      observations: "",
    };
    if (newQuestionType === "combobox") {
      newQuestion = {
        ...newQuestion,
        value: "",
        options,
      };
      if (!options.every((item) => item !== "")) {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: "Debe completar todos los campos",
          isVisible: true,
        });
        return;
      }
    } else if (newQuestionType === "freetable") {
      newQuestion = {
        ...newQuestion,
        headers: columns,
      };
      if (!columns.every((item) => item !== "")) {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: "Debe completar todos los campos",
          isVisible: true,
        });
        return;
      }
    } else if (newQuestionType === "statictable") {
      newQuestion = {
        ...newQuestion,
        headers: columns,
        nameRows: rows,
        rows: rows.map((item) => {
          return columns.map((item) => "");
        }),
      };
      if (!columns.every((item) => item !== "") || !rows.every((item) => item !== "")) {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: "Debe completar todos los campos",
          isVisible: true,
        });
        return;
      }
    } else if (newQuestionType === "line") {
      newQuestion = {
        ...newQuestion,
        value: "",
      };
    } else if (newQuestionType === "multiline") {
      newQuestion = {
        ...newQuestion,
        value: "",
      };
    } else if (newQuestionType === "numeric") {
      newQuestion = {
        ...newQuestion,
        number: 0,
      };
    } else if (newQuestionType === "link") {
      newQuestion = {
        ...newQuestion,
        link: "",
      };
    } else if (newQuestionType === "date") {
      newQuestion = {
        ...newQuestion,
        date: new Date(Date.now()),
        empty: true,
      };
    } else if (newQuestionType === "checkbox") {
      newQuestion = {
        ...newQuestion,
        checked: false,
        empty: true,
      };
    } else if (newQuestionType === "pdfinput") {
      newQuestion = {
        ...newQuestion,
        pdfname: "",
      };
    }
    newQuestions[index] = newQuestion;
    const newGroups = [...groups];
    const groupIndex = newGroups.findIndex((item) => item.id === groupId);
    newGroups[groupIndex].questions = newQuestions;
    setGroups(newGroups);
    setShowModalQuestion(false);
    setEditQuestionId(null);
    setNewQuestionName("");
    setNewQuestionType("");
    setOptions([""]);
    setCheckedImages(false);
    setColumns([""]);
    setRows([""]);
    setGroupSelected("");
  }

  function createQuestion() {
    if (!newQuestionName || !newQuestionType || !groupSelected) {
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: "Todos los campos son obligatorios",
        isVisible: true,
      });
      return;
    }
    const group: Group | undefined = groups.find(
      (item) => item.name === groupSelected
    );
    const groupId = group?.id || 0;
    let newQuestion: Question = {
      questions_id: "" + group?.questions?.length + 1,
      id: Number(group?.questions?.length) + 1,
      order: Number(group?.questions?.length) + 1,
      name: newQuestionName,
      type: newQuestionType,
      quantityImages: checkedImages ? 1 : 0,
      observations: "",
    };
    if (newQuestionType === "combobox") {
      newQuestion = {
        ...newQuestion,
        value: "",
        options,
      };
      if (!options.every((item) => item !== "")) {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: "Debe completar todos los campos",
          isVisible: true,
        });
        return;
      }
    } else if (newQuestionType === "freetable") {
      newQuestion = {
        ...newQuestion,
        headers: columns,
      };
      if (!columns.every((item) => item !== "")) {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: "Debe completar todos los campos",
          isVisible: true,
        });
        return;
      }
    } else if (newQuestionType === "statictable") {
      newQuestion = {
        ...newQuestion,
        headers: columns,
        nameRows: rows,
        rows: rows.map((item) => {
          return columns.map((item) => "");
        }),
      };
      if (!columns.every((item) => item !== "") || !rows.every((item) => item !== "")) {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: "Debe completar todos los campos",
          isVisible: true,
        });
        return;
      }
    } else if (newQuestionType === "line") {
      newQuestion = {
        ...newQuestion,
        value: "",
      };
    } else if (newQuestionType === "multiline") {
      newQuestion = {
        ...newQuestion,
        value: "",
      };
    } else if (newQuestionType === "numeric") {
      newQuestion = {
        ...newQuestion,
        number: 0,
      };
    } else if (newQuestionType === "link") {
      newQuestion = {
        ...newQuestion,
        link: "",
      };
    } else if (newQuestionType === "date") {
      newQuestion = {
        ...newQuestion,
        date: new Date(Date.now()),
        empty: true,
      };
    } else if (newQuestionType === "checkbox") {
      newQuestion = {
        ...newQuestion,
        checked: false,
        empty: true,
      };
    } else if (newQuestionType === "pdfinput") {
      newQuestion = {
        ...newQuestion,
        pdfname: "",
      };
    }
    // add question to group
    const newGroups = [...groups];
    const groupIndex = newGroups.findIndex((item) => item.id === groupId);
    const questions = newGroups[groupIndex].questions;
    questions && questions.push(newQuestion);
    setNewQuestionName("");
    setNewQuestionType("");
    setOptions([""]);
    setCheckedImages(false);
    setColumns([""]);
    setRows([""]);
    setGroupSelected("");
    setShowModalQuestion(false);
  }

  return {
    questionnaire,
    loading,
    user,
    navigate,
    groups,
    setShowModalQuestion,
    setShowModalGroup,
    opened,
    upGroup,
    downGroup,
    handleEditGroup,
    deleteGroup,
    toggleGroup,
    upQuestion,
    downQuestion,
    handleEditQuestion,
    deleteQuestion,
    showModalGroup,
    newGroupName,
    setNewGroupName,
    cancelEditGroup,
    createGroup,
    editGroupId,
    confirmEditGroup,
    showModalQuestion,
    editQuestionId,
    groupSelected,
    setGroupSelected,
    newQuestionName,
    setNewQuestionName,
    newQuestionType,
    setNewQuestionType,
    options,
    setOptions,
    columns,
    setColumns,
    rows,
    setRows,
    checkedImages,
    setCheckedImages,
    cancelEditQuestion,
    createQuestion,
    confirmEditQuestion,
    TYPEQUESTION,
    handleSave,
  };
}
