import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import Modal from "../../commonComponents/Modal";
import useRolesForm from "../hooks/useRolesForm";
import CheckIcon from "../../assets/img/checkIconMin.svg";
import Button from "./../../commonComponents/Button";
import ArrowDropDownIcon from "../../assets/img/arrowDropDownIcon.svg";

export default function RolesForm({
  roleIdToEdit,
  setRoleIdToEdit,
  setRefreshRoles,
  setCreateNewRole,
}: {
  roleIdToEdit?: number;
  setRoleIdToEdit: (roleIdToEdit: number | null) => void;
  setRefreshRoles: (refresh: boolean) => void;
  setCreateNewRole: (createNewRole: boolean) => void;
}) {
  const {
    rol,
    setRol,
    bindAgents,
    setBindAgents,
    adminAgents,
    setAdminAgents,
    impersonate,
    setImpersonate,
    errors,
    loading,
    handleSubmit,
    handleCancel,
    initialRoleName,
    mail_new_agent,
    setMail_new_agent,
    mail_suspended_agent,
    setMail_suspended_agent,
    mail_activated_agent,
    setMail_activated_agent,
    mail_inactivated_agent,
    setMail_inactivated_agent,
    agent_to_pending,
    setAgent_to_pending,
    agent_to_suspended,
    setAgent_to_suspended,
    agent_to_activated,
    setAgent_to_activated,
    agent_to_inactivated,
    setAgent_to_inactivated,
    allAgentStatusPermissions,
    handleStatusPermissions,
    showAllPermisions,
    showUsersPermissions,
    showAgentsPermissiones,
    see_users,
    setSee_users,
    edit_users,
    setEdit_users,
    enable_users,
    setEnable_users,
    create_agent,
    setCreate_agent,
    edit_agents,
    setEdit_agents,
    coverage_zone,
    setCoverage_zone,
    see_agent_users,
    setSee_agent_users,
    see_logs,
    setSee_logs,
    see_my_agent,
    setSee_my_agent,
    allUsersPermissions,
    handleUsersPermissions,
    handleAgentsPermissions,
    allAgentsPermissions,
    handleShowAllPermisions,
    handleShowAgentsPermissiones,
    handleShowUsersPermissions,
    export_agents,
    setExport_agents,
    see_documents,
    setSee_documents,
    edit_documents,
    setEdit_documents,
    handleDocumentPermissions,
    allDocumentsPermissions,
    handleShowDocumentsPermissions,
    showDocumentsPermissions,
    see_leads,
    setSee_leads,
    edit_leads,
    setEdit_leads,
    allLeadsPermissions,
    handleLeadsPermissions,
    handleShowLeadsPermissions,
    showLeadsPermissions,
    handleCertificationsPermissions,
    handleShowCertificationsPermissions,
    showCertificationsPermissions,
    edit_questionnaire,
    setEdit_questionnaire,
    see_questionnaire,
    setSee_questionnaire,
    create_order,
    setCreate_order,
    see_order,
    setSee_order,
    review_own_order,
    setReview_own_order,
    approve_order,
    setApprove_order,
  } = useRolesForm({
    roleIdToEdit,
    setRoleIdToEdit,
    setRefreshRoles,
    setCreateNewRole,
  });

  return (
    <Modal
      type="roles"
      title={roleIdToEdit ? "Editando Rol " + initialRoleName : "Nuevo Rol"}
    >
      <Container>
        {loading ? (
          <LoadingContainer>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </LoadingContainer>
        ) : (
          <>
            {!roleIdToEdit && (
              <FieldContainer>
                <Input
                  color={errors.rol ? "#FF3D00" : undefined}
                  required
                  value={rol}
                  onChange={({ target: { value } }) => setRol(value)}
                />
                <Label color={errors.rol ? "#FF3D00" : undefined}>
                  Nuevo Rol
                </Label>
              </FieldContainer>
            )}

            <TitleContainer>Usuarios</TitleContainer>
            <PermissionsContainer>
              <CheckboxContainer>
                <Checkbox onClick={() => handleUsersPermissions()}>
                  {allUsersPermissions && (
                    <img src={CheckIcon} alt="check icon" />
                  )}
                </Checkbox>
                <CheckboxText>
                  Administrar Usuarios{" "}
                  <IconConatiner
                    style={{
                      transform: showUsersPermissions
                        ? "rotateZ(-180deg)"
                        : "rotateZ(0deg)",
                    }}
                    onClick={() => handleShowUsersPermissions()}
                  >
                    <img src={ArrowDropDownIcon} alt="arrow drop down icon" />
                  </IconConatiner>
                </CheckboxText>
              </CheckboxContainer>
              <StatusAgentsContainer
                style={{ height: showUsersPermissions ? "90px" : "0px" }}
              >
                <CheckboxContainer>
                  <Checkbox onClick={() => setSee_users((prev) => !prev)}>
                    {see_users && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Ver Usuarios</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setEdit_users((prev) => !prev)}>
                    {edit_users && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Editar Usuarios</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setEnable_users((prev) => !prev)}>
                    {enable_users && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Habilitar Usuarios</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setImpersonate((prev) => !prev)}>
                    {impersonate && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Impersonar</CheckboxText>
                </CheckboxContainer>
              </StatusAgentsContainer>
            </PermissionsContainer>

            <TitleContainer>Distribuidores</TitleContainer>
            <PermissionsContainer>
              <CheckboxContainer>
                <Checkbox onClick={() => handleAgentsPermissions()}>
                  {allAgentsPermissions && (
                    <img src={CheckIcon} alt="check icon" />
                  )}
                </Checkbox>
                <CheckboxText>
                  Admininistrar Distribuidores{" "}
                  <IconConatiner
                    style={{
                      transform: showAgentsPermissiones
                        ? "rotateZ(-180deg)"
                        : "rotateZ(0deg)",
                    }}
                    onClick={() => handleShowAgentsPermissiones()}
                  >
                    <img src={ArrowDropDownIcon} alt="arrow drop down icon" />
                  </IconConatiner>
                </CheckboxText>
              </CheckboxContainer>
              <StatusAgentsContainer
                style={{ height: showAgentsPermissiones ? "200px" : "0px" }}
              >
                <CheckboxContainer>
                  <Checkbox onClick={() => setCreate_agent((prev) => !prev)}>
                    {create_agent && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Crear Distribuidor</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setEdit_agents((prev) => !prev)}>
                    {edit_agents && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Editar Distribuidores</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setBindAgents((prev) => !prev)}>
                    {bindAgents && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Vincular Usuarios a Distribuidor</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setAdminAgents((prev) => !prev)}>
                    {adminAgents && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Autorizar Distribuidores</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setCoverage_zone((prev) => !prev)}>
                    {coverage_zone && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Crear/editar Zona de Cobertura</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setSee_agent_users((prev) => !prev)}>
                    {see_agent_users && (
                      <img src={CheckIcon} alt="check icon" />
                    )}
                  </Checkbox>
                  <CheckboxText>Ver Usuarios Agente</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setSee_logs((prev) => !prev)}>
                    {see_logs && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Ver Logs de Distribuidores</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setSee_my_agent((prev) => !prev)}>
                    {see_my_agent && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Ver Mi Distribuidor</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setExport_agents((prev) => !prev)}>
                    {export_agents && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Exportar Distribuidores</CheckboxText>
                </CheckboxContainer>
              </StatusAgentsContainer>
              <CheckboxContainer>
                <Checkbox onClick={() => handleStatusPermissions()}>
                  {allAgentStatusPermissions && (
                    <img src={CheckIcon} alt="check icon" />
                  )}
                </Checkbox>
                <CheckboxText>
                  Cambios de estados Distribuidores{" "}
                  <IconConatiner
                    style={{
                      transform: showAllPermisions
                        ? "rotateZ(-180deg)"
                        : "rotateZ(0deg)",
                    }}
                    onClick={() => handleShowAllPermisions()}
                  >
                    <img src={ArrowDropDownIcon} alt="arrow drop down icon" />
                  </IconConatiner>
                </CheckboxText>
              </CheckboxContainer>
              <StatusAgentsContainer
                style={{ height: showAllPermisions ? "170px" : "0px" }}
              >
                <CheckboxContainer>
                  <Checkbox onClick={() => setMail_new_agent((prev) => !prev)}>
                    {mail_new_agent && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Recibir mail: Nuevo Distribuidor</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox
                    onClick={() => setMail_suspended_agent((prev) => !prev)}
                  >
                    {mail_suspended_agent && (
                      <img src={CheckIcon} alt="check icon" />
                    )}
                  </Checkbox>
                  <CheckboxText>Recibir mail: Distribuidor Suspendido</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox
                    onClick={() => setMail_activated_agent((prev) => !prev)}
                  >
                    {mail_activated_agent && (
                      <img src={CheckIcon} alt="check icon" />
                    )}
                  </Checkbox>
                  <CheckboxText>Recibir mail: Distribuidor Activado</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox
                    onClick={() => setMail_inactivated_agent((prev) => !prev)}
                  >
                    {mail_inactivated_agent && (
                      <img src={CheckIcon} alt="check icon" />
                    )}
                  </Checkbox>
                  <CheckboxText>Recibir mail: Distribuidor Inactivado</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox
                    onClick={() => setAgent_to_pending((prev) => !prev)}
                  >
                    {agent_to_pending && (
                      <img src={CheckIcon} alt="check icon" />
                    )}
                  </Checkbox>
                  <CheckboxText>Pasar a Pendiente</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox
                    onClick={() => setAgent_to_suspended((prev) => !prev)}
                  >
                    {agent_to_suspended && (
                      <img src={CheckIcon} alt="check icon" />
                    )}
                  </Checkbox>
                  <CheckboxText>Pasar a Suspendido</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox
                    onClick={() => setAgent_to_activated((prev) => !prev)}
                  >
                    {agent_to_activated && (
                      <img src={CheckIcon} alt="check icon" />
                    )}
                  </Checkbox>
                  <CheckboxText>Pasar a Activo</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox
                    onClick={() => setAgent_to_inactivated((prev) => !prev)}
                  >
                    {agent_to_inactivated && (
                      <img src={CheckIcon} alt="check icon" />
                    )}
                  </Checkbox>
                  <CheckboxText>Pasar a Inactivo</CheckboxText>
                </CheckboxContainer>
              </StatusAgentsContainer>
            </PermissionsContainer>

            <TitleContainer>Gestión de Documentos</TitleContainer>
            <PermissionsContainer>
              <CheckboxContainer>
                <Checkbox onClick={() => handleDocumentPermissions()}>
                  {allDocumentsPermissions && (
                    <img src={CheckIcon} alt="check icon" />
                  )}
                </Checkbox>
                <CheckboxText>
                  Documentos{" "}
                  <IconConatiner
                    style={{
                      transform: showDocumentsPermissions
                        ? "rotateZ(-180deg)"
                        : "rotateZ(0deg)",
                    }}
                    onClick={() => handleShowDocumentsPermissions()}
                  >
                    <img src={ArrowDropDownIcon} alt="arrow drop down icon" />
                  </IconConatiner>
                </CheckboxText>
              </CheckboxContainer>
              <StatusAgentsContainer
                style={{ height: showDocumentsPermissions ? "45px" : "0px" }}
              >
                <CheckboxContainer>
                  <Checkbox onClick={() => setSee_documents((prev) => !prev)}>
                    {see_documents && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Ver Documentos</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setEdit_documents((prev) => !prev)}>
                    {edit_documents && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Editar Documentos</CheckboxText>
                </CheckboxContainer>
              </StatusAgentsContainer>
            </PermissionsContainer>

            <TitleContainer>Leads</TitleContainer>
            <PermissionsContainer>
              <CheckboxContainer>
                <Checkbox onClick={() => handleLeadsPermissions()}>
                  {allLeadsPermissions && (
                    <img src={CheckIcon} alt="check icon" />
                  )}
                </Checkbox>
                <CheckboxText>
                  Administración de Leads{" "}
                  <IconConatiner
                    style={{
                      transform: showLeadsPermissions
                        ? "rotateZ(-180deg)"
                        : "rotateZ(0deg)",
                    }}
                    onClick={() => handleShowLeadsPermissions()}
                  >
                    <img src={ArrowDropDownIcon} alt="arrow drop down icon" />
                  </IconConatiner>
                </CheckboxText>
              </CheckboxContainer>
              <StatusAgentsContainer
                style={{ height: showLeadsPermissions ? "45px" : "0px" }}
              >
                <CheckboxContainer>
                  <Checkbox onClick={() => setSee_leads((prev) => !prev)}>
                    {see_leads && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Ver Leads</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setEdit_leads((prev) => !prev)}>
                    {edit_leads && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Editar Leads</CheckboxText>
                </CheckboxContainer>
              </StatusAgentsContainer>
            </PermissionsContainer>

            <TitleContainer>Certificación</TitleContainer>
            <PermissionsContainer>
              <CheckboxContainer>
                <Checkbox onClick={() => handleCertificationsPermissions()}>
                  {allLeadsPermissions && (
                    <img src={CheckIcon} alt="check icon" />
                  )}
                </Checkbox>
                <CheckboxText>
                  Cuestionarios y Ordenes de Certificación{" "}
                  <IconConatiner
                    style={{
                      transform: showCertificationsPermissions
                        ? "rotateZ(-180deg)"
                        : "rotateZ(0deg)",
                    }}
                    onClick={() => handleShowCertificationsPermissions()}
                  >
                    <img src={ArrowDropDownIcon} alt="arrow drop down icon" />
                  </IconConatiner>
                </CheckboxText>
              </CheckboxContainer>
              <StatusAgentsContainer
                style={{ height: showCertificationsPermissions ? "140px" : "0px" }}
              >
                <CheckboxContainer>
                  <Checkbox onClick={() => setEdit_questionnaire((prev) => !prev)}>
                    {edit_questionnaire && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Editar Cuestionarios</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setSee_questionnaire((prev) => !prev)}>
                    {see_questionnaire && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Ver Cuestionarios</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setCreate_order((prev) => !prev)}>
                    {create_order && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Crear Ordenes de Certificación</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setSee_order((prev) => !prev)}>
                    {see_order && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Ver Ordenes de Certificación</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setReview_own_order((prev) => !prev)}>
                    {review_own_order && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Editar Propias Ordenes de Certificación</CheckboxText>
                </CheckboxContainer>
                <CheckboxContainer>
                  <Checkbox onClick={() => setApprove_order((prev) => !prev)}>
                    {approve_order && <img src={CheckIcon} alt="check icon" />}
                  </Checkbox>
                  <CheckboxText>Aprobar Ordenes de Certificación</CheckboxText>
                </CheckboxContainer>
              </StatusAgentsContainer>
            </PermissionsContainer>
            <ButtonsContainer>
              <Button type="secondary" onClick={handleCancel}>
                Cancelar
              </Button>
              <Button type="primary" onClick={handleSubmit}>
                Guardar
              </Button>
            </ButtonsContainer>
          </>
        )}
      </Container>
    </Modal>
  );
}
const IconConatiner = styled.div`
  position: absolute;
  right: -30px;
  top: 0px;
  transform: rotateZ("0deg");
  transition: all 0.3s;
  cursor: pointer;
`;
const StatusAgentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: hidden;
  padding-left: 80px;
  transition: all 0.3s;
  gap: 6px;
  @media (max-width: 768px) {
    padding-left: 30px;
  }
`;
const Container = styled.div`
  width: 415px;
  min-height: 335px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 53px 40px 20px 40px;
  position: relative;
  transition: all 0.3s;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    padding: 10px 40px 20px 20px;
  }
`;
const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5px;
  position: relative;
  width: 335px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  min-width: 100%;
  margin-top: 40px;
  bottom: 20px;
  left: 0;
  @media (max-width: 768px) {
    width: calc(100vw - 50px);
    padding-right: 0px;
    gap: 8px;
  }
`;
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;
const Input = styled.input`
  width: 335px;
  height: 55px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const Label = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 11.5px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      line-height: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;

const TitleContainer = styled.p`
  font-family: "D-DIN-PRO-Bold";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #3c4858;
  margin-top: 14px;
`;
const Checkbox = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid #424242;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media (max-width: 768px) {
    min-width: 16px;
    min-height: 16px;
  }
`;
const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 9px;
`;
const CheckboxText = styled.p`
  font-size: 13px;
  line-height: 13px;
  color: #3c4858;
  position: relative;
`;
const PermissionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  margin-top: 16px;
`;
