import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../commonHooks/useAxios";
import { Group, Questionnaire } from "../Interfaces";
import { db } from "../../App";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";

export default function useQuestionnaireView() {
  const [questionnaire, setQuestionnaire] = useState<Questionnaire>();
  const [loading, setLoading] = useState<boolean>(true);
  const getData = useAxios();
  const navigate = useNavigate();
  const { id } = useParams();
  const [opened, setOpened] = useState<number[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [obra, setObra] = useState<string>("");
  const [agent, setAgent] = useState<string>("");
  const [client, setClient] = useState<string>("");
  const [ubication, setUbication] = useState<string>("");
  useEffect(() => {
    async function fetchData() {
      const response: Questionnaire = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/questionnaires/${id}`,
        headers: {
          accept: "*/*",
        },
      });
      setQuestionnaire(response);
    }
    async function getQuestionnaire() {
      setLoading(true);
      const docRef = doc(db, "questionnaires", `${id}`);
      // get doc with collection groups inside
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const groupCollection = await getDocs(
          collection(
            doc(db, "questionnaires", `${id}`),
            "groups"
          )
        );
        let newGroups: any[] = [];
        const groups = groupCollection.docs.map((doc) => {
          return { ...doc.data() };
        });
        for (let i = 0; i < groups.length; i++) {
          const questions = groups[i].questions.map((doc: any) => {
            const data = doc;
            if (data.type === "statictable") {
              data.rows = JSON.parse(data.rows);
              if (typeof data.rows === 'string') {
                data.rows = JSON.parse(data.rows);
              }
            } else if (data.type === "date") {
              data.date = new Date(data.date);
            }
            return { ...data };
          });
          questions.sort((a: any, b: any) => a.order - b.order);
          newGroups.push({ ...groups[i], questions });
        }
        setGroups(newGroups.sort((a, b) => a.order - b.order));
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    if (id) {
      fetchData();
      getQuestionnaire();

    }
  }, [id]); // eslint-disable-line

  function toggleGroup(id: number) {
    if (opened.includes(id)) {
      setOpened(opened.filter((item) => item !== id));
    } else {
      setOpened([...opened, id]);
    }
  }


  return {
    loading,
    groups,
    opened,
    toggleGroup,
    navigate,
    obra,
    setObra,
    agent,
    setAgent,
    client,
    setClient,
    ubication,
    setUbication,
    setGroups,
    questionnaire
  };
}
