import styled from "styled-components";

export default function Footer() {
  return (
    <FooterContainer>
      <FooterText>© {new Date().getFullYear()} YPF Solar</FooterText>
    </FooterContainer>
  );
}

const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 20px 0 20px;
  margin-top: 20px;
`;
const FooterText = styled.div`
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  color: #000000;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e7e7e7;
  padding: 12px 0 14px 0;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
