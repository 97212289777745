import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import Modal from "../../commonComponents/Modal";
import Button from "../../commonComponents/Button";
import ArrowDropDownIcon from "../../assets/img/arrowDropDownIcon.svg";
import useDocumentsForm from "../hooks/useDocumentsForm";
import { DocumentType } from "../Interfaces";

export default function DocumentsForm({
  documentIdToEdit,
  setDocumentIdToEdit,
  setRefreshDocuments,
  setCreateNewDocument,
}: {
  documentIdToEdit?: string | null;
  setDocumentIdToEdit: (agentId: string | null) => void;
  setRefreshDocuments: (refresh: boolean) => void;
  setCreateNewDocument: (create: boolean) => void;
}) {
  const {
    loading,
    document,
    setDocument,
    version,
    setVersion,
    link,
    setLink,
    showDropDownType,
    setShowDropDownType,
    documentType,
    setDocumentType,
    handleSubmit,
    options,
    height,
    errors,
  } = useDocumentsForm({
    documentIdToEdit,
    setDocumentIdToEdit,
    setRefreshDocuments,
    setCreateNewDocument,
  });

  return (
    <Modal
      type="document"
      title={documentIdToEdit ? "Editar Documento" : "Nuevo Documento"}
    >
      <Container>
        {loading ? (
          <LoadingContainer>
            <CircularProgress
              sx={{
                color: "#128297",
              }}
              size={100}
            />
          </LoadingContainer>
        ) : (
          <>
            <FieldsContainer>
              <FieldContainer>
                <Input
                  color={errors.document ? "#FF3D00" : undefined}
                  required
                  value={document}
                  onChange={({ target: { value } }) => setDocument(value)}
                />
                <Label color={errors.document ? "#FF3D00" : undefined}>
                  Documento
                </Label>
              </FieldContainer>
              <FieldContainer>
                <DropDownField
                  onClick={() => setShowDropDownType(!showDropDownType)}
                  style={{
                    height: showDropDownType
                      ? `${height * (options.length + 1) + 18}px`
                      : `${height}px`,
                    borderColor: errors.documentType ? "#FF3D00" : "#424242",
                  }}
                >
                  {(!documentType || documentType === "null") && (
                    <CurrentValueDropDown
                      style={{
                        paddingTop: "0px",
                        fontSize: "16px",
                        color: errors.documentType ? "#FF3D00" : "#128297",
                      }}
                    >
                      Tipo
                    </CurrentValueDropDown>
                  )}
                  {options.map(
                    (d: DocumentType, index: number) =>
                      documentType === String(d.document_types_id) && (
                        <>
                          <DropDownLabel key={index}>Tipo</DropDownLabel>
                          <CurrentValueDropDown key={index + 10}>
                            {d.name}
                          </CurrentValueDropDown>
                        </>
                      )
                  )}
                  <DropDownItems
                    style={{
                      overflowY: "hidden",
                    }}
                  >
                    {options.map((d: DocumentType, index: number) => (
                      <Option
                        key={index}
                        onClick={() => setDocumentType(String(d.document_types_id))}
                      >
                        {d.name}
                      </Option>
                    ))}
                  </DropDownItems>

                  <IconConatiner
                    style={{
                      transform: showDropDownType
                        ? "rotateZ(-180deg)"
                        : "rotateZ(0deg)",
                    }}
                  >
                    <img src={ArrowDropDownIcon} alt="arrow drop down icon" />
                  </IconConatiner>
                </DropDownField>
              </FieldContainer>
              <FieldContainer style={{ marginTop: height === 34 ? "auto" : "54px" }}>
                <Input
                  color={errors.version ? "#FF3D00" : undefined}
                  required
                  value={version}
                  pattern="^\d*(\.\d{0,2})?$"
                  onChange={({ target: { value } }) => {
                    if(Number(value) || value === ""){
                      if(value.includes(".")){
                        if(value.split(".")[1].length <= 2){
                          setVersion(value);
                        }
                      } else {
                        setVersion(value);
                      }
                    }
                  }}
                />
                <Label color={errors.version ? "#FF3D00" : undefined}>
                  Versión
                </Label>
              </FieldContainer>
              <FieldContainer>
                <Input
                  color={errors.link ? "#FF3D00" : undefined}
                  required
                  value={link}
                  onChange={({ target: { value } }) => setLink(value)}
                />
                <Label color={errors.link ? "#FF3D00" : undefined}>Link</Label>
              </FieldContainer>
            </FieldsContainer>
            <ButtonsContainer>
              <Button
                type="secondary"
                onClick={() => {
                  setDocumentIdToEdit(null);
                  setCreateNewDocument(false);
                }}
              >
                Cancelar
              </Button>
              <Button type="primary" onClick={() => handleSubmit()}>
                Aceptar
              </Button>
            </ButtonsContainer>
          </>
        )}
      </Container>
    </Modal>
  );
}

const Container = styled.div`
  width: 415px;
  height: 510px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding: 73px 40px 50px 40px;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    padding: 20px 10px 50px 10px;
    height: auto;
  }
`;
const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  width: 335px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
  }
`;
const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 40px;
  width: 100%;
  margin-top: 50px;
`;
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
`;
const DropDownField = styled.div`
  border: 1px solid #424242;
  border-radius: 5px;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  cursor: pointer;
  position: absolute;
  color: #128297;
  width: 335px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0px 20px 18px 20px;
  overflow-y: hidden;
  background-color: #fff;
  z-index: 6;
  transition: all 0.4s;
  max-height: 467px;
  @media (max-width: 768px) {
    padding: 0px 10px 18px 10px;
    width: 100%;
  }
`;
const DropDownItems = styled.div`
  cursor: pointer;
  color: #128297;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: hidden;
  z-index: 6;
  transition: all 0.4s;
  max-height: 403px;
  width: 100%;
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`;
const IconConatiner = styled.div`
  position: absolute;
  right: 26px;
  top: 19px;
  transform: rotateZ("0deg");
  transition: all 0.3s;
  @media (max-width: 768px) {
    right: 16px;
    top: 8px;
  }
`;
const DropDownLabel = styled.p`
  font-size: 12px;
  line-height: 15px;
  font-weight: 400;
  color: #424242;
  position: absolute;
  top: 6px;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
    top: 3px;
  }
`;
const CurrentValueDropDown = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: #128297;
  cursor: pointer;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding-top: 10px;
  text-transform: capitalize;
  @media (max-width: 768px) {
    font-family: "D-DIN-PRO";
    min-height: 34px;
    font-size: 13px;
    line-height: 13px;
    padding-top: 10px;
  }
`;
const Option = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #128297;
  cursor: pointer;
  :hover {
    background-color: #f5f5f5;
  }
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 0 0 0 20px;
  :nth-last-child() {
    margin-bottom: 18px;
  }
  text-transform: capitalize;
  @media (max-width: 768px) {
    min-height: 34px;
  }
`;
const Input = styled.input`
  width: 335px;
  height: 55px;
  background: #ffffff;
  border: 1px solid #424242;
  border-color: ${(props) => props.color || "#424242"};
  border-radius: 5px;
  outline: none;
  padding: 32px 20px 17px;
  font-size: 15px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 10px 19px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 34px;
    padding: 17px 10px 5px;
    font-size: 13px;
    line-height: 13px;
  }
`;
const Label = styled.label`
  font-size: 16px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 10px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 11.5px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 3px;
      left: 10px;
      font-weight: 500;
      line-height: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
