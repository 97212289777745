import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";
import { userContext } from "../../context/userContext";
import { Orders } from "../Interfaces";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default function useCertificationAllOrders() {
  const [certificationOrders, setCertificationOrders] = useState<Orders[]>([]);
  const [initialCertificationOrders, setInitialCertificationOrders] = useState<Orders[]>([]);
  const [certificationOrdersFiltered, setCertificationOrdersFiltered] = useState<Orders[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [certificationOrdersPerPage, setCertificationOrdersPerPage] = useState<number>(10);
  const [refreshCertificationOrders, setRefreshCertificationOrders] = useState<boolean>(true);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [certificationOrderToDelete, setCertificationOrderToDelete] = useState<Orders | null>(null);
  const [certificationOrderToPrint, setCertificationOrderToPrint] = useState<Orders | null>(null);
  const getData = useAxios();
  const [createNewCertificationOrder, setCreateNewCertificationOrder] = useState<boolean>(false);
  const [certifiers, setCertifiers] = useState<any>([]);
  const [EECCS, setEECCS] = useState<any>([]);
  const [agentName, setAgentName] = useState<string>("");
  const [EECCName, setEECCName] = useState<string>("");
  const [orderType, setOrderType] = useState<string>("");
  const [site, setSite] = useState<string>("");
  const [state, setState] = useState<string>("");
  const printContainer = useRef(null) as any;
  const fontContainer = useRef(null) as any;
  const fontTitle = useRef(null) as any;
  const fontDate = useRef(null) as any;
  const fontSignature = useRef(null) as any;
  const [fieldSorted, setFieldSorted] = useState("orders_id");
  const [orderSorted, setOrderSorted] = useState("asc");
  const {
    userState: { user },
  } = useContext(userContext);
  const navigate = useNavigate();
  const { setNotification } = useContext(notificationsContext);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response: Orders[] = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/orders/all`,
        headers: {
          accept: "*/*",
        },
      });
      const responseEECC: any = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/eecc`,
        headers: {
          accept: "*/*",
        },
      });
      const responseCertifiers: any = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/certifiers`,
        headers: {
          accept: "*/*",
        },
      });
      const arrayEECC: any = [];
      const arrayCertifiers: any = [];
      responseEECC.forEach((eecc: any) => {
        arrayEECC[eecc.users_id] = eecc;
      });
      responseCertifiers.forEach((certifier: any) => {
        arrayCertifiers[certifier.users_id] = certifier;
      });
      response.sort((a: any, b: any) => {
        const dateA = new Date(a.creation_date._seconds * 1000 +
          a.creation_date._nanoseconds / 1000000);
        const dateB = new Date(b.creation_date._seconds * 1000 +
          b.creation_date._nanoseconds / 1000000);
        return dateB.getTime() - dateA.getTime();

      });
      setCertifiers(arrayCertifiers);
      setEECCS(arrayEECC);
      setCertificationOrders(response);
      setCertificationOrdersFiltered(response);
      setInitialCertificationOrders(response);
      setTotalPages(Math.ceil(response.length / certificationOrdersPerPage));
      handlePage(1, response);
      setLoading(false);
      setRefreshCertificationOrders(false);
    }
    if ((certificationOrders.length === 0 || refreshCertificationOrders) && user) {
      fetchData();
    }
  }, [refreshCertificationOrders, user]); // eslint-disable-line


  function handleEnter(e: any) {
    if (e.key === "Enter") {
      handleFilter(e);
    }
  }
  useEffect(() => {
    const newAgentsArray = sortItems(certificationOrdersFiltered, fieldSorted, orderSorted);
    handlePage(1, newAgentsArray);
  }, [fieldSorted, orderSorted]); // eslint-disable-line

  function handleFilter(e: any) {
    const agentNameFilter = initialCertificationOrders.filter((certifier: Orders) => {
      return certifier?.agent_name?.toLowerCase().includes(agentName.toLowerCase());
    });
    const EECCFilter = agentNameFilter.filter((certifier: any) => {
      const EECC = EECCS[certifier.EECC_id]
        ? `${EECCS[certifier.EECC_id].first_name} ${EECCS[certifier.EECC_id].last_name
        }`
        : "";
      return EECC?.toLowerCase().includes(EECCName.toLowerCase());
    });
    const orderTypeFilter = EECCFilter.filter((certifier: Orders) => {
      return certifier?.type?.toLowerCase().includes(orderType.toLowerCase());
    });
    const workSiteFilter = orderTypeFilter.filter((certifier: Orders) => {
      return certifier?.work?.toLowerCase().includes(site.toLowerCase());
    });
    const stateFilter = workSiteFilter.filter((certifier: Orders) => {
      return certifier?.state?.toLowerCase().includes(state.toLowerCase());
    });
    setCertificationOrders(stateFilter);
  }

  function handleDocumentsPerPage(
    documentsPerPage: number,
    documents: Orders[] = certificationOrdersFiltered
  ) {
    setTotalPages(Math.ceil(documents.length / documentsPerPage));
    handlePage(1, documents, documentsPerPage);
  }

  function handlePage(
    page: number,
    documents = certificationOrdersFiltered,
    totalDocumentsPerPage = certificationOrdersPerPage
  ) {
    setCertificationOrdersPerPage(totalDocumentsPerPage);
    setPage(page);
    const documentsSorted = documents;
    const indexOfLastDocument = page * totalDocumentsPerPage;
    const indexOfFirstDocument = indexOfLastDocument - totalDocumentsPerPage;
    const currentDocuments = documentsSorted.slice(indexOfFirstDocument, indexOfLastDocument);
    setCertificationOrders(currentDocuments);
  }

  function setQuestionnaireIdToDelete(quest: Orders | null) {
    setCertificationOrderToDelete(quest);
  }

  async function deleteQuestionnaire(quest: Orders) {
    setLoadingDelete(true);
    try {
      const response: { message: string } = await getData({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/orders/${quest.orders_id}`,
        headers: {
          accept: "*/*",
        },
      });
      if (response.message === "Order deleted") {
        setNotification({
          theme: "success",
          titleNotification: "Certificación Eliminada",
          contentNotification: "La orden se eliminó correctamente.",
          isVisible: true,
        });
        setRefreshCertificationOrders(true);
        setCertificationOrderToDelete(null);
        setLoadingDelete(false);
      } else {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: "No se pudo eliminar la orden.",
          isVisible: true,
        });
      }
      setCertificationOrderToDelete(null);
      setLoadingDelete(false);
    } catch (error) {
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: "No se pudo eliminar la orden.",
        isVisible: true,
      });
      setCertificationOrderToDelete(null);
      setLoadingDelete(false);
    }
  }
  function exportPdf() {
    let f = new Date();
    printContainer.current.style.width = "297mm";
    printContainer.current.style.height = "210mm";
    fontContainer.current.style.fontSize = "24px";
    fontContainer.current.style.lineHeight = "24px";
    fontTitle.current.style.fontSize = "32px";
    fontTitle.current.style.lineHeight = "32px";
    fontDate.current.style.fontSize = "24px";
    fontDate.current.style.lineHeight = "24px";
    fontSignature.current.style.fontSize = "24px";
    fontSignature.current.style.lineHeight = "24px";
    setTimeout(() => {
      html2canvas(printContainer.current, {
        useCORS: true,
      }).then(function (canvas) {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("l", "mm", "a4");
        pdf.addImage(imgData, "PNG", 0, 0, 297, 210);
        pdf.save("Certificado" + f.getDate() + "_" + (f.getMonth() + 1) + "_" + f.getFullYear() + ".pdf");
        printContainer.current.style.width = "calc(90vw - 261px)";
        printContainer.current.style.height = "70vh";
        fontContainer.current.style.fontSize = "16px";
        fontContainer.current.style.lineHeight = "16px";
        fontTitle.current.style.fontSize = "25px";
        fontTitle.current.style.lineHeight = "25px";
        fontDate.current.style.fontSize = "15px";
        fontDate.current.style.lineHeight = "15px";
        fontSignature.current.style.fontSize = "15px";
        fontSignature.current.style.lineHeight = "15px";
      });
    }, 200)
  }
  const handleSort = (field: string) => {
    let order = "asc";
    if (fieldSorted === field) {
      order = orderSorted === "asc" ? "desc" : "asc";
      setOrderSorted(order);
    } else {
      setFieldSorted(field);
      setOrderSorted(order);
    }
  };



  function sortItems(
    array: any = certificationOrdersFiltered,
    field: string = fieldSorted,
    order: string = orderSorted
  ) {
    if (field === "EECC") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          const aName = EECCS[a.EECC_id]
            ? `${EECCS[a.EECC_id].first_name} ${EECCS[a.EECC_id].last_name
            }`
            : "ZZZZZZZZZZZZZ";
          const bName = EECCS[b.EECC_id]
            ? `${EECCS[b.EECC_id].first_name} ${EECCS[b.EECC_id].last_name
            }`
            : "ZZZZZZZZZZZZZ";
          if (aName > bName) return 1;
          if (aName < bName) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          const aName = EECCS[a.EECC_id]
            ? `${EECCS[a.EECC_id].first_name} ${EECCS[a.EECC_id].last_name
            }`
            : "ZZZZZZZZZZZZZ";
          const bName = EECCS[b.EECC_id]
            ? `${EECCS[b.EECC_id].first_name} ${EECCS[b.EECC_id].last_name
            }`
            : "ZZZZZZZZZZZZZ";
          if (aName < bName) return 1;
          if (aName > bName) return -1;
          return 0;
        });
      }
    } else if (field === "CERTIFIER") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          const aName = certifiers[a.certifier_id]
            ? `${certifiers[a.certifier_id].first_name} ${certifiers[a.certifier_id].last_name
            }`
            : "ZZZZZZZZZZZZZ";
          const bName = certifiers[b.certifier_id]
            ? `${certifiers[b.certifier_id].first_name} ${certifiers[b.certifier_id].last_name
            }`
            : "ZZZZZZZZZZZZZ";
          if (aName > bName) return 1;
          if (aName < bName) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          const aName = certifiers[a.certifier_id]
            ? `${certifiers[a.certifier_id].first_name} ${certifiers[a.certifier_id].last_name
            }`
            : "ZZZZZZZZZZZZZ";
          const bName = certifiers[b.certifier_id]
            ? `${certifiers[b.certifier_id].first_name} ${certifiers[b.certifier_id].last_name
            }`
            : "ZZZZZZZZZZZZZ";
          if (aName < bName) return 1;
          if (aName > bName) return -1;
          return 0;
        });
      }
    } else if (field === "creation_date") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          const aName = new Date(
            a.creation_date._seconds * 1000 +
            a.creation_date._nanoseconds / 1000000
          )
          const bName = new Date(
            b.creation_date._seconds * 1000 +
            b.creation_date._nanoseconds / 1000000
          )
          if (aName > bName) return 1;
          if (aName < bName) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          const aName = new Date(
            a.creation_date._seconds * 1000 +
            a.creation_date._nanoseconds / 1000000
          )
          const bName = new Date(
            b.creation_date._seconds * 1000 +
            b.creation_date._nanoseconds / 1000000
          )
          if (aName < bName) return 1;
          if (aName > bName) return -1;
          return 0;
        });
      }
    } else if (field === "certification_date") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          const aName = a?.certification_date
          const bName = b?.certification_date
          if (aName > bName) return 1;
          if (aName < bName) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          const aName = a?.certification_date
          const bName = b?.certification_date
          if (aName < bName) return 1;
          if (aName > bName) return -1;
          return 0;
        });
      }
    } else if (field === "percentageAproved") {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          const aName = a?.percentageAproved ? a?.percentageAproved : 0
          const bName = b?.percentageAproved ? b?.percentageAproved : 0
          if (aName > bName) return 1;
          if (aName < bName) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          const aName = a?.percentageAproved ? a?.percentageAproved : 0
          const bName = b?.percentageAproved ? b?.percentageAproved : 0
          if (aName < bName) return 1;
          if (aName > bName) return -1;
          return 0;
        });
      }
    } else {
      if (order === "asc") {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() > b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() < b[field].toLowerCase()) return -1;
          return 0;
        });
      } else {
        array.sort(function (a: any, b: any) {
          if (a[field].toLowerCase() < b[field].toLowerCase()) return 1;
          if (a[field].toLowerCase() > b[field].toLowerCase()) return -1;
          return 0;
        });
      }
    }

    return array;
  }
  return {
    document,
    certificationOrders,
    loading,
    certificationOrdersPerPage,
    handleDocumentsPerPage,
    page,
    handlePage,
    totalPages,
    setRefreshCertificationOrders,
    user,
    createNewCertificationOrder,
    setCreateNewCertificationOrder,
    certificationOrderToDelete,
    loadingDelete,
    setCertificationOrderToDelete,
    setQuestionnaireIdToDelete,
    navigate,
    deleteQuestionnaire,
    EECCS,
    certifiers,
    agentName,
    setAgentName,
    EECCName,
    setEECCName,
    orderType,
    setOrderType,
    site,
    setSite,
    state,
    setState,
    handleEnter,
    handleFilter,
    certificationOrderToPrint,
    setCertificationOrderToPrint,
    printContainer,
    exportPdf,
    fontContainer,
    fontTitle,
    fontSignature,
    fontDate,
    handleSort,
    orderSorted,
    fieldSorted,
  };
}
