import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import ArticleIcon from "../../assets/img/articleIcon.svg";
import FilterIcon from "../../assets/img/filterIcon.svg";
import Pagination from "../../commonComponents/Pagination";
import { Document, DocumentType } from "../Interfaces";
import EditIcon from "../../assets/img/editRole.svg";
import DocumentsForm from "../components/DocumentsForm";
import useDocuments from "../hooks/useDocuments";
import Tooltip from "../../commonComponents/Tooltip";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ButtonComponent from "./../../commonComponents/Button";
import AddIcon from "../../assets/img/addIcon.svg";
import Modal from "../../commonComponents/Modal";
import TrashIcon from "../../assets/img/trashIcon.svg";
import BindingIcon from "../../assets/img/bindingIcon.svg";
import ViewIcon from "../../assets/img/viewIcon.svg";

export default function Documents() {
  const {
    document,
    setDocument,
    documentType,
    setDocumentType,
    version,
    setVersion,
    documents,
    loading,
    handleFilter,
    setDocumentIdToEdit,
    documentsPerPage,
    handleDocumentsPerPage,
    page,
    handlePage,
    totalPages,
    documentIdToEdit,
    setRefreshDocuments,
    handleEnter,
    handleSort,
    orderSorted,
    fieldSorted,
    createNewDocument,
    setCreateNewDocument,
    documentToDelete,
    loadingDelete,
    setDocumentToDelete,
    deleteDocument,
    setDocumentIdToDelete,
    optionsType,
    user,
    navigate,
  } = useDocuments();
  return (
    <Container>
      <Title>Documentos</Title>
      {user?.roles_id !== 4 && (
        <CreateDocumentContainer>
          <ButtonComponent
            type="primary"
            onClick={() => setCreateNewDocument(true)}
          >
            <ButtonTextContainer>
              <img src={AddIcon} alt="add icon" /> NUEVO DOCUMENTO
            </ButtonTextContainer>
          </ButtonComponent>
        </CreateDocumentContainer>
      )}
      <MainContainer>
        <TopContainer>
          <IconContainer>
            <img src={ArticleIcon} alt="articleIcon" />
          </IconContainer>
        </TopContainer>
        <FilterContainer>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={document}
              onChange={({ target: { value } }) => setDocument(value)}
            />
            <Label>Documento</Label>
          </FieldContainer>
          <FilterSelect>
            <FilterSelectField
              value={documentType}
              onChange={({ target: { value } }) => setDocumentType(+value)}
            >
              <Option value={999}>Todos</Option>
              {optionsType?.map((option: DocumentType) => (
                <Option value={option.document_types_id}>{option.name}</Option>
              ))}
            </FilterSelectField>
            <LabelSelect>Tipo</LabelSelect>
          </FilterSelect>
          <FieldContainer>
            <Input
              required
              onKeyDown={handleEnter}
              value={version}
              onChange={({ target: { value } }) => setVersion(value)}
            />
            <Label>Versión</Label>
          </FieldContainer>
          <FilterButton onClick={handleFilter}>
            <img src={FilterIcon} alt="filter Icon" />
            <FilterButtonText>Filtrar</FilterButtonText>
          </FilterButton>
        </FilterContainer>
        <TableContainer>
          <Table>
            <thead>
              <TRH>
                <TH onClick={() => handleSort("name")}>
                  <THContainer>
                    Documento
                    <ArrowsIcon>
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          transform: "rotateZ(180deg)",
                          color:
                            fieldSorted === "name" && orderSorted === "desc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                      <PlayArrowIcon
                        style={{
                          transition: "all 0.3s ease-in-out",
                          width: "14px",
                          height: "14px",
                          color:
                            fieldSorted === "name" && orderSorted === "asc"
                              ? "#128297"
                              : "#9797975f",
                        }}
                      />
                    </ArrowsIcon>
                  </THContainer>
                </TH>
                <TH>Tipo</TH>
                <TH>Versión</TH>
                <TH>Link Repositorio</TH>
                <TH>ACCIONES</TH>
              </TRH>
            </thead>
            <tbody>
              {documents?.map((doc: Document) => (
                <TR key={doc.documents_id}>
                  <TD>{doc.name}</TD>
                  <TD>
                    {doc?.document_type?.name
                      ? doc.document_type.name
                      : "Sin Tipo"}
                  </TD>
                  <TD>{doc.version}</TD>
                  <TD style={{ textTransform: "none" }}>
                    <a
                      href={
                        doc.link.includes("http")
                          ? doc.link
                          : "http://" + doc.link
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {doc.link}
                    </a>
                  </TD>
                  <TD>
                    {user?.role?.edit_documents && (
                      <ActionsContainer>
                        <Tooltip text="Modificar Documento" width={106}>
                          <Button
                            onClick={() =>
                              setDocumentIdToEdit("" + doc.documents_id)
                            }
                          >
                            <img src={EditIcon} alt="edit Icon" />
                          </Button>
                        </Tooltip>
                        <Tooltip text="Eliminar Tipo de Documento">
                          <Button onClick={() => setDocumentIdToDelete(doc)}>
                            <img src={TrashIcon} alt="trash Icon" />
                          </Button>
                        </Tooltip>
                        <Tooltip text="Vincular Documento">
                          <Button
                            onClick={() =>
                              navigate(`/documents/binding/${doc.documents_id}`)
                            }
                          >
                            <img src={BindingIcon} alt="binding Icon" />
                          </Button>
                        </Tooltip>
                        <Tooltip text="Estado">
                          <Button
                            onClick={() =>
                              navigate(`/documents/status/${doc.documents_id}`)
                            }
                          >
                            <img src={ViewIcon} alt="status Icon" />
                          </Button>
                        </Tooltip>
                      </ActionsContainer>
                    )}
                  </TD>
                </TR>
              ))}
            </tbody>
          </Table>
        </TableContainer>
        {loading && (
          <>
            <Loading>
              <CircularProgress
                sx={{
                  color: "#128297",
                }}
                size={100}
              />
            </Loading>
          </>
        )}
        <PaginationContainer>
          <SelectDocumentsPerPageContainer>
            <SelectDocumentsPerPage>
              <SelectDocumentsPerPageField
                value={documentsPerPage}
                onChange={({ target: { value } }) =>
                  handleDocumentsPerPage(+value)
                }
              >
                <OptionPage value={10}>10 filas</OptionPage>
                <OptionPage value={25}>25 filas</OptionPage>
                <OptionPage value={50}>50 filas</OptionPage>
                <OptionPage value={100}>100 filas</OptionPage>
              </SelectDocumentsPerPageField>
            </SelectDocumentsPerPage>
          </SelectDocumentsPerPageContainer>
          <Pagination
            page={page}
            handlePage={handlePage}
            totalPages={totalPages}
          />
        </PaginationContainer>
      </MainContainer>
      {documentIdToEdit && (
        <DocumentsForm
          documentIdToEdit={documentIdToEdit}
          setDocumentIdToEdit={setDocumentIdToEdit}
          setRefreshDocuments={setRefreshDocuments}
          setCreateNewDocument={setCreateNewDocument}
        />
      )}
      {createNewDocument && (
        <DocumentsForm
          setCreateNewDocument={setCreateNewDocument}
          setDocumentIdToEdit={setDocumentIdToEdit}
          setRefreshDocuments={setRefreshDocuments}
        />
      )}
      {documentToDelete && (
        <Modal type="type" title={documentToDelete.name}>
          <ModalContainer
            style={{
              justifyContent: loadingDelete ? "center" : "space-between",
            }}
          >
            {loadingDelete ? (
              <Loading>
                <CircularProgress
                  sx={{
                    color: "#128297",
                  }}
                  size={100}
                />
              </Loading>
            ) : (
              <>
                <DeleteDocumentText>
                  Está seguro que desea eliminar el documento{" "}
                  {documentToDelete.name}?
                </DeleteDocumentText>
                <ButtonsModal>
                  <ButtonComponent
                    type="secondary"
                    onClick={() => setDocumentToDelete(null)}
                  >
                    VOLVER
                  </ButtonComponent>
                  <ButtonComponent
                    type="primary"
                    onClick={() => deleteDocument(documentToDelete)}
                  >
                    ACEPTAR
                  </ButtonComponent>
                </ButtonsModal>
              </>
            )}
          </ModalContainer>
        </Modal>
      )}
    </Container>
  );
}
const THContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  color: #128297;
  text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const ArrowsIcon = styled.div`
  display: flex;
  flex-direction: row;
  transform: rotateZ(90deg);
`;
const Container = styled.div`
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  margin-top: 38px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
  }
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 60px 10px 25px 10px;
  margin-bottom: 60px;
  position: relative;
  margin-top: 50px;
  @media (max-width: 768px) {
    margin-top: 40px;
    padding: 30px 10px 25px 10px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: -17px;
    left: 10px;
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2px;
  @media (max-width: 768px) {
    min-width: 900px;
  }
`;
const TableContainer = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const TR = styled.tr`
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
`;
const TRH = styled.tr`
  :nth-child(even) {
    background-color: #f9f9f9;
  }
`;
const TD = styled.td`
  padding: 14px 0px 13px 20px;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  text-transform: capitalize;
`;
const TH = styled.th`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  padding: 23px 20px;
  color: #128297;
  text-transform: uppercase;
  text-align: start;
  :last-of-type {
    text-align: end;
    width: 150px;
  }
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const FilterButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 167px;
  height: 55px;
  border: none;
  background: #128297;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  text-transform: uppercase;
  gap: 23px;
  padding: 0 0 0 23px;
  :hover {
    filter: brightness(90%);
  }
  @media (max-width: 768px) {
    min-width: 120px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
    & > img {
      width: 15px;
      height: 15px;
    }
  }
`;
const FilterButtonText = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  @media (max-width: 768px) {
    font-size: 13px;
    line-height: 13px;
  }
`;
const Button = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #128297;
  :hover {
    filter: brightness(90%);
  }
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
`;
const SelectDocumentsPerPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const SelectDocumentsPerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OptionPage = styled.option`
  font-size: 14px;
  color: #383838;
`;
const SelectDocumentsPerPageField = styled.select`
  width: 80px;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  padding: 8px 10px 8px 20px;
  font-size: 14px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  cursor: pointer;
`;
const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const FieldContainer = styled.div`
  position: relative;
  width: 100%;
`;
const Input = styled.input`
  width: 100%;
  border: 1px solid #424242;
  height: 55px;
  border-radius: 5px;
  border-radius: 5px;
  outline: none;
  padding: 20px;
  font-size: 15px;
  color: #128297;
  transition: 0.3s ease all;
  &:focus {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const Label = styled.label`
  font-size: 15px;
  position: absolute;
  pointer-events: none;
  top: 20px;
  left: 20px;
  color: ${(props) => props.color || "#128297"};
  transition: 0.3s ease all;
  ${Input}:focus ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  ${Input}:not(:focus):valid ~ & {
    top: 5px;
    left: 20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #424242;
  }
  @media (max-width: 768px) {
    top: 14px;
    left: 10px;
    font-size: 13px;
    line-height: 13px;
    ${Input}:focus ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
    ${Input}:not(:focus):valid ~ & {
      top: 4px;
      left: 10px;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;
      color: #424242;
    }
  }
`;
const CreateDocumentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
  gap: 20px;
`;
const ButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;
const ModalContainer = styled.div`
  width: 450px;
  height: 331px;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
  }
`;
const DeleteDocumentText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #3c4858;
  margin-top: 110px;
  @media (max-width: 768px) {
    text-align: center;
  }
`;
const ButtonsModal = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
  gap: 20px;
`;
const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
const FilterSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  border-radius: 4px;
  color: #128297;
  font-size: 14px;
  outline: none;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const FilterSelectField = styled.select`
  width: 100%;
  height: 55px;
  border: 1px solid #424242;
  border-radius: 4px;
  padding: 0 10px;
  font-size: 14px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  color: #128297;
  @media (max-width: 768px) {
    width: 150px;
    height: 40px;
    font-size: 13px;
    line-height: 13px;
    padding: 10px;
  }
`;
const LabelSelect = styled.label`
  position: absolute;
  top: 5px;
  left: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  @media (max-width: 768px) {
    top: 10px;
    left: 10px;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    color: #424242;
  }
`;
const Option = styled.option`
  font-size: 14px;
  color: #128297;
  padding: 10px 0px;
`;
