import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAxios from "../../commonHooks/useAxios";
import { notificationsContext } from "../../context/notificationsContext";
import { userContext } from "../../context/userContext";
import { Questionnaire } from "../Interfaces";

export default function useQuestionnaires() {
  const [questionnaires, setQuestionnaires] = useState<Questionnaire[]>([]);
  const [questionnairesFiltered, setQuestionnairesFiltered] = useState<Questionnaire[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [questionnairesPerPage, setQuestionnairesPerPage] = useState<number>(10);
  const [refreshQuestionnaires, setRefreshQuestionnaires] = useState<boolean>(true);
  const [loadingDelete, setLoadingDelete] = useState<boolean>(false);
  const [loadingDuplicate, setLoadingDuplicate] = useState<boolean>(false);
  const [questionnaireToDelete, setQuestionnaireToDelete] = useState<Questionnaire | null>(null);
  const [questionnaireToDuplicate, setQuestionnaireToDuplicate] = useState<Questionnaire | null>(null);
  const [questionnaireIdToEdit, setQuestionnaireIdToEdit] = useState<string | null>(
    null
  );
  const getData = useAxios();
  const [createNewQuestionnaire, setCreateNewQuestionnaire] = useState<boolean>(false);
  const {
    userState: { user },
  } = useContext(userContext);
  const navigate = useNavigate();
  const { setNotification } = useContext(notificationsContext);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const response: Questionnaire[] = await getData({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}/questionnaires`,
        headers: {
          accept: "*/*",
        },
      });
      setQuestionnaires(response);
      setQuestionnairesFiltered(response);
      setTotalPages(Math.ceil(response.length / questionnairesPerPage));
      handlePage(1, response);
      setLoading(false);
      setRefreshQuestionnaires(false);
    }
    if ((questionnaires.length === 0 || refreshQuestionnaires) && user) {
      fetchData();
    }
  }, [refreshQuestionnaires, user]); // eslint-disable-line


  function handleDocumentsPerPage(
    documentsPerPage: number,
    documents: Questionnaire[] = questionnairesFiltered
  ) {
    setTotalPages(Math.ceil(documents.length / documentsPerPage));
    handlePage(1, documents, documentsPerPage);
  }

  function handlePage(
    page: number,
    documents = questionnairesFiltered,
    totalDocumentsPerPage = questionnairesPerPage
  ) {
    setQuestionnairesPerPage(totalDocumentsPerPage);
    setPage(page);
    const documentsSorted = documents;
    const indexOfLastDocument = page * totalDocumentsPerPage;
    const indexOfFirstDocument = indexOfLastDocument - totalDocumentsPerPage;
    const currentDocuments = documentsSorted.slice(indexOfFirstDocument, indexOfLastDocument);
    setQuestionnaires(currentDocuments);
  }

  function setQuestionnaireIdToDelete(quest: Questionnaire | null) {
    setQuestionnaireToDelete(quest);
  }

  async function handleChangeActivatedQuestionnaire(questionnaire: Questionnaire) {
    setLoading(true);
    try {
      const response: { message: string } = await getData({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/questionnaires/activated/${questionnaire.questionnaires_id}`,
        headers: {
          accept: "*/*",
        },
      });
      if (response.message === "Questionnaire updated") {
        setNotification({
          theme: "success",
          titleNotification: "Cuestionario Actualizado",
          contentNotification: "El cuestionario se actualizó correctamente.",
          isVisible: true,
        });
        setLoading(false);
        setRefreshQuestionnaires(true);
      } else {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: "No se pudo actualizar el cuestionario.",
          isVisible: true,
        });
        setLoading(false);
      }
    }
    catch (error) {
      setLoading(false);
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: "No se pudo actualizar el cuestionario.",
        isVisible: true,
      });
    }
  }

  async function deleteQuestionnaire(quest: Questionnaire) {
    setLoadingDelete(true);
    try {
      const response: { message: string } = await getData({
        method: "DELETE",
        url: `${process.env.REACT_APP_API_URL}/questionnaires/${quest.questionnaires_id}`,
        headers: {
          accept: "*/*",
        },
      });
      if (response.message === "Questionnaire deleted") {
        setNotification({
          theme: "success",
          titleNotification: "Cuestionario Eliminado",
          contentNotification: "El cuestionario se eliminó correctamente.",
          isVisible: true,
        });
        setRefreshQuestionnaires(true);
        setQuestionnaireToDelete(null);
        setLoadingDelete(false);
      } else {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: "No se pudo eliminar el cuestionario.",
          isVisible: true,
        });
      }
      setQuestionnaireToDelete(null);
      setLoadingDelete(false);
    } catch (error) {
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: "No se pudo eliminar el cuestionario.",
        isVisible: true,
      });
      setQuestionnaireToDelete(null);
      setLoadingDelete(false);
    }
  }

  async function duplicateQuestionnaire(quest: Questionnaire) {
    setLoadingDuplicate(true);
    try {
      const response: { message: string } = await getData({
        method: "POST",
        url: `${process.env.REACT_APP_API_URL}/questionnaires/duplicate/${quest.questionnaires_id}`,
        headers: {
          accept: "*/*",
        },
      });
      if (response.message === "Questionnaire duplicated") {
        setNotification({
          theme: "success",
          titleNotification: "Cuestionario Duplicado",
          contentNotification: "El cuestionario se duplicó correctamente.",
          isVisible: true,
        });
        setRefreshQuestionnaires(true);
        setQuestionnaireToDuplicate(null);
        setLoadingDuplicate(false);
      } else {
        setNotification({
          theme: "error",
          titleNotification: "Error",
          contentNotification: "No se pudo duplicar el cuestionario.",
          isVisible: true,
        });
      }
      setQuestionnaireToDuplicate(null);
      setLoadingDuplicate(false);
    } catch (error) {
      setNotification({
        theme: "error",
        titleNotification: "Error",
        contentNotification: "No se pudo duplicar el cuestionario.",
        isVisible: true,
      });
      setQuestionnaireToDuplicate(null);
      setLoadingDuplicate(false);
    }
  }

  return {
    document,
    questionnaires,
    loading,
    questionnairesPerPage,
    handleDocumentsPerPage,
    page,
    handlePage,
    totalPages,
    setRefreshQuestionnaires,
    user,
    createNewQuestionnaire,
    setCreateNewQuestionnaire,
    questionnaireToDelete,
    loadingDelete,
    setQuestionnaireToDelete,
    setQuestionnaireIdToDelete,
    questionnaireIdToEdit,
    setQuestionnaireIdToEdit,
    navigate,
    handleChangeActivatedQuestionnaire,
    deleteQuestionnaire,
    questionnaireToDuplicate, 
    setQuestionnaireToDuplicate,
    loadingDuplicate,
    duplicateQuestionnaire
  };
}
