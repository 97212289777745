import { CircularProgress } from "@mui/material";
import styled from "styled-components";
import Pagination from "../../commonComponents/Pagination";
import { Questionnaire } from "../Interfaces";
import EditIcon from "../../assets/img/editRole.svg";
import EditQuest from "../../assets/img/editQuest.svg";
import QuestionarieForm from "../components/QuestionarieForm";
import useQuestionnaires from "../hooks/useQuestionnaires";
import Tooltip from "../../commonComponents/Tooltip";
import ButtonComponent from "../../commonComponents/Button";
import AddIcon from "../../assets/img/addIcon.svg";
import Modal from "../../commonComponents/Modal";
import TrashIcon from "../../assets/img/trashIcon.svg";
import ViewIcon from "../../assets/img/viewIcon.svg";
import FeedIcon from "../../assets/img/questionnaireIcon.svg";
import PasteIcon from "../../assets/img/pasteQuestionnaire.svg";
import Toggle from "../../commonComponents/Toggle";
export default function Questionnaires() {
  const {
    questionnaires,
    loading,
    questionnairesPerPage,
    handleDocumentsPerPage,
    page,
    handlePage,
    totalPages,
    setRefreshQuestionnaires,
    createNewQuestionnaire,
    setCreateNewQuestionnaire,
    questionnaireToDelete,
    loadingDelete,
    setQuestionnaireToDelete,
    setQuestionnaireIdToDelete,
    questionnaireIdToEdit,
    setQuestionnaireIdToEdit,
    handleChangeActivatedQuestionnaire,
    deleteQuestionnaire,
    navigate,
    questionnaireToDuplicate,
    setQuestionnaireToDuplicate,
    loadingDuplicate,
    duplicateQuestionnaire,
  } = useQuestionnaires();
  return (
    <Container>
      <Title>Listado de Cuestionarios</Title>
      <CreateDocumentContainer>
        <ButtonComponent
          type="primary"
          onClick={() => setCreateNewQuestionnaire(true)}
        >
          <ButtonTextContainer>
            <img src={AddIcon} alt="add icon" /> NUEVO CUESTIONARIO
          </ButtonTextContainer>
        </ButtonComponent>
      </CreateDocumentContainer>
      <MainContainer>
        <TopContainer>
          <IconContainer>
            <img src={FeedIcon} alt="questionnaire icon" />
          </IconContainer>
        </TopContainer>
        <TableContainer>
          {!loading && (
            <Table>
              <thead>
                <TRH>
                  <TH>ID</TH>
                  <TH>TIPO</TH>
                  <TH>HABILITADO</TH>
                  <TH>ACCIONES</TH>
                </TRH>
              </thead>
              <tbody>
                {questionnaires?.map((quest: Questionnaire) => (
                  <TR key={quest.questionnaires_id}>
                    <TD>{quest.id}</TD>
                    <TD>{quest.type}</TD>
                    <TD>
                      <Toggle
                        onClick={() =>
                          handleChangeActivatedQuestionnaire(quest)
                        }
                        checked={quest.activated}
                      />
                    </TD>
                    <TD>
                      <ActionsContainer>
                        <Tooltip text="Ver Cuestionario">
                          <Button
                            onClick={() =>
                              navigate(
                                `/certification/questtionaires/view/${quest.questionnaires_id}`
                              )
                            }
                          >
                            <img src={ViewIcon} alt="view Icon" />
                          </Button>
                        </Tooltip>
                        <Tooltip text="Modificar Cuestionario" width={106}>
                          <Button
                            onClick={() =>
                              navigate(
                                `/certification/questtionaires/${quest.questionnaires_id}`
                              )
                            }
                          >
                            <img src={EditIcon} alt="edit Icon" />
                          </Button>
                        </Tooltip>
                        <Tooltip text="Duplicar Cuestionario">
                          <Button
                            onClick={() => setQuestionnaireToDuplicate(quest)}
                          >
                            <img src={PasteIcon} alt="paste Icon" />
                          </Button>
                        </Tooltip>
                        <Tooltip text="Eliminar Cuestionario">
                          <Button
                            onClick={() => setQuestionnaireIdToDelete(quest)}
                          >
                            <img src={TrashIcon} alt="trash Icon" />
                          </Button>
                        </Tooltip>
                        <Tooltip text="Renombrar Cuestionario">
                          <Button
                            onClick={() => setQuestionnaireIdToEdit(quest.questionnaires_id)}
                          >
                            <img src={EditQuest} alt="trash Icon" />
                          </Button>
                        </Tooltip>
                      </ActionsContainer>
                    </TD>
                  </TR>
                ))}
              </tbody>
            </Table>
          )}
        </TableContainer>
        {loading && (
          <>
            <Loading>
              <CircularProgress
                sx={{
                  color: "#128297",
                }}
                size={100}
              />
            </Loading>
          </>
        )}
        <PaginationContainer>
          <SelectQuestionnairesPerPageContainer>
            <SelectQuestionnairesPerPage>
              <SelectQuestionnairesPerPageField
                value={questionnairesPerPage}
                onChange={({ target: { value } }) =>
                  handleDocumentsPerPage(+value)
                }
              >
                <OptionPage value={10}>10 filas</OptionPage>
                <OptionPage value={25}>25 filas</OptionPage>
                <OptionPage value={50}>50 filas</OptionPage>
                <OptionPage value={100}>100 filas</OptionPage>
              </SelectQuestionnairesPerPageField>
            </SelectQuestionnairesPerPage>
          </SelectQuestionnairesPerPageContainer>
          <Pagination
            page={page}
            handlePage={handlePage}
            totalPages={totalPages}
          />
        </PaginationContainer>
      </MainContainer>
      {questionnaireIdToEdit && (
        <QuestionarieForm
          questionnaireIdToEdit={questionnaireIdToEdit}
          setQuestionnaireIdToEdit={setQuestionnaireIdToEdit}
          setRefreshQuestionnaires={setRefreshQuestionnaires}
          setCreateNewQuestionnaire={setCreateNewQuestionnaire}
        />
      )}
      {createNewQuestionnaire && (
        <QuestionarieForm
          setCreateNewQuestionnaire={setCreateNewQuestionnaire}
          setQuestionnaireIdToEdit={setQuestionnaireIdToEdit}
          setRefreshQuestionnaires={setRefreshQuestionnaires}
        />
      )}
      {questionnaireToDelete && (
        <Modal type="type" title={questionnaireToDelete.type}>
          <ModalContainer
            style={{
              justifyContent: loadingDelete ? "center" : "space-between",
            }}
          >
            {loadingDelete ? (
              <Loading>
                <CircularProgress
                  sx={{
                    color: "#128297",
                  }}
                  size={100}
                />
              </Loading>
            ) : (
              <>
                <DeleteDocumentText>
                  Está seguro que desea eliminar el cuestionario{" "}
                  {questionnaireToDelete.type}?
                </DeleteDocumentText>
                <ButtonsModal>
                  <ButtonComponent
                    type="secondary"
                    onClick={() => setQuestionnaireToDelete(null)}
                  >
                    VOLVER
                  </ButtonComponent>
                  <ButtonComponent
                    type="primary"
                    onClick={() => deleteQuestionnaire(questionnaireToDelete)}
                  >
                    ACEPTAR
                  </ButtonComponent>
                </ButtonsModal>
              </>
            )}
          </ModalContainer>
        </Modal>
      )}
      {questionnaireToDuplicate && (
        <Modal type="type" title={questionnaireToDuplicate.type}>
          <ModalContainer
            style={{
              justifyContent: loadingDuplicate ? "center" : "space-between",
            }}
          >
            {loadingDuplicate ? (
              <Loading>
                <CircularProgress
                  sx={{
                    color: "#128297",
                  }}
                  size={100}
                />
              </Loading>
            ) : (
              <>
                <DeleteDocumentText>
                  Está seguro que desea duplicar el cuestionario{" "}
                  {questionnaireToDuplicate.type}?
                </DeleteDocumentText>
                <ButtonsModal>
                  <ButtonComponent
                    type="secondary"
                    onClick={() => setQuestionnaireToDuplicate(null)}
                  >
                    VOLVER
                  </ButtonComponent>
                  <ButtonComponent
                    type="primary"
                    onClick={() =>
                      duplicateQuestionnaire(questionnaireToDuplicate)
                    }
                  >
                    ACEPTAR
                  </ButtonComponent>
                </ButtonsModal>
              </>
            )}
          </ModalContainer>
        </Modal>
      )}
    </Container>
  );
}
const Container = styled.div`
  padding: 0 30px;
  @media (max-width: 768px) {
    padding: 0 10px;
  }
`;
const Title = styled.h1`
  font-size: 25px;
  line-height: 25px;
  color: #333333;
  margin-top: 38px;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 15px;
    color: #333333;
    margin-top: 23px;
  }
`;
const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.13);
  border-radius: 6px;
  padding: 60px 10px 25px 10px;
  margin-bottom: 60px;
  position: relative;
  margin-top: 50px;
  @media (max-width: 768px) {
    margin-top: 40px;
    padding: 30px 10px 25px 10px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 5px;
  background-color: #128297;
  color: #fff;
  position: absolute;
  top: -32.5px;
  left: 20px;
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    top: -17px;
    left: 10px;
    & > img {
      width: 17px;
      height: 17px;
    }
  }
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2px;
  @media (max-width: 768px) {
    min-width: 900px;
  }
`;
const TableContainer = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const TR = styled.tr`
  :nth-child(odd) {
    background-color: #f9f9f9;
  }
`;
const TRH = styled.tr`
  :nth-child(even) {
    background-color: #f9f9f9;
  }
`;
const TD = styled.td`
  padding: 14px 0px 13px 20px;
  font-size: 12px;
  line-height: 12px;
  color: #424242;
  text-transform: capitalize;
`;
const TH = styled.th`
  font-family: "D-DIN-PRO-Medium";
  font-size: 12px;
  line-height: 12px;
  padding: 23px 20px;
  color: #128297;
  text-transform: uppercase;
  text-align: start;
  :last-of-type {
    text-align: end;
    width: 150px;
  }
  @media (max-width: 768px) {
    font-size: 10px;
    line-height: 10px;
  }
`;
const Button = styled.button`
  width: 34px;
  height: 34px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: #128297;
  :hover {
    filter: brightness(90%);
  }
`;
const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const Loading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;
`;
const SelectQuestionnairesPerPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  height: 40px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const SelectQuestionnairesPerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OptionPage = styled.option`
  font-size: 14px;
  color: #383838;
`;
const SelectQuestionnairesPerPageField = styled.select`
  width: 80px;
  border: none;
  border-bottom: 1px solid #d2d2d2;
  padding: 8px 10px 8px 20px;
  font-size: 14px;
  outline: none;
  text-indent: 1px;
  cursor: pointer;
`;
const CreateDocumentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
  gap: 20px;
`;
const ButtonTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 20px;
`;
const ModalContainer = styled.div`
  width: 450px;
  height: 331px;
  padding: 0px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
  }
`;
const DeleteDocumentText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #3c4858;
  margin-top: 110px;
  @media (max-width: 768px) {
    text-align: center;
  }
`;
const ButtonsModal = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  align-items: center;
  gap: 20px;
`;
const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;
