import { useContext } from "react";
import { userContext } from "../context/userContext";
import { Navigate } from "react-router-dom";

export default function AdminRoute({ children }: any) {
  const {
    userState: { user },
  } = useContext(userContext);

  if (user) {
    if (user?.roles_id === 1 || user?.roles_id === 2) {
      return children;
    } else {
      return <Navigate to="/" />;
    }
  }
}
